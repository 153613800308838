<div *ngIf="!isDialog" class="row">
  <div class="col-12">
    <form
      mat-autocomplete
      class="form-horizontal"
      role="form"
      action=" "
      method="post"
      [formGroup]="formValidationRules"
      (ngSubmit)="notifyFormSubmit.emit(formValidationRules.value)"
    >
      <fusion-form-handler
        [formConfigs]="formConfigs"
        [formValidationRules]="formValidationRules"
        [autoCompleteEntities]="autoCompleteEntities"
        [autoCompleteType]="autoCompleteType"
        [entityType]="entityType"
        (notifyAutocompletion)="notifyAutocompletion.emit($event)"
        (notifyFormValidationUpdate)="notifyFormValidationUpdate.emit($event)"
      ></fusion-form-handler>
      <div class="form-group">
        <label class="col-md-5 control-label"></label>
        <div
          *ngIf="!formMetadata.stepper"
          class="col-md-4 t-padding-3"
          [ngClass]="{ 't-padding-7': formMetadata.name === 'SIGN_UP_FORM' }"
        >
          <button
            *ngIf="dialogRef"
            type="submit"
            mat-raised-button
            color="primary"
            (click)="dialogRef.close()"
            [disabled]="!formValidationRules.valid"
          >
            Send
          </button>
          <button
            *ngIf="!dialogRef"
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!formValidationRules.valid || progressState?.isInProgress || progressState?.isSuccess"
          >
            <span [ngClass]="{ 'in-progress': progressState?.isInProgress }">
              <mat-spinner
                *ngIf="progressState?.isInProgress"
                [strokeWidth]="2"
                [diameter]="15"
              ></mat-spinner>
              {{ formMetadata?.submitButtonText }}</span
            >
          </button>
          <div gray8fontcolor nanoFont class="t-padding-3">
            * indicates required field
          </div>
          <div errorFontColor nanoFont>{{ error?.message }}</div>
        </div>

        <div cardVrSpacing *ngIf="formMetadata.stepper" class="">
          <div *ngIf="formMetadata.stepper.isFirstStep">
            <button
              color="primary"
              mat-stroked-button
              matStepperNext
              [disabled]="!formValidationRules.valid"
            >
              Next
            </button>
          </div>
          <div *ngIf="formMetadata.stepper.isLastStep">
            <button mat-button matStepperPrevious>Back</button>
            <button
              color="primary"
              mat-stroked-button
              matStepperNext
              [disabled]="!formValidationRules.valid"
            >
              Submit
            </button>
          </div>
          <div
            *ngIf="
              !formMetadata.stepper.isFirstStep &&
              !formMetadata.stepper.isLastStep
            "
          >
            <button mat-button matStepperPrevious>Back</button>
            <button
              color="primary"
              mat-stroked-button
              matStepperNext
              [disabled]="!formValidationRules.valid"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="isDialog" id="signupbox">
  <div class="panel-info">
    <div class="panel-heading sticky">
      <div class="row panel-title">
        <div class="col-7">{{ formMetadata.title }}</div>
        <div
          *ngIf="dialogRef"
          (click)="dialogRef.close(); formClose()"
          class="col-2 cross-icon"
          matTooltip="Close"
          matTooltipPosition="below"
        ></div>
        <!-- <div *ngIf="formMetadata?.headingLink" class="col-3 heading-link">
          <a href="{{ formMetadata?.headingLink.path }}">{{
            formMetadata?.headingLink.title
          }}</a>
        </div> -->
      </div>
    </div>
    <div class="panel-body padding-7">
      <form
        id="signupform"
        mat-autocomplete
        class="form-horizontal"
        role="form"
        action=" "
        method="post"
        [formGroup]="formValidationRules"
        (ngSubmit)="notifyFormSubmit.emit(formValidationRules.value)"
      >
        <fieldset>
          <fusion-form-handler
            [formConfigs]="formConfigs"
            [formValidationRules]="formValidationRules"
            [autoCompleteEntities]="autoCompleteEntities"
            [autoCompleteType]="autoCompleteType"
            [entityType]="entityType"
            (notifyAutocompletion)="notifyAutocompletion.emit($event)"
          ></fusion-form-handler>

          <div class="row form-button">
            <label class="control-label"></label>
            <div class="">
              <button
                *ngIf="dialogRef"
                type="submit"
                mat-raised-button
                color="primary"
                (click)="dialogRef.close()"
                [disabled]="!formValidationRules.valid"
              >
                Send
              </button>
              <button
                *ngIf="!dialogRef"
                type="submit"
                mat-raised-button
                color="primary"
                [disabled]="!formValidationRules.valid"
              >
                {{ formMetadata?.submitButtonText }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>
