import {
  CurrentJobActionTypes,
  CurrentJobActions,
} from '../actions/current-job.actions';
import { IJob } from '../../models/interfaces/job.interfaces';

export interface CurrentJobState {
  data: IJob;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CurrentJobState = {
  data: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: CurrentJobActions
): CurrentJobState {
  switch (action.type) {
    case CurrentJobActionTypes.LoadCurrentJob: {
      return {
        ...state,
        loading: true,
      };
    }
    case CurrentJobActionTypes.LoadCurrentJobSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case CurrentJobActionTypes.LoadCurrentJobFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
