import {
  IForm,
  FormFieldType,
  NonFormItemType,
  FormDisplayType,
} from '@fusion/form';

export const ReferralsForm: IForm = {
  displayType: FormDisplayType.Modal,
  metadata: {
    title: 'Invite Friends',
    submitButtonText: 'Add Referrals',
    style: {
      topMargin: '0px',
      maxWidth: '600px',
      maxHeight: '300px',
    },
  },
  configs: [
    {
      type: FormFieldType.NonFormItem,
      nonFormItem: {
        label: `Just enter comma separated email addresses of the people you'd like to invite and we'll send them an email.`,
        type: NonFormItemType.Text,
      },
    },
    {
      label: 'Email Addresses',
      name: 'emails',
      type: FormFieldType.ChipList,
      placeholder: 'shakil.khan@dottocircle.com',
      examplePattern: 'shakil.khan@dottocircle.com',
      validation: {
        required: true,
      },
    },
  ],
};
