import { createSelector } from '@ngrx/store';

import { getFusionPaymentState } from '../reducers';
import { getRouterParams } from '@fusion/router';
import {
  getoAuthUserId,
  getoAuthUser,
  getEmployerCompanies,
  getEmployerMosques,
  getRepresentativeCompanies,
  getRepresentativeMosques,
  getoAuthUserFullName,
  getUserType,
} from '@fusion/oauth';
import { IUser, ICompany, UserType } from '@fusion/common';
import {
  IStripeBillingAddress,
  IOrder,
  IOrderItem,
  IOrderCharge,
  IDiscount,
} from '../../models/interfaces';
import { IInvoice, IBilling } from '../../models/interfaces/invoice.interfaces';
import { DiscountType, OrderChargeType } from '../../models/enums';

export const getOrderState = createSelector(getFusionPaymentState, (state) => {
  if (state == null) {
    return null;
  }
  return state.order;
});

export const getOrder = createSelector(
  getOrderState,
  (state): IOrder => {
    if (state == null) {
      return null;
    }
    return state.order;
  }
);

export const getPendingOrders = createSelector(
  getOrderState,
  (state): IOrder[] => {
    if (state == null) {
      return null;
    }
    return state.pendingOrders;
  }
);

export const getOrderItems = createSelector(getOrder, (order): IOrderItem[] => {
  if (order == null) {
    return null;
  }
  return order.items;
});

export const hasPendingOrder = createSelector(
  getOrderItems,
  (items): boolean => {
    if (items == null) {
      return null;
    }
    return items.length ? true : false;
  }
);

export const getOrderItemsSubtotal = createSelector(
  getOrderItems,
  (items): number => {
    if (items == null) {
      return null;
    }

    if (!items.length) {
      return 0;
    }

    return items.reduce((sum, item) => sum + item.price, 0);
  }
);

export const getDiscount = createSelector(
  getOrderState,
  getOrderItemsSubtotal,
  (state, subtotal): IDiscount => {
    if (state == null) {
      return null;
    }

    const discount = state.discount;
    const totalDiscount = discount
      ? discount.type === DiscountType.FixedAmount
        ? discount.amount
        : discount.type === DiscountType.Percentage
        ? (subtotal / 100) * discount.amount
        : 0
      : 0;
    return {
      ...discount,
      calculatedAmount: Math.ceil(totalDiscount),
    };
  }
);

export const getOrderCharges = createSelector(
  getOrderItemsSubtotal,
  getDiscount,
  (subtotal, discount: IDiscount): IOrderCharge[] => {
    return [
      {
        type: OrderChargeType.Subtotal,
        title: 'Subtotal',
        amount: subtotal,
        displayAmount: subtotal,
        currencyCode: '$',
      },
      {
        type: OrderChargeType.Tax,
        title: 'Tax',
        amount: 0.0,
        displayAmount: 0.0,
        currencyCode: '$',
      },
      {
        type: OrderChargeType.Discount,
        title: 'Discount',
        subtitle:
          discount && discount.type === DiscountType.Percentage
            ? `${discount.amount}%`
            : null,
        amount: -discount.calculatedAmount,
        displayAmount: discount.calculatedAmount,
        displaySymbol: discount.calculatedAmount > 0 ? '-' : null,
        currencyCode: '$',
      },
    ];
  }
);

export const getOrderTotal = createSelector(
  getOrderCharges,
  (charges): number => {
    if (charges == null) {
      return null;
    }

    return charges.reduce((sum, item) => sum + item.amount, 0);
  }
);

export const getCustomerType = createSelector(
  getRouterParams,
  getoAuthUserId,
  (params, userId): UserType => {
    if (params == null || userId == null) {
      return null;
    }
    if (params.subscriberId === userId) {
      return UserType.User;
    }
    return UserType.Company;
  }
);

export const getCustomerCompany = createSelector(
  getRouterParams,
  getEmployerCompanies,
  getEmployerMosques,
  getRepresentativeCompanies,
  getRepresentativeMosques,
  (params, userCompanies, userMosques, repCompanies, repMosques): ICompany => {
    if (params == null || userCompanies == null || repCompanies == null) {
      return null;
    }
    const companyId = params.subscriberId || params.companyId;
    const companies = [
      ...userCompanies,
      ...userMosques,
      ...repCompanies,
      ...repMosques,
    ];
    let company: ICompany = companies.find((com) => com.id === companyId);

    return company;
  }
);

export const getUserBillingAddress = createSelector(
  getoAuthUser,
  getoAuthUserFullName,
  (user: IUser, fullName: string): IStripeBillingAddress => {
    if (user == null) {
      return null;
    }

    return {
      name: fullName,
      line1: user.address,
      line2: user.unit ? `Apt/Unit# ${user.unit}` : '',
      city: user.city,
      state: user.state,
      postal_code: user.zipCode,
      country: user.country,
    };
  }
);

export const getCompanyBillingAddress = createSelector(
  getCustomerCompany,
  (company: ICompany): IStripeBillingAddress => {
    if (company == null) {
      return null;
    }

    return {
      name: `${company.name}`,
      line1: company.address,
      line2: company.unit ? `Apt/Unit# ${company.unit}` : '',
      city: company.city,
      state: company.state,
      postal_code: company.zipCode,
      country: company.country,
    };
  }
);

// required for stripe
export const getCustomerAddress = createSelector(
  getUserType,
  getUserBillingAddress,
  getCompanyBillingAddress,
  (
    customerType: string,
    userAddress: IStripeBillingAddress,
    companyAddress: IStripeBillingAddress
  ): IStripeBillingAddress => {
    if (customerType == null || userAddress == null) {
      return null;
    }

    if (customerType === UserType.Company) {
      return companyAddress ? companyAddress : null;
    }
    return userAddress;
  }
);

// required for wallet invoice
export const getBilling = createSelector(
  getUserType,
  getUserBillingAddress,
  getCompanyBillingAddress,
  (
    customerType: string,
    userAddress: IStripeBillingAddress,
    companyAddress: IStripeBillingAddress
  ): IBilling => {
    if (customerType == null) {
      return null;
    }

    if (customerType === UserType.Company) {
      return companyAddress
        ? {
            name: companyAddress.name,
            billTo: {
              address: companyAddress.line1,
              unit: companyAddress.line2,
              city: companyAddress.city,
              zipCode: companyAddress.postal_code,
              state: companyAddress.state,
              country: companyAddress.country,
            },
          }
        : null;
    }

    return {
      name: userAddress.name,
      billTo: {
        address: userAddress.line1,
        unit: userAddress.line2,
        city: userAddress.city,
        zipCode: userAddress.postal_code,
        state: userAddress.state,
        country: userAddress.country,
      },
    };
  }
);

export const getOrderInvoice = createSelector(
  getOrder,
  getOrderItems,
  getOrderCharges,
  getOrderTotal,
  getBilling,
  getDiscount,
  (
    order: IOrder,
    items: IOrderItem[],
    charges: IOrderCharge[],
    orderTotal: number,
    billing: IBilling,
    discunt: IDiscount
  ): IInvoice => {
    if (order == null) {
      return null;
    }

    const invoice: IInvoice = {
      id: order.id,
      referenceId: order.referenceId,
      items,
      charges,
      billing,
      total: {
        type: OrderChargeType.Total,
        title: 'Total',
        amount: orderTotal,
        currencyCode: '$',
      },
    };

    return invoice;
  }
);
