<div class="col2__section-two rowN">
  <div>
    <fusion-payment-balance [balance]="wallet?.total"></fusion-payment-balance>
  </div>
  <div border radius cardSpacing>
    <fusion-payment-load-wallet
      [paymentMethods]="customer?.paymentMethods"
      [currentPayment]="currentPayment"
      [couponBalance]="couponBalance"
      [isSendingPayment]="isSendingPayment"
      (notifyAddBalance)="notifyAddBalance.emit($event)"
      (notifyPaymentAuthorization)="notifyPaymentAuthorization.emit()"
      (notifyResetCurrentPayment)="notifyResetCurrentPayment.emit()"
      (notifyRedeamCoupon)="notifyRedeamCoupon.emit($event)"
      (notifyResetCouponBalance)="notifyResetCouponBalance.emit($event)"
    ></fusion-payment-load-wallet>
  </div>
  <div border radius cardSpacing>
    <fusion-payment-payment-methods
      [paymentMethods]="customer?.paymentMethods"
      [address]="address"
      [clientSecret]="clientSecret"
      [transactions]="customer?.transactions"
      (notifyAddCard)="notifyAddCard.emit($event)"
      (notifyPaymentMethod)="notifyPaymentMethod.emit($event)"
    ></fusion-payment-payment-methods>
  </div>
  <div border radius cardSpacing>
    <fusion-payment-billing-settings
      [address]="address"
    ></fusion-payment-billing-settings>
  </div>
  <div border radius cardSpacing>
    <fusion-payment-wallet-transactions
      [transactions]="wallet?.transactions"
    ></fusion-payment-wallet-transactions>
  </div>
</div>
