import * as constants from "../constants";
import {
  jobLevels,
  jobTypes,
  jobCategories,
  noOfVacancies,
  currencyCode,
  payRates,
  countries
} from "../data";
import { IForm } from "../interfaces";
import { FormFieldType, FormFieldGroup } from "../enums";

export const JobSubmissionForm: IForm = {
  metadata: {
    name: constants.JOB_SUBMISSION_FORM,
    title: "Job Submission",
    submitButtonText: "Post",
    style: {
      topMargin: "50px"
    }
  },
  configs: [
    {
      label: "Job Position",
      name: "position",
      type: FormFieldType.Text,
      placeholder: "General Manager",
      validation: {
        required: true,
        max: 100
      }
    },
    {
      label: "Job Type",
      name: "type",
      type: FormFieldType.Dropdown,
      options: jobTypes,
      validation: {
        required: true
      }
    },
    {
      label: "Job Level",
      name: "level",
      type: FormFieldType.Dropdown,
      options: jobLevels,
      validation: {
        required: true
      }
    },
    {
      label: "Job Category",
      name: "category",
      type: FormFieldType.Dropdown,
      options: jobCategories,
      validation: {
        required: true
      }
    },
    {
      label: "No of Vacancy",
      name: "vacancy",
      type: FormFieldType.Dropdown,
      options: noOfVacancies,
      validation: {
        required: true
      }
    },
    {
      label: "Job Description",
      name: "jobDescription",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: true,
        max: 15000
      }
    },
    {
      label: "Job Responsibility",
      name: "jobResponsibility",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: true,
        max: 15000
      }
    },
    {
      label: "Education Requirements",
      name: "educationRequirements",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: true,
        max: 15000
      }
    },
    {
      label: "Experience Requirements",
      name: "experienceRequirements",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: false,
        max: 15000
      }
    },
    {
      label: "Additional Requirements",
      name: "additionalRequirements",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: false,
        max: 15000
      }
    },
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Salary,
      configs: [
        {
          label: "Salary",
          name: "salary",
          type: FormFieldType.Number,
          placeholder: "$100000/year",
          examplePattern: "12345",
          validation: {
            required: true,
            pattern: "[0-9]+"
          }
        },
        {
          label: "Currency Code",
          name: "currencyCode",
          type: FormFieldType.Dropdown,
          placeholder: "$100000/year",
          options: currencyCode,
          validation: {
            required: true
          }
        },
        {
          label: "Pay Rate",
          name: "salaryUnit",
          type: FormFieldType.Dropdown,
          placeholder: "$100000/year",
          options: payRates,
          validation: {
            required: true
          }
        }
      ]
    },
    {
      label: "",
      type: FormFieldType.Checkbox,
      items: [
        {
          label: "Salary Nigotiable",
          name: "nigotiable",
          value: "nigotiable",
          validation: {
            required: false
          }
        }
      ],
      disable: {
        by: "nigotiable",
        fields: ["salary", "currencyCode", "payRate"]
      }
    },
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: "Job Location",
          name: "address",
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 50
          }
        },
        {
          label: "Unit#",
          name: "unit",
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 10
          }
        },
        {
          label: "City",
          name: "city",
          type: FormFieldType.Text,
          placeholder: "city",
          validation: {
            required: true,
            max: 30
          }
        },
        {
          label: "State",
          name: "state",
          type: FormFieldType.Text,
          validation: {
            required: false
          }
        },
        {
          label: "Zip Code",
          name: "zipCode",
          type: FormFieldType.Text,
          placeholder: "zip code",
          examplePattern: "11432",
          validation: {
            required: true,
            max: 10,
            pattern: "[0-9]+"
          }
        },
        {
          label: "Country",
          name: "country",
          type: FormFieldType.Dropdown,
          options: countries,
          validation: {
            required: true
          }
        }
      ]
    },
    {
      label: "Benifits",
      name: "benifits",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: true,
        max: 15000
      }
    },
    {
      label: "Apply Instruction",
      name: "instructions",
      type: FormFieldType.Ckeditor,
      placeholder: "apply instructions",
      validation: {
        required: false,
        max: 15000
      }
    },
    {
      label: "Application Deadline",
      name: "deadline",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Contact Email",
      name: "email",
      type: FormFieldType.Email,
      placeholder: "shakil.khan@dottocircle.com",
      examplePattern: "shakil.khan@dottocircle.com",
      validation: {
        required: false,
        pattern: "[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\\.[a-zA-Z]+"
      }
    }
  ]
};
