import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import {
  IPaymentMethod,
  ITransaction,
  ICouponBalance,
  IMakePyaymentWithCardPayload
} from "../../models/interfaces";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { StripeService } from "../../stripe/services/stripe.service";
import { PaymentType } from "../../models/enums";

@Component({
  selector: "fusion-payment-funding-payment",
  templateUrl: "./funding-payment.component.html",
  styleUrls: ["./funding-payment.component.scss"]
})
export class FundingPaymentComponent implements OnInit {
  @Output() notifyFundingContribution: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() notifyFundingPaymentAuthorization: EventEmitter<
    any
  > = new EventEmitter<any>();

  @Input() baseUrl: string;
  @Input() paymentMethods: IPaymentMethod[];
  @Input() currentPayment: ITransaction;
  @Input() couponBalance: ICouponBalance;
  @Input() isSendingPayment: boolean;
  @Input() fundingAmount: number;
  @Input() paymentType: PaymentType;

  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  stepper: any;
  cardIndex: number;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _stripe: StripeService
  ) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      amountCtrl: ["", Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      cardCtrl: ["", Validators.required]
    });
  }

  setCardIndex(index: number, stepper) {
    this.stepper = stepper;
    this.cardIndex = index;
    this.secondFormGroup.controls["cardCtrl"].setValue(this.cardIndex);
  }

  authorizeTransaction(paymentMethodId, amount) {
    const balance: IMakePyaymentWithCardPayload = {
      type: this.paymentType,
      cardId: paymentMethodId,
      amount
    };
    this.notifyFundingContribution.emit(balance);
  }

  authorizeCurrentPayment() {
    this._stripe
      .confirmCardPayment(
        this.currentPayment.clientSecret,
        this.currentPayment.paymentMethod
      )
      .subscribe(result => {
        if (result.paymentIntent) {
          this.notifyFundingPaymentAuthorization.emit();
        } else {
          console.log("confirmCardPayment => Error:", result);
        }
      });
  }

  getWalletUrl(baseUrl: string) {
    return `/${baseUrl.split('/')[1]}/wallet`;
  }
}
