import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  PaymentActionTypes,
  PostPaymentSuccess,
  PostPaymentFail,
  PostPayment,
  PostPaymentDeclined,
  NavigateToCheckout,
} from '../actions/payment.actions';
import { PaymentService } from '@fusion/service';
import { Store } from '@ngrx/store';
import { FusionPaymentState } from '../reducers/index';
import { getOrderInvoice } from '../selectors/index';
import { Params, Router } from '@angular/router';
import {
  getRouterParams,
  getRouterUrlRoot,
  getRouterUrl,
} from '@fusion/router';
import { ConfirmOrder } from '../actions/index';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionPaymentError, PaymentCode } from '../../models/enums';
import { IPostPaymentPayload, IInvoice } from '../../models/interfaces';
import { getoAuthUser } from '@fusion/oauth';
import { IUser } from '@fusion/common';

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionPaymentState>,
    private paymentService: PaymentService,
    private router: Router
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentActionTypes.PostPayment),
    withLatestFrom(
      this.store.select(getOrderInvoice),
      this.store.select(getRouterParams),
      this.store.select(getoAuthUser)
    ),
    mergeMap(
      ([{ payload, followUpAction }, invoice, params, user]: [
        PostPayment,
        IInvoice,
        Params,
        IUser
      ]) => {
        let errorPayload: IError<FusionPaymentError> = {
          code: FusionPaymentError.PostPaymentFail,
          source: ErrorSource.Validation,
          data: null,
        };

        const representative = {
          id: user.id,
          name: user.middleName
            ? `${user.firstName} ${user.middleName} ${user.lastName}`
            : `${user.firstName} ${user.lastName}`,
        };
        const payment = {
          order_id: invoice.id,
          wallet_id: payload.walletId,
          description: payload.description,
          application_id: payload.applicationId, // homerent
          customer_id: params.subscriberId,
          amount: invoice.total.amount,
          reference_id: invoice.referenceId,
          invoice,
          representative,
        };
        return this.paymentService.postPayment(payment).pipe(
          switchMap((dataResult) => {
            if (dataResult.code === PaymentCode.Success) {
              if (followUpAction) {
                return [
                  new PostPaymentSuccess(dataResult),
                  new ConfirmOrder(),
                  followUpAction,
                ];
              }
              return [new PostPaymentSuccess(dataResult), new ConfirmOrder()];
            }

            return [new PostPaymentDeclined(dataResult)];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue posting your payment. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new PostPayment(payload)],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new PostPaymentFail(errorPayload));
          })
        );
      }
    )
  ));

  
  navigateToCheckout$ = createEffect(() => this.actions$.pipe(
    ofType<NavigateToCheckout>(PaymentActionTypes.NavigateToCheckout),
    map((action) => action.isRootUrl),
    withLatestFrom(
      this.store.select(getRouterUrlRoot),
      this.store.select(getRouterUrl)
    ),
    tap(([isRootUrl, rootUrl, url]: [boolean, string, string]) => {
      this.router.navigate([`${isRootUrl ? rootUrl : url}/checkout`]);
    })
  ), { dispatch: false });
}
