import { Component, OnInit, Input } from "@angular/core";
import { IInvoice } from "../../models/interfaces";

@Component({
  selector: "fusion-payment-checkout-invoice",
  templateUrl: "./checkout-invoice.component.html",
  styleUrls: ["./checkout-invoice.component.scss"]
})
export class CheckoutInvoiceComponent implements OnInit {
  @Input() invoice: IInvoice;

  constructor() {}

  ngOnInit() {}
}
