import { Action } from "@ngrx/store";
import {
  IWallet,
  ISetupWalletPayload,
  IAddBalancePayload,
  IMakePyaymentWithCardPayload,
  ITransaction,
  ICouponBalance,
  IAuthorizePyaymentPayload
} from "../../models/interfaces";

export enum WalletActionTypes {
  LoadWallet = "[Wallet] Load",
  LoadWalletSuccess = "[Wallet] Load Success",
  LoadWalletFail = "[Wallet] Load Fail",
  SetupWallet = "[Wallet] Setup",
  SetupWalletSuccess = "[Wallet] Setup Success",
  SetupWalletFail = "[Wallet] Setup Fail",
  AddBalance = "[Wallet] Add Balance",
  AddBalanceSuccess = "[Wallet] Add Balance Success",
  AddBalanceFail = "[Wallet] Add Balance Fail",
  MakePaymentWithCard = "[Wallet] Make Payment With Card",
  MakePaymentWithCardSuccess = "[Wallet] Make Payment With Card Success",
  MakePaymentWithCardFail = "[Wallet] Make Payment With Card Fail",
  AuthorizePayment = "[Wallet] Authorize Payment",
  AuthorizePaymentSuccess = "[Wallet] Authorize Payment Success",
  AuthorizePaymentFail = "[Wallet] Authorize Payment Fail",
  ResetCurrentPayment = "[Wallet] Reset Current Payment",
  RedeemCoupon = "[Wallet] Redeem Coupon",
  RedeemCouponSuccess = "[Wallet] Redeem Coupon Success",
  RedeemCouponFail = "[Wallet] Redeem Coupon Fail",
  ResetCouponBalance = "[Wallet] Reset Coupon Balance"
}

export class LoadWallet implements Action {
  readonly type = WalletActionTypes.LoadWallet;
}

export class LoadWalletSuccess implements Action {
  readonly type = WalletActionTypes.LoadWalletSuccess;

  constructor(public payload: IWallet) {}
}

export class LoadWalletFail implements Action {
  readonly type = WalletActionTypes.LoadWalletFail;
}

export class SetupWallet implements Action {
  readonly type = WalletActionTypes.SetupWallet;

  constructor(public payload: ISetupWalletPayload) {}
}

export class SetupWalletSuccess implements Action {
  readonly type = WalletActionTypes.SetupWalletSuccess;

  constructor(public payload: IWallet) {}
}

export class SetupWalletFail implements Action {
  readonly type = WalletActionTypes.SetupWalletFail;
}

export class AddBalance implements Action {
  readonly type = WalletActionTypes.AddBalance;

  constructor(public payload: IAddBalancePayload) {}
}

export class AddBalanceSuccess implements Action {
  readonly type = WalletActionTypes.AddBalanceSuccess;
}

export class AddBalanceFail implements Action {
  readonly type = WalletActionTypes.AddBalanceFail;
}

export class MakePaymentWithCard implements Action {
  readonly type = WalletActionTypes.MakePaymentWithCard;

  constructor(public payload: IMakePyaymentWithCardPayload) {}
}

export class MakePaymentWithCardSuccess implements Action {
  readonly type = WalletActionTypes.MakePaymentWithCardSuccess;

  constructor(public payload: ITransaction) {}
}

export class MakePaymentWithCardFail implements Action {
  readonly type = WalletActionTypes.MakePaymentWithCardFail;
}

export class AuthorizePayment implements Action {
  readonly type = WalletActionTypes.AuthorizePayment;

  constructor(public payload?: IAuthorizePyaymentPayload) {}
}

export class AuthorizePaymentSuccess implements Action {
  readonly type = WalletActionTypes.AuthorizePaymentSuccess;

  constructor(public payload: ITransaction) {}
}

export class AuthorizePaymentFail implements Action {
  readonly type = WalletActionTypes.AuthorizePaymentFail;
}

export class ResetCurrentPayment implements Action {
  readonly type = WalletActionTypes.ResetCurrentPayment;
}

export class RedeemCoupon implements Action {
  readonly type = WalletActionTypes.RedeemCoupon;

  constructor(public payload: string) {}
}

export class RedeemCouponSuccess implements Action {
  readonly type = WalletActionTypes.RedeemCouponSuccess;

  constructor(public payload: ICouponBalance) {}
}

export class RedeemCouponFail implements Action {
  readonly type = WalletActionTypes.RedeemCouponFail;
}

export class ResetCouponBalance implements Action {
  readonly type = WalletActionTypes.ResetCouponBalance;
}

export type WalletActions =
  | LoadWallet
  | LoadWalletSuccess
  | LoadWalletFail
  | SetupWallet
  | SetupWalletSuccess
  | SetupWalletFail
  | MakePaymentWithCard
  | MakePaymentWithCardSuccess
  | MakePaymentWithCardFail
  | AddBalance
  | AddBalanceSuccess
  | AddBalanceFail
  | AuthorizePayment
  | AuthorizePaymentSuccess
  | AuthorizePaymentFail
  | RedeemCoupon
  | RedeemCouponSuccess
  | RedeemCouponFail
  | ResetCouponBalance
  | ResetCurrentPayment;
