<fusion-common-back
  [currentRootPath]="currentRootPath$ | async"
  [buttonText]="'back'"
></fusion-common-back>

<div pageSpacing largePage pageBody>
  <div pageTitle>
    <!-- <h1>Job Application!</h1> -->
  </div>
  <div pageContent>
    <div content>
      <ng-container *ngIf="job$ | async as currentJob">
        <div border radius cardSpacing bgGray1 class="rowN">
          <!-- company logo -->
          <ng-container *ngIf="currentJob.clientCompany; else companyLogo">
            <div class="col2">
              <div centering center smallLogo>
                <img
                  pointer
                  imageFit
                  src="{{
                    currentJob.clientCompany?.logo ||
                      '/assets/images/users/company-logo/logoPlaceholder.png'
                  }}"
                />
              </div>
              <div primaryFontColor>
                <span smallTitle semiBold>{{
                  currentJob.clientCompany.name
                }}</span>
                <div microTitle class="row3__section-two">
                  <span *ngIf="currentJob.address"
                    >{{ currentJob.address }},
                  </span>
                  <span
                    >{{ currentJob.city
                    }}<span *ngIf="currentJob.state"
                      >, {{ currentJob.state }}</span
                    >
                    - {{ currentJob.country }}</span
                  >
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #companyLogo>
            <div class="col2">
              <div centering center smallLogo>
                <img
                  pointer
                  imageFit
                  src="{{
                    currentJob.company?.logo ||
                      '/assets/images/users/company-logo/logoPlaceholder.png'
                  }}"
                />
              </div>
              <div primaryFontColor>
                <span smallTitle semiBold>{{ currentJob.company.name }}</span>
                <div microTitle class="row3__section-two">
                  <span *ngIf="currentJob.address"
                    >{{ currentJob.address }},
                  </span>
                  <span
                    >{{ currentJob.city
                    }}<span *ngIf="currentJob.state"
                      >, {{ currentJob.state }}</span
                    >
                    - {{ currentJob.country }}</span
                  >
                </div>
              </div>
            </div>
          </ng-template>

          <div extraSmallTitle>
            <span semiBold>Applying for:</span>
            <span> {{ currentJob.position }}</span>
          </div>
          <div>
            <fusion-form-template
              [isDialog]="false"
              [formValidationRules]="descriptionValidationRules"
              [formConfigs]="descriptionConfigs"
              [formMetadata]="descriptionMetadata"
              (notifyFormSubmit)="notifyDescription($event)"
            ></fusion-form-template>
          </div>
        </div>
      </ng-container>
    </div>
    <div adSpace></div>
  </div>
</div>
