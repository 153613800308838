import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromJobs from './jobs.reducer';
import * as fromCurrentJob from './current-job.reducer';
import * as fromAddJob from './add-job.reducer';
import * as fromApplications from './applications.reducer';
import * as fromCurrentApllication from './current-application.reducer';
import * as fromCurrentScreening from './current-screening.reducer';
import * as fromRecruitingClients from './recruiting-clients.reducer';
import * as fromOpenPositions from './open-positions.reducer';
import * as fromCurrentOpenPosition from './current-open-position.reducer';

export interface FusionJobState {
  jobs: fromJobs.JobsState;
  currentJob: fromCurrentJob.CurrentJobState;
  addJob: fromAddJob.AddJobState;
  applications: fromApplications.ApplicationsState;
  currentApplication: fromCurrentApllication.CurrentApplicationState;
  currentScreening: fromCurrentScreening.CurrentScreeningState;
  recruitingClients: fromRecruitingClients.RecruitingClientsState;
  openPositions: fromOpenPositions.OpenPositionsState;
  currentOpenPosition: fromCurrentOpenPosition.CurrentOpenPositionState;
}

export const reducers: ActionReducerMap<FusionJobState> = {
  jobs: fromJobs.reducer,
  currentJob: fromCurrentJob.reducer,
  addJob: fromAddJob.reducer,
  applications: fromApplications.reducer,
  currentApplication: fromCurrentApllication.reducer,
  currentScreening: fromCurrentScreening.reducer,
  recruitingClients: fromRecruitingClients.reducer,
  openPositions: fromOpenPositions.reducer,
  currentOpenPosition: fromCurrentOpenPosition.reducer,
};

export const getFusionJobState =
  createFeatureSelector<FusionJobState>('fusionJob');

// export reducer state interfaces
export { JobsState } from './jobs.reducer';
export { CurrentJobState } from './current-job.reducer';
