import { WalletActionTypes, WalletActions } from "../actions/wallet.actions";
import { IWallet, ITransaction, ICouponBalance } from "../../models/interfaces";

export interface WalletState {
  data: IWallet;
  currentPayment: ITransaction;
  coupon: ICouponBalance;
  sending: boolean;
  sent: boolean;
  loading: boolean;
  loaded: boolean;
}

export const initialState: WalletState = {
  data: null,
  currentPayment: null,
  coupon: null,
  sending: false,
  sent: false,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: WalletActions
): WalletState {
  switch (action.type) {
    case WalletActionTypes.LoadWallet: {
      return {
        ...state,
        loading: true
      };
    }
    case WalletActionTypes.LoadWalletSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case WalletActionTypes.LoadWalletFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case WalletActionTypes.MakePaymentWithCard: {
      return {
        ...state,
        sending: true
      };
    }
    case WalletActionTypes.MakePaymentWithCardSuccess:
    case WalletActionTypes.AuthorizePaymentSuccess: {
      return {
        ...state,
        currentPayment: action.payload,
        sending: false,
        sent: true
      };
    }
    case WalletActionTypes.ResetCurrentPayment: {
      return {
        ...state,
        currentPayment: null
      };
    }
    case WalletActionTypes.RedeemCouponSuccess: {
      return {
        ...state,
        coupon: action.payload
      };
    }
    case WalletActionTypes.ResetCouponBalance: {
      return {
        ...state,
        coupon: null
      };
    }
  }
  return state;
}
