<div *ngIf="!imageUrl; else cropImage">
  <fusion-common-banner-image-upload
    [isResizeDisabled]="true"
    [uploadTitle]="uploadTitle"
    (notifyImageUpload)="onNotifyImageUpload($event)"
  ></fusion-common-banner-image-upload>
</div>
<ng-template #cropImage>
  <div class="row2">
    <div class="row2__section-one">
      <angular-cropper
        #angularCropper
        [cropperOptions]="config"
        [imageUrl]="imageUrl"
      ></angular-cropper>
    </div>
    <div centering centerRight class="row2__section-two">
      <button
        mat-icon-button
        color="accent"
        aria-label="Rotate Image"
        (click)="rotate()"
      >
        <mat-icon>rotate_right</mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="crop()">
        Crop & Upload
      </button>
    </div>
  </div>
</ng-template>

<!-- <div id="result"></div> -->
