<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>Subscribe to new services!</h1></div>
  <div pageContent>
    <div content>
      <ng-container
        *ngIf="
          errorHandlingConfig$ | async as errorHandlingConfig;
          else subscriptionList
        "
      >
        <ng-template
          [fusionErrorHandler]="errorHandlingConfig"
          errorHandlingType="ErrorHandlingType.Template"
        ></ng-template>
      </ng-container>
      <ng-template #subscriptionList>
        <fusion-subscription-list
          [subscriptions]="subscriptions$ | async"
          (notifySubscribe)="notifySubscribe($event)"
        ></fusion-subscription-list>
      </ng-template>
    </div>
    <div adSpace></div>
  </div>
</div>
