import { createSelector } from '@ngrx/store';

import { getFusionSubscriptionState } from '../reducers';
import { getSubscriberCompany, getUserPermissions } from '@fusion/oauth';
import { getCurrentSubscription } from './current-subscription.selectors';
import { IFeature } from '../../models/interfaces';

const RECRUITING_AGENCY = 'Staffing & Recruiting';

export const getProfileNavigationState = createSelector(
  getFusionSubscriptionState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.profileNavigation;
  }
);

export const getProfileNavigationData = createSelector(
  getProfileNavigationState,
  (state): IFeature[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getCurrentRoleId = createSelector(
  getUserPermissions,
  getCurrentSubscription,
  (permissions, subscription): string => {
    if (permissions == null || subscription == null) {
      return null;
    }
    // for company: all roles falls under companyId which is providerId
    // for subscriptions: all roles falls under subscription id
    const providerId = subscription.providerId || subscription.id;
    const currentPermission = permissions.find(
      (permission) => permission.providerId === providerId
    );
    return currentPermission ? currentPermission.roleId : null;
  }
);

export const getProfileNavigation = createSelector(
  getProfileNavigationData,
  getSubscriberCompany,
  getCurrentRoleId,
  (navigations, subscriberCompany, roleId): IFeature[] => {
    if (navigations == null || roleId == null) {
      return null;
    }
    let allowedNavigations = navigations.filter(
      (nav) => nav.active && nav.roles.indexOf(roleId) > -1
    );

    // only for job application navigations
    if (subscriberCompany?.category === RECRUITING_AGENCY) {
      allowedNavigations = allowedNavigations.filter(
        (nav) =>
          nav.path !== 'agencies' &&
          nav.path !== 'positions' &&
          nav.path !== 'addposition'
      );
    } else {
      allowedNavigations = allowedNavigations.filter(
        (nav) => nav.path !== 'clients'
      );
    }

    return allowedNavigations;
  }
);

export const getUserProfileNavigation = createSelector(
  getProfileNavigationData,
  (navigations): IFeature[] => {
    if (navigations == null) {
      return null;
    }
    return navigations.filter((nav) => nav.active);
  }
);
