import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ICompany } from '@fusion/company';
import {
  ErrorActionType,
  ErrorHandlingType,
  ErrorSource,
  IError,
} from '@fusion/error';
import { getoAuthUserId, getSubscriberCompany } from '@fusion/oauth';
import { getRouterUrlBase } from '@fusion/router';
import { getPrivateSearch, ISearch } from '@fusion/search';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map,
} from 'rxjs/operators';
import { FusionJobError } from '../../models/enums';
import { IJob } from '../../models/interfaces';

import {
  OpenPositionsActionTypes,
  LoadOpenPositionsSuccess,
  LoadOpenPositionsFail,
  SetOpenPositionsCount,
  LoadOpenPositions,
  AddOpenPositions,
  AddOpenPositionsFail,
  AddOpenPositionsSuccess,
  LoadClientOpenPositions,
  LoadClientOpenPositionsFail,
  LoadClientOpenPositionsSuccess,
} from '../actions/open-positions.actions';
import { FusionJobState } from '../reducers';

@Injectable()
export class OpenPositionsEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<FusionJobState>,
    private jobService: JobService,
    private mappingService: MappingService
  ) {}

  
  searchOpenPositions$ = createEffect(() => this.actions$.pipe(
    ofType(OpenPositionsActionTypes.LoadOpenPositions),
    withLatestFrom(this.store.select(getPrivateSearch)),
    mergeMap(([action, search]: [any, ISearch]) => {
      let errorPayload: IError<FusionJobError> = {
        code: FusionJobError.LoadJobsFail,
        source: ErrorSource.Validation,
        data: null,
      };
      return this.jobService.getOpenPositions(search).pipe(
        switchMap((result) => {
          const mappedJobs = this.mappingService.getMappedData<IJob[]>(
            result.data,
            mappingType.camelize
          );
          return [
            new SetOpenPositionsCount(parseInt(result.count, 10)),
            new LoadOpenPositionsSuccess(mappedJobs),
          ];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading your jobs. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadOpenPositions()],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new LoadOpenPositionsFail(errorPayload));
        })
      );
    })
  ));

  
  searchClientOpenPositions$ = createEffect(() => this.actions$.pipe(
    ofType(OpenPositionsActionTypes.LoadClientOpenPositions),
    withLatestFrom(this.store.select(getPrivateSearch)),
    mergeMap(([action, search]: [any, ISearch]) => {
      let errorPayload: IError<FusionJobError> = {
        code: FusionJobError.LoadJobsFail,
        source: ErrorSource.Validation,
        data: null,
      };
      return this.jobService.getOpenPositions(search).pipe(
        switchMap((result) => {
          const mappedJobs = this.mappingService.getMappedData<IJob[]>(
            result.data,
            mappingType.camelize
          );
          return [
            new SetOpenPositionsCount(parseInt(result.count, 10)),
            new LoadClientOpenPositionsSuccess(mappedJobs),
          ];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading client Open Positions. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadClientOpenPositions()],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new LoadClientOpenPositionsFail(errorPayload));
        })
      );
    })
  ));

  
  addOpenPosition$ = createEffect(() => this.actions$.pipe(
    ofType<AddOpenPositions>(OpenPositionsActionTypes.AddOpenPositions),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getoAuthUserId),
      this.store.select(getRouterUrlBase),
      this.store.select(getSubscriberCompany)
    ),
    mergeMap(
      ([payload, userId, path, subscriberCompany]: [
        any,
        string,
        string,
        ICompany
      ]) => {
        let errorPayload: IError<FusionJobError> = {
          code: FusionJobError.AddJobFail,
          source: ErrorSource.Validation,
          data: null,
        };
        const companyId = subscriberCompany.id;
        const mappedOpenPositionRaw = this.getMappedJob(
          userId,
          companyId,
          payload,
          subscriberCompany
        );
        return this.jobService.postOpenPosition(mappedOpenPositionRaw).pipe(
          switchMap((dataResult: any) => {
            this.router.navigate([
              `${this.getCleanedPath(path)}/positions/${dataResult.id}`,
            ]);
            return [new AddOpenPositionsSuccess(dataResult)];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue adding your Open Position. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new AddOpenPositions(payload)],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new AddOpenPositionsFail(errorPayload));
          })
        );
      }
    )
  ));

  getCleanedPath(path: string) {
    return path.substring(1);
  }

  getMappedJob(
    userId: string,
    companyId: string,
    jobRaw: any,
    company: ICompany
  ) {
    return {
      company_id: companyId,
      user_id: userId,
      category: jobRaw.category,
      position: jobRaw.position,
      company_name: company.name,
      vacancy: jobRaw.vacancy,
      type: jobRaw.type,
      level: jobRaw.level,
      address: jobRaw.defaultAddress ? company.address : jobRaw.address,
      city: jobRaw.defaultAddress ? company.city : jobRaw.city,
      state: jobRaw.defaultAddress ? company.state : jobRaw.state,
      country: jobRaw.defaultAddress ? company.country : jobRaw.country,
      zip_code: jobRaw.defaultAddress ? company.zipCode : jobRaw.zipCode,
      salary: jobRaw.salary,
      currency_code: jobRaw.currencyCode,
      salary_unit: jobRaw.salaryUnit,
      negotiable: jobRaw.negotiable,
      offshore: jobRaw.offshore,
      job_details: this.getJobDetails(jobRaw),
    };
  }

  getJobDetails(jobRaw: any) {
    let details = [];
    details = jobRaw.jobDescription
      ? [
          ...details,
          {
            title: 'Job Description',
            content: jobRaw.jobDescription,
          },
        ]
      : details;

    details = jobRaw.jobResponsibility
      ? [
          ...details,
          {
            title: 'Job Responsibility',
            content: jobRaw.jobResponsibility,
          },
        ]
      : details;

    details = jobRaw.educationRequirements
      ? [
          ...details,
          {
            title: 'Education Requirements',
            content: jobRaw.educationRequirements,
          },
        ]
      : details;

    details = jobRaw.experienceRequirements
      ? [
          ...details,
          {
            title: 'Experience Requirements',
            content: jobRaw.experienceRequirements,
          },
        ]
      : details;

    details = jobRaw.additionalRequirements
      ? [
          ...details,
          {
            title: 'Additional Requirements',
            content: jobRaw.additionalRequirements,
          },
        ]
      : details;

    details = jobRaw.benifits
      ? [
          ...details,
          {
            title: 'Benifits',
            content: jobRaw.benifits,
          },
        ]
      : details;

    details = jobRaw.instructions
      ? [
          ...details,
          {
            title: 'Apply Instructions',
            content: jobRaw.instructions,
          },
        ]
      : details;

    return details;
  }
}
