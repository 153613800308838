import {
  IForm,
  FormFieldType,
  FormFieldGroup,
  FormFieldPattern,
} from '@fusion/form';
import { countries } from '@fusion/common';

export const UpdateUserProfileForm: IForm = {
  metadata: {
    name: 'UPDATE_USER_PROFILE_FORM',
    title: 'Update User Profile',
    submitButtonText: 'Update',
    style: {
      topMargin: '0px',
      maxWidth: '740px',
      maxHeight: '550px',
    },
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Name,
      configs: [
        {
          label: 'First Name',
          name: 'firstName',
          type: FormFieldType.Text,
          placeholder: 'John',
          validation: {
            required: true,
            max: 100,
          },
        },
        {
          label: 'Middle Name',
          name: 'middleName',
          type: FormFieldType.Text,
          placeholder: 'Deep',
          validation: {
            required: false,
            max: 100,
          },
        },
        {
          label: 'Last Name',
          name: 'lastName',
          type: FormFieldType.Text,
          placeholder: 'Deep',
          validation: {
            required: true,
            max: 100,
          },
        },
      ],
    },
    {
      label: 'Phone#',
      name: 'phoneNo',
      type: FormFieldType.Phone,
      placeholder: '(347) 123-1234',
      validation: {
        required: true,
        max: 100,
      },
    },
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: 'Address',
          name: 'address',
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 150,
          },
        },
        {
          label: 'Unit#',
          name: 'unit',
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 10,
          },
        },
        {
          label: 'City',
          name: 'city',
          type: FormFieldType.Text,
          placeholder: 'city',
          validation: {
            required: true,
            max: 100,
          },
        },
        {
          label: 'State',
          name: 'state',
          type: FormFieldType.Text,
          validation: {
            required: false,
          },
        },
        {
          label: 'Zip Code',
          name: 'zipCode',
          type: FormFieldType.Text,
          placeholder: 'zip code',
          examplePattern: '11432',
          validation: {
            required: false,
            max: 10,
            pattern: FormFieldPattern.Number,
          },
        },
        {
          label: 'Country',
          name: 'country',
          type: FormFieldType.Dropdown,
          options: countries,
          validation: {
            required: true,
          },
        },
      ],
    },
  ],
};
