<div section>
  <div sectionHeader>
    <div sectionTitle smallTitle microBold>
      User Profile
    </div>
    <div sectionSubtitle microTitle>
      This user detail will appears on your invoice.
    </div>
  </div>
  <div sectionContent card cardSpacing>
    <fusion-form-template
      [isDialog]="false"
      [progressState]="progressState"
      [formValidationRules]="updatedValidationRules"
      [formConfigs]="profileFormConfigs"
      [formMetadata]="profileFormMetadata"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    ></fusion-form-template>
  </div>
</div>
