import { createSelector } from '@ngrx/store';

import { getFusionSearchState } from '../reducers/index';
import { ISearchPagination } from '@fusion/common';

export const getPrivateSearchState = createSelector(
  getFusionSearchState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.privateSearch;
  }
);

export const getPrivateSearch = createSelector(
  getPrivateSearchState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.search;
  }
);

export const getPrivateSearchFollowUpAction = createSelector(
  getPrivateSearchState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.followUpContentLoadAction;
  }
);

export const getPrivateSearchOrderBy = createSelector(
  getPrivateSearch,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.order;
  }
);

export const getPrivateSearchPagination = createSelector(
  getPrivateSearch,
  (state): ISearchPagination => {
    if (state == null) {
      return null;
    }
    return state.pagination;
  }
);
