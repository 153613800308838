import { IForm, FormFieldType } from '@fusion/form';

export const MosquePreferencesForm: IForm = {
  metadata: {
    name: 'MOSQUE_PREFERENCES_FORM',
    title: 'Edit Preferences',
    description: 'add mosque / prayer place & help other muslim to pray',
    submitButtonText: 'Next',
    stepper: {
      isStepper: true,
      isFirstStep: true,
    },
  },
  configs: [
    {
      label: 'Do you have legal authority to manage this Mosque?',
      name: 'hasAccessPermission',
      type: FormFieldType.Radio,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      validation: {
        required: true,
      },
    }
  ],
};
