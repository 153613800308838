import { Injectable } from '@angular/core';
import { ICompany } from '@fusion/common';
import { getSubscriberCompany } from '@fusion/oauth';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { RecruitingPermissionType } from '../../models/enums';
import { IRecruitingClient } from '../../models/interfaces';

import {
  RecruitingClientsActionTypes,
  LoadRecruitingClientsSuccess,
  LoadRecruitingClientsFail,
  SetCurrentRecruitingClients,
  SetCurrentRecruitingClientsSuccess,
  SetCurrentRecruitingClientsFail,
  AddRecruitingClientsSuccess,
  AddRecruitingClientsFail,
  LoadRecruitingClients,
  ApproveRecruitingPermissionSuccess,
  ApproveRecruitingPermissionFail,
  ApproveRecruitingPermission,
} from '../actions/recruiting-clients.actions';
import { FusionJobState } from '../reducers';
import { getCurrentRecruitingClient } from '../selectors';

@Injectable()
export class RecruitingClientsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionJobState>,
    private jobSearchService: JobService,
    private mappingService: MappingService
  ) {}

  
  loadPermissions$ = createEffect(() => this.actions$.pipe(
    ofType<LoadRecruitingClients>(
      RecruitingClientsActionTypes.LoadRecruitingClients
    ),
    map((action) => action.permissionType),
    withLatestFrom(this.store.select(getSubscriberCompany)),
    mergeMap(
      ([permissionType, recruiterCompany]: [
        RecruitingPermissionType,
        ICompany
      ]) => {
        return this.jobSearchService
          .getRecruitingClients(permissionType, recruiterCompany.id)
          .pipe(
            switchMap((dataResult) => {
              const mappedResult = this.mappingService.getMappedData<
                IRecruitingClient[]
              >(dataResult, mappingType.camelize);
              return [new LoadRecruitingClientsSuccess(mappedResult)];
            }),
            catchError((error) => of(new LoadRecruitingClientsFail()))
          );
      }
    ),
    catchError((error) => of(new LoadRecruitingClientsFail()))
  ));

  
  addClient$ = createEffect(() => this.actions$.pipe(
    ofType(RecruitingClientsActionTypes.AddRecruitingClients),
    withLatestFrom(this.store.select(getCurrentRecruitingClient)),
    mergeMap(([action, currentClient]: [any, IRecruitingClient]) => {
      const permissionRaw = {
        client_company_id: currentClient.clientCompanyId,
        recruiter_company_id: currentClient.recruiterCompanyId,
      };
      return this.jobSearchService
        .requestRecruitingPermission(permissionRaw)
        .pipe(
          switchMap((dataResult) => {
            return [
              new AddRecruitingClientsSuccess(dataResult),
              new LoadRecruitingClients(RecruitingPermissionType.Recruiter),
            ];
          }),
          catchError((error) => of(new AddRecruitingClientsFail()))
        );
    }),
    catchError((error) => of(new AddRecruitingClientsFail()))
  ));

  
  approvePermission$ = createEffect(() => this.actions$.pipe(
    ofType<ApproveRecruitingPermission>(
      RecruitingClientsActionTypes.ApproveRecruitingPermission
    ),
    map((action) => action.permissionId),
    withLatestFrom(this.store.select(getSubscriberCompany)),
    mergeMap(([permissionId, employerCompany]: [string, ICompany]) => {
      const permissionRaw = {
        active: true,
      };
      return this.jobSearchService
        .updateRecruitingPermission(
          employerCompany.id,
          permissionId,
          permissionRaw
        )
        .pipe(
          switchMap((dataResult) => {
            return [
              new ApproveRecruitingPermissionSuccess(dataResult),
              new LoadRecruitingClients(RecruitingPermissionType.Employer),
            ];
          }),
          catchError((error) => of(new ApproveRecruitingPermissionFail()))
        );
    }),
    catchError((error) => of(new ApproveRecruitingPermissionFail()))
  ));

  
  setCurrentClient$ = createEffect(() => this.actions$.pipe(
    ofType<SetCurrentRecruitingClients>(
      RecruitingClientsActionTypes.SetCurrentRecruitingClients
    ),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getSubscriberCompany)),
    mergeMap(([clientCompany, recruiterCompany]: [ICompany, ICompany]) => {
      const currentClient: IRecruitingClient = {
        clientCompanyId: clientCompany.id,
        recruiterCompanyId: recruiterCompany.id,
        active: false,
        recruiterCompany,
        clientCompany,
      };
      return [new SetCurrentRecruitingClientsSuccess(currentClient)];
    }),
    catchError((error) => of(new SetCurrentRecruitingClientsFail()))
  ));
}
