import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser, IFilePayload } from '@fusion/common';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl: string;
  api: string;
  baseUrlDirect: string;
  apiDirect: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.user.host;
    this.api = this.configService.getConfig().services.user.api;
    this.baseUrlDirect =
      this.configService.getConfig().services.userDirect.host;
    this.apiDirect = this.configService.getConfig().services.userDirect.api;
  }

  postImage(userId: string, imageRaw: IFilePayload): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}/image`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, imageRaw, options);
  }

  addReferrals(referralsRaw: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/referrals`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, referralsRaw, options);
  }

  getReferral(referralId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/referrals/${referralId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  loadReferralStats(userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/referrers/${userId}/stats`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getUser(userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  updateUser(userBody, userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, userBody, options);
  }

  updateUserDirect(userBody, userId: string): Observable<any> {
    const fullUrl = this.baseUrlDirect + `${this.apiDirect}/users/${userId}`;
    const options = this.requestOptionsService.generateOptionsExternal();
    return this._http.put(fullUrl, userBody, options);
  }

  resetPassword(email: string): Observable<any> {
    const fullUrl =
      this.baseUrlDirect + `${this.apiDirect}/users/resetpassword`;
    const options = this.requestOptionsService.generateOptionsExternal();
    return this._http.post(fullUrl, { email }, options);
  }

  searchUsers(keywords: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users?keywords=${keywords}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  uploadPicture(userId: string, imagePayload: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}/images`;
    const options = this.requestOptionsService.generateOptions(false, true);

    const formData = new FormData();
    formData.append('file', imagePayload.blob);
    formData.append('name', imagePayload.name);
    formData.append('type', imagePayload.type);
    formData.append('reference', imagePayload.reference);
    return this._http.post(fullUrl, formData, options);
  }
}
