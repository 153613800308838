<ng-container *ngFor="let config of formConfigs">
  <fusion-form-dropdown
    *ngIf="config.name == 'applyType'"
    [formConfigs]="config"
    [formValidationRules]="formValidationRules"
    (notifySelectionChange)="getApplyTypeControlValue('applyType', config)"
  ></fusion-form-dropdown>

  <ng-container [ngSwitch]="formValidationRules.get('applyType').value">
    <ng-container *ngSwitchCase="'email'">
      <fusion-form-text-field
        *ngIf="config.name == 'email'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="'redirect'">
      <fusion-form-text-field
        *ngIf="config.name == 'url'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="apply-type__empty"></div>
    </ng-container>
  </ng-container>
</ng-container>
