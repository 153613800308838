import { Component, OnInit, Input } from '@angular/core';
import { IFormConfig } from '../../models/interfaces';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormFieldPattern } from '../../models/enums';

@Component({
  selector: 'fusion-form-job-apply-type',
  templateUrl: './job-apply-type.component.html',
  styleUrls: ['./job-apply-type.component.scss'],
})
export class JobApplyTypeComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}

  updateApplyControl(type: string, config: IFormConfig) {
    this.resetFormControlValidators('email');
    this.resetFormControlValidators('url');
    switch (type) {
      case 'email': {
        this.setFormControlValidators('email', [
          Validators.required,
          Validators.pattern(FormFieldPattern.Email),
        ]);
        break;
      }
      case 'redirect': {
        this.setFormControlValidators('url', [
          Validators.required,
          Validators.pattern(FormFieldPattern.Url),
        ]);
        break;
      }
    }
  }

  getApplyTypeControlValue(key: string, config: IFormConfig) {
    const value = this.formValidationRules.get(key).value;
    this.updateApplyControl(value, config);
    return value;
  }

  private setFormControlValidators(key: string, validators: ValidatorFn[]) {
    this.formValidationRules.controls[key].setValidators(validators);
    this.formValidationRules.controls[key].updateValueAndValidity();
  }

  private resetFormControlValidators(key: string) {
    this.formValidationRules.controls[key].reset();
    this.formValidationRules.controls[key].setValidators(null);
    this.formValidationRules.controls[key].updateValueAndValidity();
  }
}
