import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fancyTime',
})
export class FancyTimePipe implements PipeTransform {
  transform(startDate: any, option?: any, endDate?: any): any {
    let message = '';
    const startTimestamp = new Date(startDate).getTime();
    const unixStartDate = moment.unix(Math.floor(startTimestamp / 1000));
    const endTimestamp = endDate
      ? new Date(endDate).getTime()
      : new Date().getTime();
    const unixEndDate = moment.unix(Math.floor(endTimestamp / 1000));
    if (option === 'duration') {
      // years
      const years = unixEndDate.diff(unixStartDate, 'year');
      unixStartDate.add(years, 'years');
      message = years > 1 ? `${years} yrs` : years === 1 ? `${years} yr` : '';

      // months
      const months = unixEndDate.diff(unixStartDate, 'months');
      unixStartDate.add(months, 'months');
      message +=
        months > 1 ? ` ${months} mos` : months < 2 ? ` ${months} mo` : '';

      // days
      // const days = a.diff(b, 'days');

      return message;
    } else if (option === 'time') {
      return this.getFormattedTimeDuration(startDate);
    } else {
      return unixStartDate.fromNow();
    }
  }

  getFormattedTimeDuration(time: number) {
    let remainingValue = time / 1000;

    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let formatedTime = '';

    // has hours
    if (remainingValue >= 3600) {
      hours = Math.floor(remainingValue / 3600);
      formatedTime += `${hours}:`;
      remainingValue = remainingValue - hours * 3600;
    }

    // has minutes
    if (remainingValue >= 60) {
      minutes = Math.floor(remainingValue / 60);
      formatedTime +=
        hours > 0 && minutes < 10 ? `0${minutes}:` : `${minutes}:`;
      remainingValue = remainingValue - minutes * 60;
    }

    // has seconds
    if (remainingValue !== 0) {
      seconds = Math.floor(remainingValue);
      formatedTime +=
        minutes > 0 && seconds < 10 ? `0${seconds}` : `${seconds}`;
    }

    return formatedTime;
  }
}
