<div class="row2 b-padding-13">
  <div stickyHeader class="col2">
    <div centering centerLeft><h1>Manage Event!</h1></div>
    <div centering centerRight>
      <button
        class="custom-round-button"
        mat-raised-button
        color="warn"
        [disabled]="!hasPendingChanges"
        (click)="notifySave.emit(); hasPendingChanges = false"
        [mat-dialog-close]="true"
      >
        Update
      </button>
    </div>
  </div>
  <div [formGroup]="eventFormGroup" class="event-edit col2">
    <div *ngIf="event" class="rowN">
      <div class="col2">
        <div centering topLeft>
          <mat-icon>event</mat-icon>
        </div>
        <mat-form-field fullWidth>
          <mat-label>Event Title</mat-label>
          <input
            fullWidth
            matInput
            type="text"
            formControlName="title"
            (change)="onEventUpdate()"
          />
        </mat-form-field>
      </div>
      <div class="col2">
        <div centering topLeft>
          <mat-icon>schedule</mat-icon>
        </div>
        <div class="meeting-time col3">
          <div>
            <mat-form-field fullWidth>
              <mat-label>Start Date</mat-label>
              <input
                matInput
                type="text"
                mwlFlatpickr
                formControlName="start"
                [altInput]="true"
                [convertModelValue]="true"
                (ngModelChange)="onEventUpdate()"
                [enableTime]="true"
                dateFormat="Y-m-dTH:i"
                altFormat="j F, Y h:i K"
              />
            </mat-form-field>
          </div>
          <div centering center><span> to </span></div>
          <div>
            <mat-form-field fullWidth>
              <mat-label>End Date</mat-label>
              <input
                matInput
                type="text"
                mwlFlatpickr
                formControlName="end"
                [altInput]="true"
                [convertModelValue]="true"
                (ngModelChange)="onEventUpdate()"
                [enableTime]="true"
                dateFormat="Y-m-dTH:i"
                altFormat="j F, Y h:i K"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- address -->
      <ng-container [ngSwitch]="event?.meta?.eventType">
        <ng-container *ngSwitchCase="CalendarEventType.VideoConference">
          <!-- video -->
          <div class="col2">
            <div centering centerLeft>
              <mat-icon color="accent">voice_chat</mat-icon>
            </div>
            <div>
              <button
                *ngIf="!event.meta.videoConferenceId"
                mat-flat-button
                color="primary"
                (click)="onCreateVideoConference(event)"
              >
                Add Fusion Live video conferencing
              </button>

              <button
                *ngIf="event.meta.videoConferenceId"
                mat-flat-button
                color="primary"
                (click)="onJoinVideoConference(event)"
              >
                Join with Fusion Live
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="CalendarEventType.PhoneConference">
          <div class="col2">
            <div centering topLeft class="t-padding-1">
              <mat-icon>call</mat-icon>
            </div>
            <div *ngIf="event?.meta?.eventCategory === EventCategory.Interview">
              <div primaryFontColor semiBold>Join Applicant by Phone</div>
              <div extraSmallFont>{{ applicantGuest?.user?.phoneNo }}</div>
            </div>
            <div *ngIf="event?.meta?.eventCategory === EventCategory.Meeting">
              <div primaryFontColor semiBold>Join Organizer by Phone</div>
              <div extraSmallFont>--- --- ----</div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="CalendarEventType.InPersonConference">
          <!-- in-person -->
          <!-- video -->
          <div class="col2">
            <div centering centerLeft>
              <mat-icon color="accent">voice_chat</mat-icon>
            </div>
            <div>
              <button
                *ngIf="!event.meta.videoConferenceId"
                mat-flat-button
                color="primary"
                (click)="onCreateVideoConference(event)"
              >
                Add Fusion Live video conferencing
              </button>

              <button
                *ngIf="event.meta.videoConferenceId"
                mat-flat-button
                color="primary"
                (click)="onJoinVideoConference(event)"
              >
                Join with Fusion Live
              </button>
            </div>
          </div>

          <!-- location -->
          <div class="col2">
            <div centering topLeft>
              <mat-icon>place</mat-icon>
            </div>
            <div border radius cardSmallSpacing class="">
              <div class="address-line1 col2">
                <mat-form-field fullWidth>
                  <mat-label>Address</mat-label>
                  <input
                    fullWidth
                    matInput
                    type="text"
                    formControlName="address"
                    (change)="onEventUpdate()"
                  />
                </mat-form-field>
                <mat-form-field fullWidth>
                  <mat-lable>Unit#</mat-lable>
                  <input
                    fullWidth
                    matInput
                    type="text"
                    formControlName="unit"
                    (change)="onEventUpdate()"
                  />
                </mat-form-field>
              </div>
              <div class="address-line2 col2">
                <mat-form-field fullWidth>
                  <mat-label>City</mat-label>
                  <input
                    fullWidth
                    matInput
                    type="text"
                    formControlName="city"
                    (change)="onEventUpdate()"
                  />
                </mat-form-field>
                <mat-form-field fullWidth>
                  <mat-lable>State</mat-lable>
                  <input
                    fullWidth
                    matInput
                    type="text"
                    formControlName="state"
                    (change)="onEventUpdate()"
                  />
                </mat-form-field>
              </div>
              <div class="address-line2 col2">
                <mat-form-field fullWidth>
                  <mat-label>Zip Code</mat-label>
                  <input
                    fullWidth
                    matInput
                    type="text"
                    formControlName="zipCode"
                    (change)="onEventUpdate()"
                  />
                </mat-form-field>
                <mat-form-field fullWidth>
                  <mat-label>Country</mat-label>
                  <input
                    fullWidth
                    matInput
                    type="text"
                    formControlName="country"
                    (change)="onEventUpdate()"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!--  -->
      <div class="col2">
        <div centering topLeft>
          <mat-icon>notes</mat-icon>
        </div>
        <div class="t-padding-1">
          <mat-form-field fullWidth>
            <mat-label>Event Description</mat-label>
            <textarea
              fullWidth
              matInput
              (change)="onEventUpdate()"
              formControlName="description"
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="15"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="add-guest">
      <div class="col2 b-padding-5">
        <div centering centerLeft>
          <mat-icon>group_add</mat-icon>
        </div>
        <div>
          <mat-form-field class="col-12">
            <mat-label>Add guests</mat-label>
            <input
              matInput
              aria-label="Country"
              [matAutocomplete]="auto"
              [formControl]="userSearchCtrl"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayName"
            >
              <mat-option *ngFor="let entity of users" [value]="entity">
                <img
                  style="vertical-align: middle"
                  aria-hidden
                  src="{{
                    entity.profilePicture ||
                      '/assets/images/users/profile-picture/profile.png'
                  }}"
                  height="25"
                />
                <span> {{ entity?.firstName }} {{ entity?.lastName }}</span> |
                <small>{{ entity?.address }}, </small>
                <small *ngIf="entity.city">{{ entity?.city }}, </small>
                <small *ngIf="entity.state">{{ entity?.state }}, </small>
                <small *ngIf="entity.country">{{ entity?.country }} </small>
                <small *ngIf="entity.zipCode">- {{ entity?.zipCode }}</small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <!--  -->
      <div class="col2">
        <div centering topLeft>
          <mat-icon>people</mat-icon>
        </div>
        <div class="guests rowN">
          <ng-container *ngFor="let guest of event?.meta?.guests">
            <div hover class="col3">
              <div centering center>
                <div microProfileImage>
                  <img
                    imageFit
                    src="{{
                      guest?.user?.profilePicture ||
                        'assets/images/users/profile-picture/profile.png'
                    }}"
                  />
                  <div overlay class="l-margin-5">
                    <ng-container [ngSwitch]="guest?.joiningStatus">
                      <ng-container *ngSwitchCase="JoiningStatus.Accepted">
                        <mat-icon color="accent" class="icon-display"
                          >check_circle</mat-icon
                        >
                      </ng-container>

                      <ng-container *ngSwitchCase="JoiningStatus.Rejected">
                        <mat-icon color="warn" class="icon-display"
                          >highlight_off</mat-icon
                        >
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        <!-- TODO may be fetch error component -->
                        <div class="activity__empty"></div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div centering centerLeft>
                <div>
                  <div>
                    {{ guest.user?.firstName }} {{ guest.user?.middleName }}
                    {{ guest.user?.lastName }}
                  </div>
                  <div
                    nanoFont
                    semiBold
                    gray9FontColor
                    *ngIf="guest.isOrganizer"
                  >
                    Organizer
                  </div>
                  <div
                    nanoFont
                    semiBold
                    gray9FontColor
                    *ngIf="guest.isApplicant"
                  >
                    Applicant
                  </div>
                </div>
              </div>
              <div *ngIf="!guest.isOrganizer && !guest.isApplicant">
                <mat-icon
                  pointer
                  hoverDisplay
                  color="warn"
                  class="icon-display"
                  (click)="notifyRemoveGuest.emit(guest)"
                  >highlight_off</mat-icon
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
