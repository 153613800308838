<div border radius cardSpacing class="row2">
  <div>
    <div mediumTitle microBold>Become a contributor</div>
    <div sectionSubtitle microTitle>
      If you become a contributor for Dot to Circle, you will be rewarded the
      same amount you have contributed. Rewarded amount will be added to your
      Circle Wallet. You can use that balance to purchase any services offered
      by Dot to Circle.
    </div>
  </div>
  <div class="colN">
    <ng-container *ngFor="let option of fundingOptions">
      <ng-container *ngIf="!option.isDefault; else defaultFundingOption">
        <div card cardSpacing class="row4">
          <div extraSmallTitle semiBold>{{ option.title }}</div>
          <div class="row2">
            <div microFont gray9FontColor>
              {{ option.description1 }}
            </div>
            <div microFont gray9FontColor>
              {{ option.description2 }}
              <span
                ><a semiBold (click)="notifyViewContributors.emit(option)"
                  >View Contributors</a
                ></span
              >
            </div>
          </div>

          <ng-container *ngIf="!option.isCustomAmount; else customAmount">
            <div
              border
              radius
              cardSpacing
              centering
              center
              mediumTitle
              semiBold
              primaryFontColor
            >
              ${{ option.amount }}
            </div>
          </ng-container>
          <div centering center>
            <button
              mat-stroked-button
              color="primary"
              class="custom-round-button"
              (click)="notifyFundingSelection.emit(option)"
              [disabled]="option.isCustomAmount && !firstFormGroup.valid"
            >
              Contribute
            </button>
          </div>
        </div>
      </ng-container>

      <ng-template #defaultFundingOption>
        <div card cardSpacing class="funding-card row4">
          <div extraSmallTitle semiBold>{{ option.title }}</div>
          <div class="row2">
            <div microFont>
              {{ option.description1 }}
            </div>
            <div microFont>
              {{ option.description2 }}
              <span
                ><a semiBold (click)="notifyViewContributors.emit(option)"
                  >View Contributors</a
                ></span
              >
            </div>
          </div>
          <div border radius cardSpacing centering center mediumTitle semiBold>
            ${{ option.amount }}
          </div>
          <div centering center>
            <button
              mat-raised-button
              color="primary"
              class="custom-round-button"
              (click)="notifyFundingSelection.emit(option)"
            >
              Contribute
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #customAmount>
  <form [formGroup]="firstFormGroup">
    <mat-form-field
      [floatLabel]="floatLabelControl.value"
    >
      <input
        matInput
        placeholder="Amount $"
        formControlName="amountCtrl"
        type="number"
        pattern="^[1-9]\d*$"
      />
      <span matPrefix>$&nbsp;</span>
    </mat-form-field>
    <mat-error
      *ngIf="
        firstFormGroup.controls['amountCtrl'].hasError('required') &&
        firstFormGroup.controls['amountCtrl'].touched
      "
    >
      You must include Amount.
    </mat-error>
    <mat-error
      *ngIf="
        !firstFormGroup.controls['amountCtrl'].hasError('required') &&
        firstFormGroup.controls['amountCtrl'].touched &&
        !firstFormGroup.valid
      "
    >
      Amount must be positive & full number.
    </mat-error>
  </form>
</ng-template>
