import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import * as FormData from 'form-data';

import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { IUserCredential } from '@fusion/common';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  baseUrl: string;
  api: string;
  tokenFromUI = '2c7f982f7b60551242e85b49bd34262e';

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.token.host;
    this.api = this.configService.getConfig().services.token.api;
  }

  getUserToken(userCredential: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/user`;
    const options = this.requestOptionsService.generateOptions(false, true);
    const secret = this.configService.getConfig().services.token.key;
    const appId = this.configService.getConfig().appId;
    const credentials = {
      ...userCredential,
      'fusion-api-key': secret,
      'app-id': appId,
    };

    const encryptedCredentials = this.encryptUsingAES256(credentials);
    const formData = new FormData();
    // const blob = new Blob([encryptedCredentials]);
    formData.append('credentials', encryptedCredentials);
    return this._http.post(fullUrl, formData, options);
  }

  getAppToken(): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/app`;
    const options = this.requestOptionsService.generateOptions(false, true);
    const secret = this.configService.getConfig().services.token.key;
    const appId = this.configService.getConfig().appId;
    const credentials = {
      'fusion-api-key': secret,
      'app-id': appId,
    };

    const encryptedCredentials = this.encryptUsingAES256(credentials);
    // const formData = new FormData();
    // formData.append('credentials', encryptedCredentials);
    return this._http.post(fullUrl, {credentials: encryptedCredentials}, options);
  }

  getUserToken1(userCredential: IUserCredential): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/user`;
    const options = this.requestOptionsService.generateOptions(true);
    return this._http.post(fullUrl, userCredential, options);
  }

  getRefreshToken(token: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/user/refresh`;
    const options = this.requestOptionsService.generateOptions(false, true);
    const secret = this.configService.getConfig().services.token.key;
    const appId = this.configService.getConfig().appId;
    const credentials = {
      token,
      'fusion-api-key': secret,
      'app-id': appId,
    };

    const encryptedCredentials = this.encryptUsingAES256(credentials);
    const formData = new FormData();
    formData.append('credentials', encryptedCredentials);
    return this._http.post(fullUrl, formData, options);
  }

  getRefreshAppToken(token: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/app/refresh`;
    const options = this.requestOptionsService.generateOptions(false, true);
    const secret = this.configService.getConfig().services.token.key;
    const appId = this.configService.getConfig().appId;
    const credentials = {
      token,
      'fusion-api-key': secret,
      'app-id': appId,
    };

    const encryptedCredentials = this.encryptUsingAES256(credentials);
    const formData = new FormData();
    formData.append('credentials', encryptedCredentials);
    return this._http.post(fullUrl, formData, options);
  }

  getRefreshToken1(token: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/refresh`;
    const options = this.requestOptionsService.generateOptions(true);
    return this._http.post(fullUrl, { token }, options);
  }

  getUtc(date) {
    return moment.utc(date, 'YYYY/MM/DD H:mm:ss');
  }

  postUser(user: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, user, options);
  }

  encryptUsingAES256(userCredential: any) {
    const _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    const _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(userCredential),
      _key,
      {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  }
  decryptUsingAES256(encrypted: any) {
    const _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    const _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    return CryptoJS.AES.decrypt(encrypted, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
  }
}
