import { createSelector } from '@ngrx/store';

import { getFusionPaymentState } from '../reducers';
import { ICustomer, IPaymentMethod } from '../../models/interfaces';

export const getCustomerState = createSelector(
  getFusionPaymentState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.customer;
  }
);

export const getCustomer = createSelector(
  getCustomerState,
  (state): ICustomer => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getSetupCustomerProgressState = createSelector(
  getCustomerState,
  (state) => {
    if (state == null) {
      return null;
    }
    return {
      isInProgress: state.isInProgress,
      isSuccess: state.isSuccess,
    };
  }
);

export const getCurrentPaymentMethod = createSelector(
  getCustomerState,
  (state): IPaymentMethod => {
    if (state == null) {
      return null;
    }
    return state.currentPaymentMethod;
  }
);

export const getClientSecret = createSelector(
  getCurrentPaymentMethod,
  (paymentMethod): string => {
    if (paymentMethod == null) {
      return null;
    }
    return paymentMethod.clientSecret;
  }
);
