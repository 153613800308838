import { IDetail } from '../interfaces';

export const jobDetailsData: IDetail[] = [
  {
    title: 'Job Description',
    content: 'jobDescription',
  },
  {
    title: 'Job Responsibility',
    content: 'jobResponsibility',
  },
  {
    title: 'Education Requirements',
    content: 'educationRequirements',
  },
  {
    title: 'Experience Requirements',
    content: 'experienceRequirements',
  },
  {
    title: 'Additional Requirements',
    content: 'additionalRequirements',
  },
  {
    title: 'Benifits',
    content: 'benifits',
  },
  {
    title: 'Apply Instructions',
    content: 'instructions',
  },
];
