import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormConfig } from '../../models/interfaces';
import { FormFieldGroup } from '../../models/enums';

@Component({
  selector: 'fusion-form-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;
  @Input() group: FormFieldGroup;

  FormFieldGroup = FormFieldGroup;

  constructor() {}

  ngOnInit() {}

  getRequiredConfig(configs: any, options: string[]) {
    return configs.filter((config: any) => {
      if (options.length && options.indexOf(config.name) > -1) {
        return config;
      }
    });
  }
}
