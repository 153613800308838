<div class="row3">
  <div mediumTitle microBold class="row3__section-one">Billing Settings</div>
  <div microTitle class="row3__section-two">
    This address appears on your invoice and should be the legal address of your
    home or business.
  </div>
  <div border radius cardSpacing class="row3__section-three">
    <ng-container *ngIf="address">
      <div bold g4FontColor>
        {{ address.name }}
      </div>
      <div g4FontColor extraSmallFont>
        <span>{{ address.line1 }}</span>
        <span *ngIf="address.line2">, {{ address.line2 }}</span>
      </div>
      <div g4FontColor extraSmallFont>
        <span>{{ address.city }}</span>
        <span>, {{ address.state }}</span>
        <span *ngIf="address.postal_code"> - {{ address.postal_code }}</span>
      </div>
      <div g4FontColor extraSmallFont>
        <span>{{ address.country }}</span>
      </div>
    </ng-container>
  </div>
</div>
