import { Action } from '@ngrx/store';

export enum EventsActionTypes {
  LoadEvents = '[Calendar Events] Load',
  LoadEventsSuccess = '[Calendar Events] Load Success',
  LoadEventsFail = '[Calendar Events] Load Fail'
}

export class LoadEvents implements Action {
  readonly type = EventsActionTypes.LoadEvents;
}

export class LoadEventsSuccess implements Action {
  readonly type = EventsActionTypes.LoadEventsSuccess;

  constructor( public payload: any ) { }
}

export class LoadEventsFail implements Action {
  readonly type = EventsActionTypes.LoadEventsFail;
}

export type EventsActions =
  | LoadEvents
  | LoadEventsSuccess
  | LoadEventsFail;
