import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "fusion-payment-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.scss"]
})
export class BalanceComponent implements OnInit {
  @Input() balance: number = 0;

  constructor() {}

  ngOnInit() {}
}
