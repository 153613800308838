<div border radius cardSpacing class="row3">
  <div mediumTitle microBold class="row3__section-one">Funding Payment</div>
  <div microTitle class="row3__section-two">
    Your card will be charged only the amount you have prefered to contribute.
  </div>
  <mat-tab-group>
    <!-- card -->
    <mat-tab label="Card">
      <ng-container
        *ngIf="paymentMethods && paymentMethods.length; else noPaymentMethod"
      >
        <ng-container *ngIf="!currentPayment; else paymentConfirmation">
          <div border radius class="t-margin-5 row3__section-three">
            <mat-horizontal-stepper [linear]="isLinear" #stepper>
              <!-- step one -->
              <mat-step [stepControl]="secondFormGroup">
                <ng-template matStepLabel>Payment Method</ng-template>
                <div class="t-padding-5 rowN">
                  <div
                    hover
                    *ngFor="
                      let paymentMethod of paymentMethods;
                      let currentIndex = index
                    "
                  >
                    <div
                      bgColorHover
                      pointer
                      (click)="setCardIndex(currentIndex, stepper)"
                    >
                      <fusion-payment-card
                        [paymentMethod]="paymentMethod"
                        [isSelected]="currentIndex == cardIndex"
                      ></fusion-payment-card>
                    </div>
                  </div>
                </div>

                <div class="t-padding-7 action-buttons col3">
                  <button
                    mat-flat-button
                    color="primary"
                    matStepperNext
                    [disabled]="!(cardIndex >= 0)"
                  >
                    Next
                  </button>
                </div>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Authorize</ng-template>
                <div class="t-padding-5 authorization col2">
                  <div class="col2__section-one">
                    <fusion-payment-card
                      [paymentMethod]="paymentMethods[cardIndex || 0]"
                      [isSelected]="true"
                    ></fusion-payment-card>
                  </div>
                  <div
                    border
                    radius
                    centering
                    center
                    bgColor
                    class="col2__section-two"
                  >
                    <div extraSmallTitle>
                      <span>Your card will be charged</span>
                      <span semiBold> ${{ fundingAmount }}.</span>
                    </div>
                  </div>
                </div>

                <div class="t-padding-7 action-buttons col3">
                  <button mat-button matStepperPrevious>Back</button>
                  <button
                    mat-flat-button
                    color="primary"
                    [disabled]="isSendingPayment"
                    (click)="
                      authorizeTransaction(
                        paymentMethods[cardIndex].id,
                        fundingAmount
                      )
                    "
                  >
                    Authorize
                  </button>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </div>
        </ng-container>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #paymentConfirmation>
  <ng-container
    *ngIf="currentPayment && currentPayment.paid; else paymentAuthorization"
  >
    <div
      border
      radius
      cardSpacing
      centeringText
      cardSpacing
      class="row2 t-margin-5"
    >
      <div smallTitle semiBold class="row2__section-one">
        Payment Successful!
      </div>
      <div exSmallTitle g4FontColor class="row2__section-two">
        Your payment has successfully completed. ${{ currentPayment.amount }}
        has been contributed to Dot to Circle.
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #paymentAuthorization>
  <div
    errorBorder
    radius
    cardSpacing
    centeringText
    cardSpacing
    class="row3 t-margin-5"
  >
    <div smallTitle semiBold class="row3__section-one">
      Authorization Required!
    </div>
    <div exSmallTitle g4FontColor class="row3__section-two">
      Your bank require authorization to complete this transaction. Once you
      authorize ${{ currentPayment.amount }} will be added in your wallet.
    </div>
    <div class="row3__section-three">
      <button
        mat-flat-button
        color="accent"
        [disabled]="isSendingPayment"
        (click)="authorizeCurrentPayment()"
      >
        Authorize this transaction
      </button>
    </div>
  </div>
</ng-template>

<ng-template #noPaymentMethod>
  <div
    errorBorder
    radius
    cardSpacing
    centeringText
    cardSpacing
    class="row3 t-margin-5"
  >
    <div smallTitle semiBold class="row3__section-one">
      No Payment Method!
    </div>
    <div exSmallTitle g4FontColor class="row3__section-two">
      You did not add any card in your Circle Wallet yet. To proceed with
      Funding Payment please add a card in your wallet and retry again.
    </div>
    <div class="row3__section-three">
      <button
        mat-flat-button
        color="accent"
        [disabled]="isSendingPayment"
        routerLink="{{ getWalletUrl(baseUrl) }}"
      >
        Back to Circle Wallet
      </button>
    </div>
  </div>
</ng-template>
