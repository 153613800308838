import { IError } from '@fusion/error';
import { Action } from '@ngrx/store';
import { FusionoAuthError } from '../../models/enums';
import { ISession } from '../../models/interfaces';

export enum AppSessionActionTypes {
  StartAppSession = '[App Session] Start',
  StartAppSessionSuccess = '[App Session] Start Success',
  StartAppSessionFail = '[App Session] Start Fail',
  RestoreAppSession = '[App Session] Restore',
  RestoreAppSessionSuccess = '[App Session] Restore Success',
  RestoreAppSessionFail = '[App Session] Restore Fail',
  GetAppSession = '[App Session] Get',
  GetAppSessionSuccess = '[App Session] Get Success',
  GetAppSessionFail = '[App Session] Get Fail',
  RefreshAppSession = '[App Session] Refresh',
  RefreshAppSessionSuccess = '[App Session] Refresh Success',
  RefreshAppSessionFail = '[App Session] Refresh Fail',
}

export class StartAppSession implements Action {
  readonly type = AppSessionActionTypes.StartAppSession;
}

export class StartAppSessionSuccess implements Action {
  readonly type = AppSessionActionTypes.StartAppSessionSuccess;

  constructor(public session: ISession) {}
}

export class StartAppSessionFail implements Action {
  readonly type = AppSessionActionTypes.StartAppSessionFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class RestoreAppSession implements Action {
  readonly type = AppSessionActionTypes.RestoreAppSession;
}

export class RestoreAppSessionSuccess implements Action {
  readonly type = AppSessionActionTypes.RestoreAppSessionSuccess;
}

export class RestoreAppSessionFail implements Action {
  readonly type = AppSessionActionTypes.RestoreAppSessionFail;

  constructor(public payload?: IError<FusionoAuthError>) {}
}

export class GetAppSession implements Action {
  readonly type = AppSessionActionTypes.GetAppSession;
}

export class GetAppSessionSuccess implements Action {
  readonly type = AppSessionActionTypes.GetAppSessionSuccess;

  constructor(public payload: ISession) {}
}

export class GetAppSessionFail implements Action {
  readonly type = AppSessionActionTypes.GetAppSessionFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class RefreshAppSession implements Action {
  readonly type = AppSessionActionTypes.RefreshAppSession;

  constructor(public session: ISession) {}
}

export class RefreshAppSessionSuccess implements Action {
  readonly type = AppSessionActionTypes.RefreshAppSessionSuccess;

  constructor(public payload: ISession) {}
}

export class RefreshAppSessionFail implements Action {
  readonly type = AppSessionActionTypes.RefreshAppSessionFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export type AppSessionActions =
  | StartAppSession
  | StartAppSessionSuccess
  | StartAppSessionFail
  | RestoreAppSession
  | RestoreAppSessionSuccess
  | RestoreAppSessionFail
  | GetAppSession
  | GetAppSessionSuccess
  | GetAppSessionFail
  | RefreshAppSession
  | RefreshAppSessionSuccess
  | RefreshAppSessionFail;
