import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IUser, IProgressState } from "@fusion/common";
import { UntypedFormGroup } from "@angular/forms";
import { getValidationRulesWithPresetValue } from "@fusion/form";

@Component({
  selector: "user-update-profile",
  templateUrl: "./update-profile.component.html",
  styleUrls: ["./update-profile.component.scss"]
})
export class UpdateProfileComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: IUser;
  @Input() profileFormMetadata: any;
  @Input() profileFormConfigs: any;
  @Input() profileFormValidationRules: UntypedFormGroup;
  @Input() progressState: IProgressState;

  updatedValidationRules: UntypedFormGroup;

  constructor() {}

  ngOnInit() {
    this.updatedValidationRules = getValidationRulesWithPresetValue(
      this.profileFormValidationRules,
      this.profileFormConfigs,
      this.user
    );
  }
}
