import { createSelector } from '@ngrx/store';

import { getFusionoAuthState } from '../reducers';

export const getAppSessionState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.appSession;
  }
);

export const getAppSession = createSelector(
  getAppSessionState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.session;
  }
);

export const getAppSessionToken = createSelector(
  getAppSession,
  (session) => {
    if (session == null) {
      return null;
    }
    return session.token;
  }
);
