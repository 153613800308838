<button
  mat-icon-button
  color="accent"
  routerLink="{{ getBackUrl(currentRootPath, targetDir) }}"
>
  <span class="back col2">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <span extraSmallFont centering center uppercase>{{ buttonText }}</span>
  </span>
</button>
