import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers/index';
import { JobListComponent } from './containers/job-list/job-list.component';
import { JobComponent } from './containers/job/job.component';
import { JobBodyComponent } from './components/job-body/job-body.component';
import { JobHeaderComponent } from './components/job-header/job-header.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FusionCommonModule } from '@fusion/common';
import { RouterModule } from '@angular/router';
import { FusionServiceModule } from '@fusion/service';
import { effects } from './store/effects/index';
import { EffectsModule } from '@ngrx/effects';
import { FusionCompanyModule } from '@fusion/company';
import { FusionFormModule } from '@fusion/form';
import { MetadataComponent } from './components/metadata/metadata.component';
import { FusionPipeModule } from '@fusion/pipe';
import { ManageJobComponent } from './containers/manage-job/manage-job.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ApplyComponent } from './containers/apply/apply.component';
import { ApplicationsComponent } from './containers/applications/applications.component';
import { ManageApplicationComponent } from './containers/manage-application/manage-application.component';
import { PhoneScreeningComponent } from './components/phone-screening/phone-screening.component';
import { VideoInterviewComponent } from './components/video-interview/video-interview.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InPersonComponent } from './components/in-person/in-person.component';
import { JobOfferComponent } from './components/job-offer/job-offer.component';
import { JobseekerApplicationsComponent } from './containers/jobseeker-applications/jobseeker-applications.component';
import { ManageJobseekerApplicationComponent } from './containers/manage-jobseeker-application/manage-jobseeker-application.component';
import { FusionCalendarModule } from '@fusion/calendar';
import { RecruitingClientsComponent } from './containers/recruiting-clients/recruiting-clients.component';
import { RecruitingAgenciesComponent } from './containers/recruiting-agencies/recruiting-agencies.component';
import { CompanyCardComponent } from './components/company-card/company-card.component';
import { OpenPositionComponent } from './containers/open-position/open-position.component';
import { OpenPositionsComponent } from './containers/open-positions/open-positions.component';
import { AddPositionComponent } from './containers/add-position/add-position.component';
import { PositionFormComponent } from './containers/position-form/position-form.component';
import { ManageOpenPositionComponent } from './containers/manage-open-position/manage-open-position.component';
import { ClientOpenPositionsComponent } from './containers/client-open-positions/client-open-positions.component';
import { ClientOpenPositionComponent } from './containers/client-open-position/client-open-position.component';

@NgModule({
  declarations: [
    JobListComponent,
    JobComponent,
    JobBodyComponent,
    JobHeaderComponent,
    MetadataComponent,
    ManageJobComponent,
    ApplyComponent,
    ApplicationsComponent,
    ManageApplicationComponent,
    PhoneScreeningComponent,
    VideoInterviewComponent,
    InPersonComponent,
    JobOfferComponent,
    JobseekerApplicationsComponent,
    ManageJobseekerApplicationComponent,
    RecruitingClientsComponent,
    RecruitingAgenciesComponent,
    CompanyCardComponent,
    OpenPositionComponent,
    OpenPositionsComponent,
    AddPositionComponent,
    PositionFormComponent,
    ManageOpenPositionComponent,
    ClientOpenPositionsComponent,
    ClientOpenPositionComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    FusionCommonModule,
    FusionPipeModule,
    FusionCompanyModule,
    FusionServiceModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    FusionFormModule,
    FusionCalendarModule,
    StoreModule.forFeature('fusionJob', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [
    JobListComponent,
    JobComponent,
    ManageJobComponent,
    ApplyComponent,
    ApplicationsComponent,
    ManageApplicationComponent,
    JobseekerApplicationsComponent,
    ManageJobseekerApplicationComponent,
    RecruitingClientsComponent,
    RecruitingAgenciesComponent,
    OpenPositionComponent,
    OpenPositionsComponent,
    AddPositionComponent,
    PositionFormComponent,
    ManageOpenPositionComponent,
    ClientOpenPositionsComponent,
    ClientOpenPositionComponent,
  ],
})
export class FusionJobModule {}
