import {
  IForm,
  FormFieldType,
  FormFieldGroup,
  FormFieldPattern,
} from '@fusion/form';
import { countries } from '@fusion/common';

export const AddressForm: IForm = {
  metadata: {
    name: 'ADDRESS_FORM',
    title: 'Address',
    submitButtonText: 'Next',
    stepper: {
      isStepper: true,
      isLastStep: true
    },
    style: {
      topMargin: '0px',
      maxWidth: '800px',
      maxHeight: '400px',
    },
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: 'Address',
          name: 'address',
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 150,
          },
        },
        {
          label: 'Unit#',
          name: 'unit',
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 10,
          },
        },
        {
          label: 'City',
          name: 'city',
          type: FormFieldType.Text,
          placeholder: 'city',
          validation: {
            required: true,
            max: 100,
          },
        },
        {
          label: 'State',
          name: 'state',
          type: FormFieldType.Text,
          validation: {
            required: false,
          },
        },
        {
          label: 'Zip Code',
          name: 'zipCode',
          type: FormFieldType.Text,
          placeholder: 'zip code',
          examplePattern: '11432',
          validation: {
            required: false,
            max: 10,
            pattern: FormFieldPattern.Number,
          },
        },
        {
          label: 'Country',
          name: 'country',
          type: FormFieldType.Dropdown,
          options: countries,
          validation: {
            required: true,
          },
        },
      ],
    },
  ],
};
