import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap
} from "rxjs/operators";

import {
  SubscribeActionTypes,
  SubscribeSuccess,
  SubscribeFail,
  SubscribeStart
} from "../actions/index";
import { Store } from "@ngrx/store";
import { FusionSubscriptionState } from "../reducers/index";
import { SubscriptionService } from "@fusion/service";
import { getoAuthUserId } from "@fusion/oauth";
import { getRouterParams } from "@fusion/router";
import { Params } from "@angular/router";
import { AddJobseekerProfile } from "@fusion/career-profile";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import {
  FusionSubscriptionError,
  SubscriberType,
  ApplicationId
} from "../../models/enums";
import { ISubscriptionPayload } from "../../models/interfaces";

@Injectable()
export class SubscribeEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private subscriptionService: SubscriptionService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType<SubscribeStart>(SubscribeActionTypes.SubscribeStart),
    map(action => action.payload),
    withLatestFrom(
      this.store.select(getoAuthUserId),
      this.store.select(getRouterParams)
    ),
    mergeMap(
      ([payload, userId, params]: [ISubscriptionPayload, string, Params]) => {
        let errorPayload: IError<FusionSubscriptionError> = {
          code: FusionSubscriptionError.SubscribeFail,
          source: ErrorSource.Validation,
          data: null
        };

        const companyId = params.companyId;
        const subsctiotion = {
          application_id: payload.applicationId,
          subscriber_id: companyId || userId,
          subscriber_type: companyId
            ? SubscriberType.Company
            : SubscriberType.User,
          representative_id: userId
        };

        return this.subscriptionService.addSubscription(subsctiotion).pipe(
          switchMap(data => {
            if (payload.applicationId == ApplicationId.Career) {
              this.store.dispatch(new AddJobseekerProfile());
            }
            return [new SubscribeSuccess(data)];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue adding new subscription. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new SubscribeStart(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new SubscribeFail(errorPayload));
          })
        );
      }
    )
  ));
}
