import { IUser } from '@fusion/common';
import { getRouterParams } from '@fusion/router';
import { createSelector } from '@ngrx/store';
import { IGTMUser, IoAuthUser, ISession } from '../../models/interfaces';

import { getFusionoAuthState } from '../reducers';
import { getUserAppBaseUrl } from './applications.selectors';

export const getUserSessionState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.userSession;
  }
);

export const getUserSession = createSelector(getUserSessionState, (state) => {
  if (state == null) {
    return null;
  }
  return state.session;
});

export const getUserSessionToken = createSelector(getUserSession, (session) => {
  if (session == null) {
    return null;
  }
  return session.token;
});

// TODO rename as needed
export const getoAuthSession = createSelector(
  getUserSessionState,
  (oAuthSession): ISession => {
    if (oAuthSession === null) {
      return null;
    }
    return oAuthSession.session;
  }
);

export const getoAuthSessionToken = createSelector(
  getoAuthSession,
  (oAuthSession): string => {
    if (oAuthSession === null) {
      return null;
    }
    return oAuthSession.token;
  }
);

export const getoAuthDecodedToken = createSelector(
  getUserSessionState,
  (oAuthSession): IoAuthUser => {
    if (oAuthSession === null) {
      return null;
    }
    return oAuthSession.sessionProfile;
  }
);

export const getoAuthUser = createSelector(
  getoAuthDecodedToken,
  (decodedToken): IUser => {
    if (decodedToken === null) {
      return null;
    }
    return decodedToken.user || null;
  }
);

export const getoAuthUserId = createSelector(
  getoAuthUser,
  (oAuthUser): string => {
    if (oAuthUser === null) {
      return null;
    }
    return oAuthUser.id;
  }
);

export const hasUserProfileEditPermission = createSelector(
  getoAuthUserId,
  getRouterParams,
  (oAuthUserId, params): boolean => {
    if (oAuthUserId === null) {
      return null;
    }
    return oAuthUserId === params.userId;
  }
);

export const getoAuthUserFullName = createSelector(
  getoAuthUser,
  (oAuthUser): string => {
    if (oAuthUser === null) {
      return null;
    }
    return oAuthUser.middleName
      ? `${oAuthUser.firstName} ${oAuthUser.middleName} ${oAuthUser.lastName}`
      : `${oAuthUser.firstName} ${oAuthUser.lastName}`;
  }
);

export const getoAuthUserProfilePath = createSelector(
  getoAuthUserId,
  getUserAppBaseUrl,
  (oAuthUserId, userAppBaseUrl): string => {
    if (oAuthUserId === null || userAppBaseUrl == null) {
      return null;
    }

    return `${userAppBaseUrl}/users/${oAuthUserId}`;
  }
);

export const getGTMUserDetails = createSelector(
  getoAuthUser,
  getoAuthUserFullName,
  (user, userName): IGTMUser => {
    if (user === null) {
      return null;
    }
    return {
      event: 'signin',
      userId: user.id,
      email: user.email,
      name: userName,
      country: user.country,
    };
  }
);
