import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromSubscriptions from './subscriptions.reducer';
import * as fromSubscribe from './subscribe.reducer';
import * as fromRedirectSubscriber from './redirect-subscriber.reducer';
import * as fromCurrentApp from './current-app.reducer';
import * as fromProfileNavigation from './profile-navigation.reducer';
import * as fromCurrentSubscription from './current-subscription.reducer';
import * as fromPermissions from './permissions.reducer';
import * as fromSeo from './seo.reducer';

export interface FusionSubscriptionState {
  subscriptions: fromSubscriptions.SubscriptionsState;
  subscribe: fromSubscribe.SubscribeState;
  redirectSubscriber: fromRedirectSubscriber.RedirectSubscriberState;
  currentApp: fromCurrentApp.CurrentAppState;
  profileNavigation: fromProfileNavigation.ProfileNavigationState;
  currentSubscription: fromCurrentSubscription.CurrentSubscriptionState;
  permissions: fromPermissions.PermissionsState;
  seo: fromSeo.SeoState;
}

export const reducers: ActionReducerMap<FusionSubscriptionState> = {
  subscriptions: fromSubscriptions.reducer,
  subscribe: fromSubscribe.reducer,
  redirectSubscriber: fromRedirectSubscriber.reducer,
  currentApp: fromCurrentApp.reducer,
  profileNavigation: fromProfileNavigation.reducer,
  currentSubscription: fromCurrentSubscription.reducer,
  permissions: fromPermissions.reducer,
  seo: fromSeo.reducer,
};

export const getFusionSubscriptionState =
  createFeatureSelector<FusionSubscriptionState>('fusionSubscription');

// export reducer state interfaces
