import { createSelector } from '@ngrx/store';

import { getFusionoAuthState } from '../reducers';
import { IPermission } from '../../models/interfaces';
import { ICompany, IUser } from '@fusion/common';

export const getUserProfileState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.userProfile;
  }
);

export const getUserProfile = createSelector(getUserProfileState, (state) => {
  if (state == null) {
    return null;
  }
  return state.data;
});

export const getUser = createSelector(getUserProfile, (state): IUser => {
  if (state == null) {
    return null;
  }
  return state.user;
});
