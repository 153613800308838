import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarComponent } from './containers/calendar/calendar.component';
import { effects, reducers } from './store';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { JoinEventComponent } from './components/join-event/join-event.component';
import { FusionCompanyModule } from '@fusion/company';
import { UpdateEventComponent } from './components/update-event/update-event.component';
import { EventEditComponent } from './containers/event-edit/event-edit.component';
import { EventEditFormComponent } from './components/event-edit-form/event-edit-form.component';
import { FusionCommonModule } from '@fusion/common';
import { InterviewComponent } from './containers/interview/interview.component';
import { FusionPipeModule } from '@fusion/pipe';
import { InterviewEditComponent } from './containers/interview-edit/interview-edit.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    CalendarComponent,
    CreateEventComponent,
    JoinEventComponent,
    UpdateEventComponent,
    EventEditComponent,
    EventEditFormComponent,
    InterviewComponent,
    InterviewEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    FusionCompanyModule,
    FusionCommonModule,
    FusionPipeModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    StoreModule.forFeature('calendar', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [
    CalendarComponent,
    EventEditComponent,
    InterviewComponent,
    InterviewEditComponent,
  ],
})
export class FusionCalendarModule {}
