import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import {
  getJobseekerInfo,
  getCareerSummary,
  getExperiences,
  getEducations,
  getProjects,
  getPublications,
  getSkills,
  getRatings,
  IEducation,
  IExperience,
  IProject,
  IPublication,
  ISkill,
  IRating,
  AddExperiences,
  UpdateExperiences,
  UpdateEducations,
  UpdateJobseekerProfile,
  UpdateProject,
  AddProject,
  UpdatePublication,
  AddPublication,
  DeleteExperiences,
  AddEducations,
  DeleteEducations,
  DeleteProject,
  DeletePublication,
} from '@fusion/career-profile';
import { Observable } from 'rxjs';
import {
  IFormData,
  EXPERIENCE_FORM,
  EDUCATION_FORM,
  CAREER_SUMMARY_FORM,
  PUBLICATION_FORM,
  PROJECT_FORM,
  EDUCATION_UPDATE_FORM,
  EXPERIENCE_UPDATE_FORM,
  PROJECT_UPDATE_FORM,
  PUBLICATION_UPDATE_FORM,
} from '@fusion/common';
import { getRouterUrl } from '@fusion/router';
import { hasUserProfileEditPermission } from '@fusion/oauth';
import { getCompanies, SearchCompanies } from '@fusion/company';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'user-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  jobseekerInfo$: Observable<any>;
  careerSummary$: Observable<string>;
  educations$: Observable<IEducation[]>;
  experiences$: Observable<IExperience[]>;
  projects$: Observable<IProject[]>;
  publications$: Observable<IPublication[]>;
  skills$: Observable<ISkill[]>;
  ratings$: Observable<IRating[]>;
  companies$: Observable<any[]>;
  hasUserProfileEditPermission$: Observable<boolean>;

  currentRootPath$: Observable<string>;

  constructor(
    private store: Store<UserState>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getRouterUrl);
    this.jobseekerInfo$ = this.store.select(getJobseekerInfo);
    this.hasUserProfileEditPermission$ = this.store.select(
      hasUserProfileEditPermission
    );
    this.careerSummary$ = this.store.select(getCareerSummary);
    this.experiences$ = this.store.select(getExperiences);
    this.educations$ = this.store.select(getEducations);
    this.projects$ = this.store.select(getProjects);
    this.publications$ = this.store.select(getPublications);
    this.skills$ = this.store.select(getSkills);
    this.ratings$ = this.store.select(getRatings);
    this.companies$ = this.store.select(getCompanies);

    // set seo
    // this.store.dispatch(new SetUserSeo());
  }

  onNotifyFormSubmit(formData: IFormData<any>) {
    const data = formData.data;
    switch (formData.name) {
      case EXPERIENCE_FORM: {
        this.store.dispatch(new AddExperiences(data));
        break;
      }
      case EXPERIENCE_UPDATE_FORM: {
        this.store.dispatch(new UpdateExperiences(data));
        break;
      }
      case EDUCATION_FORM: {
        this.store.dispatch(new AddEducations(data));
        break;
      }
      case EDUCATION_UPDATE_FORM: {
        this.store.dispatch(new UpdateEducations(data));
        break;
      }
      case PROJECT_FORM: {
        this.store.dispatch(new AddProject(data));
        break;
      }
      case PROJECT_UPDATE_FORM: {
        this.store.dispatch(new UpdateProject(data));
        break;
      }
      case PUBLICATION_FORM: {
        this.store.dispatch(new AddPublication(data));
        break;
      }
      case PUBLICATION_UPDATE_FORM: {
        this.store.dispatch(new UpdatePublication(data));
        break;
      }
      case CAREER_SUMMARY_FORM: {
        this.store.dispatch(new UpdateJobseekerProfile(data));
        break;
      }
      default: {
        console.log(`${formData.name}: is unsupported form!`, data);
      }
    }
  }

  onNotifyAutocompletion(formData) {
    this.store.dispatch(new SearchCompanies(formData));
  }

  onNotifyDeleteExperience(experienceId) {
    this.store.dispatch(new DeleteExperiences(experienceId));
  }

  onNotifyDeleteEducation(educationId) {
    this.store.dispatch(new DeleteEducations(educationId));
  }

  onNotifyDeleteProject(projectId) {
    this.store.dispatch(new DeleteProject(projectId));
  }

  onNotifyDeletePublication(publicationId) {
    this.store.dispatch(new DeletePublication(publicationId));
  }

  onNotifyViewCompany(companyId: string) {
    const currentUrl = this.router.url;
    this.router.navigate([`../../companies/${companyId}`], {
      queryParams: { referrerUrl: currentUrl },
      relativeTo: this.route,
    });
  }
}
