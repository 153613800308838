export enum CalendarEventType {
  VideoConference = 'video-conference',
  PhoneConference = 'phone-conference',
  InPersonConference = 'in-person-conference',
}

export enum EventCategory {
  Meeting = 'meeting',
  Interview = 'interview',
}
