import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  switchMap,
  withLatestFrom,
  map,
  tap,
} from 'rxjs/operators';

import {
  OrderActionTypes,
  LoadOrderSuccess,
  LoadOrderFail,
  AddOrder,
  OrderExist,
  AddOrderSuccess,
  AddOrderFail,
  AddOrderItem,
  AddOrderItemSuccess,
  AddOrderItemFail,
  LoadOrder,
  ConfirmOrder,
  ConfirmOrderFail,
  ConfirmOrderSuccess,
  LoadPendingOrderSuccess,
  LoadPendingOrderFail,
  LoadPendingOrder,
  ActivateOrderItem,
} from '../actions/order.actions';
import { ProductService, MappingService, mappingType } from '@fusion/service';
import { Store, Action } from '@ngrx/store';
import { FusionPaymentState } from '../reducers/index';
import { getRouterParams, getRouterUrlRoot } from '@fusion/router';
import { Params, Router } from '@angular/router';
import { getCustomerType, getOrder } from '../selectors/index';
import {
  IOrderPayload,
  IOrderItemPayload,
  IPendingOrderPayload,
  IOrder,
} from '../../models/interfaces/index';
import { getoAuthUserId, getoAuthUser } from '@fusion/oauth';
import { IUser } from '@fusion/common';
import {
  getCurrentAppMetadata,
  ICurrentAppMetadata,
} from '@fusion/subscription';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionPaymentError } from '../../models/enums';

@Injectable()
export class OrderEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionPaymentState>,
    private productService: ProductService,
    private mappingService: MappingService,
    private router: Router
  ) {}

  loadOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActionTypes.LoadOrder, OrderActionTypes.OrderExist),
      withLatestFrom(
        this.store.select(getoAuthUser),
        this.store.select(getRouterParams),
        this.store.select(getCurrentAppMetadata)
      ),
      mergeMap(
        ([action, user, params, currentApp]: [
          any,
          IUser,
          Params,
          ICurrentAppMetadata
        ]) => {
          let errorPayload: IError<FusionPaymentError> = {
            code: FusionPaymentError.LoadOrderFail,
            source: ErrorSource.Validation,
            data: null,
          };
          const subscriberId = params.subscriberId;
          return this.productService
            .getCurrentOrder(subscriberId, user.id, currentApp.applicationId)
            .pipe(
              switchMap((dataResult) => {
                const mappedData = this.mappingService.getMappedData(
                  dataResult,
                  mappingType.camelize
                );
                return [new LoadOrderSuccess(mappedData)];
              }),
              catchError((error) => {
                errorPayload = {
                  ...errorPayload,
                  source: ErrorSource.API,
                  data: error,
                  config: {
                    type: ErrorHandlingType.Dialog,
                    message:
                      'Sorry, we are having some issue loading your order. Please try again later.',
                    action: {
                      primary: {
                        type: ErrorActionType.Dispatch,
                        reference: [new LoadOrder()],
                        title: 'Retry',
                      },
                    },
                  },
                };
                return of(new LoadOrderFail(errorPayload));
              })
            );
        }
      )
    )
  );

  loadPendingOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadPendingOrder>(OrderActionTypes.LoadPendingOrder),
      map((action) => action.payload),
      withLatestFrom(
        this.store.select(getoAuthUser),
        this.store.select(getRouterUrlRoot),
        this.store.select(getRouterParams)
      ),
      mergeMap(
        ([payload, user, currentUrl, params]: [
          IPendingOrderPayload,
          IUser,
          string,
          Params
        ]) => {
          let errorPayload: IError<FusionPaymentError> = {
            code: FusionPaymentError.LoadPendingOrderFail,
            source: ErrorSource.Validation,
            data: null,
          };
          const customerId = params.subscriberId;
          return this.productService.getPendingOrders(customerId, user.id).pipe(
            switchMap((dataResult) => {
              const mappedData = this.mappingService.getMappedData(
                dataResult,
                mappingType.camelize
              );
              return [
                new LoadPendingOrderSuccess(
                  this.cleanupPendingOrder(
                    mappedData,
                    user,
                    currentUrl,
                    payload.referencePath
                  )
                ),
              ];
            }),
            catchError((error) => {
              errorPayload = {
                ...errorPayload,
                source: ErrorSource.API,
                data: error,
                config: {
                  type: ErrorHandlingType.Dialog,
                  message:
                    'Sorry, we are having some issue loading your pending order. Please try again later.',
                  action: {
                    primary: {
                      type: ErrorActionType.Dispatch,
                      reference: [new LoadPendingOrder(payload)],
                      title: 'Retry',
                    },
                  },
                },
              };
              return of(new LoadPendingOrderFail(errorPayload));
            })
          );
        }
      )
    )
  );

  addOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddOrder>(OrderActionTypes.AddOrder),
      map((action) => action.payload),
      withLatestFrom(
        this.store.select(getCustomerType),
        this.store.select(getRouterParams),
        this.store.select(getoAuthUserId),
        this.store.select(getCurrentAppMetadata)
      ),
      mergeMap(
        ([payload, userType, params, userId, currentApp]: [
          IOrderPayload,
          string,
          Params,
          string,
          ICurrentAppMetadata
        ]) => {
          let errorPayload: IError<FusionPaymentError> = {
            code: FusionPaymentError.AddOrderFail,
            source: ErrorSource.Validation,
            data: null,
          };
          const orderData = {
            customer_id: params.subscriberId,
            customer_type: userType,
            representative_user_id: userId,
            is_shippable: payload.isShippable,
            application_id: currentApp.applicationId,
          };

          return this.productService.addOrder(orderData).pipe(
            switchMap((dataResult) => {
              if (!dataResult) {
                return [new OrderExist()];
              }
              const mappedData = this.mappingService.getMappedData(
                dataResult,
                mappingType.camelize
              );
              return [new AddOrderSuccess(mappedData), new LoadOrder()];
            }),
            catchError((error) => {
              errorPayload = {
                ...errorPayload,
                source: ErrorSource.API,
                data: error,
                config: {
                  type: ErrorHandlingType.Dialog,
                  message:
                    'Sorry, we are having some issue adding your order. Please try again later.',
                  action: {
                    primary: {
                      type: ErrorActionType.Dispatch,
                      reference: [new AddOrder(payload)],
                      title: 'Retry',
                    },
                  },
                },
              };
              return of(new AddOrderFail(errorPayload));
            })
          );
        }
      )
    )
  );

  addOrderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddOrderItem>(OrderActionTypes.AddOrderItem),
      map((action) => action.payload),
      withLatestFrom(this.store.select(getOrder)),
      mergeMap(([payload, order]: [IOrderItemPayload, IOrder]) => {
        let errorPayload: IError<FusionPaymentError> = {
          code: FusionPaymentError.AddOrderItemFail,
          source: ErrorSource.Validation,
          data: null,
        };
        const itemData = {
          product_id: payload.productId,
          quantity: payload.quantity,
          reference_id: payload.orderReferenceId,
        };

        if (order) {
          // TODO remove once supporting multiple items per order
          const existingOrderItem = order.items.find(
            (item) => item.id === payload.productId
          );
          if (existingOrderItem) {
            // return [];
          }
          return this.productService.addOrderItem(order.id, itemData).pipe(
            switchMap((dataResult) => {
              const mappedData = this.mappingService.getMappedData(
                dataResult,
                mappingType.camelize
              );
              return [new AddOrderItemSuccess(mappedData), new LoadOrder()];
            }),
            catchError((error) => {
              errorPayload = {
                ...errorPayload,
                source: ErrorSource.API,
                data: error,
                config: {
                  type: ErrorHandlingType.Dialog,
                  message:
                    'Sorry, we are having some issue adding item in your order. Please try again later.',
                  action: {
                    primary: {
                      type: ErrorActionType.Dispatch,
                      reference: [new AddOrderItem(payload)],
                      title: 'Retry',
                    },
                  },
                },
              };
              return of(new AddOrderItemFail(errorPayload));
            })
          );
        }
        // return of();
      })
    )
  );

  confirmOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConfirmOrder>(OrderActionTypes.ConfirmOrder),
      withLatestFrom(this.store.select(getOrder)),
      mergeMap(([payload, order]: [any, any]) => {
        let errorPayload: IError<FusionPaymentError> = {
          code: FusionPaymentError.ConfirmOrderFail,
          source: ErrorSource.Validation,
          data: null,
        };
        const orderData = {
          is_completed: true,
        };
        return this.productService.confirmOrder(order.id, orderData).pipe(
          switchMap((dataResult) => {
            const mappedData: any = this.mappingService.getMappedData(
              dataResult,
              mappingType.camelize
            );

            return [
              new ConfirmOrderSuccess(mappedData),
              new ActivateOrderItem(dataResult.referenceId),
            ];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue confirming your order. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new ConfirmOrder()],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new ConfirmOrderFail(errorPayload));
          })
        );
      })
    )
  );

  confirmOrderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConfirmOrderSuccess>(OrderActionTypes.ConfirmOrderSuccess),
      withLatestFrom(this.store.select(getRouterUrlRoot)),
      switchMap(([action, path]: [Action, string]) => {
        this.router.navigate([`${path}/success`]);
        return [];
      })
    )
  );

  cleanupPendingOrder(
    orders,
    user: IUser,
    currentUrl: string,
    referencePath: string
  ) {
    return orders.map((order, index) => {
      return {
        position: index + 1,
        metadata: {
          date: order.updatedAt,
          agentName: `${user.firstName} ${user.lastName}`,
          agentId: user.id,
        },
        status: order.isCompleted,
        checkout: referencePath
          ? `${currentUrl}/${referencePath}/${order.referenceId}/checkout`
          : `${currentUrl}/${order.referenceId}/checkout`,
        reference: referencePath
          ? `${currentUrl}/${referencePath}/${order.referenceId}`
          : `${currentUrl}/${order.referenceId}`,
      };
    });
  }
}
