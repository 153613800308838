import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import {
  IFundingOption,
  getCurrentFunding,
  LoadCurrentFundingOption,
  AddFunding,
  LoadContributors,
  IContributor,
  getFundingContributors,
} from '@fusion/payment';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'user-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.scss'],
})
export class ContributorsComponent implements OnInit {
  funding$: Observable<IFundingOption>;
  contributors$: Observable<IContributor[]>;

  constructor(
    private store: Store<UserState>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadCurrentFundingOption());
    this.store.dispatch(new LoadContributors());

    this.funding$ = this.store.select(getCurrentFunding);
    this.contributors$ = this.store.select(getFundingContributors);
  }

  onNotifyFundingSelection(fundingOption: IFundingOption) {
    this.store.dispatch(new AddFunding(fundingOption));
    this.router.navigate([`payment`], {
      relativeTo: this.route,
    });
  }
}
