import { createSelector } from '@ngrx/store';

import { getFusionJobState } from '../reducers/index';
import { IJob } from '../../models/interfaces/job.interfaces';

export const getJobsState = createSelector(getFusionJobState, (state) => {
  if (state == null) {
    return null;
  }
  return state.jobs;
});

export const getJobEntities = createSelector(getJobsState, (state) => {
  if (state == null) {
    return null;
  }
  return state.entities;
});

export const getJobCount = createSelector(getJobsState, (state) => {
  if (state == null) {
    return null;
  }
  return state.count;
});

export const jobsLoading = createSelector(getJobsState, (state) => {
  if (state == null) {
    return null;
  }
  return state.loading;
});

// TODO include metadata in store once processing data mapping
export const getJobs = createSelector(getJobEntities, (jobs): IJob[] => {
  if (jobs == null) {
    return null;
  }

  return jobs.map((job) => {
    return {
      ...job,
      metadata: [
        {
          isSalary: false,
          label: job.level
        },
        {
          isSalary: false,
          label: job.type
        },
        {
          isSalary: false,
          label: job.category
        },
        {
          isSalary: !job.negotiable,
          amount: job.salary,
          currencyCode: job.currencyCode,
          label: !job.negotiable ? `${job.salaryUnit}` : `Negotiable`,
        },
      ],
    };
  });
});
