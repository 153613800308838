import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StripeComponent } from "./containers/stripe/stripe.component";

@NgModule({
  declarations: [StripeComponent],
  imports: [CommonModule],
  exports: [StripeComponent],
})
export class StripeModule {}
