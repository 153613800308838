<fusion-career-profile-header
  [jobseekerInfo]="jobseekerInfo$ | async"
></fusion-career-profile-header>
<div pageSpacing extralargePage pageBody>
  <div pageContent>
    <div content>
      <fusion-career-profile-body
        [allowEdit]="hasUserProfileEditPermission$ | async"
        [careerSummary]="careerSummary$ | async"
        [experiences]="experiences$ | async"
        [educations]="educations$ | async"
        [skills]="skills$ | async"
        [projects]="projects$ | async"
        [publications]="publications$ | async"
        [autoCompleteEntities]="companies$ | async"
        (notifyFormSubmit)="onNotifyFormSubmit($event)"
        (notifyAutocompletion)="onNotifyAutocompletion($event)"
        (notifyDeleteExperience)="onNotifyDeleteExperience($event)"
        (notifyDeleteEducation)="onNotifyDeleteEducation($event)"
        (notifyDeleteProject)="onNotifyDeleteProject($event)"
        (notifyDeletePublication)="onNotifyDeletePublication($event)"
        (notifyViewCompany)="onNotifyViewCompany($event)"
      ></fusion-career-profile-body>
    </div>
    <div adSpace></div>
  </div>
</div>
