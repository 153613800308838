import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { IAppSettings, ISideNav } from "../../models/interfaces";
import { Observable } from "rxjs";
import { DeviceService } from "../../services/device/device.service";
import { tap } from "rxjs/operators";

@Component({
  selector: "fusion-oauth-body",
  templateUrl: "./body.component.html",
  styleUrls: ["./body.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BodyComponent implements OnInit {
  @Input() isHomeApp: boolean;
  @Input() sideNav: ISideNav[];
  @Input() currentRootPath: string;
  @Input() isUserApp: boolean;
  @Input() appSettings: IAppSettings;

  isMobile$: Observable<boolean>;
  isMobile: boolean = false;

  constructor(
    private deviceService: DeviceService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.cd.detectChanges();
    this.isMobile$ = this.deviceService.isMobile$.pipe(
      tap(result => (this.isMobile = result))
    );
    this.cd.detectChanges();
  }
}
