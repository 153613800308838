import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { AddCardDialogComponent } from "../add-card-dialog/add-card-dialog.component";
import { IStripeBillingAddress } from "../../models/interfaces";

@Component({
  selector: "fusion-payment-add-card",
  templateUrl: "./add-card.component.html",
  styleUrls: ["./add-card.component.scss"]
})
export class AddCardComponent implements OnInit, OnChanges {
  @Output() notifyPaymentMethod: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() notifyAddCard: EventEmitter<any> = new EventEmitter<any>();
  @Input() address: IStripeBillingAddress;
  @Input() clientSecret: string;
  private dialogRef: MatDialogRef<AddCardDialogComponent>;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  ngOnChanges() {
    // any data from observable need to re-assign on change
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.clientSecret = this.clientSecret;
      this.dialogRef.componentInstance.address = this.address;
    }
  }

  openDialog(): void {
    this.notifyAddCard.emit(event);
    this.dialogRef = this.dialog.open(AddCardDialogComponent, {
      width: "800px",
      height: "700px",
      data: {
        address: this.address,
        clientSecret: this.clientSecret
      }
    });

    this.dialogRef.componentInstance.notifyPaymentMethod.subscribe(
      (paymentMethod: string) => {
        this.notifyPaymentMethod.emit(paymentMethod);
      }
    );
  }
}
