<div class="image-upload row2">
  <div pointer border radius image>
    <img radius *ngIf="image" imageFit [src]="image" />
    <div
      centering
      center
      imageOverlayTransparent
      *ngIf="isUploading"
      class="loading-shade"
    >
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <div centering center imageOverlayTransparent>
      <label class="banner-image__label" for="banner-image__file-input">
        <div mediumFontSize>
          <mat-icon largeFont>cloud_upload</mat-icon>
        </div>
        <div *ngIf="uploadTitle" microTitle>{{ uploadTitle }}</div>
      </label>

      <input
        accept="image/*"
        id="banner-image__file-input"
        class="banner-image__file-input"
        type="file"
        (change)="onFileChange($event)"
      />
    </div>
  </div>
  <div *ngIf="!isActiveProperty" centering centerRight>
    <button mat-raised-button color="primary" (click)="notifyCheckout.emit()">
      Continue to Checkout
    </button>
  </div>
</div>
