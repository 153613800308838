import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormConfig } from '../../models/interfaces';

@Component({
  selector: 'fusion-form-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss']
})
export class DatetimepickerComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;
  hide: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
