<div
  class="form-group"
  [ngClass]="{
    'has-error':
      !formValidationRules.controls[formConfigs.name].valid &&
      formValidationRules.controls[formConfigs.name].touched
  }"
>
  <div class="example-container inputGroupContainer">
    <mat-form-field>
      <mat-label>{{ formConfigs.label }} </mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [formControl]="formValidationRules.controls[formConfigs.name]"
        required="{{
          formValidationRules.controls[formConfigs.name].hasError('required')
        }}"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-error
      *ngIf="
        formValidationRules.controls[formConfigs.name].hasError('required') &&
        formValidationRules.controls[formConfigs.name].touched
      "
    >
      You must include {{ formConfigs.label }}.
    </mat-error>
    <mat-error
      *ngIf="
        formValidationRules.controls[formConfigs.name].hasError('pattern') &&
        formValidationRules.controls[formConfigs.name].touched
      "
    >
      Your {{ formConfigs.label }} is invalid. Allowed format is
      <b>{{ formConfigs.examplePattern }}</b>
    </mat-error>
    <mat-error
      *ngIf="
        formValidationRules.controls[formConfigs.name].hasError('minlength') &&
        formValidationRules.controls[formConfigs.name].touched
      "
    >
      Your {{ formConfigs.label }} must be at least
      {{ formConfigs.validation.min }} characters long.
    </mat-error>
    <mat-error
      *ngIf="
        formValidationRules.controls[formConfigs.name].hasError('maxlength') &&
        formValidationRules.controls[formConfigs.name].touched
      "
    >
      Your {{ formConfigs.label }} cannot exceed
      {{ formConfigs.validation.max }} characters.
    </mat-error>
  </div>
</div>
