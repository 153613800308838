import { Component, OnInit, Input } from '@angular/core';
import { IJobMetadata } from '../../models/interfaces';

@Component({
  selector: 'fusion-job-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  @Input() metadata: IJobMetadata[];
  @Input() chipColor: string;

  constructor() {}

  ngOnInit() {}
}
