import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ISearchPagination,
  ISort,
  sortOption,
  ISearchForm,
  jobCategories,
} from '@fusion/common';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import {
  RestoreSearch,
  getSearchKeywords,
  getSearchCategories,
  getSearchPagination,
  getSearchLevels,
  getSearchTypes,
  getSearchOrderBy,
  SetSearchCategory,
  SetSearchKeywords,
  SetSearchType,
  SetSearchLevel,
  SetSearchPagination,
  SetOrderBy,
  SearchStart,
  SearchOrderType,
} from '@fusion/search';
import { getRouterUrlRoot } from '@fusion/router';
import {
  getJobs,
  getJobCount,
  jobsLoading,
  LoadCurrentJobSuccess,
  IJob,
  LoadJobs,
} from '@fusion/job';

@Component({
  selector: 'user-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  orderBy$: Observable<string>;
  jobCount$: Observable<number>;
  jobPagination$: Observable<ISearchPagination>;
  jobs$: Observable<IJob[]>;
  keywords$: Observable<string>;
  jobCategory$: Observable<string>;
  jobLevels$: Observable<any>;
  jobTypes$: Observable<any>;
  isJobLoading$: Observable<boolean>;
  currentRootPath$: Observable<string>;
  removable: boolean = true;
  sortOption: ISort = sortOption;
  searchForm: ISearchForm;

  constructor(private store: Store<UserState>) {}

  ngOnInit() {
    // restore search state from url
    this.store.dispatch(new SearchStart(new LoadJobs()));

    // search form
    this.searchForm = {
      search: {
        label: 'Job Search',
        paceholder: 'Search by Position, Category or Company!',
      },
      searchDropdown: {
        categories: jobCategories,
        label: 'Search by Category',
      },
    };

    // select query
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.keywords$ = this.store.select(getSearchKeywords);
    this.jobCategory$ = this.store.select(getSearchCategories);
    this.jobPagination$ = this.store.select(getSearchPagination);
    this.jobLevels$ = this.store.select(getSearchLevels);
    this.jobTypes$ = this.store.select(getSearchTypes);
    this.jobs$ = this.store.select(getJobs);
    this.jobCount$ = this.store.select(getJobCount);
    this.isJobLoading$ = this.store.select(jobsLoading);
    this.orderBy$ = this.store.select(getSearchOrderBy);
  }

  notifyRemoveCategory(): void {
    this.store.dispatch(new SetSearchCategory(null));
  }

  notifySearch(keywords: string) {
    this.store.dispatch(new SetSearchKeywords(keywords));
  }

  notifyJobCategory(category: string) {
    this.store.dispatch(new SetSearchCategory(category));
  }

  notifyJobType(jobType: string) {
    this.store.dispatch(new SetSearchType(jobType));
  }

  notifyJobLevel(jobLevel: string) {
    this.store.dispatch(new SetSearchLevel(jobLevel));
  }

  notifyPagination(event: ISearchPagination) {
    const pagination: ISearchPagination = {
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
      offset: (event.pageIndex + 1) * event.pageSize - event.pageSize,
    };
    this.store.dispatch(new SetSearchPagination(pagination));
  }

  notifySort(order: SearchOrderType) {
    this.store.dispatch(new SetOrderBy(order));
  }

  notifyCurrentJob(currentJob: IJob) {
    this.store.dispatch(new LoadCurrentJobSuccess(currentJob));
  }
}
