import { CalendarEvent } from 'angular-calendar';
import { CalendarEventType, EventCategory, JoiningStatus } from '../enums';
import { ICalendarEventMetadata } from '../interfaces';
import { colors } from './colors.data';

export const rawEvents: CalendarEvent<ICalendarEventMetadata>[] = [
  {
    id: '7cc89f92-dfa8-44df-ac1f-b836efa74826',
    start: new Date('2022-05-06T04:00:00.000Z'),
    end: new Date('2022-05-08T22:42:54.882Z'),
    title: 'A 3 day event',
    color: colors.pending,
    cssClass: 'fusion-calendar-event__title',
    allDay: true,
    resizable: { beforeStart: true, afterEnd: true },
    draggable: true,
    meta: {
      eventCategory: EventCategory.Meeting,
      eventType: CalendarEventType.VideoConference,
      // guestCount: 3,
      // acceptedGuestCount: 1,
      // rejectedGuestCount: 1,
      // waitingguestCount: 1,
      guests: [
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: true,
          joiningStatus: JoiningStatus.Accepted,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Shakil',
            middleName: null,
            lastName: 'Khan',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Pending,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Sharmin',
            middleName: null,
            lastName: 'Rima',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c6ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Rejected,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c6ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Fatima',
            middleName: 'Khan',
            lastName: 'Ayra',
            phoneNo: '(347) 754-9322',
            profilePicture: null,
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
      ],
    },
  },
  {
    id: '27aa5bd8-df19-46e2-839a-9bd684a8420f',
    start: new Date('2022-05-07T04:00:00.000Z'),
    end: new Date('2022-05-07T05:00:00.000Z'),
    title: 'An event with no end date',
    color: colors.pending,
    cssClass: 'fusion-calendar-event__title',
    meta: {
      eventCategory: EventCategory.Meeting,
      eventType: CalendarEventType.VideoConference,
      // guestCount: 3,
      // acceptedGuestCount: 1,
      // rejectedGuestCount: 1,
      // waitingguestCount: 1,
      guests: [
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: true,
          joiningStatus: JoiningStatus.Accepted,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Shakil',
            middleName: null,
            lastName: 'Khan',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Pending,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Sharmin',
            middleName: null,
            lastName: 'Rima',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c6ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Rejected,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c6ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Fatima',
            middleName: 'Khan',
            lastName: 'Ayra',
            phoneNo: '(347) 754-9322',
            profilePicture: null,
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
      ],
    },
  },
  {
    id: 'da55ac03-511e-4959-ba0b-091f071d2d1b',
    start: new Date('2022-05-29T03:59:59.999Z'),
    end: new Date('2022-06-04T03:59:59.999Z'),
    title: 'A long event that spans 2 months',
    color: colors.pending,
    cssClass: 'fusion-calendar-event__title',
    allDay: true,
    meta: {
      eventCategory: EventCategory.Meeting,
      eventType: CalendarEventType.VideoConference,
      // guestCount: 3,
      // acceptedGuestCount: 1,
      // rejectedGuestCount: 1,
      // waitingguestCount: 1,
      guests: [
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: true,
          joiningStatus: JoiningStatus.Accepted,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Shakil',
            middleName: null,
            lastName: 'Khan',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Pending,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Sharmin',
            middleName: null,
            lastName: 'Rima',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c6ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Rejected,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c6ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Fatima',
            middleName: 'Khan',
            lastName: 'Ayra',
            phoneNo: '(347) 754-9322',
            profilePicture: null,
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
      ],
    },
  },
  {
    id: '46a8106a-0303-4366-b0c0-06c47b48f31b',
    start: new Date('2022-05-07T06:00:00.000Z'),
    end: new Date('2022-05-08T00:42:54.882Z'),
    title: 'A draggable and resizable event',
    color: colors.pending,
    cssClass: 'fusion-calendar-event__title',
    resizable: { beforeStart: true, afterEnd: true },
    draggable: true,
    meta: {
      eventCategory: EventCategory.Meeting,
      eventType: CalendarEventType.VideoConference,
      // guestCount: 3,
      // acceptedGuestCount: 1,
      // rejectedGuestCount: 1,
      // waitingguestCount: 1,
      guests: [
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: true,
          joiningStatus: JoiningStatus.Accepted,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Shakil',
            middleName: null,
            lastName: 'Khan',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Pending,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Sharmin',
            middleName: null,
            lastName: 'Rima',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c6ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Rejected,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c6ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Fatima',
            middleName: 'Khan',
            lastName: 'Ayra',
            phoneNo: '(347) 754-9322',
            profilePicture: null,
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
      ],
    },
  },
  {
    id: '10648b55-961f-4f4c-82cb-a15576ddb4fa',
    start: new Date('2022-05-10T18:00:00.000Z'),
    end: new Date('2022-05-10T19:00:00.000Z'),
    title:
      'A large title experiment that will help me to decide how it workscd',
    color: colors.pending,
    cssClass: 'fusion-calendar-event__title',
    resizable: { beforeStart: true, afterEnd: true },
    draggable: true,
    meta: {
      eventCategory: EventCategory.Meeting,
      eventType: CalendarEventType.VideoConference,
      description: `Hi Shakil,
      Feel free to suggest an alternate time if this time does not work. 
      Hope you are well. I'm working on tech approach for an test-UI preview on Maestro for various tests. I wanted to syncup and get some insights from you. Hope this time works for you. Talk to you soon
      - Geeta`,
      // guestCount: 3,
      // acceptedGuestCount: 1,
      // rejectedGuestCount: 1,
      // waitingguestCount: 1,
      guests: [
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c9ae',
          isOrganizer: true,
          joiningStatus: JoiningStatus.Accepted,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c9ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Shakil',
            middleName: null,
            lastName: 'Khan',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '2b023af6-344f-47d2-acc7-4a6d3088152d',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Pending,
          note: null,
          user: {
            id: '2b023af6-344f-47d2-acc7-4a6d3088152d',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Sharmin',
            middleName: null,
            lastName: 'Rima',
            phoneNo: '(347) 754-9322',
            profilePicture:
              'https://fusion-cdn.nyc3.digitaloceanspaces.com/qa-user-api/user/335d687c-a675-47de-b12f-cf1374c8c9ae/1650911226858.jpg?AWSAccessKeyId=CFALQ25Z6CVGEWYFJV3Q&Expires=1652216267&Signature=poevz3hcvqJWAk0Gqj3lj34X5QU%3D',
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
        {
          userId: '335d687c-a675-47de-b12f-cf1374c8c6ae',
          isOrganizer: false,
          joiningStatus: JoiningStatus.Rejected,
          note: null,
          user: {
            id: '335d687c-a675-47de-b12f-cf1374c8c6ae',
            gender: null,
            email: 'shakil.khan@gmail.com',
            address: '166-05 Highland Ave',
            userType: null,
            password: null,
            unit: '5X',
            city: 'Jamaica',
            state: 'NY',
            country: 'US',
            privacy: true,
            subscribe: false,
            firstName: 'Fatima',
            middleName: 'Khan',
            lastName: 'Ayra',
            phoneNo: '(347) 754-9322',
            profilePicture: null,
            bannerPicture: null,
            zipCode: 11432,
            createdAt: '2022-04-25T17:44:26.379Z',
            updatedAt: '2022-04-25T18:34:46.077Z',
          },
        },
      ],
    },
  },
];
