import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IDropdown } from '@fusion/common';
import { getCompany, ICompany } from '@fusion/company';
import {
  IFormConfig,
  IFormMetadata,
  setFormValidationRules,
} from '@fusion/form';
import { getRouterUrlRoot } from '@fusion/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { applicationStatus } from '../../models/data';
import { ApplicationStatus } from '../../models/enums';
import { CoverLetterForm, ManageApplicationForm } from '../../models/forms';
import { IJob, IJobApplication, IScreening } from '../../models/interfaces';
import {
  AcceptInterview,
  AddScreening,
  LoadCurrentApplication,
  LoadCurrentJob,
  UpdateScreening,
} from '../../store/actions';
import { FusionJobState } from '../../store/reducers';
import {
  getCurrentApplication,
  getCurrentJob,
  getScreenings,
} from '../../store/selectors';

@Component({
  selector: 'fusion-job-manage-jobseeker-application',
  templateUrl: './manage-jobseeker-application.component.html',
  styleUrls: ['./manage-jobseeker-application.component.scss'],
})
export class ManageJobseekerApplicationComponent implements OnInit {
  currentRootPath$: Observable<string>;
  jobApplication$: Observable<IJobApplication>;
  screenings$: Observable<IScreening[]>;

  enableScreeningUpdate = false;
  ApplicationStatus = ApplicationStatus;
  applicationStatus = applicationStatus;
  selectedInterviewOption: IDropdown;

  descriptionMetadata: IFormMetadata = ManageApplicationForm.metadata;
  descriptionConfigs: IFormConfig[] = ManageApplicationForm.configs;
  descriptionValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(ManageApplicationForm.configs)
  );
  constructor(private store: Store<FusionJobState>, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadCurrentApplication());
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.jobApplication$ = this.store.select(getCurrentApplication);
    this.screenings$ = this.store.select(getScreenings);
  }

  notifyDescription(data) {
    console.log('------->', data);
    // this.store.dispatch(new ApplyJob(data.coverLetter));
  }

  onInterviewSelection(interviewOption: IDropdown) {
    this.selectedInterviewOption = interviewOption;
  }

  onManageJobApplication(abc) {}

  onStartScreening() {
    this.store.dispatch(new AddScreening());
  }

  onNotifyScreeningUpdate(screeningPayload: IScreening) {
    this.store.dispatch(new UpdateScreening(screeningPayload));
  }

  onAcceptInterviewScreening(screeningId: string) {
    console.log('--------->screeningId', screeningId);
    this.store.dispatch(new AcceptInterview(screeningId));
  }
}
