import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  UrlTree,
} from "@angular/router";
import { Observable, interval } from "rxjs";
import { Store } from "@ngrx/store";
import { map, delayWhen } from "rxjs/operators";
import { FusionPaymentState } from "../reducers";
import { LoadOrder } from "../actions";
import { getOrderState } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class LoadOrderGuard implements CanActivate {
  constructor(private store: Store<FusionPaymentState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.store.dispatch(new LoadOrder());
    return this.store.select(getOrderState).pipe(
      delayWhen((wallet) =>
        !wallet.loaded
          ? interval(Math.random() * 1000)
          : interval(Math.random() * 0)
      ),
      map(() => true)
    );
  }
}
