import { IScreening } from '../../models/interfaces';
import {
  CurrentScreeningActionTypes,
  CurrentScreeningActions,
} from '../actions/current-screening.actions';

export interface CurrentScreeningState {
  data: IScreening;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CurrentScreeningState = {
  data: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: CurrentScreeningActions
): CurrentScreeningState {
  switch (action.type) {
    case CurrentScreeningActionTypes.SetCurrentScreening: {
      return {
        ...state,
        loading: true,
      };
    }
    case CurrentScreeningActionTypes.SetCurrentScreeningSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case CurrentScreeningActionTypes.SetCurrentScreeningFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
