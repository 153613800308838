<div *ngIf="invoice" class="rowN">
  <!-- checkout header -->
  <div class="checkout-header col2">
    <div smallLogo>
      <img
        imageFit
        src="/assets/images/users/company-logo/dot2circle-logo.svg"
      />
    </div>
    <div exLargeTitle semiBold centeringLeft>Checkout!</div>
  </div>

  <!-- billing -->
  <div *ngIf="invoice.billing" class="billing col2">
    <!-- bill to -->
    <ng-container *ngIf="invoice.billing.billTo as billTo">
      <div border radius cardSpacing class="col2__section-one">
        <div semiBold smallTitle>Bill To</div>
        <div bold g4FontColor>
          {{ invoice.billing.name }}
        </div>
        <div g4FontColor extraSmallFont>
          <span>{{ billTo.address }}</span>
          <span *ngIf="billTo.unit">, {{ billTo.unit }}</span>
        </div>
        <div g4FontColor extraSmallFont>
          <span>{{ billTo.city }}</span>
          <span>, {{ billTo.state }}</span>
          <span *ngIf="billTo.zipCode"> - {{ billTo.zipCode }}</span>
        </div>
        <div g4FontColor extraSmallFont>
          <span>{{ billTo.country }}</span>
        </div>
      </div>
    </ng-container>
    <!-- TODO ship to -->
    <div class="col2__section-two"></div>
  </div>

  <!-- checkout body -->
  <div border radius cardSpacing class="checkout col2">
    <div class="col2__section-one">
      <fusion-payment-checkout-invoice
        [invoice]="invoice"
      ></fusion-payment-checkout-invoice>
    </div>
    <div class="col2__section-two">
      <fusion-payment-checkout-summary
        [invoice]="invoice"
      ></fusion-payment-checkout-summary>
    </div>
  </div>

  <div border radius>
    <div cardSpacing>
      <div mediumTitle microBold>Select Wallet</div>
      <div microTitle>Your wallet will be charged the total order amount.</div>
    </div>

    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="walletFormGroup">
        <form [formGroup]="walletFormGroup">
          <ng-template matStepLabel>Payment Method</ng-template>
          <div class="v-padding-5 colN">
            <ng-container *ngFor="let wallet of paymentWallets">
              <div
                border
                radius
                pointer
                cardSpacing
                (click)="selectWallet(wallet, stepper)"
                class="col2"
              >
                <div class="col2__section-one">
                  <div bold g4FontColor>{{ wallet.type }} Wallet</div>
                  <div g4FontColor>{{ wallet.name }}</div>
                </div>
                <div
                  *ngIf="selectedWallet && selectedWallet.id == wallet.id"
                  class="col2__section-two check-icon"
                >
                  <mat-icon
                    aria-hidden="false"
                    aria-label="selected"
                    color="accent"
                    >check_circle</mat-icon
                  >
                </div>
              </div>
            </ng-container>
          </div>

          <div>
            <button
              mat-flat-button
              color="primary"
              matStepperNext
              [disabled]="!walletFormGroup.valid"
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Authorize</ng-template>
        <ng-container *ngIf="!payment; else paymentDeclined">
          <div class="v-padding-5 colN" *ngIf="selectedWallet">
            <div border radius cardSpacing class="col2">
              <div class="col2__section-one">
                <div bold g4FontColor>{{ selectedWallet.type }} Wallet</div>
                <div g4FontColor>{{ selectedWallet.name }}</div>
              </div>

              <div class="col2__section-two check-icon">
                <mat-icon
                  aria-hidden="false"
                  aria-label="selected"
                  color="accent"
                  >check_circle</mat-icon
                >
              </div>
            </div>
            <div border radius cardSpacing centering center bgColor>
              <div>
                <span
                  >Your {{ selectedWallet.type }} Wallet will be charged:</span
                >
                <span semiBold> ${{ invoice.total.amount }}</span
                >.
              </div>
            </div>
          </div>

          <div class="action-buttons col3">
            <button mat-button matStepperPrevious>Back</button>
            <button
              mat-flat-button
              color="primary"
              matStepperNext
              (click)="authorizePayment()"
            >
              Authorize
            </button>
          </div>
        </ng-container>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>

<ng-template #paymentDeclined>
  <ng-container *ngIf="payment.code === PaymentCode.WalletDeclined">
    <div
      errorBorder
      radius
      cardSpacing
      centeringText
      cardSpacing
      class="row3 t-margin-5"
    >
      <div smallTitle semiBold class="row3__section-one">Payment Declined!</div>
      <div exSmallTitle g4FontColor class="row3__section-two">
        Your {{ selectedWallet.type }} Wallet does not have enough balance.
        Please load required balance in you {{ selectedWallet.type }} Wallet to
        complete this transaction.
      </div>

      <div *ngIf="paymentWallets.length > 1" class="row3__section-three">
        <button mat-flat-button color="accent" (click)="resetCurrentPayment()">
          Reset to try another wallet
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="payment.code === PaymentCode.NoWallet">
    <div
      errorBorder
      radius
      cardSpacing
      centeringText
      cardSpacing
      class="row3 t-margin-5"
    >
      <div smallTitle semiBold class="row3__section-one">Payment Declined!</div>
      <div exSmallTitle g4FontColor class="row3__section-two">
        Your {{ selectedWallet.type }} Wallet is not setup yet. Please navigate
        to your {{ selectedWallet.type }} profile and setup your Wallet to
        complete this transaction.
      </div>

      <div *ngIf="paymentWallets.length > 1" class="row3__section-three">
        <button mat-flat-button color="accent" (click)="resetCurrentPayment()">
          Reset to try another wallet
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
