import { v4 } from 'uuid';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { getoAuthUser, getoAuthUserFullName } from '@fusion/oauth';
import { ContentService } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { IEventUpdatePayload, IVideoConferenceMetadata } from '../../models';

import {
  VideoConferenceActionTypes,
  JoinVideoConference,
  CreateVideoConference,
  JoinVideoConferenceSuccess,
  JoinVideoConferenceFail,
  CreateVideoConferenceSuccess,
  CreateVideoConferenceFail,
} from '../actions/video-conference.actions';
import { CalendarState } from '../reducers';
import { UpdateCurrentEvent } from '../actions';
import { IUser } from '@fusion/common';

@Injectable()
export class VideoConferenceEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CalendarState>,
    private contentService: ContentService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  
  joinVideoConference$ = createEffect(() => this.actions$.pipe(
    ofType<JoinVideoConference>(VideoConferenceActionTypes.JoinVideoConference),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getoAuthUserFullName),
      this.store.select(getoAuthUser)
    ),
    mergeMap(
      ([payload, guestName, oauthUser]: [
        IVideoConferenceMetadata,
        string,
        IUser
      ]) => {
        const meetingPayload = {
          role: payload.role,
          full_name: guestName,
          profile_picture: oauthUser.profilePicture,
        };
        return this.contentService.joinMeeting(payload.id, meetingPayload).pipe(
          switchMap((dataResult) => {
            const joinUrl = dataResult.join_url;
            this._document.defaultView.open(joinUrl, '_blank');
            return [new JoinVideoConferenceSuccess(payload.id)];
          }),
          catchError((error) => of(new JoinVideoConferenceFail()))
        );
      }
    ),
    catchError((error) => of(new JoinVideoConferenceFail()))
  ));

  
  createVideoConefrence$ = createEffect(() => this.actions$.pipe(
    ofType<CreateVideoConference>(
      VideoConferenceActionTypes.CreateVideoConference
    ),
    map((action) => action.payload),
    mergeMap((payload: IVideoConferenceMetadata) => {
      const meetingPayload = {
        id: v4(),
        title: payload.title,
        description: payload.description,
      };
      return this.contentService.createMeeting(meetingPayload).pipe(
        switchMap((dataResult) => {
          const syncEvent: IEventUpdatePayload = {
            videoConferenceId: dataResult.id,
          };
          return [
            new CreateVideoConferenceSuccess(dataResult),
            new UpdateCurrentEvent(syncEvent),
          ];
        })
      );
    }),
    catchError((error) => of(new CreateVideoConferenceFail()))
  ));
}
