import { createSelector } from '@ngrx/store';

import { getFusionCareerProfileState } from '../reducers';
import { getoAuthUserFullName, getoAuthUser } from '@fusion/oauth';
import { getExperiences } from './experiences.selectors';
import { getEducations } from './educations.selectors';
import { IJobseeker, ICareerSnapshot } from '../../models/interfaces';
import { IUser } from '@fusion/common';

export const getJobseekerProfileState = createSelector(
  getFusionCareerProfileState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.jobseekerProfile;
  }
);

export const getJobseekerProfile = createSelector(
  getJobseekerProfileState,
  (state): IJobseeker => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getCareerSummary = createSelector(
  getJobseekerProfile,
  (metadata): string => {
    if (metadata == null) {
      return null;
    }
    return metadata.careerSummary;
  }
);

export const getJobseekerUserProfile = createSelector(
  getJobseekerProfile,
  (metadata): IUser => {
    if (metadata == null) {
      return null;
    }
    return metadata.user;
  }
);

export const getoJobseekerFullName = createSelector(
  getJobseekerUserProfile,
  (user): string => {
    if (user === null) {
      return null;
    }
    return user.middleName
      ? `${user.firstName} ${user.middleName} ${user.lastName}`
      : `${user.firstName} ${user.lastName}`;
  }
);

export const getJobseekerInfo = createSelector(
  getoJobseekerFullName,
  getJobseekerUserProfile,
  getExperiences,
  getEducations,
  (name, user, experiences, educations) => {
    if (!name || !user || !experiences || !educations) {
      return null;
    }
    return {
      name: name,
      profile_picture: user.profilePicture,
      banner_picture: user.bannerPicture,
      experience: experiences.find((ex) => ex.endDate === null) || null,
      education: educations[0] || null,
    };
  }
);

export const getCareerSnapshot = createSelector(
  getoJobseekerFullName,
  getJobseekerUserProfile,
  getCareerSummary,
  getExperiences,
  getEducations,
  (name, user, careerSummary, experiences, educations): ICareerSnapshot => {
    if (!name || !user) {
      return null;
    }
    return {
      userId: user.id,
      fullName: name,
      careerSummary: careerSummary,
      profilePicture: user.profilePicture,
      bannerPicture: user.bannerPicture,
      latestExperience:
        experiences && experiences.length ? experiences[0] : null,
      latestEducation: educations && educations.length ? educations[0] : null,
    };
  }
);
