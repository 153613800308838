import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  IFormConfig,
  IFormMetadata,
  setFormValidationRules,
} from '@fusion/form';
import { getRouterUrlRoot } from '@fusion/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoverLetterForm, DescriptionForm } from '../../models/forms';
import { IJob } from '../../models/interfaces';
import { LoadCurrentJob, ApplyJob } from '../../store/actions';
import { FusionJobState } from '../../store/reducers';
import { getCurrentJob } from '../../store/selectors';

@Component({
  selector: 'job-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent implements OnInit {
  job$: Observable<IJob>;
  currentRootPath$: Observable<string>;

  descriptionMetadata: IFormMetadata = CoverLetterForm.metadata;
  descriptionConfigs: IFormConfig[] = CoverLetterForm.configs;
  descriptionValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(CoverLetterForm.configs)
  );

  constructor(private store: Store<FusionJobState>, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadCurrentJob());
    this.job$ = this.store.select(getCurrentJob);
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
  }

  notifyDescription(data) {
    this.store.dispatch(new ApplyJob(data.coverLetter));
  }
}
