import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormConfig } from '../../models/interfaces';

@Component({
  selector: 'fusion-form-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CkeditorComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;
  ckeditorContent: null;
  defaultToolConfig: any;
  articleToolConfig: any;

  constructor() {}

  ngOnInit() {
    if (this.formValidationRules && this.formConfigs) {
      this.ckeditorContent =
        this.formValidationRules.controls[this.formConfigs.name].value;
    }
    this.defaultToolConfig = {
      toolbar: [
        {
          name: 'basicstyles',
          items: ['Bold', 'Italic', 'Underline', 'Strike'],
        },
        {
          name: 'paragraph',
          items: ['NumberedList', 'BulletedList', '-', 'Blockquote'],
        },
      ],
      extraPlugins: 'divarea',
    };

    this.articleToolConfig = {
      toolbar: [
        {
          name: 'basicstyles',
          items: [
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            '-',
            'JustifyLeft',
            'JustifyCenter',
            'JustifyRight',
            '-',
            'RemoveFormat',
          ],
        },
        { name: 'styles', items: ['Font', 'FontSize', 'Format'] },
        {
          name: 'paragraph',
          items: [
            'NumberedList',
            'BulletedList',
            '-',
            'Link',
            'Unlink',
            '-',
            'Blockquote',
          ],
        },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        {
          name: 'insert',
          items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'],
        },
        { name: 'document', items: ['Source'] },
        { name: 'editing', items: ['Scayt'] },
        { name: 'tools', items: ['Maximize'] },
      ],
      extraPlugins: 'divarea',
    };
  }
}
