import { v4 } from 'uuid';
import { Injectable } from '@angular/core';
import { IUser } from '@fusion/common';
import { getoAuthUser, getoAuthUserId } from '@fusion/oauth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CalendarEvent } from 'angular-calendar';
import { addMinutes, endOfHour } from 'date-fns';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import {
  CalendarEventType,
  colors,
  EventCategory,
  JoiningStatus,
  VideoConferenceRole,
} from '../../models';
import {
  ICalendarEventMetadata,
  ICreateEventPayload,
  IEventGuest,
  IEventUpdatePayload,
} from '../../models/interfaces';

import {
  CurrentEventActionTypes,
  CreateEventFail,
  AddEventGuest,
  CurrentEventSync,
  SaveCurrentEventSuccess,
  SaveCurrentEventFail,
  CreateEventSuccess,
  CreateEvent,
  UpdateCurrentEvent,
  UpdateCurrentEventSuccess,
  UpdateCurrentEventFail,
  UpdateJoiningStatus,
  UpdateJoiningStatusSuccess,
  UpdateJoiningStatusFail,
  SetCurrentEvent,
  SetCurrentEventSuccess,
  SetCurrentEventFail,
  DeleteCurrentEventSuccess,
  DeleteCurrentEventFail,
  LoadCurrentEventSuccess,
  LoadCurrentEventFail,
  SaveExistingEventSuccess,
  SaveExistingEventFail,
} from '../actions/current-event.actions';
import { CalendarState } from '../reducers';
import { getCurrentEvent } from '../selectors';
import { AddGuests, LoadEvents, LoadGuests } from '../actions';
import { ContentService, MappingService, mappingType } from '@fusion/service';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';

@Injectable()
export class CurrentEventEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CalendarState>,
    private contentService: ContentService,
    private mappingService: MappingService
  ) {}

  
  setCurrentEvent$ = createEffect(() => this.actions$.pipe(
    ofType<SetCurrentEvent>(CurrentEventActionTypes.SetCurrentEvent),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(([payload, currentEvent]: [CalendarEvent, CalendarEvent]) => {
      return [new SetCurrentEventSuccess(payload), new LoadGuests()];
    }),
    catchError((error) => of(new SetCurrentEventFail()))
  ));

  
  loadCurrentEvent$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentEventActionTypes.LoadCurrentEvent),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([action, params]: [any, Params]) => {
      const eventId = params.eventId;
      return this.contentService.getEvent(eventId).pipe(
        switchMap((dataResult) => {
          const mappedEvent = this.mappingService.getMappedData(
            dataResult,
            mappingType.camelize
          );
          return [new LoadCurrentEventSuccess(mappedEvent)];
        }),
        catchError((error) => of(new LoadCurrentEventFail()))
      );
    }),
    catchError((error) => of(new LoadCurrentEventFail()))
  ));

  
  saveCurrentEvent$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentEventActionTypes.SaveCurrentEvent),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(
      ([action, currentEvent]: [
        any,
        CalendarEvent<ICalendarEventMetadata>
      ]) => {
        const eventPayload = {
          title: currentEvent.title,
          start: currentEvent.start,
          end: currentEvent.end,
          event_category: currentEvent?.meta?.eventCategory,
          event_type: currentEvent?.meta?.eventType,
          description: currentEvent?.meta?.description,
          video_conference_id: currentEvent?.meta?.videoConferenceId,
          location: currentEvent?.meta?.location,
          guests: currentEvent?.meta?.guests.map((guest) => {
            return {
              guest_role: guest.guestRole,
              is_organizer: guest.isOrganizer,
              is_applicant: guest.isApplicant,
              joining_status: guest.joiningStatus,
              user_id: guest.userId,
            };
          }),
        };
        return this.contentService.createEvent(eventPayload).pipe(
          switchMap((dataResult) => {
            return [new SaveCurrentEventSuccess(dataResult), new LoadEvents()];
          }),
          catchError((error) => of(new SaveCurrentEventFail()))
        );
      }
    ),
    catchError((error) => of(new SaveCurrentEventFail()))
  ));

  
  saveExistingEvent$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentEventActionTypes.SaveExistingEvent),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(
      ([action, currentEvent]: [
        any,
        CalendarEvent<ICalendarEventMetadata>
      ]) => {
        const eventId: any = currentEvent.id;
        const eventPayload = {
          title: currentEvent.title,
          start: currentEvent.start,
          end: currentEvent.end,
          description: currentEvent?.meta?.description || undefined,
          location: currentEvent?.meta?.location || undefined,
          video_conference_id:
            currentEvent?.meta?.videoConferenceId || undefined,
        };
        return this.contentService.updateEvent(eventId, eventPayload).pipe(
          switchMap((dataResult) => {
            return [new SaveExistingEventSuccess(dataResult), new LoadEvents()];
          }),
          catchError((error) => of(new SaveExistingEventFail()))
        );
      }
    ),
    catchError((error) => of(new SaveExistingEventFail()))
  ));

  
  deleteCurrentEvent$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentEventActionTypes.DeleteCurrentEvent),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(
      ([action, currentEvent]: [
        any,
        CalendarEvent<ICalendarEventMetadata>
      ]) => {
        const eventId: any = currentEvent.id;
        return this.contentService.removeEvent(eventId).pipe(
          switchMap((dataResult) => {
            return [
              new DeleteCurrentEventSuccess(dataResult),
              new LoadEvents(),
            ];
          }),
          catchError((error) => of(new DeleteCurrentEventFail()))
        );
      }
    ),
    catchError((error) => of(new DeleteCurrentEventFail()))
  ));

  
  updateJoiningStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateJoiningStatus>(CurrentEventActionTypes.UpdateJoiningStatus),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(
      ([payload, currentEvent]: [
        IEventUpdatePayload,
        CalendarEvent<ICalendarEventMetadata>
      ]) => {
        const eventId: any = currentEvent.id;
        const guestId: any = currentEvent?.meta?.currentGuest?.id;
        const joiningStatusPayload = {
          joining_status: payload.joiningStatus,
        };
        return this.contentService
          .updateJoiningStatus(eventId, guestId, joiningStatusPayload)
          .pipe(
            switchMap((dataResult) => {
              return [
                new UpdateJoiningStatusSuccess(dataResult),
                new LoadEvents(),
              ];
            }),
            catchError((error) => of(new UpdateJoiningStatusFail()))
          );
      }
    ),
    catchError((error) => of(new UpdateJoiningStatusFail()))
  ));

  
  createEvent$ = createEffect(() => this.actions$.pipe(
    ofType<CreateEvent>(CurrentEventActionTypes.CreateEvent),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getoAuthUser)),
    mergeMap(([payload, organizer]: [ICreateEventPayload, IUser]) => {
      const currentEvent: CalendarEvent<ICalendarEventMetadata> = {
        id: v4(),
        title: payload?.title ? payload.title : 'New event',
        start: payload?.start
          ? payload?.start
          : addMinutes(endOfHour(new Date()), 1),
        end: payload?.end
          ? payload?.end
          : addMinutes(endOfHour(new Date()), 61),
        color: colors.accepted,
        cssClass: 'fusion-calendar-event__default',
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        meta: {
          description: payload?.description ? payload.description : undefined,
          location: payload?.location ? payload.location : undefined,
          eventCategory: payload?.eventCategory
            ? payload.eventCategory
            : EventCategory.Meeting,
          eventType: payload?.eventType
            ? payload.eventType
            : CalendarEventType.VideoConference,
          guests: [
            {
              guestRole: VideoConferenceRole.Moderator,
              userId: organizer.id,
              isOrganizer: true,
              joiningStatus: JoiningStatus.Accepted,
              user: organizer,
            },
          ],
        },
      };
      if (payload?.applicantGuest) {
        const guestPayload: IEventGuest = {
          ...payload.applicantGuest,
          isApplicant: true,
        };
        return [
          new CreateEventSuccess(currentEvent),
          new AddEventGuest(guestPayload),
        ];
      }
      return [new CreateEventSuccess(currentEvent)];
    }),
    catchError((error) => of(new CreateEventFail()))
  ));

  
  updateEvent$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCurrentEvent>(CurrentEventActionTypes.UpdateCurrentEvent),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(
      ([payload, currentEvent]: [
        IEventUpdatePayload,
        CalendarEvent<ICalendarEventMetadata>
      ]) => {
        const updatedCurrentEvent: CalendarEvent<ICalendarEventMetadata> = {
          ...currentEvent,
          title: payload.title ? payload.title : currentEvent.title,
          start: payload.start ? payload.start : currentEvent.start,
          end: payload.end ? payload.end : currentEvent.end,
          meta: {
            ...currentEvent.meta,
            location: payload.location
              ? payload.location
              : currentEvent?.meta?.location,
            description: payload.description
              ? payload.description
              : currentEvent?.meta?.description,
            videoConferenceId: payload.videoConferenceId
              ? payload.videoConferenceId
              : currentEvent?.meta?.videoConferenceId,
          },
        };
        return [new UpdateCurrentEventSuccess(updatedCurrentEvent)];
      }
    ),
    catchError((error) => of(new UpdateCurrentEventFail()))
  ));

  
  addGuest$ = createEffect(() => this.actions$.pipe(
    ofType<AddEventGuest>(CurrentEventActionTypes.AddEventGuest),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(
      ([currentGuest, currentEvent]: [
        IEventGuest,
        CalendarEvent<ICalendarEventMetadata>
      ]) => {
        const updatedEvent: CalendarEvent<ICalendarEventMetadata> = {
          ...currentEvent,
          meta: {
            ...currentEvent.meta,
            guests: [
              ...currentEvent.meta.guests,
              {
                id: currentGuest?.id,
                userId: currentGuest?.user?.id,
                joiningStatus: JoiningStatus.Pending,
                guestRole: currentGuest.guestRole
                  ? currentGuest.guestRole
                  : VideoConferenceRole.Attendee,
                isOrganizer: false,
                isApplicant: currentGuest.isApplicant,
                user: currentGuest.user,
              },
            ],
          },
        };
        return [new CurrentEventSync(updatedEvent)];
      }
    ),
    catchError((error) => of(new CreateEventFail()))
  ));
}
