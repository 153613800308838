<ng-container *ngIf="isPreview; else screeningEditor">
  <div class="video-screening col3">
    <!-- interviewer -->
    <div centering center class="col3__section-one">
      <div>
        <div centering center>
          <div centering center extraSmallLogo>
            <img
              imageFit
              src="{{ company?.logo || '/assets/images/job/job-banner-9.jpg' }}"
            />
            <div overlay class="l-margin-6 t-margin-3">
              <mat-icon color="accent" class="icon-display"
                >check_circle</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- meeting time -->
    <div centering center class="col3__section-two">
      <div>
        <div class="interview-time">
          <div>
            <mat-icon *ngIf="!screeningValues.hasEventCreated"
              >voice_chat</mat-icon
            >
            <mat-icon color="accent" *ngIf="screeningValues.hasEventCreated"
              >voice_chat</mat-icon
            >
          </div>
          <div class="interview-time">
            <div chip light primary>
              <div nanoFont normal>
                <span>{{
                  screeningValues.startDatetime | date: 'EEEE, MMMM d'
                }}</span>
                <span dot></span>
                <span>{{
                  screeningValues.startDatetime | date: 'h:mm a'
                }}</span>
                <span> - </span>
                <span>{{ screeningValues.endDatetime | date: 'hh:mm a' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- applicant -->
    <div centering center class="col3__section-three">
      <div>
        <div centering center>
          <div centering center extraSmallProfileImage>
            <img
              imageFit
              src="{{
                applicant?.profilePicture ||
                  '/assets/images/job/job-banner-9.jpg'
              }}"
            />
            <div *ngIf="screeningValues?.hasAccepted" overlay class="l-margin-6 t-margin-3">
              <mat-icon color="accent" class="icon-display"
                >check_circle</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #screeningEditor>
  <div fullWidth>
    <fusion-form-template
      [isDialog]="false"
      [existingValues]="screeningValues"
      [formValidationRules]="videoInterviewValidationRules"
      [formConfigs]="videoInterviewConfigs"
      [formMetadata]="videoInterviewMetadata"
      (notifyFormSubmit)="notifyDescription($event)"
    ></fusion-form-template>
  </div>
</ng-template>
