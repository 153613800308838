import {
  AddJobActionTypes,
  CurrentJobActionTypes,
  CurrentOpenPositionActionTypes,
  JobsActionTypes,
  OpenPositionsActionTypes,
} from '../../store/actions';

export type FusionJobError =
  | AddJobActionTypes
  | CurrentJobActionTypes
  | CurrentOpenPositionActionTypes
  | OpenPositionsActionTypes
  | JobsActionTypes;

export const FusionJobError = {
  ...AddJobActionTypes,
  ...CurrentJobActionTypes,
  ...CurrentOpenPositionActionTypes,
  ...OpenPositionsActionTypes,
  ...JobsActionTypes,
};
