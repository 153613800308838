<div class="job row2">
  <div bottomBorder image class="row2__section-one">
    <div overlayGrayShade class="header col2">
      <div class="overlay-container row2">
        <fusion-common-back
          [currentRootPath]="currentRootPath"
          [targetDir]="''"
          [buttonText]="'back'"
        ></fusion-common-back>
        <div fullHeight>
          <fusion-job-header
            [job]="job"
            [metadata]="metadata"
            [isEditable]="isEditable"
            (notifyViewCompany)="onNotifyViewCompany($event)"
          ></fusion-job-header>
        </div>
      </div>
      <div centering class="action-button">
        <div center>
          <span *ngIf="isEditable">
            <a
              class="custom-round-button"
              mat-raised-button
              color="warn"
              (click)="onManageJob()"
              >Manage Job</a
            >
            <!-- <fusion-form-dialog
              semiBold
              primaryFontColor
              (click)="updateValidationRules()"
              [isDialog]="true"
              [buttonTitle]="'Manage Job'"
              [iconType]="ModalIconType.RoundedButton"
              [formValidationRules]="formValidationRules"
              [formConfigs]="formConfigs"
              [formMetadata]="formMetadata"
              (notifyFormSubmit)="notifyFormSubmit.emit($event)"
              (notifyFormValidationUpdate)="
                notifyFormValidationUpdate.emit($event)
              "
            ></fusion-form-dialog> -->
          </span>
          <ng-container [ngSwitch]="job?.applyType">
            <ng-container *ngSwitchCase="JobApply.Email">
              <a
                *ngIf="!isEditable"
                class="custom-round-button"
                mat-raised-button
                color="warn"
                href="mailto:{{
                  job.email
                }}?subject=Applying for the Position of {{
                  job.position
                }}&body=Add your Cover Letter here"
                target="_blank"
                >Apply Now</a
              >
            </ng-container>
            <ng-container *ngSwitchCase="JobApply.Redirect">
              <a
                *ngIf="!isEditable"
                class="custom-round-button"
                mat-raised-button
                color="warn"
                href="{{ job.url }}"
                target="_blank"
                >Apply Now</a
              >
            </ng-container>
            <ng-container *ngSwitchCase="JobApply.CircleApply">
              <a
                *ngIf="!isEditable"
                class="custom-round-button"
                mat-raised-button
                color="warn"
                (click)="navigateToApply()"
                >Apply Now</a
              >
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <img imageFit src="/assets/images/job/job-banner-4.jpg" />
  </div>

  <div pageSpacing extraLargePage pageBody class="row2__section-two col2">
    <div pageContent class="col2__section-one job-detail row2">
      <div content>
        <div class="row2__section-one">
          <fusion-job-body [job]="job"></fusion-job-body>
        </div>
        <div *ngIf="company" class="row2__section-two">
          <div card cardSpacing>
            <fusion-company-preview
              [company]="company"
            ></fusion-company-preview>
          </div>
        </div>
      </div>
      <div class="col2__section-two"></div>
    </div>
  </div>
</div>
