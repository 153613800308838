import { Injectable } from '@angular/core';
import { getCareerSnapshot, ICareerSnapshot } from '@fusion/career-profile';
import { OpenGraphType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  SeoSocialShareService,
  JsonLdService,
  SeoSocialShareData,
} from 'ngx-seo';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

import {
  SeoActionTypes,
  SetUserSeoSuccess,
  SetUserSeoFail,
} from '../actions/seo.actions';
import { FusionSubscriptionState } from '../reducers';

@Injectable()
export class SeoEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private seoService: SeoSocialShareService,
    private jsonLdService: JsonLdService
  ) {}

  
  setUserSeo$ = createEffect(() => this.actions$.pipe(
    ofType(SeoActionTypes.SetUserSeo),
    withLatestFrom(this.store.select(getCareerSnapshot)),
    mergeMap(([action, career]: [any, ICareerSnapshot]) => {
      const jobTitle = career.latestExperience
        ? ` - ${career.latestExperience.position} - ${career.latestExperience.company.name}`
        : '';
      const seoPayload: SeoSocialShareData = {
        title: `${career.fullName}${jobTitle} | Dot to Circle`,
        description: `View ${career.fullName}'s profile on Dot to Circle, the world's largest professional and career management community.`,
        image: career.profilePicture,
        type: OpenGraphType.Article,
      };
      this.seoService.setData(seoPayload);
      return [new SetUserSeoSuccess(seoPayload)];
    }),
    catchError((error) => of(new SetUserSeoFail()))
  ));
}
