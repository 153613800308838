import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { of } from 'rxjs';
import { ConfigService } from '../../config/config.service';

declare var dataLayer;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  appConfig: any;
  userDetails: any = {};

  constructor(
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.appConfig = this.configService.getConfig();
  }

  public setCustomDimensions(dimensions: any) {
    if (!dimensions) {
      throw new Error(
        'GoogleTagManagerService: setCustomDimensions: provide missing custom dimensions'
      );
    }
    this.userDetails = { ...this.userDetails, ...dimensions };
    try {
      this._document.defaultView['fusion'] = { userProfile: this.userDetails };
      dataLayer.push(this.userDetails);
    } catch (error) {
      // this catches dataLayer is not defined which means gtm didn't intialize
      console.error(
        'GoogleTagManagerService: setCustomDimensions: GTM failed to initialize'
      );
    }
  }

  public setGTM() {
    const gtmAccountId = this.appConfig.gtm ? this.appConfig.gtm.id : null;
    if (gtmAccountId && !isPlatformServer(this.platformId)) {
      this.injectScriptTags(gtmAccountId);
    }
  }

  private injectScriptTags(gtm: string) {
    const head = this._document.head;
    const body = this._document.body;
    const headerScript = this._document.createRange().createContextualFragment(
      `<script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    </script>
    <!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtm}');</script>`
    );
    const bodyScript = this._document.createRange().createContextualFragment(
      `<!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->`
    );
    head.insertBefore(headerScript, head.firstChild);
    body.insertBefore(bodyScript, body.firstChild);
  }
}
