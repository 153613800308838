import { IoAuthUser, ISession } from '../../models/interfaces';
import {
  UserSessionActionTypes,
  UserSessionActions,
} from '../actions/user-session.actions';

export interface UserSessionState {
  session: ISession;
  sessionProfile: IoAuthUser;
}

export const initialState: UserSessionState = {
  session: null,
  sessionProfile: null,
};

export function reducer(
  state = initialState,
  action: UserSessionActions
): UserSessionState {
  switch (action.type) {
    case UserSessionActionTypes.StartUserSessionSuccess: {
      return {
        ...state,
        session: action.session,
      };
    }
    case UserSessionActionTypes.DecodeUserSessionTokenSuccess: {
      return {
        ...state,
        sessionProfile: action.payload,
      };
    }
  }
  return state;
}
