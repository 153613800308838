import { getoAuthUserId } from '@fusion/oauth';
import { createSelector } from '@ngrx/store';
import { IEventGuest } from '../../models';

import { getCalendarState } from '../reducers';

export const getCurrentEventState = createSelector(
  getCalendarState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.currentEvent;
  }
);

export const getCurrentEvent = createSelector(getCurrentEventState, (state) => {
  if (state == null) {
    return null;
  }
  return state.data;
});

export const getNewEventId = createSelector(getCurrentEventState, (state) => {
  if (state == null) {
    return null;
  }
  return state.newEventId;
});

export const getCurrentGuest = createSelector(
  getCurrentEvent,
  getoAuthUserId,
  (currentEvent, oAuthGuestId): IEventGuest => {
    if (currentEvent == null) {
      return null;
    }
    return currentEvent?.meta?.currentGuest;
  }
);

export const getApplicantGuest = createSelector(getCurrentEvent, (currentEvent) => {
  if (currentEvent == null) {
    return null;
  }
  const guests = currentEvent.meta.guests;
  return guests.find((guest) => guest.isApplicant);
});
