import { Action } from '@ngrx/store';
import {
  ICreateEventPayload,
  IEventGuest,
  IEventUpdatePayload,
} from '../../models/interfaces';

export enum CurrentEventActionTypes {
  LoadCurrentEvent = '[Calendar Current Event] Load',
  LoadCurrentEventSuccess = '[Calendar Current Event] Load Success',
  LoadCurrentEventFail = '[Calendar Current Event] Load Fail',
  SetCurrentEvent = '[Calendar Current Event] Set',
  SetCurrentEventSuccess = '[Calendar Current Event] Set Success',
  SetCurrentEventFail = '[Calendar Current Event] Set Fail',
  DeleteCurrentEvent = '[Calendar Current Event] Delete',
  DeleteCurrentEventSuccess = '[Calendar Current Event] Delete Success',
  DeleteCurrentEventFail = '[Calendar Current Event] Delete Fail',
  CurrentEventSync = '[Calendar Current Event] Sync',
  UpdateJoiningStatus = '[Calendar Current Event] Update Joining Status',
  UpdateJoiningStatusSuccess = '[Calendar Current Event] Update Joining Status Success',
  UpdateJoiningStatusFail = '[Calendar Current Event] Update Joining Status Fail',
  UpdateCurrentEvent = '[Calendar Current Event] Update',
  UpdateCurrentEventSuccess = '[Calendar Current Event] Update Success',
  UpdateCurrentEventFail = '[Calendar Current Event] Update Fail',
  SaveCurrentEvent = '[Calendar Current Event] Save',
  SaveCurrentEventSuccess = '[Calendar Current Event] Save Success',
  SaveCurrentEventFail = '[Calendar Current Event] Save Fail',
  SaveExistingEvent = '[Calendar Current Event] Save Existing',
  SaveExistingEventSuccess = '[Calendar Current Event] Save Existing Success',
  SaveExistingEventFail = '[Calendar Current Event] Save Fail',
  AddEventGuest = '[Calendar Current Event] Add Guest',
  AddEventGuestSuccess = '[Calendar Current Event] Add Guest Success',
  AddEventGuestFail = '[Calendar Current Event] Add Guest Fail',
  CreateEvent = '[Calendar Current Event] Create',
  CreateEventSuccess = '[Calendar Current Event] Create Success',
  CreateEventFail = '[Calendar Current Event] Create Fail',
  NotifyCreateEventComplete = '[Calendar Current Event] Notify Create Complete',
}

export class LoadCurrentEvent implements Action {
  readonly type = CurrentEventActionTypes.LoadCurrentEvent;
}

export class LoadCurrentEventSuccess implements Action {
  readonly type = CurrentEventActionTypes.LoadCurrentEventSuccess;

  constructor(public payload: any) {}
}

export class LoadCurrentEventFail implements Action {
  readonly type = CurrentEventActionTypes.LoadCurrentEventFail;
}

export class SetCurrentEvent implements Action {
  readonly type = CurrentEventActionTypes.SetCurrentEvent;

  constructor(public payload: any) {}
}

export class SetCurrentEventSuccess implements Action {
  readonly type = CurrentEventActionTypes.SetCurrentEventSuccess;

  constructor(public payload: any) {}
}

export class SetCurrentEventFail implements Action {
  readonly type = CurrentEventActionTypes.SetCurrentEventFail;
}

export class DeleteCurrentEvent implements Action {
  readonly type = CurrentEventActionTypes.DeleteCurrentEvent;
}

export class DeleteCurrentEventSuccess implements Action {
  readonly type = CurrentEventActionTypes.DeleteCurrentEventSuccess;

  constructor(public payload: any) {}
}

export class DeleteCurrentEventFail implements Action {
  readonly type = CurrentEventActionTypes.DeleteCurrentEventFail;
}

export class CurrentEventSync implements Action {
  readonly type = CurrentEventActionTypes.CurrentEventSync;

  constructor(public payload: any) {}
}

export class UpdateJoiningStatus implements Action {
  readonly type = CurrentEventActionTypes.UpdateJoiningStatus;

  constructor(public payload: IEventUpdatePayload) {}
}

export class UpdateJoiningStatusSuccess implements Action {
  readonly type = CurrentEventActionTypes.UpdateJoiningStatusSuccess;

  constructor(public payload: any) {}
}

export class UpdateJoiningStatusFail implements Action {
  readonly type = CurrentEventActionTypes.UpdateJoiningStatusFail;
}

export class UpdateCurrentEvent implements Action {
  readonly type = CurrentEventActionTypes.UpdateCurrentEvent;

  constructor(public payload: IEventUpdatePayload) {}
}

export class UpdateCurrentEventSuccess implements Action {
  readonly type = CurrentEventActionTypes.UpdateCurrentEventSuccess;

  constructor(public payload: any) {}
}

export class UpdateCurrentEventFail implements Action {
  readonly type = CurrentEventActionTypes.UpdateCurrentEventFail;
}

export class SaveCurrentEvent implements Action {
  readonly type = CurrentEventActionTypes.SaveCurrentEvent;
}

export class SaveCurrentEventSuccess implements Action {
  readonly type = CurrentEventActionTypes.SaveCurrentEventSuccess;

  constructor(public payload: any) {}
}

export class SaveCurrentEventFail implements Action {
  readonly type = CurrentEventActionTypes.SaveCurrentEventFail;
}

export class SaveExistingEvent implements Action {
  readonly type = CurrentEventActionTypes.SaveExistingEvent;
}

export class SaveExistingEventSuccess implements Action {
  readonly type = CurrentEventActionTypes.SaveExistingEventSuccess;

  constructor(public payload: any) {}
}

export class SaveExistingEventFail implements Action {
  readonly type = CurrentEventActionTypes.SaveExistingEventFail;
}

export class AddEventGuest implements Action {
  readonly type = CurrentEventActionTypes.AddEventGuest;

  constructor(public payload: IEventGuest) {}
}

export class AddEventGuestSuccess implements Action {
  readonly type = CurrentEventActionTypes.AddEventGuestSuccess;

  constructor(public payload: any) {}
}

export class AddEventGuestFail implements Action {
  readonly type = CurrentEventActionTypes.AddEventGuestFail;
}

export class CreateEvent implements Action {
  readonly type = CurrentEventActionTypes.CreateEvent;

  constructor(public payload?: ICreateEventPayload) {}
}

export class CreateEventSuccess implements Action {
  readonly type = CurrentEventActionTypes.CreateEventSuccess;

  constructor(public payload: any) {}
}

export class CreateEventFail implements Action {
  readonly type = CurrentEventActionTypes.CreateEventFail;
}

export class NotifyCreateEventComplete implements Action {
  readonly type = CurrentEventActionTypes.NotifyCreateEventComplete;
}

export type CurrentEventActions =
  | LoadCurrentEvent
  | LoadCurrentEventSuccess
  | LoadCurrentEventFail
  | SetCurrentEvent
  | SetCurrentEventSuccess
  | SetCurrentEventFail
  | DeleteCurrentEvent
  | DeleteCurrentEventSuccess
  | DeleteCurrentEventFail
  | CurrentEventSync
  | UpdateJoiningStatus
  | UpdateJoiningStatusSuccess
  | UpdateJoiningStatusFail
  | UpdateCurrentEvent
  | UpdateCurrentEventSuccess
  | UpdateCurrentEventFail
  | SaveCurrentEvent
  | SaveCurrentEventSuccess
  | SaveCurrentEventFail
  | SaveExistingEvent
  | SaveExistingEventSuccess
  | SaveExistingEventFail
  | AddEventGuest
  | AddEventGuestSuccess
  | AddEventGuestFail
  | CreateEvent
  | CreateEventSuccess
  | CreateEventFail
  | NotifyCreateEventComplete;
