import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IProject } from "../../models/interfaces";
import {
  ModalIconType,
  IFormMetadata,
  IFormConfig,
  setFormValidationRules
} from "@fusion/form";
import { ProjectForm, ProjectUpdateForm } from "../../models/forms";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "fusion-career-profile-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"]
})
export class ProjectsComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyDeleteProject: EventEmitter<any> = new EventEmitter<any>();
  @Input() projects: IProject[];
  @Input() autoCompleteEntities: any;
  @Input() allowEdit: boolean;

  projectFormMetadata: IFormMetadata = ProjectForm.metadata;
  projectFormConfigs: IFormConfig[] = ProjectForm.configs;
  projectFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(ProjectForm.configs)
  );
  projectUpdateFormMetadata: IFormMetadata = ProjectUpdateForm.metadata;
  projectUpdateFormConfigs: IFormConfig[] = ProjectUpdateForm.configs;
  projectUpdateFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(ProjectUpdateForm.configs)
  );

  isExpanded: boolean = false;
  ModalIconType = ModalIconType;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {}

  notifyCardFooterClick() {
    this.isExpanded = this.isExpanded ? false : true;
  }
}
