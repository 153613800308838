import { Inject, Injectable } from '@angular/core';
import {
  ConfigService,
  CookieService,
  GoogleTagManagerService,
  MappingService,
  mappingType,
  ServerCookieService,
  TokenService,
} from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as moment from 'moment';
import * as jwt from 'jsonwebtoken';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  switchMap,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  UserSessionActionTypes,
  StartUserSessionSuccess,
  RestoreUserSession,
  StartUserSession,
  DecodeUserSessionToken,
  RestoreUserSessionFail,
  RestoreUserSessionSuccess,
  RefreshUserSession,
  RefreshUserSessionSuccess,
  StartUserSessionFail,
  DestroyUserSessionSuccess,
  DecodeUserSessionTokenSuccess,
  DecodeUserSessionTokenFail,
  RedirectToUserProfileApp,
} from '../actions/user-session.actions';
import { ErrorSource, IError } from '@fusion/error';
import { IGTMUser, IoAuthUser, ISession } from '../../models/interfaces';
import { FusionoAuthError } from '../../models/enums';
import { LoadUserPermissions, RedirectToHomeApp } from '../actions';
import { Store } from '@ngrx/store';
import { FusionoAuthState } from '../reducers';
import {
  getApplications,
  getGTMUserDetails,
  getHomeAppBaseUrl,
  getoAuthUserId,
  getoAuthUserProfilePath,
  getUserSession,
} from '../selectors';
import { getRouterQueryParams } from '@fusion/router';
import { Params } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class UserSessionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionoAuthState>,
    private configService: ConfigService,
    private tokenService: TokenService,
    private cookieService: CookieService,
    private mappingService: MappingService,
    private serverCookieService: ServerCookieService,
    private googleTagManagerService: GoogleTagManagerService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  startUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StartUserSession>(UserSessionActionTypes.StartUserSession),
      switchMap((action: StartUserSession) => {
        // oAuth scenario
        if (action.session) {
          return [
            new StartUserSessionSuccess(action.session),
            new DecodeUserSessionToken(
              action.session,
              new RedirectToUserProfileApp()
            ),
          ];
        }
        return [new RestoreUserSession()];
      })
    )
  );

  restoreUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSessionActionTypes.RestoreUserSession),
      mergeMap((action: any) => {
        const appSessionCookie =
          this.configService.getConfig().auth.gateway.cookie;
        return this.cookieService.get(appSessionCookie).pipe(
          switchMap((appToken) => {
            const decodedToken = jwt.decode(appToken.token);
            const exp = moment.unix(decodedToken.exp);
            const session: ISession = {
              token: appToken.token,
              expiration: new Date(decodedToken.exp * 1000),
            };
            const isExpired = this.isExpired(exp);
            if (isExpired) {
              return [
                new RestoreUserSessionFail(),
                new RefreshUserSession(session),
              ];
            }
            return [
              new RestoreUserSessionSuccess(session),
              new StartUserSessionSuccess(session),
              new DecodeUserSessionToken(session),
            ];
          }),
          catchError((error) => {
            const errorPayload: IError<FusionoAuthError> = {
              code: FusionoAuthError.SessionRestoreFail,
              source: ErrorSource.Validation,
              data: error,
            };
            return [
              new RestoreUserSessionFail(errorPayload),
              new StartUserSessionFail(),
            ];
          })
        );
      })
    )
  );

  refreshUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RefreshUserSession>(UserSessionActionTypes.RefreshUserSession),
      map((action) => action.session),
      withLatestFrom(this.store.select(getUserSession)),
      mergeMap(
        ([userSessionPayload, userSessionFromStore]: [ISession, ISession]) => {
          const currentUserSession = userSessionPayload || userSessionFromStore;
          return this.tokenService
            .getRefreshToken(currentUserSession.token)
            .pipe(
              switchMap((dataResult) => {
                const decodedToken = jwt.decode(dataResult.token);
                const session: ISession = {
                  token: dataResult.token,
                  expiration: new Date(decodedToken.exp * 1000),
                };

                return [
                  new RefreshUserSessionSuccess(session),
                  new StartUserSessionSuccess(session),
                  new DecodeUserSessionToken(session),
                ];
              })
            );
        }
      )
    )
  );

  tokenDecode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DecodeUserSessionToken>(
        UserSessionActionTypes.DecodeUserSessionToken
      ),
      mergeMap((action: DecodeUserSessionToken) => {
        const decodedToken = jwt.decode(action.session?.token);
        const mappedToken = this.mappingService.getMappedData<IoAuthUser>(
          decodedToken,
          mappingType.camelize
        );
        if (action.followUpAction) {
          return [
            new DecodeUserSessionTokenSuccess(mappedToken),
            new LoadUserPermissions(),
            action.followUpAction,
          ];
        }
        return [
          new DecodeUserSessionTokenSuccess(mappedToken),
          new LoadUserPermissions(),
        ];
      }),
      catchError((error) => {
        const errorPayload: IError<FusionoAuthError> = {
          code: FusionoAuthError.TokenDecodeFail,
          source: ErrorSource.Validation,
          data: error,
        };
        return of(new DecodeUserSessionTokenFail(errorPayload));
      })
    )
  );

  destroyoUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSessionActionTypes.DestroyUserSession),
      withLatestFrom(this.store.select(getApplications)),
      switchMap(([action, apps]: [any, any]) => {
        const domain = apps.protocol === 'https' ? apps.domain : null;
        this.cookieService.deleteAll('/', domain);
        return [new DestroyUserSessionSuccess(), new RedirectToHomeApp()];
      })
    )
  );

  onGetUserSessionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RefreshUserSessionSuccess>(
          UserSessionActionTypes.StartUserSessionSuccess,
          UserSessionActionTypes.RefreshUserSessionSuccess
        ),
        map((action) => action.session),
        tap((session: ISession) => {
          const apps = this.configService.getConfig().apps;
          const protocol = apps.protocol;
          const domain = apps.domain;
          const isSecure = protocol === 'https' ? true : false;
          const userSessionCookieName =
            this.configService.getConfig().auth.gateway.cookie;
          if (session) {
            this.serverCookieService.set(
              userSessionCookieName,
              session.token,
              session.expiration,
              '/',
              domain,
              isSecure
            );
          }
        })
      ),
    { dispatch: false }
  );

  redirectToHomeApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSessionActionTypes.RedirectToHomeApp),
        withLatestFrom(this.store.select(getHomeAppBaseUrl)),
        map(([action, homeAppUrl]: [any, any]) => {
          this._document.defaultView.location.href = homeAppUrl;
        })
      ),
    { dispatch: false }
  );

  redirectToUserProfileApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RedirectToUserProfileApp>(
          UserSessionActionTypes.RedirectToUserProfileApp
        ),
        map((action) => action.payload),
        withLatestFrom(
          this.store.select(getoAuthUserId),
          this.store.select(getoAuthUserProfilePath),
          this.store.select(getRouterQueryParams)
        ),
        map(
          ([payload, userId, userProfileAppUrl, params]: [
            any,
            string,
            any,
            Params
          ]) => {
            let referrerUrl = params.referrerUrl;
            if (referrerUrl) {
              referrerUrl = referrerUrl.replace('{{userId}}', userId);
              this._document.defaultView.location.href = referrerUrl;
            } else {
              this._document.defaultView.location.href = userProfileAppUrl;
            }
          }
        )
      ),
    { dispatch: false }
  );

  setGTMUserSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSessionActionTypes.DecodeUserSessionTokenSuccess),
        withLatestFrom(this.store.select(getGTMUserDetails)),
        tap(([action, gtmUser]: [any, IGTMUser]) => {
          this.googleTagManagerService.setCustomDimensions(gtmUser);
        })
      ),
    { dispatch: false }
  );

  getDays(expDate, iatDate) {
    return expDate.diff(iatDate, 'days');
  }

  isExpired(expDate) {
    return expDate.diff(new Date(), 'minute') <= 0;
  }
}
