import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { getRouterParams } from '@fusion/router';
import {
  MappingService,
  mappingType,
  SubscriptionService,
} from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  switchMap,
  withLatestFrom,
  map,
} from 'rxjs/operators';
import {
  IRedirectSubscriberPayload,
  IUserPermission,
} from '../../models/interfaces';

import {
  UserPermissionsActionTypes,
  LoadUserPermissionsSuccess,
  LoadUserPermissionsFail,
  RedirectSubscriber,
  RedirectSubscriberSuccess,
  RedirectSubscriberFail,
} from '../actions/user-permissions.actions';
import { FusionoAuthState } from '../reducers';
import { getApplications, getoAuthUserId } from '../selectors';

@Injectable()
export class UserPermissionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionoAuthState>,
    private subscriptionService: SubscriptionService,
    private mappingService: MappingService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType(UserPermissionsActionTypes.LoadUserPermissions),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([action, userId]: [any, string]) => {
      return this.subscriptionService.getUserPermissions(userId).pipe(
        switchMap((dataResult) => {
          const mappedPermissions =
            this.mappingService.getMappedData<IUserPermission>(
              dataResult,
              mappingType.camelize
            );
          return [new LoadUserPermissionsSuccess(mappedPermissions)];
        })
      );
    }),
    catchError((error) => of(new LoadUserPermissionsFail()))
  ));

  
  redirectSubscriber$ = createEffect(() => this.actions$.pipe(
    ofType<RedirectSubscriber>(UserPermissionsActionTypes.RedirectSubscriber),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getoAuthUserId),
      this.store.select(getRouterParams),
      this.store.select(getApplications)
    ),
    mergeMap(
      ([payload, oauthUserId, params, apps]: [
        IRedirectSubscriberPayload,
        string,
        Params,
        any
      ]) => {
        const protocol = apps.protocol;
        const domain = apps.domain;
        const subdomains = apps.subdomains;
        const subdomainPorts = apps.subdomainPorts;
        const companyId = params.companyId;
        const appSubscriberId = companyId
          ? companyId
          : payload.subscriberId
          ? payload.subscriberId
          : oauthUserId;

        const appHost =
          domain === 'localhost'
            ? `${protocol}://${domain}:${subdomainPorts[payload.domain]}`
            : `${protocol}://${subdomains[payload.domain]}.${domain}`;
        // TODO rename payload.domain to payload.subdomain
        switch (payload.domain) {
          case subdomains.learning:
          case subdomains.rent: {
            this._document.defaultView.location.href = `${appHost}/${appSubscriberId}/search`;
            break;
          }
          case subdomains.author: {
            this._document.defaultView.location.href = `${appHost}/${appSubscriberId}/contents`;
            break;
          }
          default: {
            this._document.defaultView.location.href = `${appHost}/${appSubscriberId}`;
          }
        }

        return of(new RedirectSubscriberSuccess());
      }
    ),
    catchError((error) => {
      // const errorPayload: IError<FusionSubscriptionError> = {
      //   code: FusionSubscriptionError.RedirectSubscriberFail,
      //   source: ErrorSource.Validation,
      //   data: error,
      // };
      return of(new RedirectSubscriberFail());
    })
  ));
}
