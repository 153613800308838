import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IUser } from "@fusion/common";
import { getValidationRulesWithPresetValue } from "@fusion/form";

@Component({
  selector: "user-update-email",
  templateUrl: "./update-email.component.html",
  styleUrls: ["./update-email.component.scss"]
})
export class UpdateEmailComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: IUser;
  @Input() emailFormMetadata: any;
  @Input() emailFormConfigs: any;
  @Input() emailFormValidationRules: UntypedFormGroup;

  updatedValidationRules: UntypedFormGroup;
  getValidationRulesWithPresetValue = getValidationRulesWithPresetValue;

  constructor() {}

  ngOnInit() {
    this.updatedValidationRules = getValidationRulesWithPresetValue(
      this.emailFormValidationRules,
      this.emailFormConfigs,
      this.user
    );
  }
}
