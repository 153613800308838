import { IUser } from '@fusion/common';
import { Action } from '@ngrx/store';
import { IEventGuest } from '../../models';

export enum GuestsActionTypes {
  LoadGuests = '[Guests] Load',
  LoadGuestsSuccess = '[Guests] Load Success',
  LoadGuestsFail = '[Guests] Load Fail',
  AddGuests = '[Guests] Add',
  AddGuestsSuccess = '[Guests] Add Success',
  AddGuestsFail = '[Guests] Add Fail',
  RemoveGuests = '[Guests] Remove',
  RemoveGuestsSuccess = '[Guests] Remove Success',
  RemoveGuestsFail = '[Guests] Remove Fail',
}

export class LoadGuests implements Action {
  readonly type = GuestsActionTypes.LoadGuests;
}

export class LoadGuestsSuccess implements Action {
  readonly type = GuestsActionTypes.LoadGuestsSuccess;

  constructor(public payload: any) {}
}

export class LoadGuestsFail implements Action {
  readonly type = GuestsActionTypes.LoadGuestsFail;
}

export class AddGuests implements Action {
  readonly type = GuestsActionTypes.AddGuests;

  constructor(public payload: IEventGuest) {}
}

export class AddGuestsSuccess implements Action {
  readonly type = GuestsActionTypes.AddGuestsSuccess;

  constructor(public payload: any) {}
}

export class AddGuestsFail implements Action {
  readonly type = GuestsActionTypes.AddGuestsFail;
}

export class RemoveGuests implements Action {
  readonly type = GuestsActionTypes.RemoveGuests;

  constructor(public payload: IEventGuest) {}
}

export class RemoveGuestsSuccess implements Action {
  readonly type = GuestsActionTypes.RemoveGuestsSuccess;

  constructor(public payload: any) {}
}

export class RemoveGuestsFail implements Action {
  readonly type = GuestsActionTypes.RemoveGuestsFail;
}

export type GuestsActions =
  | LoadGuests
  | LoadGuestsSuccess
  | LoadGuestsFail
  | AddGuests
  | AddGuestsSuccess
  | AddGuestsFail
  | RemoveGuests
  | RemoveGuestsSuccess
  | RemoveGuestsFail;
