<div *ngIf="applications" class="colN">
  <div
    border
    radius
    elevation
    *ngFor="let app of applications"
    class="application col2"
  >
    <div bgColor lRadius class="vector col2__section-one"></div>
    <div cardSpacing class="col2__section-two row3">
      <div smallTitle semiBold class="row3__section-one">{{ app.name }}</div>
      <div g5FontColor class="row3__section-two">
        <fusion-common-description
          [description]="app.description"
          [defaultLength]="80"
        ></fusion-common-description>
      </div>
      <div
        *ngIf="app.active && app.isSubscribed && !isOnlySubscriptions"
        bgColor
        centering
        noPointer
        g3FontColor
        border
        radius
        cardSpacing
        extraSmallFont
        class="row3__section-three"
      >
        Subscribed
      </div>
      <div
        *ngIf="app.active && app.isSubscribed && isOnlySubscriptions"
        primaryBgColor
        whiteFontColor
        centering
        pointer
        border
        radius
        cardSpacing
        extraSmallFont
        class="row3__section-three"
        (click)="notifyViewApplication.emit(app)"
      >
        View & Manage
        <mat-icon class="l-padding-3">open_in_new</mat-icon>
      </div>
      <div
        *ngIf="app.active && !app.isSubscribed"
        primaryBgColor
        whiteFontColor
        pointer
        centering
        border
        radius
        cardSpacing
        extraSmallFont
        class="row3__section-three"
        (click)="notifySubscribe.emit(app)"
      >
        Subscribe
      </div>
      <div
        *ngIf="!app.active && !app.isSubscribed"
        secondaryBgColor
        whiteFontColor
        centering
        border
        radius
        cardSpacing
        extraSmallFont
        class="row3__section-three"
      >
        Coming Soon
      </div>
    </div>
  </div>
</div>
