import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { CookieService } from '../cookie/cookie.service';
import { ServerCookieService } from '../server-cookie/server-cookie.service';

@Injectable({
  providedIn: 'root',
})
export class GatewayService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private cookieService: CookieService,
    private serverCookieService: ServerCookieService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  getAccessToken() {
    const gatewayTokenCookie =
      this.configService.getConfig().auth.gateway.cookie;
    return this.serverCookieService.get(gatewayTokenCookie);
  }

  getAppToken() {
    const appSessionCookie =
      this.configService.getConfig().auth.gateway.appSessionCookie;
    // angular universal + public features use case
    return this.serverCookieService.get(appSessionCookie);
  }

  setAccessToken() {
    const gatewayTokenCookie = this.configService.getConfig().apiGatewayCookie;
    return this.http.get(`/user/api/token/refresh`).subscribe((data: any) => {
      // 'gatewayTokenCookie', 'cookieValue', 'days from now'
      this.cookieService.set(
        gatewayTokenCookie,
        data.Token,
        data.Expiration,
        '/'
      );
    });
  }
}
