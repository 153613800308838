import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { IInvoice } from "../../models/interfaces";
import { InvoiceComponent } from "../invoice/invoice.component";

@Component({
  selector: "fusion-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"]
})
export class PaymentsComponent implements OnInit {
  @Input() transactions: any;
  @Input() displayColumns: string[];

  @ViewChild(MatSort) sort: MatSort;
  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  openDialog(invoice: IInvoice): void {
    const dialogRef = this.dialog.open(InvoiceComponent, {
      width: "700px",
      height: "530px",
      maxWidth: "700px",
      maxHeight: "auto",
      data: {
        invoice: invoice
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
