import { createSelector } from '@ngrx/store';

import { getFusionJobState } from '../reducers/index';
import { IJob, IJobMetadata } from '../../models/interfaces/job.interfaces';

export const getCurrentJobState = createSelector(getFusionJobState, (state) => {
  if (state == null) {
    return null;
  }
  return state.currentJob;
});

export const getCurrentJob = createSelector(
  getCurrentJobState,
  (state): IJob => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getJobMetadata = createSelector(getCurrentJob, (job): IJobMetadata[] => {
  if (job == null) {
    return null;
  }
  return [
    {
      isSalary: false,
      label: job.level
    },
    {
      isSalary: false,
      label: job.type
    },
    {
      isSalary: false,
      label: job.category
    },
    {
      isSalary: !job.negotiable,
      amount: job.salary,
      currencyCode: job.currencyCode,
      label: !job.negotiable ? `${job.salaryUnit}` : `Negotiable`,
    },
  ];
});

// export const getCurrentJobSeo = createSelector(
//   getCurrentJob,
//   getRouterUrl,
//   getCompany,
//   (job, url, company): ISeo => {
//     if (job == null || url == null || company == null) {
//       return null;
//     }
//     return {
//       type: 'Jobseeker',
//       title: job.position,
//       description: job.job_details[0].content.substring(0, 300),
//       image: company.logo,
//       url: url
//     };
//   }
// );
