import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  switchMap,
  withLatestFrom,
  map,
} from 'rxjs/operators';

import {
  CompaniesActionTypes,
  LoadCompaniesSuccess,
  LoadCompaniesFail,
  LoadCompanies,
  SearchCompanies,
  SearchCompaniesSuccess,
  SearchCompaniesFail,
} from '../actions/index';
import { Store } from '@ngrx/store';
import { FusionCompanyState } from '../reducers/index';
import { EmployerService, MappingService, mappingType } from '@fusion/service';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionCompanyError } from '../../models/enums';
import { ICompany } from '../../models/interfaces';

@Injectable()
export class CompaniesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionCompanyState>,
    private employerService: EmployerService,
    private mappingService: MappingService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCompanies>(CompaniesActionTypes.LoadCompanies),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([action, params]: [any, Params]) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.LoadCompaniesFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const employerId = params.employerId;
      return this.employerService.getCompanies(employerId).pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData<ICompany[]>(
            dataResult,
            mappingType.camelize
          );
          return [new LoadCompaniesSuccess(mappedData)];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading companies. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadCompanies()],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new LoadCompaniesFail(errorPayload));
        })
      );
    })
  ));

  
  searchCompanies$ = createEffect(() => this.actions$.pipe(
    ofType<SearchCompanies>(CompaniesActionTypes.SearchCompanies),
    map((action) => action.payload),
    mergeMap((payload: string) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.SearchCompaniesFail,
        source: ErrorSource.Validation,
        data: null,
      };
      return this.employerService.searchCompanies(payload).pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData<ICompany[]>(
            dataResult,
            mappingType.camelize
          );
          return [new SearchCompaniesSuccess(mappedData)];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue searching companies. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new SearchCompanies(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new SearchCompaniesFail(errorPayload));
        })
      );
    })
  ));
}
