import { IEventDisplayStatus } from '../interfaces';

export const colors: IEventDisplayStatus = {
  pending: {
    primary: '#5275e7',
    secondary: '#fff',
  },
  pendingAndExpired: {
    primary: '#5275e7',
    secondary: '#fff',
  },
  accepted: {
    primary: '#5275e7',
    secondary: '#01d4ff',
  },
  acceptedAndExpired: {
    primary: '#96abf1',
    secondary: '#bef4ff',
  },
  rejected: {
    primary: '#5275e7',
    secondary: '#fff',
  },
  rejectedAndExpired: {
    primary: '#5275e7',
    secondary: '#fff',
  },
};
