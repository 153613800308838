import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import { mergeMap, catchError, map } from "rxjs/operators";

import {
  RatingActionTypes,
  LoadRating,
  LoadRatingSuccess,
  LoadRatingFail
} from "../actions/index";

@Injectable()
export class RatingEffects {
  constructor(public actions$: Actions) {}

  
  getRating$ = createEffect(() => this.actions$.pipe(
    ofType<LoadRating>(RatingActionTypes.LoadRating),
    map(action => action.payload),
    mergeMap((payload: any) => {
      return [new LoadRatingSuccess(payload)];
    })
  ));
}
