<div *ngFor="let config of formConfigs">
  <ng-container [ngSwitch]="config.type">
    <ng-container *ngSwitchCase="FormFieldType.Text">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Url">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Phone">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Email">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Number">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Password">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.File">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.ChipList">
      <fusion-form-chip-list
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-chip-list>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Date">
      <fusion-form-datepicker
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-datepicker>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Radio">
      <fusion-form-radio
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
        (notifyFormValidationUpdate)="notifyFormValidationUpdate.emit($event)"
      ></fusion-form-radio>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Checkbox">
      <fusion-form-checkbox
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
        (notifyFormValidationUpdate)="notifyFormValidationUpdate.emit($event)"
      ></fusion-form-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Dropdown">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Textarea">
      <fusion-form-textarea
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-textarea>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.QuillEditor">
      <fusion-form-quill-editor
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-quill-editor>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Ckeditor">
      <fusion-form-ckeditor
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-ckeditor>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Autocomplete">
      <fusion-form-auto-complete
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
        [autoCompleteEntities]="autoCompleteEntities"
        [autoCompleteType]="autoCompleteType"
        [entityType]="entityType"
        (notifyAutocompletion)="notifyAutocompletion.emit($event)"
      ></fusion-form-auto-complete>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.NonFormItem">
      <ng-container [ngSwitch]="config.nonFormItem.type">
        <ng-container *ngSwitchCase="NonFormItemType.Html">
          <div
            nanoFont
            g4FontColor
            class="b-padding-3"
            [innerHTML]="config.nonFormItem.label"
          ></div>
        </ng-container>
        <ng-container *ngSwitchCase="NonFormItemType.Text">
          <div microFont g4FontColor class="b-padding-3">
            {{ config.nonFormItem.label }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="NonFormItemType.Alert">
          <div class="b-padding-5">
            <div
              warningBorder
              radius
              cardSmallSpacing
              microFont
              warningBackground
              centering
              center
            >
              {{ config.nonFormItem.label }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="NonFormItemType.Link">
          <a (click)="navigateTo(config.nonFormItem.path)">{{
            config.nonFormItem.label
          }}</a>
        </ng-container>
        <ng-container *ngSwitchCase="NonFormItemType.Dash">
          <span> - </span>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="FormFieldType.Group">
      <ng-container [ngSwitch]="config.group">
        <ng-container *ngSwitchCase="FormFieldGroup.Salary">
          <fusion-form-salary
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-salary>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.Time">
          <fusion-form-time
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-time>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.DateRange">
          <fusion-form-date-range
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-date-range>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.Address">
          <div border radius cardSmallSpacing>
            <fusion-form-address
              [group]="config.group"
              [formConfigs]="config.configs"
              [formValidationRules]="formValidationRules"
            ></fusion-form-address>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.ShortAddress">
          <fusion-form-address
            [group]="config.group"
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-address>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.Name">
          <fusion-form-name
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-name>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.HomeProperty">
          <fusion-form-home-property
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-home-property>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.JobMetadata">
          <fusion-form-job-metadata
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-job-metadata>
        </ng-container>

        <ng-container *ngSwitchCase="FormFieldGroup.JobApplyType">
          <fusion-form-job-apply-type
            [formConfigs]="config.configs"
            [formValidationRules]="formValidationRules"
          ></fusion-form-job-apply-type>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <!-- TODO may be fetch error component -->
      <div class="fusion-form__empty">{{ config.type }} is not supported</div>
    </ng-container>
  </ng-container>
</div>
