import { Action } from "@ngrx/store";
import { ITransactionPayload } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionPaymentError } from "../../models/enums";

export enum TransactionActionTypes {
  LoadTransaction = "[Transaction] Load",
  LoadTransactionSuccess = "[Transaction] Load Success",
  LoadTransactionFail = "[Transaction] Load Fail"
}

export class LoadTransaction implements Action {
  readonly type = TransactionActionTypes.LoadTransaction;

  constructor(public payload: ITransactionPayload) {}
}

export class LoadTransactionSuccess implements Action {
  readonly type = TransactionActionTypes.LoadTransactionSuccess;

  constructor(public payload: any) {}
}

export class LoadTransactionFail implements Action {
  readonly type = TransactionActionTypes.LoadTransactionFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export type TransactionActions =
  | LoadTransaction
  | LoadTransactionSuccess
  | LoadTransactionFail;
