import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ReferralsForm } from '../../models/forms';
import { IReferralStats } from '../../models/interfaces';
import { AddReferrals, LoadReferralsStats } from '../../store/actions';
import { FusionoAuthState } from '../../store/reducers';
import { getReferralStats } from '../../store/selectors';

@Component({
  selector: 'fusion-oauth-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss'],
})
export class ReferralsComponent implements OnInit {
  @Input() displayStats: boolean = true;

  referralsForm = ReferralsForm;

  referralStats$: Observable<IReferralStats>;

  constructor(private store: Store<FusionoAuthState>) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadReferralsStats());

    this.referralStats$ = this.store.select(getReferralStats);
  }

  onNotifyFormSubmit(eventData: any) {
    const emails = eventData?.data?.emails;
    this.store.dispatch(new AddReferrals(emails));
  }
}
