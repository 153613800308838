import { createSelector } from "@ngrx/store";

import { getFusionPaymentState } from "../reducers";
import { IPayment } from "../../models/interfaces/payment.interfaces";

export const getPaymentState = createSelector(getFusionPaymentState, state => {
  if (state == null) {
    return null;
  }
  return state.payment;
});

export const getPayment = createSelector(
  getPaymentState,
  (state): IPayment => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
