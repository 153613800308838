import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map,
} from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { FusionJobState } from '../reducers/index';
import {
  getSearchState,
  SearchState,
  getPrivateSearch,
  ISearch,
  getSearch,
} from '@fusion/search';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionJobError } from '../../models/enums';
import {
  LoadJobsFail,
  SetJobsCount,
  LoadJobsSuccess,
  JobsActionTypes,
  LoadPrivateJobsSuccess,
  LoadPrivateJobsFail,
  LoadPrivateJobs,
  LoadJobs,
} from '../actions/index';
import { IJob } from '../../models/interfaces';

@Injectable()
export class JobsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionJobState>,
    private jobSearchService: JobService,
    private mappingService: MappingService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType(JobsActionTypes.LoadJobs),
    map((action) => action),
    withLatestFrom(this.store.select(getSearch)),
    mergeMap(([action, search]: [any, ISearch]) => {
      let errorPayload: IError<FusionJobError> = {
        code: FusionJobError.LoadJobsFail,
        source: ErrorSource.Validation,
        data: null,
      };
      return this.jobSearchService.getJobs(search).pipe(
        switchMap((result) => {
          const mappedJobs = this.mappingService.getMappedData<IJob[]>(
            result.data,
            mappingType.camelize
          );
          return [
            new SetJobsCount(parseInt(result.count, 10)),
            new LoadJobsSuccess(mappedJobs),
          ];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading jobs. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadJobs()],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new LoadJobsFail(errorPayload));
        })
      );
    })
  ));

  
  searchPrivateJobs$ = createEffect(() => this.actions$.pipe(
    ofType(JobsActionTypes.LoadPrivateJobs),
    map((action) => action),
    withLatestFrom(this.store.select(getPrivateSearch)),
    mergeMap(([action, search]: [any, ISearch]) => {
      let errorPayload: IError<FusionJobError> = {
        code: FusionJobError.LoadPrivateJobsFail,
        source: ErrorSource.Validation,
        data: null,
      };
      return this.jobSearchService.getJobs(search).pipe(
        switchMap((result) => {
          const mappedJobs = this.mappingService.getMappedData<IJob[]>(
            result.data,
            mappingType.camelize
          );
          return [
            new SetJobsCount(parseInt(result.count, 10)),
            new LoadPrivateJobsSuccess(mappedJobs),
          ];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading your jobs. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadPrivateJobs()],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new LoadPrivateJobsFail(errorPayload));
        })
      );
    })
  ));
}
