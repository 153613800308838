import { IForm, FormFieldType, FormFieldPattern } from "@fusion/form";

export const UpdateUserEmailForm: IForm = {
  metadata: {
    name: "UPDATE_USER_PROFILE_FORM",
    title: "Update User Email",
    submitButtonText: "Update"
  },
  configs: [
    {
      label: "Email",
      name: "email",
      type: FormFieldType.Email,
      placeholder: "shakil.khan@dottocircle.com",
      examplePattern: "shakil.khan@dottocircle.com",
      validation: {
        required: true,
        pattern: FormFieldPattern.Email,
        max: 100,
      }
    }
  ]
};
