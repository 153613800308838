import { oAuthEffects } from './oauth.effects';
import { UserProfileEffects } from './user-profile.effects';
import { SignUpEffects } from './sign-up.effects';
import { ApplicationsEffects } from './applications.effects';
import { ErrorEffects } from './error.effects';
import { UserEffects } from './user.effects';
import { UserPermissionsEffects } from './user-permissions.effects';
import { ReferralsEffects } from './referrals.effects';
import { AppSessionEffects } from './app-session.effects';
import { UserSessionEffects } from './user-session.effects';

// include all effects
export const effects: any[] = [
  oAuthEffects,
  UserProfileEffects,
  SignUpEffects,
  ApplicationsEffects,
  ErrorEffects,
  UserEffects,
  UserPermissionsEffects,
  ReferralsEffects,
  AppSessionEffects,
  UserSessionEffects,
];

// export from effects
