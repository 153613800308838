import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUser } from '@fusion/common';
import { CalendarEvent } from 'angular-calendar';
import { map, startWith } from 'rxjs/operators';
import {
  CalendarEventType,
  EventCategory,
  JoiningStatus,
  VideoConferenceRole,
} from '../../models';
import {
  ICalendarEventMetadata,
  IEventGuest,
  IEventUpdatePayload,
  IVideoConferenceMetadata,
} from '../../models/interfaces';

@Component({
  selector: 'calendar-update-event',
  templateUrl: './update-event.component.html',
  styleUrls: ['./update-event.component.scss'],
})
export class UpdateEventComponent implements OnInit {
  @Output() notifySave = new EventEmitter();
  @Output() notifyGuestUser = new EventEmitter<IUser>();
  @Output() notifyJoinVideoConference =
    new EventEmitter<IVideoConferenceMetadata>();
  @Output() notifyCreateVideoConference =
    new EventEmitter<IVideoConferenceMetadata>();
  @Output() notifyAutocompletion = new EventEmitter<string>();
  @Output() notifyEventUpdate = new EventEmitter<IEventUpdatePayload>();

  @Input() users: IUser[];
  @Input() applicantGuest: IEventGuest;
  _event: CalendarEvent<ICalendarEventMetadata>;

  @Input()
  set event(event: CalendarEvent<ICalendarEventMetadata>) {
    this._event = event;
    this.initArticleFormGroup(event);
  }

  get event() {
    return this._event;
  }

  EventCategory = EventCategory;
  CalendarEventType = CalendarEventType;
  JoiningStatus = JoiningStatus;
  userSearchCtrl = new UntypedFormControl();
  articleFormGroup: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.userSearchCtrl.valueChanges
      .pipe(
        startWith(''),
        map((searchOption) => {
          if (typeof searchOption === 'string') {
            this.notifyAutocompletion.emit(searchOption);
          } else {
            this.notifyGuestUser.emit(searchOption);
          }
        })
      )
      .subscribe();
  }

  private initArticleFormGroup(event: CalendarEvent<ICalendarEventMetadata>) {
    this.articleFormGroup = new UntypedFormGroup({
      title: new UntypedFormControl(event ? event.title : null),
      description: new UntypedFormControl(
        event ? event.meta.description : null
      ),
      start: new UntypedFormControl(event ? event.start : null),
      end: new UntypedFormControl(event ? event.end : null),
      address: new UntypedFormControl(
        event ? event?.meta?.location?.address : null
      ),
      unit: new UntypedFormControl(event ? event?.meta?.location?.unit : null),
      city: new UntypedFormControl(event ? event?.meta?.location?.city : null),
      state: new UntypedFormControl(
        event ? event?.meta?.location?.state : null
      ),
      zipCode: new UntypedFormControl(
        event ? event?.meta?.location?.zipCode : null
      ),
      country: new UntypedFormControl(
        event ? event?.meta?.location?.country : null
      ),
    });
  }

  displayName(entity: IUser): string {
    return null;
  }

  update(key: string, value: string) {
    const updatePayload: IEventUpdatePayload = {
      [key]: value,
    };

    this.notifyEventUpdate.emit(updatePayload);
  }

  onEventUpdate() {
    const updatePayload: IEventUpdatePayload = {
      ...this.articleFormGroup.value,
      location: {
        address: this.articleFormGroup.value.address,
        unit: this.articleFormGroup.value.unit,
        city: this.articleFormGroup.value.city,
        state: this.articleFormGroup.value.state,
        zipCode: this.articleFormGroup.value.zipCode,
        country: this.articleFormGroup.value.country,
      },
    };
    this.notifyEventUpdate.emit(updatePayload);
  }

  onCreateVideoConference(event: CalendarEvent<ICalendarEventMetadata>) {
    const videoConferencePayload: IVideoConferenceMetadata = {
      eventId: event.id,
      title: event.title,
      description: event.meta.description,
    };

    this.notifyCreateVideoConference.emit(videoConferencePayload);
  }

  onJoinVideoConference(currentEvent: CalendarEvent<ICalendarEventMetadata>) {
    const joinVodeoPayload: IVideoConferenceMetadata = {
      eventId: currentEvent.id,
      id: currentEvent.meta.videoConferenceId,
      role: VideoConferenceRole.Moderator,
    };
    this.notifyJoinVideoConference.emit(joinVodeoPayload);
  }
}
