import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import {
  PrivateSearchActionTypes,
  LoadPrivateSearchContents,
  SetHardQuery,
  SetHardQuerySuccess,
  PrivateSearchStart,
} from '../actions/private-search.actions';
import { Action, Store } from '@ngrx/store';
import { FusionSearchState } from '../reducers/index';
import { getoAuthUserId } from '@fusion/oauth';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';
import { SubscriberType } from '@fusion/subscription';
import { IHardQuery } from '../../models';
import { getPrivateSearchFollowUpAction } from '../selectors';

@Injectable()
export class PrivateSearchEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSearchState>
  ) {}

  
  privateSearch$ = createEffect(() => this.actions$.pipe(
    ofType<PrivateSearchStart>(PrivateSearchActionTypes.PrivateSearchStart),
    mergeMap((payload: PrivateSearchStart) => {
      const hardQueryRef = payload.hardQueryRef;
      return [new SetHardQuery(hardQueryRef)];
    })
  ));

  
  setHardQuery$ = createEffect(() => this.actions$.pipe(
    ofType<SetHardQuery>(PrivateSearchActionTypes.SetHardQuery),
    map((action) => action.hardQueryRef),
    withLatestFrom(
      this.store.select(getoAuthUserId),
      this.store.select(getRouterParams)
    ),
    mergeMap(([hardQueryRef, userId, params]: [string, string, Params]) => {
      let hardQuery: IHardQuery = {
        type: SubscriberType.Company,
        id: hardQueryRef ? params[hardQueryRef] : params.subscriberId,
      };

      if (params.subscriberId === userId) {
        hardQuery = {
          type: SubscriberType.User,
          id: userId,
        };
      }

      return [new SetHardQuerySuccess(hardQuery)];
    })
  ));

  
  processPrivateSearch$ = createEffect(() => this.actions$.pipe(
    ofType(
      PrivateSearchActionTypes.SetHardQuerySuccess,
      PrivateSearchActionTypes.SetPrivateSearchPagination,
      PrivateSearchActionTypes.SetPrivateSearchOrderBy
    ),
    withLatestFrom(this.store.select(getPrivateSearchFollowUpAction)),
    mergeMap(([payload, followUpAction]: [any, Action]) => {
      return [followUpAction];
    })
  ));
}
