import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap
} from "rxjs/operators";

import {
  EducationsActionTypes,
  AddEducationsSuccess,
  AddEducationsFail,
  AddEducations,
  UpdateEducations,
  UpdateEducationsSuccess,
  UpdateEducationsFail,
  DeleteEducations,
  DeleteEducationsSuccess,
  DeleteEducationsFail
} from "../actions/educations.actions";
import { Store } from "@ngrx/store";
import { FusionCareerProfileState } from "../reducers/index";
import { getoAuthUserId } from "@fusion/oauth";
import { EmployerService } from "@fusion/service";
import { LoadJobseekerProfile } from "../actions/jobseeker-profile.actions";
import { FusionCareerProfileError } from "../../models";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";

@Injectable()
export class EducationsEffects {
  constructor(
    public actions$: Actions,
    private store: Store<FusionCareerProfileState>,
    private employerService: EmployerService
  ) {}

  
  addEducations$ = createEffect(() => this.actions$.pipe(
    ofType<AddEducations>(EducationsActionTypes.AddEducations),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([payload, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.AddEducationsFail,
        source: ErrorSource.Validation,
        data: null
      };
      const mappedExperience = this.getMappedEducation(userId, payload);
      return this.employerService.postEducation(mappedExperience).pipe(
        switchMap(dataresult => {
          return [
            new AddEducationsSuccess(dataresult),
            new LoadJobseekerProfile()
          ];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue adding your education. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new AddEducations(payload)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new AddEducationsFail(errorPayload));
        })
      );
    })
  ));

  
  updateEducations$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateEducations>(EducationsActionTypes.UpdateEducations),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([payload, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.UpdateEducationsFail,
        source: ErrorSource.Validation,
        data: null
      };
      const educationId = payload.id;
      const mappedExperience = this.getMappedEducation(userId, payload);
      delete mappedExperience.id;
      return this.employerService
        .updateEducation(mappedExperience, educationId)
        .pipe(
          switchMap(dataresult => {
            return [
              new UpdateEducationsSuccess(dataresult),
              new LoadJobseekerProfile()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue updating your education. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new UpdateEducations(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new UpdateEducationsFail(errorPayload));
          })
        );
    })
  ));

  
  deleteEducations$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteEducations>(EducationsActionTypes.DeleteEducations),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([educationId, userId]: [string, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.DeleteEducationsFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.employerService.deleteEducation(educationId, userId).pipe(
        switchMap(dataResult => {
          return [
            new DeleteEducationsSuccess(dataResult),
            new LoadJobseekerProfile()
          ];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue deleting your education. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new DeleteEducations(educationId)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new DeleteEducationsFail(errorPayload));
        })
      );
    })
  ));

  getMappedEducation(userId: string, educationRaw: any) {
    if (educationRaw.company.id) {
      return {
        id: educationRaw.id,
        user_id: userId,
        company_id: educationRaw.company.id,
        major: educationRaw.major,
        degree_type: educationRaw.degreeType,
        start_date: educationRaw.startDate,
        graduation_date: educationRaw.graduationDate || null,
        grade: educationRaw.grade || null,
        activities: educationRaw.activities || null
      };
    }
    return {
      user_id: userId,
      company: {
        name: educationRaw.company || null,
        category: educationRaw.category || null,
        type: educationRaw.type || null,
        address: educationRaw.address || null,
        unit: educationRaw.unit || null,
        city: educationRaw.city || null,
        state: educationRaw.state || null,
        country: educationRaw.country || null,
        zip_code: educationRaw.zipCode || null
      },
      major: educationRaw.major,
      degree_type: educationRaw.degreeType,
      start_date: educationRaw.startDate,
      graduation_date: educationRaw.graduationDate || null,
      grade: educationRaw.grade || null,
      activities: educationRaw.activities || null
    };
  }
}
