import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { FusionSubscriptionState } from '../reducers';
import { LoadCurrentApp } from '../actions';
import { getCurrentApp } from '../selectors';
import { filter, map, take, tap } from 'rxjs/operators';
import { RedirectToHomeApp } from '@fusion/oauth';

@Injectable({
  providedIn: 'root',
})
export class ApplicationGuard implements CanActivateChild {
  constructor(private store: Store<FusionSubscriptionState>) {}
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isStandalone = next.data.isStandalone;
    // TODO only applicable for Islam app
    if (isStandalone) {
      return of(true);
    }
    const applicationId = next.data.applicationId;
    const currentPath = next.data.currentPath || '';

    return this.store.select(getCurrentApp).pipe(
      tap((currentApp) => {
        if (!currentApp) {
          this.store.dispatch(
            new LoadCurrentApp({
              applicationId,
              currentPath,
            })
          );
        }
      }),
      take(2),
      filter((currentApp) => currentApp !== null),
      tap((currentApp) => {
        if (!currentApp.active) {
          this.store.dispatch(new RedirectToHomeApp());
        }
      }),
      map(() => true)
    );
  }
}
