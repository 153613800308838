import { PaymentActionTypes, PaymentActions } from "../actions/payment.actions";
import { IPayment } from "../../models/interfaces/payment.interfaces";

export interface PaymentState {
  data: IPayment;
  loading: boolean;
  loaded: boolean;
}

export const initialState: PaymentState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: PaymentActions
): PaymentState {
  switch (action.type) {
    case PaymentActionTypes.PostPayment: {
      return {
        ...state,
        loading: true
      };
    }
    case PaymentActionTypes.PostPaymentSuccess:
    case PaymentActionTypes.PostPaymentDeclined: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case PaymentActionTypes.PostPaymentFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case PaymentActionTypes.ResetPaymentMethod: {
      return {
        ...state,
        data: null
      };
    }
  }
  return state;
}
