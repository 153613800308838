import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromEvents from './events.reducer';
import * as fromCurrentEvent from './current-event.reducer';
import * as fromGuests from './guests.reducer';
import * as fromVideoCoference from './video-conference.reducer';

export interface CalendarState {
  events: fromEvents.EventsState;
  currentEvent: fromCurrentEvent.CurrentEventState;
  guests: fromGuests.GuestsState;
  videoConference: fromVideoCoference.VideoConferenceState;
}

export const reducers: ActionReducerMap<CalendarState> = {
  events: fromEvents.reducer,
  currentEvent: fromCurrentEvent.reducer,
  guests: fromGuests.reducer,
  videoConference: fromVideoCoference.reducer,
};

export const getCalendarState =
  createFeatureSelector<CalendarState>('calendar');

// export reducer state interfaces
