import { IRecruitingClient } from '../../models/interfaces';
import {
  RecruitingClientsActionTypes,
  RecruitingClientsActions,
} from '../actions/recruiting-clients.actions';

export interface RecruitingClientsState {
  clients: IRecruitingClient[];
  currentClient: IRecruitingClient;
  loading: boolean;
  loaded: boolean;
}

export const initialState: RecruitingClientsState = {
  clients: [],
  currentClient: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: RecruitingClientsActions
): RecruitingClientsState {
  switch (action.type) {
    case RecruitingClientsActionTypes.LoadRecruitingClients: {
      return {
        ...state,
        loading: true,
      };
    }
    case RecruitingClientsActionTypes.LoadRecruitingClientsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        clients: action.payload,
      };
    }
    case RecruitingClientsActionTypes.LoadRecruitingClientsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case RecruitingClientsActionTypes.SetCurrentRecruitingClientsSuccess: {
      return {
        ...state,
        currentClient: action.payload,
      };
    }
    case RecruitingClientsActionTypes.AddRecruitingClientsSuccess: {
      return {
        ...state,
        currentClient: null,
      };
    }
  }
  return state;
}
