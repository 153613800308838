import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionsComponent } from './containers/subscriptions/subscriptions.component';
import { SubscribeComponent } from './containers/subscribe/subscribe.component';
import { CompaniesComponent } from './containers/companies/companies.component';
import {
  OauthRouteGuard,
  OauthUserGuard,
  AppSessionGuard,
  UserSessionGuard,
} from '@fusion/oauth';
import {
  LoadSubscriptionsResolver,
  ApplicationId,
  ActiveNavigationGuard,
  ApplicationGuard,
} from '@fusion/subscription';
import { NotificationsComponent } from './containers/notifications/notifications.component';
import { SettingsComponent } from './containers/settings/settings.component';
import { WalletComponent } from './containers/wallet/wallet.component';
import { LoadWalletGuard } from '@fusion/payment';
import { FundingComponent } from './containers/funding/funding.component';
import { FundingPaymentComponent } from './containers/funding-payment/funding-payment.component';
import { ContributorsComponent } from './containers/contributors/contributors.component';
import { MosquesComponent } from './containers/mosques/mosques.component';
import { AddMosqueComponent } from './containers/add-mosque/add-mosque.component';
import { LoadJobseekerProfileResolver } from '@fusion/career-profile';
import { ProfileComponent } from './containers/profile/profile.component';
import { SearchComponent } from './containers/search/search.component';
import { JobComponent } from './containers/job/job.component';
import {
  JobseekerApplicationsComponent,
  ApplyComponent,
  ManageJobseekerApplicationComponent,
} from '@fusion/job';
import { CalendarComponent, EventEditComponent } from '@fusion/calendar';
import { CompanyComponent, LoadCompanyResolver } from '@fusion/company';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AppSessionGuard, UserSessionGuard, ApplicationGuard],
    data: { applicationId: ApplicationId.User },
    children: [
      {
        path: 'jobs',
        component: SearchComponent,
        canActivate: [ActiveNavigationGuard],
        data: { currentPath: 'jobs' },
      },
      {
        path: 'jobs/:jobId',
        canActivate: [ActiveNavigationGuard],
        component: JobComponent,
        data: { currentPath: 'jobs' },
      },
      {
        path: 'jobs/:jobId/apply',
        canActivate: [ActiveNavigationGuard],
        component: ApplyComponent,
        data: { currentPath: 'jobs' },
      },
      {
        path: 'applications',
        canActivate: [OauthRouteGuard, ActiveNavigationGuard],
        component: JobseekerApplicationsComponent,
        data: { currentPath: 'applications' },
      },
      {
        path: 'applications/:applicationId',
        canActivate: [OauthRouteGuard, ActiveNavigationGuard],
        component: ManageJobseekerApplicationComponent,
        data: { currentPath: 'applications' },
      },
      {
        path: 'users/:userId',
        component: ProfileComponent,
        canActivate: [ActiveNavigationGuard],
        data: { currentPath: '' },
        resolve: {
          loadSubscriptions: LoadJobseekerProfileResolver,
        },
      },
      {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'calendar' },
        resolve: {
          loadSubscriptions: LoadSubscriptionsResolver,
        },
      },
      {
        path: 'calendar/:eventId',
        component: EventEditComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'calendar' },
        resolve: {
          loadSubscriptions: LoadSubscriptionsResolver,
        },
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'subscriptions' },
        resolve: {
          loadSubscriptions: LoadSubscriptionsResolver,
        },
      },
      {
        path: 'subscribe',
        component: SubscribeComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'subscribe' },
        resolve: {
          loadSubscriptions: LoadSubscriptionsResolver,
        },
      },
      {
        path: 'mosques',
        component: MosquesComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'mosques' },
        resolve: {
          loadSubscriptions: LoadSubscriptionsResolver,
        },
      },
      {
        path: 'mosques/new',
        component: AddMosqueComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'mosques' },
        resolve: {
          loadSubscriptions: LoadSubscriptionsResolver,
        },
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'companies' },
        resolve: {
          loadSubscriptions: LoadSubscriptionsResolver,
        },
      },
      {
        path: 'companies/:companyId',
        component: CompanyComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'companies' },
        resolve: {
          loadCompany: LoadCompanyResolver,
        },
      },
      {
        path: 'wallet',
        component: WalletComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          LoadWalletGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'wallet' },
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'notifications' },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'settings' },
      },
      {
        path: 'funding',
        component: FundingComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'funding' },
      },
      {
        path: 'funding/:fundingOptionId',
        component: ContributorsComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          LoadWalletGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'funding' },
      },
      {
        path: 'funding/:fundingOptionId/payment',
        component: FundingPaymentComponent,
        canActivate: [
          OauthRouteGuard,
          OauthUserGuard,
          LoadWalletGuard,
          ActiveNavigationGuard,
        ],
        data: { currentPath: 'funding' },
      },
      {
        path: '**',
        redirectTo: 'jobs',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
