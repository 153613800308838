import { createSelector } from '@ngrx/store';

import { getFusionPaymentState } from '../reducers';
import {
  IWallet,
  ITransaction,
  ICouponBalance,
  IPaymentWallet,
} from '../../models/interfaces';
import { getoAuthUser, getUserType, getoAuthUserFullName } from '@fusion/oauth';
import { IUser, UserType } from '@fusion/common';
import { WalletType } from '../../models/enums';
import { getCustomerCompany } from './order.selectors';
import { ICompany } from '@fusion/company';

export const getWalletState = createSelector(getFusionPaymentState, (state) => {
  if (state == null) {
    return null;
  }
  return state.wallet;
});

export const getWallet = createSelector(
  getWalletState,
  (state): IWallet => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const isWalletLoading = createSelector(
  getWalletState,
  (state): boolean => {
    if (state == null) {
      return null;
    }
    return !state.data && state.loading;
  }
);

export const getCurrentPayment = createSelector(
  getWalletState,
  (state): ITransaction => {
    if (state == null) {
      return null;
    }
    return state.currentPayment;
  }
);

export const getCouponBalance = createSelector(
  getWalletState,
  (state): ICouponBalance => {
    if (state == null) {
      return null;
    }
    return state.coupon;
  }
);

export const isSendingPayment = createSelector(
  getWalletState,
  (state): boolean => {
    if (state == null) {
      return null;
    }
    return state.sending;
  }
);

export const getPaymentWallets = createSelector(
  getUserType,
  getoAuthUser,
  getoAuthUserFullName,
  getCustomerCompany,
  (
    customerType: string,
    user: IUser,
    username: string,
    company: ICompany
  ): IPaymentWallet[] => {
    if (customerType == null || user == null) {
      return null;
    }
    let wallets: IPaymentWallet[] = [];
    if (customerType === UserType.Company) {
      if (!company) {
        return null;
      }

      wallets = [
        ...wallets,
        {
          id: company.id,
          name: company.name,
          type: WalletType.Company,
        },
      ];
    }

    wallets = [
      ...wallets,
      {
        id: user.id,
        name: username,
        type: WalletType.Personal,
      },
    ];
    return wallets;
  }
);
