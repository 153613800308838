import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map
} from "rxjs/operators";

import {
  TransactionActionTypes,
  LoadTransactionSuccess,
  LoadTransactionFail,
  LoadTransaction
} from "../actions/transaction.actions";
import { FusionPaymentState } from "../reducers";
import { Store } from "@ngrx/store";
import { getRouterParams, getRouterUrlRoot } from "@fusion/router";
import { Params } from "@angular/router";
import { PaymentService, MappingService, mappingType } from "@fusion/service";
import { ITransactionPayload } from "../../models/interfaces";
import {
  getCurrentAppMetadata,
  ICurrentAppMetadata
} from "@fusion/subscription";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionPaymentError } from "../../models/enums";

@Injectable()
export class TransactionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionPaymentState>,
    private paymentService: PaymentService,
    private mappingService: MappingService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType<LoadTransaction>(TransactionActionTypes.LoadTransaction),
    map(action => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getRouterUrlRoot),
      this.store.select(getCurrentAppMetadata)
    ),
    mergeMap(
      ([payload, params, rootUrl, appMetadata]: [
        ITransactionPayload,
        Params,
        string,
        ICurrentAppMetadata
      ]) => {
        let errorPayload: IError<FusionPaymentError> = {
          code: FusionPaymentError.LoadTransactionFail,
          source: ErrorSource.Validation,
          data: null
        };
        const applicationId = appMetadata.applicationId;
        const referencePath = payload.referencePath;
        const customerId = params.subscriberId;
        return this.paymentService.getInvoices(customerId, applicationId).pipe(
          switchMap(dataResult => {
            let mappedResult = this.mappingService.getMappedData(
              dataResult,
              mappingType.camelize
            );
            return [
              new LoadTransactionSuccess(
                this.cleanupTransaction(mappedResult, rootUrl, referencePath)
              )
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue loading your transaction. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new LoadTransaction(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new LoadTransactionFail(errorPayload));
          })
        );
      }
    )
  ));

  cleanupTransaction(transactions, rootUrl: string, referencePath: string) {
    return transactions.map(t => {
      return {
        ...t,
        metadata: {
          date: t.updatedAt,
          payerName: t.representative.name,
          payerId: t.representative.id
        },
        reference: referencePath
          ? `${rootUrl}/${referencePath}/${t.referenceId}`
          : `${rootUrl}/${t.referenceId}`
      };
    });
  }
}
