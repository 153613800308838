import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormConfig } from '../../models/interfaces';
import {
  FormFieldType,
  FormFieldGroup,
  AutocompleteType,
  NonFormItemType,
} from '../../models/enums';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fusion-form-handler',
  templateUrl: './form-handler.component.html',
  styleUrls: ['./form-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHandlerComponent implements OnInit {
  @Input() formConfigs: IFormConfig[];
  @Input() formValidationRules: UntypedFormGroup;
  @Input() autoCompleteEntities: any;
  @Input() autoCompleteType: AutocompleteType;
  @Input() entityType: string;
  @Output() notifyFormValidationUpdate = new EventEmitter<boolean>();
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();

  FormFieldType = FormFieldType;
  NonFormItemType = NonFormItemType;
  FormFieldGroup = FormFieldGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  navigateTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }
}
