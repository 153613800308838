<div *ngIf="job" class="header-overlay row2">
  <div cardHrSpacing class="row2__section-one col2">
    <!-- company logo -->
    <div centering center logo class="col2__section-one">
      <ng-container *ngIf="job.clientCompany; else companyLogo">
        <img
          pointer
          imageFit
          src="{{
            job.clientCompany?.logo ||
              '/assets/images/users/company-logo/logoPlaceholder.png'
          }}"
          (click)="notifyViewCompany.emit(job?.clientCompany?.id)"
        />
      </ng-container>
      <ng-template #companyLogo>
        <ng-container *ngIf="job.company; else promotionalCompanyLogo">
          <img
            pointer
            imageFit
            src="{{
              job.company?.logo ||
                '/assets/images/users/company-logo/logoPlaceholder.png'
            }}"
            (click)="notifyViewCompany.emit(job?.company?.id)"
          />
        </ng-container>
        <ng-template #promotionalCompanyLogo>
          <img
            imageFit
            src="{{ '/assets/images/users/company-logo/logoPlaceholder.png' }}"
          />
        </ng-template>
      </ng-template>
    </div>
    <!-- job header -->
    <div primaryFontColor class="col2__section-two row3">
      <div class="row3__section-one">
        <span largetitle bold>{{ job.position }}</span>
        <span centering centerLeft class="job-status">
          <span *ngIf="isEditable && !job.active" chip draft>draft</span>
          <span *ngIf="job.active" chip live>live</span>
        </span>
      </div>
      <div microTitle class="row3__section-two">
        <ng-container *ngIf="job.clientCompany; else regularJob">
          <span
            pointer
            errorFontColor
            (click)="notifyViewCompany.emit(job?.clientCompany?.id)"
            >{{ job?.clientCompany?.name }}</span
          >
        </ng-container>
        <ng-template #regularJob>
          <ng-container *ngIf="job.company; else promotionalJob">
            <span
              pointer
              errorFontColor
              (click)="notifyViewCompany.emit(job?.company?.id)"
              >{{ job?.company?.name }}</span
            >
          </ng-container>
          <ng-template #promotionalJob>
            <span errorFontColor>{{ job.companyName }}</span>
          </ng-template>
        </ng-template>

        <span dot></span>
        <span *ngIf="job.address">{{ job.address }}, </span>
        <span
          >{{ job.city }}<span *ngIf="job.state">, {{ job.state }}</span> -
          {{ job.country }}</span
        >
      </div>
      <div nanoTitle semiBold class="row3__section-three">
        Posted {{ job.createdAt | fancyTime }}
      </div>
    </div>
  </div>

  <div cardHrSpacing class="row2__section-two row3">
    <div>
      <fusion-job-metadata
        [metadata]="metadata"
        [chipColor]="'default'"
      ></fusion-job-metadata>
    </div>

    <ng-container [ngSwitch]="jobListingType">
      <ng-container *ngSwitchCase="JobListingType.OpenPosition">
        <ng-container *ngIf="job.active; else draftPosition">
          <div
            warningBackground
            border
            radius
            cardSmallSpacing
            class="open-position"
          >
            <span primaryFontColor nanoTitle
              ><span semiBold>Note:</span> This Open Position currently is
              available to all approved recruiting agencies for promotion.</span
            >
          </div>
        </ng-container>
        <ng-template #draftPosition>
          <div
            warningBackground
            border
            radius
            cardSmallSpacing
            class="open-position"
          >
            <span errorFontColor nanoTitle
              ><span semiBold>Note:</span> This Open Position currently is not
              available to the recruiting agencies for promotion.</span
            >
          </div>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="footer col2">
          <div *ngIf="job.clientCompany">
            <div microFont semiBold>Managed by</div>
            <div
              bgLightYellow
              centering
              centerLeft
              border
              radius
              cardSmallSpacing
            >
              <mat-icon color="accent" class="icon-display"
                >verified_user</mat-icon
              >
              <span primaryFontColor nanoTitle semiBold>Recruiter</span>
              <div centering center>
                <div smallLogo>
                  <img
                    pointer
                    imageFit
                    src="{{
                      job.company?.logo || '/assets/images/job/job-banner-9.jpg'
                    }}"
                    (click)="notifyViewCompany.emit(job?.company?.id)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="social-share row2">
            <div centering centerLeft microFont semiBold>Share</div>
            <div>
              <fusion-common-social-icons
                [currentUrl]="'/jobs/' + job.id"
              ></fusion-common-social-icons>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
