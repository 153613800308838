import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IUser } from '@fusion/common';
import { ICompany } from '@fusion/company';
import {
  IFormConfig,
  IFormMetadata,
  setFormValidationRules,
} from '@fusion/form';
import { ApplicationStatus } from '../../models/enums';
import { PhoneScreeningForm } from '../../models/forms';
import { IInterview, IScreening } from '../../models/interfaces';

@Component({
  selector: 'phone-screening',
  templateUrl: './phone-screening.component.html',
  styleUrls: ['./phone-screening.component.scss'],
})
export class PhoneScreeningComponent implements OnInit {
  @Output() notifyScreeningUpdate = new EventEmitter<IScreening>();
  @Input() isPreview: boolean;
  @Input() company: ICompany;
  @Input() applicant: IUser;
  screeningValues: any = {};
  _currentScreening: IScreening;
  @Input('currentScreening') set currentScreening(
    currentScreening: IScreening
  ) {
    if (!currentScreening) {
      return;
    }
    this._currentScreening = currentScreening;
    const interview: IInterview = currentScreening.interview;
    if (interview) {
      this.screeningValues = {
        phoneNo: interview.phoneNo,
        hasAccepted: interview.hasAccepted,
        ...interview.time,
      };
    }
  }

  get currentScreening() {
    return this._currentScreening;
  }

  descriptionMetadata: IFormMetadata = PhoneScreeningForm.metadata;
  descriptionConfigs: IFormConfig[] = PhoneScreeningForm.configs;
  descriptionValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(PhoneScreeningForm.configs)
  );
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  notifyDescription(eventData) {
    const screeningPayload: IScreening = {
      id: this.currentScreening.id,
      status: ApplicationStatus.PhoneScreening,
      interview: {
        time: {
          date: eventData.date,
          startDatetime: eventData.startDatetime,
          endDatetime: eventData.endDatetime,
          startHour: eventData.startHour,
          startMinute: eventData.startMinute,
          startPeriod: eventData.startPeriod,
          endHour: eventData.endHour,
          endMinute: eventData.endMinute,
          endPeriod: eventData.endPeriod,
        },
        phoneNo: eventData.phoneNo,
      },
    };
    console.log('========>', screeningPayload);
    this.notifyScreeningUpdate.emit(screeningPayload);
  }
}
