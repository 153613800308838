import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import { mergeMap, catchError, map, switchMap } from "rxjs/operators";

import {
  UnclaimedCompaniesActionTypes,
  LoadUnclaimedCompaniesSuccess,
  LoadUnclaimedCompaniesFail,
  LoadUnclaimedCompanies
} from "../actions/unclaimed-companies.actions";
import { EmployerService, MappingService, mappingType } from "@fusion/service";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionCompanyError } from "../../models/enums";
import { ICompany } from "../../models/interfaces";

@Injectable()
export class UnclaimedCompaniesEffects {
  constructor(
    private actions$: Actions,
    private employerService: EmployerService,
    private mappingService: MappingService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUnclaimedCompanies>(
      UnclaimedCompaniesActionTypes.LoadUnclaimedCompanies
    ),
    map(action => action.payload),
    mergeMap((payload: any) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.LoadUnclaimedCompaniesFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.employerService.getUnclaimedCompanies(payload, false).pipe(
        switchMap(dataResult => {
          const mappedData = this.mappingService.getMappedData<ICompany[]>(
            dataResult,
            mappingType.camelize
          );
          return [new LoadUnclaimedCompaniesSuccess(mappedData)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue loading unclaimed companies. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadUnclaimedCompanies(payload)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new LoadUnclaimedCompaniesFail(errorPayload));
        })
      );
    })
  ));
}
