import {
  IForm,
  FormFieldType,
  FormFieldGroup,
  FormFieldPattern,
} from '@fusion/form';
import {
  companyTypes,
  companyCategories,
  noOfEmployees,
  countries,
} from '@fusion/common';

export const AddCompanyForm: IForm = {
  metadata: {
    name: 'COMPANY_FORM',
    title: 'Add Company',
    description: 'add company & unlock your fortune!',
    submitButtonText: 'add/claim company',
    isToggleButton: true,
    style: {
      topMargin: '0px',
      maxWidth: '740px',
      maxHeight: '550px',
    },
  },
  configs: [
    {
      label: 'Company Name',
      name: 'name',
      type: FormFieldType.Autocomplete,
      placeholder: 'Company Name',
      autoFillItems: [
        'category',
        'website',
        'type',
        'founded',
        'noOfEmployees',
        'phoneNo',
        'companyDetail',
        'address',
        'city',
        'zipCode',
        'state',
        'country',
      ],
      validation: {
        required: true,
        min: 2,
        max: 100,
      },
    },
    {
      label: 'Company Type',
      name: 'type',
      type: FormFieldType.Dropdown,
      options: companyTypes,
      validation: {
        required: true,
      },
    },
    {
      label: 'Company Category',
      name: 'category',
      type: FormFieldType.Dropdown,
      options: companyCategories,
      validation: {
        required: true,
      },
    },
    {
      label: 'No of Employees',
      name: 'noOfEmployees',
      type: FormFieldType.Dropdown,
      options: noOfEmployees,
      validation: {
        required: true,
      },
    },
    {
      label: 'Date Founded',
      name: 'founded',
      type: FormFieldType.Date,
      placeholder: 'YYYY-MM-DD',
      examplePattern: '1985-12-30',
      validation: {
        required: true,
      },
    },
    // company details - start
    {
      label: 'Company Overview',
      name: 'companyDetail',
      type: FormFieldType.Textarea,
      placeholder: 'project description',
      validation: {
        required: true,
        min: 100,
        max: 10000,
      },
    },
    // company details - ends
    {
      label: 'Phone #',
      name: 'phoneNo',
      type: FormFieldType.Phone,
      placeholder: '(347) 123-1234',
      validation: {
        required: true,
        min: 2,
        max: 100,
      },
    },
    {
      label: 'Website',
      name: 'website',
      type: FormFieldType.Url,
      placeholder: 'https://example.com',
      examplePattern: 'https://example.com',
      validation: {
        required: false,
        min: 2,
        max: 500,
        pattern: FormFieldPattern.Url,
      },
    },
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: 'Address',
          name: 'address',
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 150,
          },
        },
        {
          label: 'Unit#',
          name: 'unit',
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 10,
          },
        },
        {
          label: 'City',
          name: 'city',
          type: FormFieldType.Text,
          placeholder: 'city',
          validation: {
            required: true,
            max: 100,
          },
        },
        {
          label: 'State',
          name: 'state',
          type: FormFieldType.Text,
          validation: {
            required: false,
          },
        },
        {
          label: 'Zip Code',
          name: 'zipCode',
          type: FormFieldType.Text,
          placeholder: 'zip code',
          examplePattern: '11432',
          validation: {
            required: false,
            max: 10,
            pattern: FormFieldPattern.Number,
          },
        },
        {
          label: 'Country',
          name: 'country',
          type: FormFieldType.Dropdown,
          options: countries,
          validation: {
            required: true,
          },
        },
      ],
    },
  ],
};
