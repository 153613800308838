import { CalendarEvent } from 'angular-calendar';
import { ICalendarEventMetadata } from '../../models/interfaces';
import { EventsActionTypes, EventsActions } from '../actions/events.actions';

export interface EventsState {
  data: CalendarEvent<ICalendarEventMetadata>[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: EventsState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: EventsActions
): EventsState {
  switch (action.type) {
    case EventsActionTypes.LoadEvents: {
      return {
        ...state,
        loading: true,
      };
    }
    case EventsActionTypes.LoadEventsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case EventsActionTypes.LoadEventsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
