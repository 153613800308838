import { Action } from '@ngrx/store';
import { IJobApplication, IScreening } from '../../models/interfaces';

export enum ApplicationsActionTypes {
  LoadApplications = '[Job Applications] Load',
  LoadApplicationsSuccess = '[Job Applications] Load Success',
  LoadApplicationsFail = '[Job Applications] Load Fail',
  LoadJobseekerApplications = '[Jobseeker Applications] Load',
  LoadJobseekerApplicationsSuccess = '[Jobseeker Applications] Load Success',
  LoadJobseekerApplicationsFail = '[Jobseeker Applications] Load Fail',
  ApplyJob = '[Job Applications] Apply',
  ApplyJobSuccess = '[Job Applications] Apply Success',
  ApplyJobFail = '[Job Applications] Apply Fail',
  AddScreening = '[Job Applications] Add Screening',
  AddScreeningSuccess = '[Job Applications] Add Screening Success',
  AddScreeningFail = '[Job Applications] Add Screening Fail',
  UpdateScreening = '[Job Applications] Update Screening',
  UpdateScreeningSuccess = '[Job Applications] Update Screening Success',
  UpdateScreeningFail = '[Job Applications] Update Screening Fail',
  UpdateInterview = '[Job Applications] Update Interview',
  UpdateInterviewSuccess = '[Job Applications] Update Interview Success',
  UpdateInterviewFail = '[Job Applications] Update Interview Fail',
  AcceptInterview = '[Job Applications] Accept Interview',
  AcceptInterviewSuccess = '[Job Applications] Accept Interview Success',
  AcceptInterviewFail = '[Job Applications] Accept Interview Fail',
}

export class LoadApplications implements Action {
  readonly type = ApplicationsActionTypes.LoadApplications;
}

export class LoadApplicationsSuccess implements Action {
  readonly type = ApplicationsActionTypes.LoadApplicationsSuccess;

  constructor(public payload: IJobApplication[]) {}
}

export class LoadApplicationsFail implements Action {
  readonly type = ApplicationsActionTypes.LoadApplicationsFail;
}

export class LoadJobseekerApplications implements Action {
  readonly type = ApplicationsActionTypes.LoadJobseekerApplications;
}

export class LoadJobseekerApplicationsSuccess implements Action {
  readonly type = ApplicationsActionTypes.LoadJobseekerApplicationsSuccess;

  constructor(public payload: IJobApplication[]) {}
}

export class LoadJobseekerApplicationsFail implements Action {
  readonly type = ApplicationsActionTypes.LoadJobseekerApplicationsFail;
}

export class ApplyJob implements Action {
  readonly type = ApplicationsActionTypes.ApplyJob;

  constructor(public payload: string) {}
}

export class ApplyJobSuccess implements Action {
  readonly type = ApplicationsActionTypes.ApplyJobSuccess;

  constructor(public payload: any) {}
}

export class ApplyJobFail implements Action {
  readonly type = ApplicationsActionTypes.ApplyJobFail;
}

export class AddScreening implements Action {
  readonly type = ApplicationsActionTypes.AddScreening;

  constructor(public payload?: IScreening) {}
}

export class AddScreeningSuccess implements Action {
  readonly type = ApplicationsActionTypes.AddScreeningSuccess;

  constructor(public payload: any) {}
}

export class AddScreeningFail implements Action {
  readonly type = ApplicationsActionTypes.AddScreeningFail;
}

export class UpdateScreening implements Action {
  readonly type = ApplicationsActionTypes.UpdateScreening;

  constructor(public payload: IScreening) {}
}

export class UpdateScreeningSuccess implements Action {
  readonly type = ApplicationsActionTypes.UpdateScreeningSuccess;

  constructor(public payload: any) {}
}

export class UpdateScreeningFail implements Action {
  readonly type = ApplicationsActionTypes.UpdateScreeningFail;
}

export class UpdateInterview implements Action {
  readonly type = ApplicationsActionTypes.UpdateInterview;

  constructor(public eventId: string) {}
}

export class UpdateInterviewSuccess implements Action {
  readonly type = ApplicationsActionTypes.UpdateInterviewSuccess;

  constructor(public payload: any) {}
}

export class UpdateInterviewFail implements Action {
  readonly type = ApplicationsActionTypes.UpdateInterviewFail;
}

export class AcceptInterview implements Action {
  readonly type = ApplicationsActionTypes.AcceptInterview;

  constructor(public screeningId: string) {}
}

export class AcceptInterviewSuccess implements Action {
  readonly type = ApplicationsActionTypes.AcceptInterviewSuccess;

  constructor(public payload: any) {}
}

export class AcceptInterviewFail implements Action {
  readonly type = ApplicationsActionTypes.AcceptInterviewFail;
}

export type ApplicationsActions =
  | LoadApplications
  | LoadApplicationsSuccess
  | LoadApplicationsFail
  | LoadJobseekerApplications
  | LoadJobseekerApplicationsSuccess
  | LoadJobseekerApplicationsFail
  | ApplyJob
  | ApplyJobSuccess
  | ApplyJobFail
  | AddScreening
  | AddScreeningSuccess
  | AddScreeningFail
  | UpdateScreening
  | UpdateScreeningSuccess
  | UpdateScreeningFail
  | UpdateInterview
  | UpdateInterviewSuccess
  | UpdateInterviewFail
  | AcceptInterview
  | AcceptInterviewSuccess
  | AcceptInterviewFail;
