import { IJob } from '../../models/interfaces';
import { 
  OpenPositionsActionTypes,
  OpenPositionsActions
} from '../actions/open-positions.actions';

export interface OpenPositionsState {
  entities: IJob[];
  count: number;
  loading: boolean;
  loaded: boolean;
}

export const initialState: OpenPositionsState = {
  entities: [],
  count: 0,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: OpenPositionsActions
): OpenPositionsState {
  switch (action.type) {
    case OpenPositionsActionTypes.LoadOpenPositions: {
      return {
        ...state,
        loading: true
      };
    }
    case OpenPositionsActionTypes.LoadOpenPositionsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        entities: action.payload
      };
    }
    case OpenPositionsActionTypes.LoadOpenPositionsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case OpenPositionsActionTypes.SetOpenPositionsCount: {
      return {
        ...state,
        count: action.payload,
      };
    }
  }
  return state;
}
