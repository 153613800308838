import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  CurrentJobActionTypes,
  LoadCurrentJob,
  LoadCurrentJobFail,
} from '../actions/current-job.actions';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';
import * as fromActions from '../actions/index';
import { Store, Action } from '@ngrx/store';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { FusionJobState } from '../reducers/index';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionJobError } from '../../models/enums';
import { IJob } from '../../models/interfaces/job.interfaces';

@Injectable()
export class CurrentJobEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionJobState>,
    private jobSearchService: JobService,
    private mappingService: MappingService
  ) {}

  effect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadCurrentJob>(CurrentJobActionTypes.LoadCurrentJob),
      map((action) => action),
      withLatestFrom(this.store.select(getRouterParams)),
      mergeMap(([action, params]: [Action, Params]) => {
        let errorPayload: IError<FusionJobError> = {
          code: FusionJobError.LoadCurrentJobFail,
          source: ErrorSource.Validation,
          data: null,
        };
        return this.jobSearchService.getJob(params.jobId).pipe(
          switchMap((result) => {
            const mappedJob = this.mappingService.getMappedData<IJob>(
              result,
              mappingType.camelize
            );
            return [new fromActions.LoadCurrentJobSuccess(mappedJob)];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue loading current job. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new LoadCurrentJob()],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new LoadCurrentJobFail(errorPayload));
          })
        );
      })
    )
  );
}
