import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IJob } from '../../models/interfaces/job.interfaces';

@Component({
  selector: 'fusion-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
})
export class JobListComponent implements OnInit {
  @Input() jobs: IJob[];
  @Input() currentRootPath: string;
  @Input() parentDir: string;
  @Output() notifyCurrentJob = new EventEmitter<IJob>();

  constructor() {}

  ngOnInit() {}

  getPreviewDir(jobId) {
    if (this.parentDir && this.parentDir.length > 0) {
      return `${this.currentRootPath}/${this.parentDir}/${jobId}`;
    }
    return `${this.currentRootPath}/${jobId}`;
  }
}
