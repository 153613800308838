import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import { Observable } from 'rxjs';
import { IProfileNav, getActiveNav, IEmptyState } from '@fusion/common';
import {
  getoAuthUserId,
  getoAuthUserFullName,
  getEmployerCompanies,
  getRepresentativeCompanies,
  FusionoAuthError,
} from '@fusion/oauth';
import {
  LoadRedirectSubscriber,
  hasCompanySubscription,
  FusionSubscriptionError,
  IApplication,
} from '@fusion/subscription';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  getUnclaimedCompanies,
  LoadUnclaimedCompanies,
  AddCompany,
  ICompany,
} from '@fusion/company';
import {
  IErrorHandlingConfig,
  getErrorHandlingConfig,
  ErrorHandlingType,
} from '@fusion/error';
import { AddCompanyForm } from '../../models/forms';
import { setFormValidationRules } from '@fusion/form';
import { userNav } from '../../models/data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'user-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CompaniesComponent implements OnInit {
  companies$: Observable<ICompany[]>;
  unclaimedCompanies$: Observable<ICompany[]>;
  repCompanies$: Observable<ICompany[]>;
  userName$: Observable<string>;
  currentRootPath$: Observable<string>;
  badgeCount: number = 18;
  hasCompanySubscription$: Observable<any>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;
  userProfileErrorHandlingConfig$: Observable<IErrorHandlingConfig>;
  emptyState: IEmptyState = {
    path: '/subscribe',
    card: {
      title: 'add subscription',
      description: 'Explore our unlimited services!',
    },
    message: {
      title: 'no company subscription',
      description: 'Subscribe company to access resources!',
    },
  };
  ErrorHandlingType = ErrorHandlingType;

  // form setup
  addCompanyFormMetadata: any = AddCompanyForm.metadata;
  addCompanyFormConfigs: any = AddCompanyForm.configs;
  addCompanyFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(AddCompanyForm.configs)
  );

  constructor(
    private store: Store<UserState>,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getoAuthUserId);
    this.userName$ = this.store.select(getoAuthUserFullName);
    this.companies$ = this.store.select(getEmployerCompanies);
    this.repCompanies$ = this.store.select(getRepresentativeCompanies);
    this.hasCompanySubscription$ = this.store.select(hasCompanySubscription);
    this.unclaimedCompanies$ = this.store.select(getUnclaimedCompanies);
    this.errorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionSubscriptionError.LoadSubscriptionsFail)
    );
    this.userProfileErrorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionoAuthError.LoadUserProfileFail)
    );
  }

  onNotifyViewCompany(companyId: string) {
    const currentUrl = this.router.url;
    this.router.navigate([companyId], {
      queryParams: { referrerUrl: currentUrl },
      relativeTo: this.route,
    });
  }

  onNotifyManageCompany(companyId: string) {
    const payload: IApplication = {
      domain: 'company',
      id: companyId,
    };
    this.store.dispatch(new LoadRedirectSubscriber(payload));
  }

  onNotifyAutocompletion(autoCompleteInput: string) {
    this.store.dispatch(new LoadUnclaimedCompanies(autoCompleteInput));
  }

  onNotifyFormSubmit(formData) {
    const companyPayload: ICompany = {
      ...formData.data,
      hasAccessPermission: 'true',
    };
    this.store.dispatch(new AddCompany(companyPayload));
  }
}
