<div section>
  <div sectionHeader>
    <div sectionTitle smallTitle microBold>
      Email
    </div>
    <div sectionSubtitle microTitle>
      Dot to Circle will use this information to contact you.
    </div>
  </div>
  <div sectionContent card cardSpacing>
    <fusion-form-template
      [isDialog]="false"
      [formValidationRules]="updatedValidationRules"
      [formConfigs]="emailFormConfigs"
      [formMetadata]="emailFormMetadata"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    ></fusion-form-template>
  </div>
</div>
