import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getRouterUrlRoot } from '@fusion/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IJob, IJobApplication } from '../../models/interfaces';
import {
  LoadApplications,
  LoadCurrentJob,
  LoadJobseekerApplications,
} from '../../store/actions';
import { FusionJobState } from '../../store/reducers';
import { getApplications, getCurrentJob } from '../../store/selectors';

@Component({
  selector: 'jobseeker-applications',
  templateUrl: './jobseeker-applications.component.html',
  styleUrls: ['./jobseeker-applications.component.scss'],
})
export class JobseekerApplicationsComponent implements OnInit {
  currentRootPath$: Observable<string>;
  jobApplications$: Observable<IJobApplication[]>;

  constructor(
    private store: Store<FusionJobState>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.store.dispatch(new LoadCurrentJob());
    this.store.dispatch(new LoadJobseekerApplications());
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.jobApplications$ = this.store.select(getApplications);
  }

  onManageJobApplication(application: IJobApplication) {
    this.router.navigate([`${application.id}`], {
      relativeTo: this.route,
    });
  }

  onViewCompany(companyId: string) {
    const currentUrl = this.router.url;
    this.router.navigate([`../companies/${companyId}`], {
      queryParams: { referrerUrl: currentUrl },
      relativeTo: this.route,
    });
  }
}
