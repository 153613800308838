<div centering center class="error-container">
  <div
    errorBorder
    cardExSpacing
    radius
    errorFontColor
    *ngIf="errorHandlingConfig"
    class="error row3"
  >
    <div class="row3__section-one">
      <mat-icon largeFont aria-hidden="false" aria-label="warning" color="warn"
        >warning</mat-icon
      >
    </div>
    <div class="row3__section-two">{{ errorHandlingConfig.message }}</div>
    <ng-container
      *ngIf="
        errorHandlingConfig?.action?.primary &&
          errorHandlingConfig?.action?.secondary;
        else singleAction
      "
    >
      <div class="row3__section-three col1">
        <div
          centeringRight
          *ngIf="errorHandlingConfig?.action.secondary"
          class="col1__section-one"
        >
          <button
            mat-stroked-button
            color="primary"
            (click)="
              errorHandlingActionSecondary(
                errorHandlingConfig?.action.secondary
              )
            "
          >
            {{ errorHandlingConfig.action.secondary.title }}
          </button>
        </div>
        <div
          centeringLeft
          *ngIf="errorHandlingConfig?.action.primary"
          class="col1__section-two"
        >
          <button
            mat-raised-button
            color="primary"
            (click)="
              errorHandlingActionPrimary(errorHandlingConfig?.action.primary)
            "
          >
            {{ errorHandlingConfig.action.primary.title }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #singleAction>
      <div *ngIf="errorHandlingConfig?.action?.primary" class="row3__section-three">
        <button
          mat-raised-button
          color="primary"
          (click)="
            errorHandlingActionPrimary(errorHandlingConfig?.action?.primary)
          "
        >
          {{ errorHandlingConfig.action.primary.title }}
        </button>
      </div>
    </ng-template>
  </div>
</div>
