<div border radious class="colN padding-1">
  <!-- Facebook -->
  <a
    centering
    center
    href="http://www.facebook.com/sharer.php?u=https://preview.dottocircle.com{{
      currentUrl
    }}"
    target="_blank"
  >
    <mat-icon color="{{ color }}" svgIcon="facebook-box"></mat-icon>
  </a>

  <!-- LinkedIn -->
  <a
    centering
    center
    href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://preview.dottocircle.com{{
      currentUrl
    }}"
    target="_blank"
  >
    <mat-icon color="{{ color }}" svgIcon="linkedin-box"></mat-icon>
  </a>

  <!-- Twitter -->
  <a
    centering
    center
    href="https://twitter.com/share?url=https://preview.dottocircle.com{{
      currentUrl
    }}"
    target="_blank"
  >
    <mat-icon color="{{ color }}" svgIcon="twitter-box"></mat-icon>
  </a>

  <!-- Google+ -->
  <a
    centering
    center
    href="https://plus.google.com/share?url=https://preview.dottocircle.com{{
      currentUrl
    }}"
    target="_blank"
  >
    <mat-icon color="{{ color }}" svgIcon="google-plus-box"></mat-icon>
  </a>
</div>
