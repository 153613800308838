import { createSelector } from '@ngrx/store';

import { getFusionoAuthState } from '../reducers';
import { getRouterParams } from '@fusion/router';
import { UserType } from '@fusion/common';
import { getoAuthUserId } from './user-session.selectors';

export const getUserState = createSelector(getFusionoAuthState, (state) => {
  if (state == null) {
    return null;
  }
  return state.user;
});

export const getUserType = createSelector(
  getRouterParams,
  getoAuthUserId,
  (params, userId): UserType => {
    if (params == null || userId == null) {
      return null;
    }
    if (params.subscriberId && params.subscriberId !== userId) {
      return UserType.Company;
    }
    // TODO verify if this has any side effect
    if (params.companyId) {
      return UserType.Company;
    }
    return UserType.User;
  }
);
