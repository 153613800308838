import { Component, OnInit, Input } from '@angular/core';
import { ICompany } from '../../models/interfaces';

@Component({
  selector: 'fusion-company-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  @Input() company: ICompany;

  constructor() {}

  ngOnInit() {}
}
