import { IForm, FormFieldType, FormFieldPattern } from '@fusion/form';

export const MosqueContactForm: IForm = {
  metadata: {
    name: 'MOSQUE_CONTACT_FORM',
    title: 'Add Mosque',
    description: 'add mosque / prayer place & help other muslim to pray',
    submitButtonText: 'Next',
    stepper: {
      isStepper: true,
    },
  },
  configs: [
    // company details - ends
    {
      label: 'Phone #',
      name: 'phoneNo',
      type: FormFieldType.Phone,
      placeholder: '(347) 123-1234',
      validation: {
        required: false,
        min: 2,
        max: 100,
      },
    },
    {
      label: 'Website',
      name: 'website',
      type: FormFieldType.Url,
      placeholder: 'http://elitecareer.net',
      examplePattern: 'http://elitecareer.net',
      validation: {
        required: false,
        min: 2,
        max: 1000,
        pattern: FormFieldPattern.Url,
      },
    },
  ],
};
