import { createSelector } from '@ngrx/store';
import { IJobMetadata } from '../../models/interfaces';

import { getFusionJobState } from '../reducers';

export const getCurrentOpenPositionState = createSelector(
  getFusionJobState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.currentOpenPosition;
  }
);

export const getCurrentOpenPosition = createSelector(
  getCurrentOpenPositionState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getOpenPositionMetadata = createSelector(
  getCurrentOpenPosition,
  (openPosition): IJobMetadata[] => {
    if (openPosition == null) {
      return null;
    }
    return [
      {
        isSalary: false,
        label: openPosition.level,
      },
      {
        isSalary: false,
        label: openPosition.type,
      },
      {
        isSalary: false,
        label: openPosition.category,
      },
      {
        isSalary: !openPosition.negotiable,
        amount: openPosition.salary,
        currencyCode: openPosition.currencyCode,
        label: !openPosition.negotiable
          ? `${openPosition.salaryUnit}`
          : `Negotiable`,
      },
    ];
  }
);
