import { Component, OnInit, Input } from "@angular/core";
import { IStripeBillingAddress } from "../../models/interfaces";

@Component({
  selector: "fusion-payment-billing-settings",
  templateUrl: "./billing-settings.component.html",
  styleUrls: ["./billing-settings.component.scss"]
})
export class BillingSettingsComponent implements OnInit {
  @Input() address: IStripeBillingAddress;

  constructor() {}

  ngOnInit() {}
}
