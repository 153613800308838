import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import { isSameDay } from 'date-fns';
import { Observable } from 'rxjs';
import { CalendarEventType, EventCategory, JoiningStatus } from '../../models';
import {
  ICalendarEventMetadata,
  IEventGuest,
  IEventRawData,
  IEventUpdatePayload,
  IGuestSummary,
  IVideoConferenceMetadata,
} from '../../models/interfaces';

@Component({
  selector: 'calendar-join-event',
  templateUrl: './join-event.component.html',
  styleUrls: ['./join-event.component.scss'],
})
export class JoinEventComponent implements OnInit {
  @Output() notifyDeleteEvent = new EventEmitter();
  @Output() notifyJoiningStatusUpdate = new EventEmitter<IEventUpdatePayload>();
  @Output() notifyJoinVideoConference =
    new EventEmitter<IVideoConferenceMetadata>();
  isSameDay = true;
  currentEvent: CalendarEvent<ICalendarEventMetadata>;
  guests$: Observable<IEventGuest[]>;
  guestSummary$: Observable<IGuestSummary>;
  currentGuest$: Observable<IEventGuest>;
  applicantGuest$: Observable<IEventGuest>;

  JoiningStatus = JoiningStatus;
  EventCategory = EventCategory;
  CalendarEventType = CalendarEventType;

  constructor(
    public dialogRef: MatDialogRef<JoinEventComponent>,
    @Inject(MAT_DIALOG_DATA) public eventData: IEventRawData,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.eventData) {
      this.currentEvent = this.eventData.event;
      this.guests$ = this.eventData.guests;
      this.guestSummary$ = this.eventData.guestSummary;
      this.currentGuest$ = this.eventData.currentGuest;
      this.applicantGuest$ = this.eventData.applicantGuest;
      this.cdr.detectChanges();
    }
    this.isSameDay = isSameDay(this.currentEvent.start, this.currentEvent.end);
  }

  onJoiningStatusUpdate(joiningStatus: JoiningStatus) {
    this.notifyJoiningStatusUpdate.emit({ joiningStatus });
  }

  onJoinVideoConference(
    currentEvent: CalendarEvent<ICalendarEventMetadata>,
    currentGuest: IEventGuest
  ) {
    const joinVodeoPayload: IVideoConferenceMetadata = {
      eventId: currentEvent.id,
      id: currentEvent.meta.videoConferenceId,
      role: currentGuest.guestRole,
    };
    this.notifyJoinVideoConference.emit(joinVodeoPayload);
  }

  onNotifyUpdateEvent(eventId: any) {
    this.router.navigate([`calendar/${eventId}`], {
      relativeTo: this.route,
    });
  }
}
