import { IJobApplication } from '../../models/interfaces';
import {
  CurrentApplicationActionTypes,
  CurrentApplicationActions,
} from '../actions/current-application.actions';

export interface CurrentApplicationState {
  data: IJobApplication;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CurrentApplicationState = {
  data: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: CurrentApplicationActions
): CurrentApplicationState {
  switch (action.type) {
    case CurrentApplicationActionTypes.LoadCurrentApplication: {
      return {
        ...state,
        loading: true,
      };
    }
    case CurrentApplicationActionTypes.LoadCurrentApplicationSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case CurrentApplicationActionTypes.LoadCurrentApplicationFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
