<ng-container [ngSwitch]="fusionForm?.displayType">
  <ng-container *ngSwitchCase="FormDisplayType.Modal">
    <fusion-form-dialog
      [formValidationRules]="updatedValidationRules || formValidationRules"
      [formConfigs]="formConfigs"
      [formMetadata]="formMetadata"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    ></fusion-form-dialog>
  </ng-container>

  <ng-container *ngSwitchCase="FormDisplayType.Plain">
    <fusion-form-template
      [error]="error"
      [progressState]="progressState"
      [formValidationRules]="updatedValidationRules || formValidationRules"
      [formConfigs]="formConfigs"
      [formMetadata]="formMetadata"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    ></fusion-form-template>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <!-- TODO may be fetch error component -->
    <div class="fusion-form__default"></div>
  </ng-container>
</ng-container>
