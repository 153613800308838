import { oAuthActionTypes, oAuthActions } from '../actions/oauth.actions';
import { ISession, IoAuthUser } from '../../models/interfaces';

export interface oAuthState {
  userSession: ISession;
  appSession: ISession;
  sessionProfile: IoAuthUser;
  isInProgress: boolean;
  isSuccess: boolean;
}

export const initialState: oAuthState = {
  userSession: null,
  appSession: null,
  sessionProfile: null,
  isInProgress: false,
  isSuccess: false
};

export function reducer(
  state = initialState,
  action: oAuthActions
): oAuthState {
  switch (action.type) {
    case oAuthActionTypes.oAuthStart:
    case oAuthActionTypes.GetAppToken:
    case oAuthActionTypes.PasswordResetRequest: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case oAuthActionTypes.oAuthSuccess:
    case oAuthActionTypes.GetAppTokenSuccess:
    case oAuthActionTypes.SessionRestoreSuccess:
    case oAuthActionTypes.TokenRefreshSuccess: {
      return {
        ...state,
        isInProgress: false,
        userSession: action.payload,
      };
    }
    case oAuthActionTypes.PasswordResetRequestSuccess: {
      return {
        ...state,
        isInProgress: false,
        isSuccess: true
      };
    }
    case oAuthActionTypes.SessionEnd: {
      return initialState;
    }
    case oAuthActionTypes.TokenDecodeSuccess: {
      return {
        ...state,
        sessionProfile: action.payload,
      };
    }
    case oAuthActionTypes.oAuthFail:
    case oAuthActionTypes.GetAppTokenFail:
    case oAuthActionTypes.PasswordResetRequestFail: {
      return {
        ...state,
        isInProgress: false,
      };
    }
  }
  return state;
}
