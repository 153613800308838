import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers, CustomSerializer } from './store/reducers';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
  FullRouterStateSerializer,
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { effects } from './store';

@NgModule({
  declarations: [],
  imports: [
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    StoreModule.forFeature('fusionRouter', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [],
  providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
})
export class FusionRouterModule {}
