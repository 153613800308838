import { Action } from '@ngrx/store';

export enum SeoActionTypes {
  SetUserSeo = '[Seo User] Set',
  SetUserSeoSuccess = '[Seo User] Set Success',
  SetUserSeoFail = '[Seo User] Set Fail',
}

export class SetUserSeo implements Action {
  readonly type = SeoActionTypes.SetUserSeo;
}

export class SetUserSeoSuccess implements Action {
  readonly type = SeoActionTypes.SetUserSeoSuccess;

  constructor( public payload: any ) { }
}

export class SetUserSeoFail implements Action {
  readonly type = SeoActionTypes.SetUserSeoFail;
}

export type SeoActions =
  | SetUserSeo
  | SetUserSeoSuccess
  | SetUserSeoFail;
