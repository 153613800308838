<div card cardSpacing whiteFontColor primaryBgColor class="balance-card row2">
  <div class="row2__section-one col2">
    <div mediumTitle class="col2__section-one">Funding Total</div>
    <div centering centerRight class="col2__section-two">
      <img
        extraSmallProfileImage
        alt="elitecareer"
        src="/assets/images/users/company-logo/dot2circle-logo-white.svg"
      />
    </div>
  </div>
  <div centering centerRight class="row2__section-two">
    <span extraLargeTitle>${{ fundingTotal }}</span>
  </div>
</div>
