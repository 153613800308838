import { Injectable } from '@angular/core';
import { Router, NavigationExtras, Params } from '@angular/router';

import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import {
  withLatestFrom,
  mergeMap,
  map,
  debounceTime,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';

// from feature
import * as fromActions from '../actions/index';
import * as fromSelectors from '../selectors/index';
import {
  RestoreSearch,
  SearchActionTypes,
  SearchStart,
} from '../actions/index';
import { getRouterQueryParams, getRouterUrl } from '@fusion/router';
import { arrayify } from '@fusion/common';
import { FusionSearchState, SearchState } from '../reducers/index';
import { ISearch } from '../../models';

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSearchState>,
    private router: Router
  ) {}

  
  startSearch$ = createEffect(() => this.actions$.pipe(
    ofType<SearchStart>(SearchActionTypes.SearchStart),
    map((action) => action.followUpAction),
    mergeMap((followUpAction: Action) => {
      return [new RestoreSearch(followUpAction)];
    })
  ));

  
  processSearch$ = createEffect(() => this.actions$.pipe(
    ofType(
      SearchActionTypes.SetSearchCategory,
      SearchActionTypes.SetSearchLevel,
      SearchActionTypes.SetSearchType,
      SearchActionTypes.SetSearchKeywords,
      SearchActionTypes.SetOrderBy,
      SearchActionTypes.SetSearchPagination
    ),
    withLatestFrom(this.store.select(fromSelectors.getSearchFollowUpAction)),
    debounceTime(500),
    mergeMap(([action, followUpAction]: [Action, Action]) => {
      return [new fromActions.UpdateSearchUrl(), followUpAction];
    })
  ));

  
  restoreCurrentSearch$ = createEffect(() => this.actions$.pipe(
    ofType<RestoreSearch>(SearchActionTypes.RestoreSearch),
    map((action) => action.followUpAction),
    withLatestFrom(this.store.select(getRouterQueryParams)),
    mergeMap(([followUpAction, params]: [Action, Params]) => {
      const paramsCount = Object.keys(params).length;
      // restore search for no params and empty state
      if (paramsCount === 0) {
        return [
          new fromActions.UpdateSearchUrl(),
          followUpAction
        ];
      }
      // restore search for no params but existing state
      if (paramsCount === 0) {
        return [new fromActions.UpdateSearchUrl()];
      }
      // restore search for existing params but empty state
      if (paramsCount > 0) {
        const search: ISearch = {
          active: true,
          keywords: params.keywords || null,
          levels: arrayify(params.level),
          types: arrayify(params.type),
          category: params.category || null,
          order: params.order || null,
          pagination: {
            pageIndex: params.pageIndex ? parseInt(params.pageIndex, 10) : 0,
            pageSize: params.pageSize ? parseInt(params.pageSize, 10) : 10,
            offset: params.offset ? parseInt(params.offset, 10) : 0,
          },
        };

        return [
          new fromActions.RestoreSearchSuccess(search),
          followUpAction
        ];
      }
      // return of();
    })
  ));

  
  searchNavigation$ = createEffect(() => this.actions$.pipe(
    ofType<fromActions.UpdateSearchUrl>(SearchActionTypes.UpdateSearchUrl),
    map((action) => action),
    withLatestFrom(
      this.store.select(fromSelectors.getSearchState),
      this.store.select(getRouterUrl)
    ),
    tap(([action, state, path]: [Action, SearchState, string]) => {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          keywords: state.search.keywords,
          type: state.search.types.length > 0 ? state.search.types : null,
          level: state.search.levels.length > 0 ? state.search.levels : null,
          category: state.search.category,
          order: state.search.order,
          pageIndex: state.search.pagination.pageIndex,
          pageSize: state.search.pagination.pageSize,
          offset: state.search.pagination.offset,
          override: true,
        },
      };

      this.router.navigate([this.getCleanedPath(path)], navigationExtras);
    })
  ), { dispatch: false });

  getCleanedPath(path: string) {
    if (path && path !== '') {
      const pathParts = path.split('?');
      return `${pathParts[0]}`;
    }
    return path;
  }
}
