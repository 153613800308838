import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  AddJobActionTypes,
  AddJobFail,
  AddJob,
  AddJobSuccess,
  UpdateJob,
  UpdateJobSuccess,
  UpdateJobFail,
} from '../actions/add-job.actions';
import { LoadCurrentJob } from '../actions/current-job.actions';
import { Store } from '@ngrx/store';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { getRouterParams, getRouterUrlBase } from '@fusion/router';
import { Params, Router } from '@angular/router';
import { getoAuthUserId } from '@fusion/oauth';
import { FusionJobState } from '../reducers';
import {
  IOrderItemPayload,
  AddOrderItem,
  ActivateOrderItem,
  OrderActionTypes,
  ActivateOrderItemSuccess,
  ActivateOrderItemFail,
  getCustomerCompany,
} from '@fusion/payment';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionJobError } from '../../models/enums';
import { IDetail, IJob } from '../../models/interfaces';
import { ICompany } from '@fusion/company';
import { getCurrentJob } from '../selectors';
import { constants } from 'perf_hooks';
import { jobDetailsData } from '../../models/data';

@Injectable()
export class AddJobEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionJobState>,
    private jobService: JobService,
    private mappingService: MappingService,
    private router: Router
  ) {}

  
  addJob$ = createEffect(() => this.actions$.pipe(
    ofType<AddJob>(AddJobActionTypes.AddJob),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId),
      this.store.select(getRouterUrlBase),
      this.store.select(getCustomerCompany)
    ),
    mergeMap(
      ([payload, params, userId, path, company]: [
        any,
        Params,
        string,
        string,
        ICompany
      ]) => {
        let errorPayload: IError<FusionJobError> = {
          code: FusionJobError.AddJobFail,
          source: ErrorSource.Validation,
          data: null,
        };
        const companyId = params.subscriberId;
        const mappedJob = this.getMappedJob(
          userId,
          companyId,
          payload,
          company
        );
        return this.jobService.postJob(mappedJob).pipe(
          switchMap((dataResult: any) => {
            const itemData: IOrderItemPayload = {
              productId: 'e083e16b-acd6-4037-ba2d-84cf2d6d8c1a',
              quantity: 1,
              orderReferenceId: dataResult.id,
            };
            this.router.navigate([
              `${this.getCleanedPath(path)}/jobs/${dataResult.id}/checkout`,
            ]);
            return [new AddJobSuccess(dataResult), new AddOrderItem(itemData)];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue adding your job. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new AddJob(payload)],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new AddJobFail(errorPayload));
          })
        );
      }
    )
  ));

  
  updateJob$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateJob>(AddJobActionTypes.UpdateJob),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getCurrentJob),
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId),
      this.store.select(getRouterUrlBase),
      this.store.select(getCustomerCompany)
    ),
    mergeMap(
      ([payload, currentJob, params, userId, path, company]: [
        IJob,
        IJob,
        Params,
        string,
        string,
        ICompany
      ]) => {
        let errorPayload: IError<FusionJobError> = {
          code: FusionJobError.UpdateJobFail,
          source: ErrorSource.Validation,
          data: null,
        };
        const jobId = params.jobId;
        const companyId = params.subscriberId;
        const updatedJob = this.getUpdatedJob(
          userId,
          companyId,
          currentJob,
          payload,
          company
        );

        const mappedJob = this.mappingService.getMappedData<any>(
          updatedJob,
          mappingType.underscore
        );

        delete mappedJob.company_id;
        delete mappedJob.likes;
        delete mappedJob.applications;
        delete mappedJob.default_address;
        delete mappedJob.client_company;
        delete mappedJob.client_company_id;
        delete mappedJob.company;
        return this.jobService.updateJob(jobId, mappedJob).pipe(
          switchMap((dataResult: any) => {
            return [new UpdateJobSuccess(dataResult), new LoadCurrentJob()];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue updating your job. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new UpdateJob(payload)],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new UpdateJobFail(errorPayload));
          })
        );
      }
    )
  ));

  
  activateJob$ = createEffect(() => this.actions$.pipe(
    ofType<ActivateOrderItem>(OrderActionTypes.ActivateOrderItem),
    map((action) => action.payload),
    switchMap((referenceId: string) => {
      const data = {
        active: true,
      };

      return this.jobService.updateJob(referenceId, data).pipe(
        switchMap((dataResult) => {
          return [new ActivateOrderItemSuccess()];
        })
      );
    }),
    catchError((error) => {
      return of(new ActivateOrderItemFail());
    })
  ));

  getCleanedPath(path: string) {
    return path.substring(1);
  }

  getUpdatedJob(
    userId,
    companyId,
    currentJob: IJob,
    jobRaw,
    company: ICompany
  ) {
    return {
      ...currentJob,
      ...jobRaw,
      companyId,
      userId,
      companyName: company.name,
      jobDetails: this.getUpdatedJobDetails(
        currentJob.jobDetails,
        jobRaw.jobDetails || []
      ),
    };
  }

  getUpdatedJobDetails(jobDetails: IDetail[], jobDetailsPayload: IDetail[]) {
    let updatedJobDetails = jobDetails.map((detail) => {
      const mappedDetail = jobDetailsPayload.find(
        (d) => d.title === detail.title
      );
      if (mappedDetail) {
        return {
          ...detail,
          content: mappedDetail.content,
        };
      }
      return {
        ...detail,
      };
    });

    jobDetailsPayload.map((d) => {
      const detail = updatedJobDetails.find(
        (updatedDetail) => updatedDetail.title === d.title
      );
      if (!detail) {
        updatedJobDetails = [
          ...updatedJobDetails,
          {
            ...d,
          },
        ];
      }
    });

    // sorting
    let sortedDetails = [];
    jobDetailsData.map((detailData) => {
      const item = updatedJobDetails.find(
        (updatedDetail) => updatedDetail.title === detailData.title
      );
      if (item) {
        sortedDetails = [
          ...sortedDetails,
          {
            ...item,
          },
        ];
      }
    });

    return sortedDetails;
  }

  getMappedJob(userId, companyId, jobRaw, company: ICompany) {
    return {
      company_id: companyId,
      user_id: userId,
      category: jobRaw.category,
      position: jobRaw.position,
      company_name: company.name,
      vacancy: jobRaw.vacancy,
      type: jobRaw.type,
      level: jobRaw.level,
      address: jobRaw.defaultAddress ? company.address : jobRaw.address,
      city: jobRaw.defaultAddress ? company.city : jobRaw.city,
      state: jobRaw.defaultAddress ? company.state : jobRaw.state,
      country: jobRaw.defaultAddress ? company.country : jobRaw.country,
      zip_code: jobRaw.defaultAddress ? company.zipCode : jobRaw.zipCode,
      salary: jobRaw.salary,
      currency_code: jobRaw.currencyCode,
      salary_unit: jobRaw.salaryUnit,
      negotiable: jobRaw.negotiable,
      offshore: jobRaw.offshore,
      apply_type: jobRaw.applyType,
      url: jobRaw.url,
      deadline: jobRaw.deadline,
      email: jobRaw.email,
      job_details: this.getJobDetails(jobRaw),
    };
  }

  getJobDetails(jobRaw) {
    let details = [];
    details = jobRaw.jobDescription
      ? [
          ...details,
          {
            title: 'Job Description',
            content: jobRaw.jobDescription,
          },
        ]
      : details;

    details = jobRaw.jobResponsibility
      ? [
          ...details,
          {
            title: 'Job Responsibility',
            content: jobRaw.jobResponsibility,
          },
        ]
      : details;

    details = jobRaw.educationRequirements
      ? [
          ...details,
          {
            title: 'Education Requirements',
            content: jobRaw.educationRequirements,
          },
        ]
      : details;

    details = jobRaw.experienceRequirements
      ? [
          ...details,
          {
            title: 'Experience Requirements',
            content: jobRaw.experienceRequirements,
          },
        ]
      : details;

    details = jobRaw.additionalRequirements
      ? [
          ...details,
          {
            title: 'Additional Requirements',
            content: jobRaw.additionalRequirements,
          },
        ]
      : details;

    details = jobRaw.benifits
      ? [
          ...details,
          {
            title: 'Benifits',
            content: jobRaw.benifits,
          },
        ]
      : details;

    details = jobRaw.instructions
      ? [
          ...details,
          {
            title: 'Apply Instructions',
            content: jobRaw.instructions,
          },
        ]
      : details;

    return details;
  }
}
