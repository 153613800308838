import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map,
} from 'rxjs/operators';

import {
  CustomerActionTypes,
  LoadCustomerSuccess,
  LoadCustomerFail,
  SetupCustomerSuccess,
  SetupCustomerFail,
  AddCardSuccess,
  AddCardFail,
  ConfirmCard,
  ConfirmCardFail,
  ConfirmCardSuccess,
  LoadCustomer,
} from '../actions/customer.actions';
import { Store } from '@ngrx/store';
import { FusionPaymentState } from '../reducers';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';
import { PaymentService, MappingService, mappingType } from '@fusion/service';
import { ICustomer, IPaymentMethod } from '../../models/interfaces';
import { UserType, IUser } from '@fusion/common';
import { getoAuthUser, getoAuthUserId } from '@fusion/oauth';
import { getCompany, ICompany } from '@fusion/company';
import { SetupWallet } from '../actions/wallet.actions';
import { getCurrentPaymentMethod } from '../selectors';
import { ErrorHandlingType, ErrorSource, IError } from '@fusion/error';
import { FusionPaymentError } from '../../models/enums';

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionPaymentState>,
    private paymentService: PaymentService,
    private mappingService: MappingService
  ) {}

  
  loadCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.LoadCustomer),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(([action, params, userId]: [any, Params, string]) => {
      const customerId = params.companyId || userId;
      return this.paymentService.getCustomer(customerId).pipe(
        switchMap((dataResult) => {
          const mappedDataResult = this.mappingService.getMappedData<ICustomer>(
            dataResult,
            mappingType.camelize
          );
          return [new LoadCustomerSuccess(mappedDataResult)];
        })
      );
    }),
    catchError((error) => of(new LoadCustomerFail()))
  ));

  
  setupCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.SetupCustomer),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getoAuthUser),
      this.store.select(getCompany)
    ),
    mergeMap(
      ([action, params, user, company]: [any, Params, IUser, ICompany]) => {
        // TODO refoctor for more reliable data points for followings
        const userType = params.companyId ? UserType.Company : UserType.User;
        const customerId = params.companyId || user.id;
        const userName = user.middleName
          ? `${user.firstName} ${user.middleName} ${user.lastName}`
          : `${user.firstName} ${user.lastName}`;
        const customer = {
          customer_id: customerId,
          customer_type: userType,
          name: userType === UserType.Company ? company.name : userName,
          email: user.email, // TODO for company should be company email
        };
        return this.paymentService.postCustomer(customer).pipe(
          switchMap((dataResult) => {
            const mappedDataResult =
              this.mappingService.getMappedData<ICustomer>(
                dataResult,
                mappingType.camelize
              );
            return [
              new SetupCustomerSuccess(mappedDataResult),
              new SetupWallet({ customerId, customerType: userType }),
            ];
          }),
          catchError((error) => {
            const errorPayload: IError<FusionPaymentError> = {
              code: FusionPaymentError.SetupCustomerFail,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Message,
                message:
                  'Sorry, we are having some issue setting up your Wallet. Please try again later.',
              },
            };
            return of(new SetupCustomerFail(errorPayload));
          })
        );
      }
    )
  ));

  
  addCard$ = createEffect(() => this.actions$.pipe(
    ofType(CustomerActionTypes.AddCard),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(([action, params, userId]: [any, Params, string]) => {
      const customerId = params.companyId || userId;
      const customer = {
        customer_id: customerId,
      };
      return this.paymentService.postCard(customer).pipe(
        switchMap((dataResult) => {
          const mappedDataResult =
            this.mappingService.getMappedData<IPaymentMethod>(
              dataResult,
              mappingType.camelize
            );
          return [new AddCardSuccess(mappedDataResult)];
        })
      );
    }),
    catchError((error) => of(new AddCardFail()))
  ));

  
  confirmCard$ = createEffect(() => this.actions$.pipe(
    ofType<ConfirmCard>(CustomerActionTypes.ConfirmCard),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentPaymentMethod)),
    mergeMap(([payload, paymentMethod]: [any, IPaymentMethod]) => {
      const paymentMethodId = paymentMethod.id;
      const card = {
        payment_method: payload,
      };

      return this.paymentService.confirmCard(card, paymentMethodId).pipe(
        switchMap((dataResult) => {
          const mappedDataResult =
            this.mappingService.getMappedData<IPaymentMethod>(
              dataResult,
              mappingType.camelize
            );
          return [new ConfirmCardSuccess(mappedDataResult), new LoadCustomer()];
        })
      );
    }),
    catchError((error) => of(new ConfirmCardFail()))
  ));
}
