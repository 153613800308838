import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IErrorHandlingConfig } from '@fusion/error';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import { getoAuthUserId } from '@fusion/oauth';

@Component({
  selector: 'user-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  currentRootPath$: Observable<string>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;
  badgeCount: number = 18;

  constructor(private store: Store<UserState>) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getoAuthUserId);
  }
}
