import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from '@angular/core';
import { StripeModule } from './stripe/stripe.module';
import { CheckoutComponent } from './containers/checkout/checkout.component';
import { StripeCheckoutComponent } from './components/stripe-checkout/stripe-checkout.component';
import { CheckoutInvoiceComponent } from './components/checkout-invoice/checkout-invoice.component';
import { CheckoutSummaryComponent } from './components/checkout-summary/checkout-summary.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers/index';
import { effects } from './store/effects/index';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SuccessComponent } from './containers/success/success.component';
import { TransactionsComponent } from './containers/transactions/transactions.component';
import {
  FusionCommonModule,
  environment,
  environmentProd,
} from '@fusion/common';
import { OrdersComponent } from './containers/orders/orders.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { LoadWalletComponent } from './components/load-wallet/load-wallet.component';
import { BalanceComponent } from './components/balance/balance.component';
import { WalletTransactionsComponent } from './components/wallet-transactions/wallet-transactions.component';
import { WalletComponent } from './containers/wallet/wallet.component';
import { BillingSettingsComponent } from './components/billing-settings/billing-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './components/card/card.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { RedeemCouponComponent } from './components/redeem-coupon/redeem-coupon.component';
import { AddCardComponent } from './components/add-card/add-card.component';
import { AddCardDialogComponent } from './components/add-card-dialog/add-card-dialog.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { guards } from './store/guards/index';
import { FundingOptionsComponent } from './containers/funding-options/funding-options.component';
import { FundingPaymentComponent } from './containers/funding-payment/funding-payment.component';
import { FundingBalanceComponent } from './containers/funding-balance/funding-balance.component';
import { FusionPipeModule } from '@fusion/pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    CheckoutComponent,
    StripeCheckoutComponent,
    CheckoutInvoiceComponent,
    CheckoutSummaryComponent,
    SuccessComponent,
    TransactionsComponent,
    OrdersComponent,
    InvoiceComponent,
    LoadWalletComponent,
    BalanceComponent,
    WalletTransactionsComponent,
    WalletComponent,
    BillingSettingsComponent,
    CardComponent,
    PaymentMethodsComponent,
    RedeemCouponComponent,
    AddCardComponent,
    AddCardDialogComponent,
    PaymentsComponent,
    FundingOptionsComponent,
    FundingPaymentComponent,
    FundingBalanceComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FusionPipeModule,
    MatStepperModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FusionCommonModule,
    NgxPayPalModule,
    StripeModule,
    StoreModule.forFeature('fusionPayment', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [
    StripeModule,
    CheckoutComponent,
    SuccessComponent,
    TransactionsComponent,
    OrdersComponent,
    WalletComponent,
    StripeCheckoutComponent,
    FundingOptionsComponent,
    FundingBalanceComponent,
    FundingPaymentComponent,
  ],
  providers: [guards],
})
export class FusionPaymentModule {
  static forRoot(
    appEnvironment: any
  ): ModuleWithProviders<FusionPaymentModule> {
    return {
      ngModule: FusionPaymentModule,
      providers: [
        {
          provide: 'stripeKey', // you can also use InjectionToken
          useValue: appEnvironment.production
            ? environmentProd.stripeKey
            : environment.stripeKey,
        },
        {
          provide: 'stripeOptions',
          useValue: null,
        },
      ],
    };
  }
}
