import { JobsEffects } from './jobs.effects';
import { CurrentJobEffects } from './current-job.effects';
import { AddJobEffects } from './add-job.effects';
import { ErrorEffects } from './error.effects';
import { ApplicationsEffects } from './applications.effects';
import { CurrentApplicationEffects } from './current-application.effects';
import { CurrentScreeningEffects } from './current-screening.effects';
import { RecruitingClientsEffects } from './recruiting-clients.effects';
import { RecruitingAgenciesEffects } from './recruiting-agencies.effects';
import { CurrentOpenPositionEffects } from './current-open-position.effects';
import { OpenPositionsEffects } from './open-positions.effects';

// import effects

// include all effects
export const effects: any[] = [
  JobsEffects,
  CurrentJobEffects,
  AddJobEffects,
  ErrorEffects,
  ApplicationsEffects,
  CurrentApplicationEffects,
  CurrentScreeningEffects,
  RecruitingClientsEffects,
  RecruitingAgenciesEffects,
  CurrentOpenPositionEffects,
  OpenPositionsEffects,
];

// export from effects
