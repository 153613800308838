import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormConfig } from '../../models/interfaces';

@Component({
  selector: 'fusion-form-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {}

  getRequiredConfig(configs: any, options: string[]) {
    return configs.filter((config: any) => {
      if (options.length && options.indexOf(config.name) > -1) {
        return config;
      }
    });
  }
}
