import {
  IForm,
  FormFieldType,
  FormFieldGroup,
  FormFieldPattern,
  FormDisplayType,
  NonFormItemType,
} from '@fusion/form';
import { countries } from '@fusion/common';

export const ReferralSignUpForm: IForm = {
  displayType: FormDisplayType.Plain,
  metadata: {
    name: 'SIGN_UP_FORM',
    title: 'Sign Up',
    submitButtonText: 'Sign Up',
    style: {
      topMargin: '0px',
      maxWidth: '740px',
      maxHeight: '550px',
    },
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Name,
      configs: [
        {
          label: 'First Name',
          name: 'firstName',
          type: FormFieldType.Text,
          placeholder: 'John',
          validation: {
            required: true,
            max: 100,
          },
        },
        {
          label: 'Middle Name',
          name: 'middleName',
          type: FormFieldType.Text,
          placeholder: 'Deep',
          validation: {
            required: false,
            max: 100,
          },
        },
        {
          label: 'Last Name',
          name: 'lastName',
          type: FormFieldType.Text,
          placeholder: 'Deep',
          validation: {
            required: true,
            max: 100,
          },
        },
      ],
    },
    // {
    //   type: FormFieldType.Group,
    //   group: FormFieldGroup.ShortAddress,
    //   configs: [
    //     {
    //       label: 'City',
    //       name: 'city',
    //       type: FormFieldType.Text,
    //       placeholder: 'city',
    //       validation: {
    //         required: true,
    //         max: 100,
    //       },
    //     },
    //     {
    //       label: 'Country',
    //       name: 'country',
    //       type: FormFieldType.Dropdown,
    //       options: countries,
    //       validation: {
    //         required: true,
    //       },
    //     },
    //   ],
    // },
    {
      label: 'Password',
      name: 'password',
      type: FormFieldType.Password,
      placeholder: 'password',
      examplePattern: 'at least 1 uppercase, 1 lowercase, 1 special case char and 1 number',
      validation: {
        required: true,
        pattern: FormFieldPattern.Password,
        min: 8,
        max: 50,
      },
    },
    {
      type: FormFieldType.NonFormItem,
      nonFormItem: {
        label: `<span>By signing up, you agree to our <a>terms of service</a> and <a>privacy policy</a>.</span>`,
        type: NonFormItemType.Html,
      },
    },
  ],
};
