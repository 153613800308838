export enum ApplicationStatus {
  Pending = 'pending',
  SkillsAssessment = 'skills-assessment',
  TechnicalExercise = 'technical-exercise',
  PhoneScreening = 'phone-screening',
  VideoInterview = 'video-interview',
  InPerson = 'in-person',
  BackgroundCheck = 'background-check',
  JobOffer = 'job-offer'
}
