<div page>
  <div topNav><fusion-oauth-top-nav></fusion-oauth-top-nav></div>
  <div body>
    <fusion-oauth-body
      [sideNav]="sideNav$ | async"
      [isUserApp]="true"
      [currentRootPath]="'/' + (currentRootPath$ | async)"
    >
    </fusion-oauth-body>
  </div>
  <div footer><fusion-common-footer></fusion-common-footer></div>
</div>
