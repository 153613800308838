import { createSelector } from '@ngrx/store';

import { getFusionoAuthState } from '../reducers';
import { getUserAppBaseUrl } from './applications.selectors';
import { ISession, IoAuthUser, IGTMUser } from '../../models/interfaces';
import { IUser } from '@fusion/common';
import { getRouterParams } from '@fusion/router';

export const getOauthState = createSelector(getFusionoAuthState, (state) => {
  if (state == null) {
    return null;
  }
  return state.oAuth;
});

export const getOauthProgressState = createSelector(getOauthState, (state) => {
  if (state == null) {
    return null;
  }
  return {
    isInProgress: state.isInProgress,
    isSuccess: state.isSuccess,
  };
});

// export const getoAuthSession = createSelector(
//   getOauthState,
//   (oAuthSession): ISession => {
//     if (oAuthSession === null) {
//       return null;
//     }
//     return oAuthSession.userSession;
//   }
// );

// export const getoAuthSessionToken = createSelector(
//   getoAuthSession,
//   (oAuthSession): string => {
//     if (oAuthSession === null) {
//       return null;
//     }
//     return oAuthSession.token;
//   }
// );

// export const getoAuthDecodedToken = createSelector(
//   getOauthState,
//   (oAuthSession): IoAuthUser => {
//     if (oAuthSession === null) {
//       return null;
//     }
//     return oAuthSession.sessionProfile;
//   }
// );

// export const getoAuthUser = createSelector(
//   getoAuthDecodedToken,
//   (decodedToken): IUser => {
//     if (decodedToken === null) {
//       return null;
//     }
//     return decodedToken.user || null;
//   }
// );

// export const getoAuthUserId = createSelector(
//   getoAuthUser,
//   (oAuthUser): string => {
//     if (oAuthUser === null) {
//       return null;
//     }
//     return oAuthUser.id;
//   }
// );

// export const hasUserProfileEditPermission = createSelector(
//   getoAuthUserId,
//   getRouterParams,
//   (oAuthUserId, params): boolean => {
//     if (oAuthUserId === null) {
//       return null;
//     }
//     return oAuthUserId === params.userId;
//   }
// );

// export const getoAuthUserFullName = createSelector(
//   getoAuthUser,
//   (oAuthUser): string => {
//     if (oAuthUser === null) {
//       return null;
//     }
//     return oAuthUser.middleName
//       ? `${oAuthUser.firstName} ${oAuthUser.middleName} ${oAuthUser.lastName}`
//       : `${oAuthUser.firstName} ${oAuthUser.lastName}`;
//   }
// );

// export const getoAuthUserProfilePath = createSelector(
//   getoAuthUserId,
//   getUserAppBaseUrl,
//   (oAuthUserId, userAppBaseUrl): string => {
//     if (oAuthUserId === null || userAppBaseUrl == null) {
//       return null;
//     }

//     return `${userAppBaseUrl}/users/${oAuthUserId}`;
//   }
// );

// export const getGTMUserDetails = createSelector(
//   getoAuthUser,
//   getoAuthUserFullName,
//   (user, userName): IGTMUser => {
//     if (user === null) {
//       return null;
//     }
//     return {
//       event: 'signin',
//       userId: user.id,
//       name: userName,
//       country: user.country,
//     };
//   }
// );
