<div *ngIf="formConfigs && formValidationRules" class="row">
  <div
    *ngFor="
      let config of getRequiredConfig(formConfigs, [
        'firstName',
        'lastName'
      ])
    "
  >
    <div class="col-6" *ngIf="config.name == 'firstName'">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
    <!-- <div class="col-4" *ngIf="config.name == 'middleName'">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div> -->
    <div class="col-6" *ngIf="config.name == 'lastName'">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
  </div>
</div>
