<form
  mat-autocomplete
  role="form"
  action=" "
  method="post"
  [formGroup]="formValidationRules"
  (ngSubmit)="notifyFormSubmit.emit(formValidationRules.value)"
>
  <div class="row3 b-padding-9">
    <fusion-common-dialog-header
      sticky
      class="row3__section-one"
      [title]="formMetadata.title"
      (notifyDialogClose)="formClose()"
    ></fusion-common-dialog-header>

    <div class="h-padding-9 v-padding-5 row3__section-two">
      <fusion-form-handler
        [formConfigs]="formConfigs"
        [formValidationRules]="formValidationRules"
        [autoCompleteEntities]="autoCompleteEntities"
        [autoCompleteType]="autoCompleteType"
        [entityType]="entityType"
        (notifyAutocompletion)="notifyAutocompletion.emit($event)"
        (notifyFormValidationUpdate)="notifyFormValidationUpdate.emit($event)"
      ></fusion-form-handler>
    </div>

    <div centering class="row3__section-three">
      <button
        *ngIf="dialogRef"
        type="submit"
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(formValidationRules.value)"
        [disabled]="!formValidationRules.valid"
      >
        {{
          formMetadata?.submitButtonText &&
          (formMetadata?.submitButtonText).toLowerCase() == 'next'
            ? 'Submit'
            : formMetadata?.submitButtonText
        }}
        <span class="glyphicon glyphicon-send"></span>
      </button>
    </div>
  </div>
</form>
