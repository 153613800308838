import { jobDetailsData } from '../data';
import { IJob } from '../interfaces';

export function getUpdatedJob(currentJob: IJob): IJob {
  const updatedJob = {
    ...currentJob,
  };

  jobDetailsData?.map((detail) => {
    const item = updatedJob?.jobDetails?.find((d) => d.title === detail.title);
    updatedJob[detail.content] = item ? item.content : null;
  });

  return updatedJob;
}

export function getMappedJobDetails(rawJobDetails: any) {
  let details = [];

  jobDetailsData?.map((detail) => {
    const content = rawJobDetails[detail.content];
    details = content
      ? [
          ...details,
          {
            title: detail.title,
            content,
          },
        ]
      : details;
  });

  return {
    jobDetails: details,
  };
}
