import { Action } from '@ngrx/store';
import { IVideoConferenceMetadata } from '../../models';

export enum VideoConferenceActionTypes {
  LoadVideoConference = '[Video Conference] Load',
  LoadVideoConferenceSuccess = '[Video Conference] Load Success',
  LoadVideoConferenceFail = '[Video Conference] Load Fail',
  JoinVideoConference = '[Video Conference] Join',
  JoinVideoConferenceSuccess = '[Video Conference] Join Success',
  JoinVideoConferenceFail = '[Video Conference] Join Fail',
  CreateVideoConference = '[Video Conference] Create',
  CreateVideoConferenceSuccess = '[Video Conference] Create Success',
  CreateVideoConferenceFail = '[Video Conference] Create Fail',
}

export class LoadVideoConference implements Action {
  readonly type = VideoConferenceActionTypes.LoadVideoConference;
}

export class LoadVideoConferenceSuccess implements Action {
  readonly type = VideoConferenceActionTypes.LoadVideoConferenceSuccess;

  constructor(public payload: any) {}
}

export class LoadVideoConferenceFail implements Action {
  readonly type = VideoConferenceActionTypes.LoadVideoConferenceFail;
}

export class JoinVideoConference implements Action {
  readonly type = VideoConferenceActionTypes.JoinVideoConference;

  constructor(public payload: IVideoConferenceMetadata) {}
}

export class JoinVideoConferenceSuccess implements Action {
  readonly type = VideoConferenceActionTypes.JoinVideoConferenceSuccess;

  constructor(public payload: any) {}
}

export class JoinVideoConferenceFail implements Action {
  readonly type = VideoConferenceActionTypes.JoinVideoConferenceFail;
}

export class CreateVideoConference implements Action {
  readonly type = VideoConferenceActionTypes.CreateVideoConference;
  
  constructor(public payload: IVideoConferenceMetadata) {}
}

export class CreateVideoConferenceSuccess implements Action {
  readonly type = VideoConferenceActionTypes.CreateVideoConferenceSuccess;

  constructor(public payload: any) {}
}

export class CreateVideoConferenceFail implements Action {
  readonly type = VideoConferenceActionTypes.CreateVideoConferenceFail;
}

export type VideoConferenceActions =
  | LoadVideoConference
  | LoadVideoConferenceSuccess
  | LoadVideoConferenceFail
  | JoinVideoConference
  | JoinVideoConferenceSuccess
  | JoinVideoConferenceFail
  | CreateVideoConference
  | CreateVideoConferenceSuccess
  | CreateVideoConferenceFail;
