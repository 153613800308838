import { Action } from '@ngrx/store';
import { IReferralStats } from '../../models/interfaces';

export enum ReferralsActionTypes {
  AddReferrals = '[Referrals] Add',
  AddReferralsSuccess = '[Referrals] Add Success',
  AddReferralsFail = '[Referrals] Add Fail',
  LoadReferralsStats = '[Referrals] Load Stats',
  LoadReferralsStatsSuccess = '[Referrals] Load Stats Success',
  LoadReferralsStatsFail = '[Referrals] Load Stats Fail',
}

export class AddReferrals implements Action {
  readonly type = ReferralsActionTypes.AddReferrals;

  constructor(public payload: string[]) {}
}

export class AddReferralsSuccess implements Action {
  readonly type = ReferralsActionTypes.AddReferralsSuccess;

  constructor(public payload: any) {}
}

export class AddReferralsFail implements Action {
  readonly type = ReferralsActionTypes.AddReferralsFail;
}

export class LoadReferralsStats implements Action {
  readonly type = ReferralsActionTypes.LoadReferralsStats;
}

export class LoadReferralsStatsSuccess implements Action {
  readonly type = ReferralsActionTypes.LoadReferralsStatsSuccess;

  constructor(public payload: IReferralStats) {}
}

export class LoadReferralsStatsFail implements Action {
  readonly type = ReferralsActionTypes.LoadReferralsStatsFail;
}

export type ReferralsActions =
  | AddReferrals
  | AddReferralsSuccess
  | AddReferralsFail
  | LoadReferralsStats
  | LoadReferralsStatsSuccess
  | LoadReferralsStatsFail;
