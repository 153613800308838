<div card class="row3">
  <!-- section header -->
  <fusion-career-profile-card-header
    class="row3__section-one"
    [isColor]="false"
    [isEditable]="allowEdit"
    [title]="'Publications'"
    [buttonTitle]="'Add'"
    [iconType]="ModalIconType.AddIcon"
    [formValidationRules]="publicationFormValidationRules"
    [formConfigs]="publicationFormConfigs"
    [formMetadata]="publicationFormMetadata"
    (notifyFormSubmit)="notifyFormSubmit.emit($event)"
  ></fusion-career-profile-card-header>

  <!-- section body -->
  <div class="row3__section-two">
    <div
      hover
      bottomDivider
      cardSpacing
      career
      *ngFor="let publication of publications"
    >
      <!-- logo -->
      <div careerLogo smallLogo>
        <img
          imageFit
          src="/assets/images/users/company-logo/{{ 'logoPlaceholder.png' }}"
        />
      </div>

      <!-- detail -->
      <div careerDetail>
        <!-- position -->
        <div careerDetailHeader>
          <h5 g6FontColor>{{ publication?.title }}</h5>
          <div exSmallTitle g6FontColor>{{ publication?.publisher }}</div>
          <div microTitle g5FontColor>
            {{ publication.publishedAt | date: "d MMM, yyyy" }}
          </div>
          <div exSmallTitle g5FontColor>
            <a href="{{ publication.website }}" target="_blank"
              >View Publication</a
            >
          </div>
        </div>

        <!-- summary -->
        <div careerDetailSummary *ngIf="isExpanded && publication.description">
          <h6 g6FontColor>Publication Details</h6>
          <div [innerHTML]="publication.description"></div>
        </div>
      </div>

      <!-- action buttons -->
      <div *ngIf="allowEdit" careerButton hoverDisplay>
        <button
          (click)="notifyDeletePublication.emit(publication.id)"
          class="custom-button"
          mat-fab
          color="accent"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <fusion-career-profile-card-header
          [currentEntity]="publication"
          [buttonTitle]="'Edit'"
          [iconType]="ModalIconType.EditIcon"
          [formValidationRules]="publicationUpdateFormValidationRules"
          [formConfigs]="publicationUpdateFormConfigs"
          [formMetadata]="publicationUpdateFormMetadata"
          (notifyFormSubmit)="notifyFormSubmit.emit($event)"
        ></fusion-career-profile-card-header>
      </div>
    </div>
  </div>

  <!-- section footer -->
  <div *ngIf="publications.length" class="row3__section-three">
    <fusion-common-card-footer
      (notifyCardFooterClick)="notifyCardFooterClick()"
      [title]="isExpanded ? 'view less' : 'view more'"
    ></fusion-common-card-footer>
  </div>
</div>
