import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { getoAuthUserId } from '@fusion/oauth';
import { getRouterParams } from '@fusion/router';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { IJob, IJobApplication, IScreening } from '../../models/interfaces';

import {
  ApplicationsActionTypes,
  ApplyJob,
  ApplyJobSuccess,
  ApplyJobFail,
  LoadApplications,
  LoadApplicationsFail,
  LoadApplicationsSuccess,
  AddScreening,
  AddScreeningSuccess,
  AddScreeningFail,
  UpdateScreeningFail,
  UpdateScreening,
  UpdateScreeningSuccess,
  LoadJobseekerApplications,
  LoadJobseekerApplicationsSuccess,
  LoadJobseekerApplicationsFail,
  UpdateInterview,
  UpdateInterviewSuccess,
  UpdateInterviewFail,
  AcceptInterviewSuccess,
  AcceptInterviewFail,
  AcceptInterview,
} from '../actions/applications.actions';
import { LoadCurrentApplication } from '../actions/current-application.actions';
import { FusionJobState } from '../reducers';
import {
  getCurrentApplication,
  getCurrentJob,
  getCurrentScreening,
  getScreenings,
} from '../selectors';

@Injectable()
export class ApplicationsEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<FusionJobState>,
    private jobService: JobService,
    private mappingService: MappingService
  ) {}

  
  loadApplications$ = createEffect(() => this.actions$.pipe(
    ofType<LoadApplications>(ApplicationsActionTypes.LoadApplications),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([payload, params]: [any, Params]) => {
      const jobId = params?.jobId;
      return this.jobService.getJobApplications(jobId).pipe(
        switchMap((dataResult) => {
          const mappedApplications = this.mappingService.getMappedData<
            IJobApplication[]
          >(dataResult, mappingType.camelize);
          return [new LoadApplicationsSuccess(mappedApplications)];
        }),
        catchError((error) => of(new LoadApplicationsFail()))
      );
    }),
    catchError((error) => of(new LoadApplicationsFail()))
  ));

  
  loadJobseekerApplications$ = createEffect(() => this.actions$.pipe(
    ofType<LoadJobseekerApplications>(
      ApplicationsActionTypes.LoadJobseekerApplications
    ),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([payload, userId]: [any, string]) => {
      return this.jobService.getJobseekerApplications(userId).pipe(
        switchMap((dataResult) => {
          const mappedApplications = this.mappingService.getMappedData<
            IJobApplication[]
          >(dataResult, mappingType.camelize);
          return [new LoadJobseekerApplicationsSuccess(mappedApplications)];
        }),
        catchError((error) => of(new LoadJobseekerApplicationsFail()))
      );
    }),
    catchError((error) => of(new LoadJobseekerApplicationsFail()))
  ));

  
  jobApply$ = createEffect(() => this.actions$.pipe(
    ofType<ApplyJob>(ApplicationsActionTypes.ApplyJob),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getCurrentJob),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(([payload, currentJob, userId]: [string, IJob, string]) => {
      const jobId = currentJob.id;
      let jobPayload: IJobApplication = {
        applicantUserId: userId,
        coverLetter: payload,
      };
      jobPayload = this.mappingService.getMappedData(
        jobPayload,
        mappingType.underscore
      );
      return this.jobService.postJobApllication(jobId, jobPayload).pipe(
        switchMap((dataResult) => {
          this.router.navigate([`applications`]);
          return [new ApplyJobSuccess(dataResult)];
        }),
        catchError((error) => of(new ApplyJobFail()))
      );
    }),
    catchError((error) => of(new ApplyJobFail()))
  ));

  
  addScreening$ = createEffect(() => this.actions$.pipe(
    ofType<AddScreening>(ApplicationsActionTypes.AddScreening),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getCurrentApplication),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(
      ([payload, currentJobApplication, userId]: [
        IScreening,
        IJobApplication,
        string
      ]) => {
        const applicationId = currentJobApplication.id;
        let screeningPayload: IScreening = {
          reviewerUserId: userId,
          status: payload?.status,
          interview: payload?.interview,
        };
        screeningPayload = this.mappingService.getMappedData(
          screeningPayload,
          mappingType.underscore
        );
        return this.jobService
          .postJobScreening(applicationId, screeningPayload)
          .pipe(
            switchMap((dataResult) => {
              return [
                new AddScreeningSuccess(dataResult),
                new LoadCurrentApplication(),
              ];
            }),
            catchError((error) => of(new AddScreeningFail()))
          );
      }
    ),
    catchError((error) => of(new AddScreeningFail()))
  ));

  
  updateScreening$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateScreening>(ApplicationsActionTypes.UpdateScreening),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getCurrentApplication),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(
      ([payload, currentJobApplication, userId]: [
        IScreening,
        IJobApplication,
        string
      ]) => {
        const applicationId = currentJobApplication.id;
        const screeningId = payload.id;
        let screeningPayload: IScreening = {
          reviewerUserId: userId,
          status: payload?.status,
          interview: payload?.interview,
        };
        screeningPayload = this.mappingService.getMappedData(
          screeningPayload,
          mappingType.underscore
        );
        return this.jobService
          .updateJobScreening(applicationId, screeningId, screeningPayload)
          .pipe(
            switchMap((dataResult) => {
              return [
                new UpdateScreeningSuccess(dataResult),
                new LoadCurrentApplication(),
              ];
            }),
            catchError((error) => of(new UpdateScreeningFail()))
          );
      }
    ),
    catchError((error) => of(new UpdateScreeningFail()))
  ));

  
  updateInterview$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateInterview>(ApplicationsActionTypes.UpdateInterview),
    map((action) => action.eventId),
    withLatestFrom(
      this.store.select(getCurrentApplication),
      this.store.select(getCurrentScreening),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(
      ([eventId, currentJobApplication, currentScreening, userId]: [
        string,
        IJobApplication,
        IScreening,
        string
      ]) => {
        const applicationId = currentJobApplication.id;
        const screeningId = currentScreening.id;
        let screeningPayload: IScreening = {
          reviewerUserId: userId,
          interview: {
            ...currentScreening.interview,
            eventId: eventId,
            hasEventCreated: true,
          },
        };
        screeningPayload = this.mappingService.getMappedData(
          screeningPayload,
          mappingType.underscore
        );
        return this.jobService
          .updateJobScreening(applicationId, screeningId, screeningPayload)
          .pipe(
            switchMap((dataResult) => {
              return [
                new UpdateInterviewSuccess(dataResult),
                new LoadCurrentApplication(),
              ];
            }),
            catchError((error) => of(new UpdateInterviewFail()))
          );
      }
    ),
    catchError((error) => of(new UpdateInterviewFail()))
  ));

  
  acceptInterview$ = createEffect(() => this.actions$.pipe(
    ofType<AcceptInterview>(ApplicationsActionTypes.AcceptInterview),
    map((action) => action.screeningId),
    withLatestFrom(
      this.store.select(getCurrentApplication),
      this.store.select(getScreenings),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(
      ([screeningId, currentJobApplication, screenings, userId]: [
        string,
        IJobApplication,
        IScreening[],
        string
      ]) => {
        const applicationId = currentJobApplication.id;
        const currentScreening = screenings.find(
          (screening) => screening.id === screeningId
        );
        let screeningPayload: IScreening = {
          interview: {
            ...currentScreening.interview,
            hasAccepted: true,
          },
        };
        screeningPayload = this.mappingService.getMappedData(
          screeningPayload,
          mappingType.underscore
        );
        return this.jobService
          .updateJobScreening(applicationId, screeningId, screeningPayload)
          .pipe(
            switchMap((dataResult) => {
              return [
                new AcceptInterviewSuccess(dataResult),
                new LoadCurrentApplication(),
              ];
            }),
            catchError((error) => of(new AcceptInterviewFail()))
          );
      }
    ),
    catchError((error) => of(new AcceptInterviewFail()))
  ));
}
