import { createSelector } from '@ngrx/store';
import { IJobApplication } from '../../models/interfaces';

import { getFusionJobState } from '../reducers';

export const getApplicationsState = createSelector(
  getFusionJobState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.applications;
  }
);

export const getApplications = createSelector(
  getApplicationsState,
  (state): IJobApplication[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
