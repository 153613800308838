import { DESCRIPTION_FORM } from '../constants';
import { IForm, FormFieldType } from '@fusion/form';

export const CoverLetterForm: IForm = {
  metadata: {
    name: DESCRIPTION_FORM,
    title: 'Cover Letter',
    submitButtonText: 'Apply',
    style: {
      topMargin: '0px',
      maxWidth: '800px',
      maxHeight: '400px',
    },
  },
  configs: [
    {
      label: 'Job Cover Letter',
      name: 'coverLetter',
      type: FormFieldType.Ckeditor,
      placeholder: 'Job Cover Letter',
      validation: {
        required: true,
        max: 15000,
      },
    },
  ],
};
