import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(@Inject('appConfig') private appConfig: any) {}

  getConfig(): any {
    return this.appConfig.config;
  }
}
