<div cardExSpacing class="row3">
  <!-- invoice header -->
  <div class="row3__section-one col2">
    <div smallLogo class="col2__section-one">
      <img
        imageFit
        src="/assets/images/users/company-logo/dot2circle-logo.svg"
      />
    </div>
    <div class="col2__section-two row2">
      <div exLargeTitle semiBold primaryFontColor class="row2__section-one">
        Invoice!
      </div>
      <div nanoTitle g3FontColor class="row2__section-two">
        Invoice# {{ invoice.id }}
      </div>
    </div>
  </div>
  <!-- billing -->
  <div *ngIf="invoice.billing" class="billing col2">
    <!-- bill to -->
    <ng-container *ngIf="invoice.billing.billTo as billTo">
      <div border radius cardSpacing class="col2__section-one">
        <div semiBold smallTitle>Bill To</div>
        <div bold g4FontColor>
          {{ invoice.billing.name }}
        </div>
        <div g4FontColor extraSmallFont>
          <span>{{ billTo.address }}</span>
          <span *ngIf="billTo.unit">, {{ billTo.unit }}</span>
        </div>
        <div g4FontColor extraSmallFont>
          <span>{{ billTo.city }}</span>
          <span>, {{ billTo.state }}</span>
          <span *ngIf="billTo.zipCode"> - {{ billTo.zipCode }}</span>
        </div>
        <div g4FontColor extraSmallFont>
          <span>{{ billTo.country }}</span>
        </div>
      </div>
    </ng-container>
    <!-- TODO ship to -->
    <div class="col2__section-two"></div>
  </div>
  <!-- invoice body -->
  <div border radius cardSpacing class="row3__section-three">
    <fusion-payment-checkout-invoice
      [invoice]="invoice"
    ></fusion-payment-checkout-invoice>
  </div>
</div>
