<ng-container *ngIf="isNavOption; else nonNavOption">
  <div class="nav__item" (click)="openDialog()">
    <ng-container [ngSwitch]="iconType">
      <ng-container *ngSwitchCase="ModalIconType.SignIn">
        <mat-icon>lock_open</mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.SignUp">
        <mat-icon>person_add</mat-icon>
      </ng-container>
    </ng-container>
    <span>{{ buttonTitle }}</span>
  </div>
</ng-container>

<ng-template #nonNavOption>
  <ng-container
    *ngIf="
      formMetadata?.isToggleButton && !formMetadata?.modalButton;
      else buttonConfig
    "
  >
    <mat-button-toggle-group #group="matButtonToggleGroup" vertical="true">
      <mat-button-toggle value="left" checked (click)="openDialog()">
        <mat-icon>add</mat-icon>
        {{ formMetadata.submitButtonText }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </ng-container>
</ng-template>

<ng-template #buttonConfig>
  <!-- TODO use only this button configs and cleanup other sections -->
  <ng-container
    *ngIf="formMetadata?.modalButton as buttonConfig; else button_link"
  >
    <ng-container [ngSwitch]="buttonConfig?.type">
      <ng-container *ngSwitchCase="ModalButtonType.Icon"></ng-container>
      <ng-container *ngSwitchCase="ModalButtonType.Menu"></ng-container>
      <ng-container *ngSwitchCase="ModalButtonType.Card"></ng-container>
      <ng-container *ngSwitchCase="ModalButtonType.IconText">
        <button
          class="custom-button"
          mat-stroked-button
          color="primary"
          (click)="openDialog()"
        >
          <ng-container [ngSwitch]="buttonConfig?.iconType">
            <ng-container *ngSwitchCase="ModalIconType.AddIcon">
              <mat-icon>add</mat-icon>
            </ng-container>
          </ng-container>
          <span>{{ buttonConfig.text }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #button_link>
  <span
    (click)="openDialog()"
    matTooltip="{{ buttonTitle }}"
    [matTooltipPosition]="'above'"
    class="menu"
  >
    <ng-container [ngSwitch]="iconType">
      <ng-container *ngSwitchCase="ModalIconType.AddIcon">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.AddIconButton">
        <button class="custom-button" mat-stroked-button color="primary">
          <mat-icon>add</mat-icon> <span>{{ buttonTitle }}</span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.EditIcon">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.EditPlainIcon">
        <button class="custom-button" mat-icon-button color="primary">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.SignIn">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>lock_open</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.RoundedButton">
        <button class="custom-round-button" mat-raised-button color="warn">
          {{ buttonTitle }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.Card">
        <div
          border
          radius
          cardSpacing
          elevation
          pointer
          class="add-card row2"
          *ngIf="formMetadata.modalButton as buttonConfig"
        >
          <div centeringBottom class="row2__section-one">
            <button mat-fab color="accent"><mat-icon>add</mat-icon></button>
          </div>
          <div class="row2__section-two row2">
            <div centeringText class="row2__section-one">
              <h6>{{ buttonConfig?.card.title }}</h6>
            </div>

            <div centering class="row2__section-two">
              <div g3FontColor exSmallFont>
                {{ buttonConfig?.card.message }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.SignUp">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>person_add</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.Menu">
        <button mat-menu-item>{{ buttonTitle }}</button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.None">
        <span>{{ buttonTitle }}</span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <button
          [ngClass]="{ fullWidth: formMetadata?.isFullWidthSubmitButton }"
          mat-stroked-button
        >
          {{ formMetadata?.submitButtonText }}
        </button>
      </ng-container>
    </ng-container>
  </span>
</ng-template>
