import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IEducation } from '../../models/interfaces';
import {
  ModalIconType,
  IFormMetadata,
  IFormConfig,
  setFormValidationRules,
} from '@fusion/form';
import { EducationForm, EducationUpdateForm } from '../../models/forms';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'fusion-career-profile-educations',
  templateUrl: './educations.component.html',
  styleUrls: ['./educations.component.scss'],
})
export class EducationsComponent implements OnInit {
  @Output() notifyViewCompany = new EventEmitter<string>();
  @Output() notifyFormSubmit = new EventEmitter<any>();
  @Output() notifyAutocompletion = new EventEmitter<string>();
  @Output() notifyDeleteEducation = new EventEmitter<string>();

  @Input() educations: IEducation[];
  @Input() autoCompleteEntities: any;
  @Input() allowEdit: boolean;

  // form setup
  educationFormMetadata: IFormMetadata = EducationForm.metadata;
  educationFormConfigs: IFormConfig[] = EducationForm.configs;
  educationFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(EducationForm.configs)
  );
  educationUpdateFormMetadata: IFormMetadata = EducationUpdateForm.metadata;
  educationUpdateFormConfigs: IFormConfig[] = EducationUpdateForm.configs;
  educationUpdateFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(EducationUpdateForm.configs)
  );

  isExpanded: boolean = false;
  ModalIconType = ModalIconType;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {}

  notifyCardFooterClick() {
    this.isExpanded = this.isExpanded ? false : true;
  }
}
