import { Action } from '@ngrx/store';
import { IError } from '@fusion/error';
import { FusionJobError } from '../../models/enums';
import { IJob } from '../../models/interfaces/job.interfaces';

export enum CurrentJobActionTypes {
  LoadCurrentJob = '[Current Job] Load',
  LoadCurrentJobSuccess = '[Current Job] Load Success',
  LoadCurrentJobFail = '[Current Job] Load Fail',
}

export class LoadCurrentJob implements Action {
  readonly type = CurrentJobActionTypes.LoadCurrentJob;
}

export class LoadCurrentJobSuccess implements Action {
  readonly type = CurrentJobActionTypes.LoadCurrentJobSuccess;

  constructor(public payload: IJob) {}
}

export class LoadCurrentJobFail implements Action {
  readonly type = CurrentJobActionTypes.LoadCurrentJobFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export type CurrentJobActions =
  | LoadCurrentJob
  | LoadCurrentJobSuccess
  | LoadCurrentJobFail;
