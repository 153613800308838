import { Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServerCookieService {
  constructor(private cookieService: CookieService) {}

  get(cookiename: string): Observable<any> {
    return of({ token: this.cookieService.get(cookiename) });
  }

  set(
    cookiename: string,
    value: string,
    expiration?: any,
    path?: string,
    domain?: string,
    secure?: boolean
  ) {
    const options: CookieOptions = {
      path: path,
      domain: domain,
      expires: expiration,
      secure: secure,
    };
    this.cookieService.put(cookiename, value, options);
  }

  delete(cookiename: string) {
    this.cookieService.remove(cookiename);
  }

  deleteAll(path: string, domain: string) {
    const options: CookieOptions = {
      path: path,
      domain: domain,
    };
    this.cookieService.removeAll(options);
  }
}
