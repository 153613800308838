<div *ngFor="let job of jobs">
  <div bottomDivider class="row4">
    <div class="row4__section-one col1">
      <div centeringLeft picoFont uppercase class="col1__section-one">
        Fusion Sponsored
      </div>
      <div centeringRight nanoFont class="col1__section-two">
        <i>{{ job.createdAt | fancyTime }}</i>
      </div>
    </div>
    <div class="row4__section-two">
      <a
        smallTitle
        routerLink="{{ getPreviewDir(job.id) }}"
        (click)="notifyCurrentJob.emit(job)"
        >{{ job.position }}</a
      >
    </div>
    <div class="job-preview row4__section-three col3">
      <div
        class="col3__section-one"
        [innerHTML]="job.jobDetails[0].content | truncate: 125"
      ></div>
      <div nanoFont centering class="col3__section-two">
        {{ job.companyName || job?.company?.name }}
      </div>
      <div nanoFont centering class="col3__section-three">
        {{ job.city }}
      </div>
    </div>
    <div class="row4__section-four b-padding-3">
      <fusion-job-metadata
        [metadata]="job.metadata"
        [chipColor]="'default'"
      ></fusion-job-metadata>
    </div>
  </div>
</div>
