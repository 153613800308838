<div card class="row2">
  <fusion-career-profile-card-header
    [isEditable]="allowEdit"
    [title]="'Career Summary'"
    [buttonTitle]="careerSummary ? 'Edit' : 'Add'"
    [iconType]="careerSummary ? ModalIconType.EditIcon : ModalIconType.AddIcon"
    [currentEntity]="{ careerSummary: careerSummary }"
    [formValidationRules]="careerSummaryFormValidationRules"
    [formConfigs]="careerSummaryFormConfigs"
    [formMetadata]="careerSummaryFormMetadata"
    (notifyFormSubmit)="notifyFormSubmit.emit($event)"
  ></fusion-career-profile-card-header>
  <div
    *ngIf="careerSummary"
    cardSpacing
    class="row2__section-two"
    [innerHTML]="careerSummary"
  ></div>
</div>
