<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>Add and Manage Mosques!</h1></div>
  <div pageContent>
    <div content>
      <mat-tab-group
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        dynamicHeight
      >
        <!-- first tab -->
        <mat-tab label="My Mosques">
          <div class="tab-content rowN">
            <ng-container
              *ngIf="
                errorHandlingConfig$ | async as errorHandlingConfig;
                else subscriptionList
              "
            >
              <ng-template
                [fusionErrorHandler]="errorHandlingConfig"
                errorHandlingType="ErrorHandlingType.Template"
              ></ng-template>
            </ng-container>
            <ng-template #subscriptionList>
              <ng-container
                *ngIf="hasMosqueSubscription$ | async; else emptySubscription"
              >
                <fusion-company-list
                  [companies]="companies$ | async"
                  [isEmployerMode]="true"
                  [isNavigateMode]="true"
                  [buttonLabel]="buttonLabel"
                  [currentRootPath]="'/' + (currentRootPath$ | async)"
                  [formConfigs]="addMosqueFormConfigs"
                  [formMetadata]="addMosqueFormMetadata"
                  [formValidationRules]="addMosqueFormValidationRules"
                  (notifyViewCompany)="onNotifyViewCompany($event)"
                  (notifyManageCompany)="onNotifyManageCompany($event)"
                  (notifyAutocompletion)="onNotifyAutocompletion($event)"
                  (notifyFormSubmit)="onNotifyFormSubmit($event)"
                ></fusion-company-list>
              </ng-container>
              <ng-template #emptySubscription>
                <fusion-common-empty-state
                  [emptyState]="emptyState"
                  [currentRootPath]="'/'"
                ></fusion-common-empty-state>
              </ng-template>
              <div class="row2">
                <ng-container
                  *ngIf="
                    userProfileErrorHandlingConfig$
                      | async as errorHandlingConfig;
                    else repCompanyList
                  "
                >
                  <ng-template
                    [fusionErrorHandler]="errorHandlingConfig"
                    errorHandlingType="ErrorHandlingType.Template"
                  ></ng-template>
                </ng-container>
                <ng-template #repCompanyList>
                  <ng-container
                    *ngIf="
                      (repCompanies$ | async) && (repCompanies$ | async).length
                    "
                  >
                    <div class="row2__section-one">
                      <span largeTitle g5FontColor light>Representative</span>
                    </div>
                    <div class="row2__section-two">
                      <fusion-company-list
                        [companies]="repCompanies$ | async"
                        (notifyViewCompany)="onNotifyViewCompany($event)"
                        (notifyManageCompany)="onNotifyManageCompany($event)"
                      ></fusion-company-list>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </ng-template>
          </div>
        </mat-tab>

        <!-- second tab -->
        <!-- <mat-tab label="Interested Mosques">
          <div class="tab-content">
            <user-mosque-search
              [searchForm]="searchForm"
              [keywords]="keywords$ | async"
              (notifySearch)="onNotifySearch($event)"
              (notifySearchCategory)="onNotifySearchCategory($event)"
            ></user-mosque-search>

            <fusion-search-result
              [removable]="removable"
              [keywords]="keywords$ | async"
              [searchCategory]="searchCategory$ | async"
              (notifyRemoveCategory)="notifyRemoveCategory()"
            ></fusion-search-result>
          </div>
        </mat-tab> -->
      </mat-tab-group>
    </div>
    <div adSpace></div>
  </div>
</div>
