import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ICompany } from '../../models/interfaces';

@Component({
  selector: 'fusion-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent implements OnInit {
  @Output() notifyViewCompany = new EventEmitter<string>();
  @Output() notifyManageCompany = new EventEmitter<string>();
  @Input() company: ICompany;
  @Input() buttonLabel: string;

  constructor() {}

  ngOnInit() {}
}
