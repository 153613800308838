import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from './store/reducers';
import { Observable } from 'rxjs';
import { getoAuthUserId, ISideNav } from '@fusion/oauth';
import { getUserProfileNavigation } from '@fusion/subscription';
import { getRelativeUrl } from '@fusion/router';
import { SwUpdate } from '@angular/service-worker';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'user-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentRootPath$: Observable<string>;
  sideNav$: Observable<ISideNav[]>;
  title = 'User | Dot to Circle';

  constructor(
    private store: Store<UserState>,
    private updates: SwUpdate,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.updates.available.subscribe((event) => {
      this.updates
        .activateUpdate()
        .then(() => this._document.defaultView.location.reload());
    });
  }

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getoAuthUserId);
    this.sideNav$ = this.store.select(getUserProfileNavigation);
  }
}
