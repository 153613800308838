import { createSelector } from '@ngrx/store';
import { IJobApplication, IScreening } from '../../models/interfaces';

import { getFusionJobState } from '../reducers';

export const getCurrentApplicationState = createSelector(
  getFusionJobState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.currentApplication;
  }
);

export const getCurrentApplication = createSelector(
  getCurrentApplicationState,
  (state): IJobApplication => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getScreenings = createSelector(
  getCurrentApplication,
  (application): IScreening[] => {
    if (application == null) {
      return null;
    }
    return application.screenings;
  }
);
