<fusion-common-back
  [currentRootPath]="currentRootPath$ | async"
  [buttonText]="'back'"
></fusion-common-back>

<div pageSpacing extraLargePage pageBody>
  <div pageTitle class="col2">
    <h1>Manage your job!</h1>
    <div centering centerRight *ngIf="!(job$ | async)?.active">
      <a
        class="custom-round-button"
        mat-raised-button
        color="warn"
        (click)="onManageJob()"
        >Publish Job</a
      >
    </div>
  </div>
  <div pageContent>
    <div content>
      <div class="rowN">
        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Update Job Details</div>
            <div sectionSubtitle microTitle>
              Your job details will be the searching keywords for the jobseeker.
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="job$ | async"
              [formValidationRules]="jobMetadataValidationRules"
              [formConfigs]="jobMetadataConfigs"
              [formMetadata]="jobMetadata"
              (notifyFormSubmit)="notifyJobMetedata($event)"
            ></fusion-form-template>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Location</div>
            <div sectionSubtitle microTitle>
              Job location is very important factor for jobseeker
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="job$ | async"
              [formValidationRules]="addressValidationRules"
              [formConfigs]="addressConfigs"
              [formMetadata]="addressMetadata"
              (notifyFormSubmit)="notifyAddress($event)"
              (notifyFormValidationUpdate)="
                onNotifyFormValidationUpdate($event)
              "
            ></fusion-form-template>
          </div>
        </div>
        <mat-divider></mat-divider>

        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Description</div>
            <div sectionSubtitle microTitle>
              Briefly describe this position.
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="updatedJob"
              [formValidationRules]="descriptionValidationRules"
              [formConfigs]="descriptionConfigs"
              [formMetadata]="descriptionMetadata"
              (notifyFormSubmit)="notifyDescription($event)"
            ></fusion-form-template>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Responsibilities</div>
            <div sectionSubtitle microTitle>
              Summarize the key responsibilities of this role, which will help
              potential jobseekers to apply.
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="updatedJob"
              [formValidationRules]="jobResponsibilitiesValidationRules"
              [formConfigs]="jobResponsibilitiesConfigs"
              [formMetadata]="jobResponsibilitiesMetadata"
              (notifyFormSubmit)="notifyJobResponsibilities($event)"
            ></fusion-form-template>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Requirements</div>
            <div sectionSubtitle microTitle>
              Add requirements for this position.
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="updatedJob"
              [formValidationRules]="jobRequirementsValidationRules"
              [formConfigs]="jobRequirementsConfigs"
              [formMetadata]="jobRequirementsMetadata"
              (notifyFormSubmit)="notifyJobRequirements($event)"
            ></fusion-form-template>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Salary</div>
            <div sectionSubtitle microTitle>
              We encourage you to add tentative salary to be more transparent
              and it will help potential jobseeker to apply.
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="job$ | async"
              [formValidationRules]="salaryValidationRules"
              [formConfigs]="salaryConfigs"
              [formMetadata]="salaryMetadata"
              (notifyFormSubmit)="notifySalary($event)"
            ></fusion-form-template>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Apply</div>
            <div sectionSubtitle microTitle>
              Choose your apply option. Currently we are supporting job
              applications through email or redirecting jobseekers to your
              preferred web page.
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="job$ | async"
              [formValidationRules]="jobApplyValidationRules"
              [formConfigs]="jobApplyConfigs"
              [formMetadata]="jobApplyMetadata"
              (notifyFormSubmit)="notifyJobApply($event)"
            ></fusion-form-template>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Application deadline</div>
            <div sectionSubtitle microTitle>
              Choose application deadline to help jobseekers to decide whether
              they can apply for this position or not.
            </div>
          </div>
          <div card cardSpacing sectionContent>
            <fusion-form-template
              [isDialog]="false"
              [existingValues]="job$ | async"
              [formValidationRules]="jobDeadlineValidationRules"
              [formConfigs]="jobDeadlineConfigs"
              [formMetadata]="jobDeadlineMetadata"
              (notifyFormSubmit)="notifyJobDeadline($event)"
            ></fusion-form-template>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Banner Picture</div>
            <div sectionSubtitle microTitle>
              This image will be displayed with your course.
            </div>
          </div>
          <div card cardSpacing sectionContent class="row2">
            <div image class="row2__section-one">
              <ng-container *ngIf="job$ | async as currentClass">
                <img
                  radius
                  imageFit
                  src="{{
                    currentClass.bannerImage
                      ? currentClass.bannerImage
                      : 'assets/images/users/banner/banner.jpg'
                  }}"
                />
              </ng-container>
            </div>
            <div class="row2__section-two">
              <fusion-common-banner-image-crop
                [aspectRatio]="12 / 4.5"
                [uploadTitle]="'Upload Banner (1200 x 450)px'"
                [isRoundedCanvas]="false"
                (notifyBannerImageUpload)="onNotifyBannerImageUpload($event)"
              ></fusion-common-banner-image-crop>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div adSpace>
      <div fullWidth centering center>
        <a
          *ngIf="(job$ | async)?.applications?.count"
          class="custom-round-button"
          mat-raised-button
          color="warn"
          (click)="onManageJobApplications()"
          >Manage Applications</a
        >
      </div>
    </div>
  </div>
</div>
