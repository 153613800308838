import { Action } from '@ngrx/store';
import { IError } from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { IoAuthCredentials, ISession } from '../../models/interfaces';

export enum oAuthActionTypes {
  oAuthStart = '[oAuth] Start',
  oAuthSuccess = '[oAuth] Success',
  oAuthFail = '[oAuth] Fail',
  GetAppToken = '[oAuth] Get App Token',
  GetAppTokenSuccess = '[oAuth] Get App Token Success',
  GetAppTokenFail = '[oAuth] Get App Token Fail',
  SessionStart = '[oAuth] Session Start',
  SessionEnd = '[oAuth] Session End',
  SessionRestore = '[oAuth] Session Restore',
  SessionRestoreSuccess = '[oAuth] Session Restore Success',
  SessionRestoreFail = '[oAuth] Session Restore Fail',
  TokenDecode = '[oAuth] Token Decode',
  TokenDecodeSuccess = '[oAuth] Token Decode Success',
  TokenDecodeFail = '[oAuth] Token Decode Fail',
  TokenRefresh = '[oAuth] Token Refresh',
  TokenRefreshSuccess = '[oAuth] Token Refresh Success',
  TokenRefreshFail = '[oAuth] Token Refresh Fail',
  PasswordResetRequest = '[oAuth] Password Reset Request',
  PasswordResetRequestSuccess = '[oAuth] Password Reset Request Success',
  PasswordResetRequestFail = '[oAuth] Password Reset Request Fail',
  ResetPassword = '[oAuth] Reset Password',
  ResetPasswordSuccess = '[oAuth] Reset Password Success',
  ResetPasswordFail = '[oAuth] Reset Password Fail',
  ValidateResetPasswordToken = '[oAuth] Validate Reset Password Token',
  ValidateResetPasswordTokenSuccess = '[oAuth] Validate Reset Password Token Success',
  ValidateResetPasswordTokenFail = '[oAuth] Validate Reset Password Token Fail',
}

export class oAuthStart implements Action {
  readonly type = oAuthActionTypes.oAuthStart;

  constructor(public payload: IoAuthCredentials) {}
}

export class oAuthSuccess implements Action {
  readonly type = oAuthActionTypes.oAuthSuccess;

  constructor(public payload: ISession) {}
}

export class oAuthFail implements Action {
  readonly type = oAuthActionTypes.oAuthFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class GetAppToken implements Action {
  readonly type = oAuthActionTypes.GetAppToken;
}

export class GetAppTokenSuccess implements Action {
  readonly type = oAuthActionTypes.GetAppTokenSuccess;

  constructor(public payload: ISession) {}
}

export class GetAppTokenFail implements Action {
  readonly type = oAuthActionTypes.GetAppTokenFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class SessionStart implements Action {
  readonly type = oAuthActionTypes.SessionStart;

  constructor(public payload: any) {}
}

export class SessionEnd implements Action {
  readonly type = oAuthActionTypes.SessionEnd;
}

export class SessionRestore implements Action {
  readonly type = oAuthActionTypes.SessionRestore;
}

export class SessionRestoreSuccess implements Action {
  readonly type = oAuthActionTypes.SessionRestoreSuccess;

  constructor(public payload: ISession) {}
}

export class SessionRestoreFail implements Action {
  readonly type = oAuthActionTypes.SessionRestoreFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class TokenDecode implements Action {
  readonly type = oAuthActionTypes.TokenDecode;

  constructor(public payload: any) {}
}

export class TokenDecodeSuccess implements Action {
  readonly type = oAuthActionTypes.TokenDecodeSuccess;

  constructor(public payload: any) {}
}

export class TokenDecodeFail implements Action {
  readonly type = oAuthActionTypes.TokenDecodeFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class TokenRefresh implements Action {
  readonly type = oAuthActionTypes.TokenRefresh;
}

export class TokenRefreshSuccess implements Action {
  readonly type = oAuthActionTypes.TokenRefreshSuccess;

  constructor(public payload: ISession) {}
}

export class TokenRefreshFail implements Action {
  readonly type = oAuthActionTypes.TokenRefreshFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class PasswordResetRequest implements Action {
  readonly type = oAuthActionTypes.PasswordResetRequest;

  constructor(public email: string) {}
}

export class PasswordResetRequestSuccess implements Action {
  readonly type = oAuthActionTypes.PasswordResetRequestSuccess;
}

export class PasswordResetRequestFail implements Action {
  readonly type = oAuthActionTypes.PasswordResetRequestFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class ResetPassword implements Action {
  readonly type = oAuthActionTypes.ResetPassword;

  constructor(public password: string) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = oAuthActionTypes.ResetPasswordSuccess;
}

export class ResetPasswordFail implements Action {
  readonly type = oAuthActionTypes.ResetPasswordFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class ValidateResetPasswordToken implements Action {
  readonly type = oAuthActionTypes.ValidateResetPasswordToken;
}

export class ValidateResetPasswordTokenSuccess implements Action {
  readonly type = oAuthActionTypes.ValidateResetPasswordTokenSuccess;
}

export class ValidateResetPasswordTokenFail implements Action {
  readonly type = oAuthActionTypes.ValidateResetPasswordTokenFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export type oAuthActions =
  | oAuthStart
  | oAuthSuccess
  | oAuthFail
  | GetAppToken
  | GetAppTokenSuccess
  | GetAppTokenFail
  | SessionStart
  | SessionEnd
  | SessionRestore
  | SessionRestoreSuccess
  | SessionRestoreFail
  | TokenDecode
  | TokenDecodeSuccess
  | TokenDecodeFail
  | TokenRefresh
  | TokenRefreshSuccess
  | TokenRefreshFail
  | PasswordResetRequest
  | PasswordResetRequestSuccess
  | PasswordResetRequestFail
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFail
  | ValidateResetPasswordToken
  | ValidateResetPasswordTokenSuccess
  | ValidateResetPasswordTokenFail;
