import { IForm, FormFieldType, FormFieldGroup, FormFieldPattern } from "@fusion/form";
import {
  degreeType,
  countries,
  companyTypes,
  EDUCATION_UPDATE_FORM
} from "@fusion/common";

export const EducationUpdateForm: IForm = {
  metadata: {
    name: EDUCATION_UPDATE_FORM,
    title: "Update Education",
    submitButtonText: "Update Education",
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "530px"
    }
  },
  configs: [
    {
      label: "Degree Type",
      name: "degreeType",
      type: FormFieldType.Dropdown,
      options: degreeType,
      validation: {
        required: true
      }
    },
    {
      label: "Major/Field of study",
      name: "major",
      type: FormFieldType.Text,
      placeholder: "Industrial & Production Engineering",
      validation: {
        required: true
      }
    },
    {
      label: "Start Date",
      name: "startDate",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Graduation Date (or expected)",
      name: "graduationDate",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Grade / CGPA",
      name: "grade",
      type: FormFieldType.Text,
      placeholder: "3.75",
      validation: {
        required: false
      }
    },
    {
      label: "School Name",
      name: "company",
      type: FormFieldType.Autocomplete,
      placeholder: "School Name",
      validation: {
        required: true
      },
      autoFillItems: [
        "address",
        "unit",
        "city",
        // "type",
        "zipCode",
        "state",
        "country"
      ]
    },
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: "School Address",
          name: "address",
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 150
          }
        },
        {
          label: "Unit#",
          name: "unit",
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 10
          }
        },
        {
          label: "City",
          name: "city",
          type: FormFieldType.Text,
          placeholder: "city",
          validation: {
            required: true,
            max: 100
          }
        },
        {
          label: "State",
          name: "state",
          type: FormFieldType.Text,
          validation: {
            required: false
          }
        },
        {
          label: "Zip Code",
          name: "zipCode",
          type: FormFieldType.Text,
          placeholder: "zip code",
          examplePattern: "11432",
          validation: {
            required: true,
            max: 10,
            pattern: FormFieldPattern.Number
          }
        },
        {
          label: "Country",
          name: "country",
          type: FormFieldType.Dropdown,
          options: countries,
          validation: {
            required: true
          }
        }
      ]
    },
    // {
    //   label: "Company Type",
    //   name: "type",
    //   type: FormFieldType.Dropdown,
    //   options: companyTypes,
    //   validation: {
    //     required: true
    //   }
    // },
    {
      label: "Activities",
      name: "activities",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: false,
        min: 10,
        max: 10000
      }
    }
  ]
};
