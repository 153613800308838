import { createSelector } from '@ngrx/store';

import { Params } from '@angular/router';

import { getFusionRouterState } from '../reducers';

export const getRouterReducer = createSelector(
  getFusionRouterState,
  (routerState) => {
    if (routerState == null) {
      return null;
    }
    return routerState.routerReducer;
  }
);

export const getRouterState = createSelector(
  getRouterReducer,
  (routerReducer) => {
    if (routerReducer == null) {
      return null;
    }
    return routerReducer.state;
  }
);

export const getCurrentRouterParams = createSelector(
  getRouterState,
  (routerState) => {
    if (routerState == null) {
      return null;
    }
    return {
      parent: routerState.parentParams,
      child: routerState.params,
    };
  }
);

export const getRouterParams = createSelector(getRouterState, (routerState) => {
  if (routerState == null) {
    return null;
  }
  return routerState.params;
});

export const getRouterUrl = createSelector(getRouterState, (routerState) => {
  if (routerState == null) {
    return null;
  }
  return routerState.url;
});

export const getRelativeUrl = createSelector(getRouterUrl, (url) => {
  if (url == null) {
    return null;
  }
  const params = url.split('/');
  return `/${params[1]}`;
});

export const getRouterUrlRoot = createSelector(
  getRouterState,
  (routerState) => {
    if (routerState == null) {
      return null;
    }
    return routerState.urlRoot;
  }
);

export const getRouterUrlBase = createSelector(
  getRouterState,
  (routerState) => {
    if (routerState == null) {
      return null;
    }
    return routerState.urlBase;
  }
);

export const getRouterQueryParams = createSelector(
  getRouterState,
  (routerState): Params => {
    if (routerState == null) {
      return null;
    }
    return routerState.queryParams;
  }
);

export const getCurrentReferrerUrl = createSelector(
  getRouterQueryParams,
  (routerState) => {
    if (routerState == null) {
      return null;
    }
    return routerState.referrerUrl;
  }
);
