import { Action } from "@ngrx/store";
import { IFunding, IFundingOption, IContributor } from "../../models/interfaces";

export enum FundingActionTypes {
  AddFunding = "[Funding] Add",
  AddFundingSuccess = "[Funding] Add Success",
  AddFundingFail = "[Funding] Add Fail",
  ConfirmFunding = "[Funding] Confirm",
  ConfirmFundingSuccess = "[Funding] Confirm Success",
  ConfirmFundingFail = "[Funding] Confirm Fail",
  LoadInProgressFunding = "[Funding] Load In Progress",
  LoadInProgressFundingSuccess = "[Funding] Load In Progress Success",
  LoadInProgressFundingFail = "[Funding] Load In Progress Fail",
  LoadFunding = "[Funding] Load",
  LoadFundingSuccess = "[Funding] Load Success",
  LoadFundingFail = "[Funding] Load Fail",
  LoadCurrentFundingOption = "[Funding] Load Current Option",
  LoadCurrentFundingOptionSuccess = "[Funding] Load Current Option Success",
  LoadCurrentFundingOptionFail = "[Funding] Load Current Option Fail",
  LoadFundingOptions = "[Funding] Load Options",
  LoadFundingOptionsSuccess = "[Funding] Load Options Success",
  LoadFundingOptionsFail = "[Funding] Load Options Fail",
  LoadFundingBalance = "[Funding] Load Balance",
  LoadFundingBalanceSuccess = "[Funding] Load Balance Success",
  LoadFundingBalanceFail = "[Funding] Load Balance Fail",
  LoadContributors = "[Funding] Load Contributors",
  LoadContributorsSuccess = "[Funding] Load Contributors Success",
  LoadContributorsFail = "[Funding] Load Contributors Fail"
}

export class AddFunding implements Action {
  readonly type = FundingActionTypes.AddFunding;

  constructor(public payload: IFundingOption) {}
}

export class AddFundingSuccess implements Action {
  readonly type = FundingActionTypes.AddFundingSuccess;

  constructor(public payload: IFunding) {}
}

export class AddFundingFail implements Action {
  readonly type = FundingActionTypes.AddFundingFail;
}

export class ConfirmFunding implements Action {
  readonly type = FundingActionTypes.ConfirmFunding;
}

export class ConfirmFundingSuccess implements Action {
  readonly type = FundingActionTypes.ConfirmFundingSuccess;

  constructor(public payload: IFunding) {}
}

export class ConfirmFundingFail implements Action {
  readonly type = FundingActionTypes.ConfirmFundingFail;
}

export class LoadInProgressFunding implements Action {
  readonly type = FundingActionTypes.LoadInProgressFunding;
}

export class LoadInProgressFundingSuccess implements Action {
  readonly type = FundingActionTypes.LoadInProgressFundingSuccess;

  constructor(public payload: IFunding) {}
}

export class LoadInProgressFundingFail implements Action {
  readonly type = FundingActionTypes.LoadInProgressFundingFail;
}

export class LoadFunding implements Action {
  readonly type = FundingActionTypes.LoadFunding;
}

export class LoadFundingSuccess implements Action {
  readonly type = FundingActionTypes.LoadFundingSuccess;

  constructor(public payload: IFunding[]) {}
}

export class LoadFundingFail implements Action {
  readonly type = FundingActionTypes.LoadFundingFail;
}

export class LoadCurrentFundingOption implements Action {
  readonly type = FundingActionTypes.LoadCurrentFundingOption;
}

export class LoadCurrentFundingOptionSuccess implements Action {
  readonly type = FundingActionTypes.LoadCurrentFundingOptionSuccess;

  constructor(public payload: IFundingOption) {}
}

export class LoadCurrentFundingOptionFail implements Action {
  readonly type = FundingActionTypes.LoadCurrentFundingOptionFail;
}

export class LoadFundingOptions implements Action {
  readonly type = FundingActionTypes.LoadFundingOptions;
}

export class LoadFundingOptionsSuccess implements Action {
  readonly type = FundingActionTypes.LoadFundingOptionsSuccess;

  constructor(public payload: IFundingOption[]) {}
}

export class LoadFundingOptionsFail implements Action {
  readonly type = FundingActionTypes.LoadFundingOptionsFail;
}

export class LoadFundingBalance implements Action {
  readonly type = FundingActionTypes.LoadFundingBalance;
}

export class LoadFundingBalanceSuccess implements Action {
  readonly type = FundingActionTypes.LoadFundingBalanceSuccess;

  constructor(public payload: number) {}
}

export class LoadFundingBalanceFail implements Action {
  readonly type = FundingActionTypes.LoadFundingBalanceFail;
}

export class LoadContributors implements Action {
  readonly type = FundingActionTypes.LoadContributors;
}

export class LoadContributorsSuccess implements Action {
  readonly type = FundingActionTypes.LoadContributorsSuccess;

  constructor(public payload: IContributor[]) {}
}

export class LoadContributorsFail implements Action {
  readonly type = FundingActionTypes.LoadContributorsFail;
}

export type FundingActions =
  | AddFunding
  | AddFundingSuccess
  | AddFundingFail
  | ConfirmFunding
  | ConfirmFundingSuccess
  | ConfirmFundingFail
  | LoadInProgressFunding
  | LoadInProgressFundingSuccess
  | LoadInProgressFundingFail
  | LoadFunding
  | LoadFundingSuccess
  | LoadFundingFail
  | LoadCurrentFundingOption
  | LoadCurrentFundingOptionSuccess
  | LoadCurrentFundingOptionFail
  | LoadFundingOptions
  | LoadFundingOptionsSuccess
  | LoadFundingOptionsFail
  | LoadFundingBalance
  | LoadFundingBalanceSuccess
  | LoadFundingBalanceFail
  | LoadContributors
  | LoadContributorsSuccess
  | LoadContributorsFail;
