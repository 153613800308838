<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>Crowdfunding for Dot to Circle, Inc</h1></div>
  <div pageContentNoAd>
    <div content class="rowN">
      <fusion-payment-funding-balance
        [fundingTotal]="fundingTotal$ | async"
      ></fusion-payment-funding-balance>
      <!-- options -->
      <fusion-payment-funding-options
        [fundingOptions]="fundingOptions$ | async"
        (notifyFundingSelection)="onNotifyFundingSelection($event)"
        (notifyViewContributors)="onNotifyViewContributors($event)"
      ></fusion-payment-funding-options>
    </div>
  </div>
</div>
