import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalNumber',
})
export class OrdinalNumberPipe implements PipeTransform {
  transform(number: any): any {
    switch (number) {
      case 1: {
        return 'st';
      }
      case 2: {
        return 'nd';
      }
      case 3: {
        return 'rd';
      }
      default: {
        return 'th';
      }
    }
  }
}
