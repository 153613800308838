import { createSelector } from '@ngrx/store';
import { IJob } from '../../models/interfaces';

import { getFusionJobState } from '../reducers';

export const getOpenPositionsState = createSelector(
  getFusionJobState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.openPositions;
  }
);

export const getOpenPositionsRaw = createSelector(
  getOpenPositionsState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.entities;
  }
);

export const getOpenPositionCount = createSelector(
  getOpenPositionsState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.count;
  }
);

export const isOpenPositionsLoading = createSelector(
  getOpenPositionsState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.loading;
  }
);

// TODO include metadata in store once processing data mapping
export const getOpenPositions = createSelector(
  getOpenPositionsRaw,
  (openPositions): IJob[] => {
    if (openPositions == null) {
      return null;
    }

    return openPositions.map((openPosition) => {
      return {
        ...openPosition,
        metadata: [
          {
            isSalary: false,
            label: openPosition.level,
          },
          {
            isSalary: false,
            label: openPosition.type,
          },
          {
            isSalary: false,
            label: openPosition.category,
          },
          {
            isSalary: !openPosition.negotiable,
            amount: openPosition.salary,
            currencyCode: openPosition.currencyCode,
            label: !openPosition.negotiable
              ? `${openPosition.salaryUnit}`
              : `Negotiable`,
          },
        ],
      };
    });
  }
);
