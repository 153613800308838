<div *ngIf="job" class="rowN">
  <div *ngFor="let detail of job.jobDetails">
    <h6>{{ detail.title }}</h6>
    <div [innerHTML]="detail.content" class="r-padding-12"></div>
  </div>
  <div *ngIf="job.address">
    <h6>Location</h6>
    <div [innerHTML]="job.address"></div>
  </div>
  <!-- <div *ngIf="job.email">
    <h5>Apply To</h5>
    <ul>
      <li>{{ job.email }}</li>
    </ul>
  </div> -->
</div>
