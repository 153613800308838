import { FundingActionTypes, FundingActions } from "../actions/funding.actions";
import { IFunding, IFundingOption, IContributor } from "../../models/interfaces";

export interface FundingState {
  funding: IFunding[];
  inProgressFunding: IFunding;
  fundingOptions: IFundingOption[];
  currentFundingOption: IFundingOption;
  contributors: IContributor[];
  fundingTotal: number;
  loading: boolean;
  loaded: boolean;
}

export const initialState: FundingState = {
  funding: null,
  inProgressFunding: null,
  fundingOptions: null,
  currentFundingOption: null,
  contributors: null,
  fundingTotal: 0,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: FundingActions
): FundingState {
  switch (action.type) {
    case FundingActionTypes.LoadFundingOptions: {
      return {
        ...state,
        loading: true
      };
    }
    case FundingActionTypes.LoadFundingOptionsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        fundingOptions: action.payload
      };
    }
    case FundingActionTypes.LoadFundingOptionsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case FundingActionTypes.LoadCurrentFundingOption: {
      return {
        ...state,
        loading: true
      };
    }
    case FundingActionTypes.LoadCurrentFundingOptionSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        currentFundingOption: action.payload
      };
    }
    case FundingActionTypes.LoadCurrentFundingOptionFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case FundingActionTypes.AddFunding: {
      return {
        ...state,
        loading: true,
        currentFundingOption: action.payload
      };
    }
    case FundingActionTypes.LoadInProgressFunding: {
      return {
        ...state,
        loading: true
      };
    }
    case FundingActionTypes.AddFundingSuccess:
    case FundingActionTypes.LoadInProgressFundingSuccess:
    case FundingActionTypes.ConfirmFundingSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        inProgressFunding: action.payload
      };
    }
    case FundingActionTypes.LoadInProgressFundingFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case FundingActionTypes.LoadFunding: {
      return {
        ...state,
        loading: true
      };
    }
    case FundingActionTypes.LoadFundingSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        funding: action.payload
      };
    }
    case FundingActionTypes.LoadFundingFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case FundingActionTypes.LoadFundingBalanceSuccess: {
      return {
        ...state,
        fundingTotal: action.payload
      };
    }
    case FundingActionTypes.LoadContributorsSuccess: {
      return {
        ...state,
        contributors: action.payload
      };
    }
  }
  return state;
}
