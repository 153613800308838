import { IReferralStats } from '../../models/interfaces';
import {
  ReferralsActionTypes,
  ReferralsActions,
} from '../actions/referrals.actions';

export interface ReferralsState {
  data: any;
  stats: IReferralStats;
  loading: boolean;
  loaded: boolean;
}

export const initialState: ReferralsState = {
  data: null,
  stats: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: ReferralsActions
): ReferralsState {
  switch (action.type) {
    case ReferralsActionTypes.AddReferrals: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReferralsActionTypes.AddReferralsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case ReferralsActionTypes.AddReferralsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ReferralsActionTypes.LoadReferralsStats: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReferralsActionTypes.LoadReferralsStatsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        stats: action.payload,
      };
    }
    case ReferralsActionTypes.LoadReferralsStatsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
