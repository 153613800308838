<fusion-common-back
  [currentRootPath]="currentRootPath$ | async"
  [buttonText]="'back'"
></fusion-common-back>

<div pageSpacing extraLargePage pageBody>
  <div pageTitle>
    <!-- <h1>Job Application!</h1> -->
  </div>
  <div pageContent>
    <ng-container *ngIf="jobApplication$ | async as jobApplication">
      <div content class="rowN">
        <div primaryFontColor class="col2">
          <div smallProfileImage>
            <img
              imageFit
              src="{{
                jobApplication?.applicant?.profilePicture ||
                  '/assets/images/job/job-banner-9.jpg'
              }}"
            />
          </div>
          <div class="application_header col2">
            <div>
              <div mediumTitle semiBold>
                {{ jobApplication?.applicant?.firstName }}
                {{ jobApplication?.applicant?.lastName }}
              </div>
              <div microTitle>
                {{ jobApplication.createdAt | fancyTime }}
              </div>
            </div>

            <div centering center>
              <div>
                <div semiBold extraSmallTitle>
                  {{ (screenings$ | async).length || '-'
                  }}<sup>{{
                    (screenings$ | async).length | ordinalNumber
                  }}</sup>
                </div>
                <div picoFont>Round Screening</div>
              </div>
            </div>
            <!-- <div centering centerRight>
              <div pointer chip live>Circle Career Profile</div>
            </div> -->
          </div>
        </div>
        <div class="row2">
          <div extraSmallTitle>
            <span semiBold>Applied for:</span>
            <span> {{ jobApplication?.job?.position }}</span>
          </div>
          <div>
            <div extraSmallTitle semiBold>Cover Letter</div>
            <fusion-common-description
              [description]="jobApplication.coverLetter"
              [defaultLength]="250"
            ></fusion-common-description>
          </div>
        </div>

        <!-- screening -->
        <div extraSmallTitle semiBold>Application Screening</div>
        <div border cardSpacing radius bgGray1>
          <div class="screening col2">
            <div class="col2__section-one">
              <a class="custom-round-button" mat-button color="warn"
                >Reject Apllication</a
              >
            </div>
            <div centering center class="col2__section-two">
              <ng-container
                *ngIf="
                  (screenings$ | async)?.length === 0;
                  else inProgressScreenings
                "
              >
                <a
                  class="custom-round-button"
                  mat-raised-button
                  color="warn"
                  [disabled]="enableScreeningUpdate"
                  (click)="onStartScreening()"
                  >Start Screening</a
                >
              </ng-container>
              <ng-template #inProgressScreenings>
                <ng-container *ngIf="screenings$ | async as screenings">
                  <a
                    class="custom-round-button"
                    mat-raised-button
                    color="warn"
                    [disabled]="
                      screenings[0].status === ApplicationStatus.Pending
                    "
                    (click)="onStartScreening()"
                    >Start Next Screening Round</a
                  >
                </ng-container>
              </ng-template>
            </div>
          </div>
        </div>

        <ng-container *ngFor="let screening of screenings$ | async">
          <div border radius bgGray1>
            <div cardSpacing bottomBorder class="col3">
              <div semiBold>
                {{ screening.round
                }}<sup>{{ screening?.round | ordinalNumber }}</sup> Round
              </div>
              <div centering>
                <span center chip light live>{{ screening.status }}</span>
              </div>
              <div></div>
            </div>
            <div cardSpacing>
              <!-- interview options -->
              <ng-container *ngIf="!screening?.interview; else previousRound">
                <ng-container *ngIf="!selectedInterviewOption">
                  <div class="screening-options colN">
                    <ng-container *ngFor="let option of applicationStatus">
                      <div
                        microFont
                        pointer
                        border
                        radius
                        cardSpacing
                        class="col2"
                        (click)="onInterviewSelection(option)"
                      >
                        <div *ngIf="option.icon"></div>
                        <div>{{ option.name }}</div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <!-- interview editor -->
                <ng-container [ngSwitch]="selectedInterviewOption?.code">
                  <ng-container
                    *ngSwitchCase="ApplicationStatus.PhoneScreening"
                  >
                    <phone-screening
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></phone-screening>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="ApplicationStatus.VideoInterview"
                  >
                    <video-interview
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></video-interview>
                  </ng-container>
                  <ng-container *ngSwitchCase="ApplicationStatus.InPerson">
                    <in-person
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></in-person>
                  </ng-container>
                  <ng-container *ngSwitchCase="ApplicationStatus.JobOffer">
                    <job-offer
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></job-offer>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <!-- TODO may be fetch error component -->
                    <div class="activity__empty"></div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #previousRound>
                <!-- interview readonly -->
                <ng-container [ngSwitch]="screening.status">
                  <ng-container
                    *ngSwitchCase="ApplicationStatus.PhoneScreening"
                  >
                    <phone-screening
                      [isPreview]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></phone-screening>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="ApplicationStatus.VideoInterview"
                  >
                    <video-interview
                      [isPreview]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></video-interview>
                  </ng-container>
                  <ng-container *ngSwitchCase="ApplicationStatus.InPerson">
                    <in-person
                      [isPreview]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></in-person>
                  </ng-container>
                  <ng-container *ngSwitchCase="ApplicationStatus.JobOffer">
                    <job-offer
                      [isPreview]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></job-offer>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <!-- TODO may be fetch error component -->
                    <div class="activity__empty"></div>
                  </ng-container>
                </ng-container>
              </ng-template>
            </div>
            <div topBorder cardSpacing class="action-buttons col2">
              <div microTitle centering centerLeft class="col2__section-one">
                <span semiBold>Last Updated</span>
                <span>: {{ screening.updatedAt | fancyTime }}</span>
              </div>
              <div centering center class="col2__section-two">
                <calendar-interview
                  [eventId]="screening?.interview?.eventId"
                  [eventPayload]="getEventPayload(jobApplication, screening)"
                  (notifyEventInit)="onNotifyEventInit($event)"
                  (notifyEventId)="onNotifyEventId($event)"
                ></calendar-interview>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div adSpace></div>
  </div>
</div>
