import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

// from reducer
import * as fromOrder from "./order.reducer";
import * as fromPayment from "./payment.reducer";
import * as fromTransaction from "./transaction.reducer";
import * as fromCustomer from "./customer.reducer";
import * as fromWallet from "./wallet.reducer";
import * as fromFunding from './funding.reducer';

export interface FusionPaymentState {
  order: fromOrder.OrderState;
  payment: fromPayment.PaymentState;
  transaction: fromTransaction.TransactionState;
  customer: fromCustomer.CustomerState;
  wallet: fromWallet.WalletState;
  funding: fromFunding.FundingState;
}

export const reducers: ActionReducerMap<FusionPaymentState> = {
  order: fromOrder.reducer,
  payment: fromPayment.reducer,
  transaction: fromTransaction.reducer,
  customer: fromCustomer.reducer,
  wallet: fromWallet.reducer,
  funding: fromFunding.reducer
};

export const getFusionPaymentState = createFeatureSelector<FusionPaymentState>(
  "fusionPayment"
);

// export reducer state interfaces
