import { createSelector } from '@ngrx/store';
import { IEventGuest, IGuestSummary, JoiningStatus } from '../../models';

import { getCalendarState } from '../reducers';

export const getGuestsState = createSelector(getCalendarState, (state) => {
  if (state == null) {
    return null;
  }
  return state.guests;
});

export const getGuests = createSelector(getGuestsState, (state) => {
  if (state == null) {
    return null;
  }
  return state.data;
});

export const getGuestSummary = createSelector(
  getGuests,
  (guests): IGuestSummary => {
    if (guests == null) {
      return null;
    }

    const totalGuestCount = guests.length;
    const acceptedGuests: IEventGuest[] = guests.filter(
      (guest) => guest.joiningStatus === JoiningStatus.Accepted
    );
    const acceptedGuestCount = acceptedGuests.length;
    const rejectedGuests: IEventGuest[] = guests.filter(
      (guest) => guest.joiningStatus === JoiningStatus.Rejected
    );
    const rejectedGuestCount = rejectedGuests.length;
    return {
      guestCount: totalGuestCount,
      acceptedGuestCount: acceptedGuestCount,
      rejectedGuestCount: rejectedGuestCount,
      waitingguestCount:
        totalGuestCount - acceptedGuestCount - rejectedGuestCount,
    };
  }
);

export const getApplicant = createSelector(getGuests, (guests) => {
  if (guests == null) {
    return null;
  }
  return guests.find((guest) => guest.isApplicant);
});
