<div class="row2">
  <div class="row2__section-one col2">
    <div class="col2__section-one row2">
      <div mediumTitle microBold class="row2__section-one">Payment Methods</div>
      <div microTitle class="row2__section-two">
        Card will be charged monthly for resources used. All major credit /
        debit cards accepted.
      </div>
    </div>
    <div centering centerRight class="col2__section-two">
      <fusion-payment-add-card
        [address]="address"
        [clientSecret]="clientSecret"
        (notifyAddCard)="notifyAddCard.emit($event)"
        (notifyPaymentMethod)="notifyPaymentMethod.emit($event)"
      ></fusion-payment-add-card>
    </div>
  </div>

  <div
    *ngIf="paymentMethods && paymentMethods.length"
    class="row2__section-two"
  >
    <mat-tab-group>
      <mat-tab label="Cards">
        <div class="t-padding-5 rowN">
          <div *ngFor="let paymentMethod of paymentMethods">
            <fusion-payment-card
              [paymentMethod]="paymentMethod"
            ></fusion-payment-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Transactions">
        <div class="t-padding-5">
          <fusion-payments
            [transactions]="transactions"
            [displayColumns]="displayColumns"
          ></fusion-payments>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
