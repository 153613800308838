import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs/operators';
import { FusionSubscriptionState } from '../reducers';
import { LoadCurrentApp } from '../actions';
import { getCurrentApp } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadApplicationResolver implements Resolve<any> {
  constructor(private store: Store<FusionSubscriptionState>) {}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return this.store.select(getCurrentApp).pipe(
      tap((currentApp) => {
        if (!currentApp) {
          const applicationId = next.data.applicationId;
          const currentPath = next.data.currentPath || '';
          this.store.dispatch(
            new LoadCurrentApp({
              applicationId,
              currentPath,
            })
          );
        }
      }),
      take(2),
      filter((currentApp) => currentApp !== null),
      map(() => true)
    );
  }
}
