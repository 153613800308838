import { 
  VideoConferenceActionTypes,
  VideoConferenceActions
} from '../actions/video-conference.actions';

export interface VideoConferenceState {
  data: any;
  loading: boolean;
  loaded: boolean;
}

export const initialState: VideoConferenceState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: VideoConferenceActions
): VideoConferenceState {
  switch (action.type) {
    case VideoConferenceActionTypes.LoadVideoConference: {
      return {
        ...state,
        loading: true
      };
    }
    case VideoConferenceActionTypes.LoadVideoConferenceSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case VideoConferenceActionTypes.LoadVideoConferenceFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
