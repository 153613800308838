<div class="row3">
  <div mediumTitle microBold class="row3__section-one">Load Wallet</div>
  <div microTitle class="row3__section-two">
    Your card will be charged only the amount you prefer to load in your wallet.
  </div>
  <mat-tab-group>
    <!-- card -->
    <mat-tab *ngIf="paymentMethods && paymentMethods.length" label="Card">
      <ng-container *ngIf="!currentPayment; else paymentConfirmation">
        <div border radius class="t-margin-5 row3__section-three">
          <ng-container *ngIf="!(breakpoint$ | async).matches; else mobileView">
            <mat-horizontal-stepper [linear]="isLinear" #stepper>
              <!-- step one -->
              <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                  <ng-template matStepLabel>Load Amount</ng-template>
                  <div
                    border
                    radius
                    cardSpacing
                    class="t-margin-5 long-container"
                  >
                    <mat-form-field [style.fontSize]="'24px'">
                      <input
                        #loadAmount
                        matInput
                        placeholder="Load Amount"
                        formControlName="amountCtrl"
                        type="number"
                        pattern="^[1-9]\d*$"
                        class="example-right-align"
                      />
                      <span matPrefix>$&nbsp;</span>
                      <span matSuffix>.00</span>
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        firstFormGroup.controls['amountCtrl'].hasError(
                          'required'
                        ) && firstFormGroup.controls['amountCtrl'].touched
                      "
                    >
                      You must include Amount.
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !firstFormGroup.controls['amountCtrl'].hasError(
                          'required'
                        ) &&
                        firstFormGroup.controls['amountCtrl'].touched &&
                        !firstFormGroup.valid
                      "
                    >
                      Amount must be positive & full number.
                    </mat-error>
                  </div>
                  <div class="t-padding-7">
                    <button
                      mat-flat-button
                      color="primary"
                      matStepperNext
                      [disabled]="!firstFormGroup.valid"
                    >
                      Next
                    </button>
                  </div>
                </form>
              </mat-step>
              <!-- step two -->
              <mat-step [stepControl]="secondFormGroup">
                <ng-template matStepLabel>Payment Method</ng-template>
                <div class="t-padding-5 rowN">
                  <div
                    hover
                    *ngFor="
                      let paymentMethod of paymentMethods;
                      let currentIndex = index
                    "
                  >
                    <div
                      bgColorHover
                      pointer
                      (click)="setCardIndex(currentIndex, stepper)"
                    >
                      <fusion-payment-card
                        [paymentMethod]="paymentMethod"
                        [isSelected]="currentIndex == cardIndex"
                      ></fusion-payment-card>
                    </div>
                  </div>
                </div>

                <div class="t-padding-7 action-buttons col3">
                  <button mat-button matStepperPrevious>Back</button>
                  <button
                    mat-flat-button
                    color="primary"
                    matStepperNext
                    [disabled]="!(cardIndex >= 0)"
                  >
                    Next
                  </button>
                </div>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Authorize</ng-template>
                <div class="t-padding-5 authorization col2">
                  <div class="col2__section-one">
                    <fusion-payment-card
                      [paymentMethod]="paymentMethods[cardIndex || 0]"
                      [isSelected]="true"
                    ></fusion-payment-card>
                  </div>
                  <div
                    border
                    radius
                    centering
                    center
                    bgColor
                    class="col2__section-two"
                  >
                    <div extraSmallTitle>
                      <span>Your card will be charged</span>
                      <span semiBold> ${{ loadAmount.value }}.</span>
                    </div>
                  </div>
                </div>

                <div class="t-padding-7 action-buttons col3">
                  <button mat-button matStepperPrevious>Back</button>
                  <button
                    mat-flat-button
                    color="primary"
                    [disabled]="isSendingPayment"
                    (click)="
                      authorizeTransaction(
                        paymentMethods[cardIndex].id,
                        loadAmount.value
                      )
                    "
                  >
                    Authorize
                  </button>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </ng-container>
          <ng-template #mobileView>
            <mat-vertical-stepper [linear]="isLinear" #stepper>
              <!-- step one -->
              <mat-step [stepControl]="firstFormGroup">
                <form cardHrSpacing [formGroup]="firstFormGroup">
                  <ng-template matStepLabel>Load Amount</ng-template>
                  <div
                    border
                    radius
                    cardSpacing
                    class="t-margin-5 long-container"
                  >
                    <mat-form-field [style.fontSize]="'18px'">
                      <input
                        #loadAmount
                        matInput
                        placeholder="Load Amount"
                        formControlName="amountCtrl"
                        type="number"
                        pattern="^[1-9]\d*$"
                        class="example-right-align"
                      />
                      <span matPrefix>$&nbsp;</span>
                      <span matSuffix>.00</span>
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        firstFormGroup.controls['amountCtrl'].hasError(
                          'required'
                        ) && firstFormGroup.controls['amountCtrl'].touched
                      "
                    >
                      You must include Amount.
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !firstFormGroup.controls['amountCtrl'].hasError(
                          'required'
                        ) &&
                        firstFormGroup.controls['amountCtrl'].touched &&
                        !firstFormGroup.valid
                      "
                    >
                      Amount must be positive & full number.
                    </mat-error>
                  </div>
                  <div class="t-padding-7">
                    <button
                      mat-flat-button
                      color="primary"
                      matStepperNext
                      [disabled]="!firstFormGroup.valid"
                    >
                      Next
                    </button>
                  </div>
                </form>
              </mat-step>
              <!-- step two -->
              <mat-step [stepControl]="secondFormGroup">
                <div cardHrSpacing>
                  <ng-template matStepLabel>Payment Method</ng-template>
                  <div class="t-padding-5 rowN">
                    <div
                      hover
                      *ngFor="
                        let paymentMethod of paymentMethods;
                        let currentIndex = index
                      "
                    >
                      <div
                        bgColorHover
                        pointer
                        (click)="setCardIndex(currentIndex, stepper)"
                      >
                        <fusion-payment-card
                          [paymentMethod]="paymentMethod"
                          [isSelected]="currentIndex == cardIndex"
                        ></fusion-payment-card>
                      </div>
                    </div>
                  </div>

                  <div class="t-padding-7 action-buttons col3">
                    <button mat-button matStepperPrevious>Back</button>
                    <button
                      mat-flat-button
                      color="primary"
                      matStepperNext
                      [disabled]="!(cardIndex >= 0)"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </mat-step>
              <mat-step>
                <div cardHrSpacing>
                  <ng-template matStepLabel>Authorize</ng-template>
                  <div class="t-padding-5 authorization col2">
                    <div class="col2__section-one">
                      <fusion-payment-card
                        [paymentMethod]="paymentMethods[cardIndex || 0]"
                        [isSelected]="true"
                      ></fusion-payment-card>
                    </div>
                    <div
                      border
                      radius
                      centering
                      center
                      bgColor
                      class="col2__section-two"
                    >
                      <div extraSmallTitle>
                        <span>Your card will be charged</span>
                        <span semiBold> ${{ loadAmount.value }}.</span>
                      </div>
                    </div>
                  </div>

                  <div class="t-padding-7 action-buttons col3">
                    <button mat-button matStepperPrevious>Back</button>
                    <button
                      mat-flat-button
                      color="primary"
                      [disabled]="isSendingPayment"
                      (click)="
                        authorizeTransaction(
                          paymentMethods[cardIndex].id,
                          loadAmount.value
                        )
                      "
                    >
                      Authorize
                    </button>
                  </div>
                </div>
              </mat-step>
            </mat-vertical-stepper>
          </ng-template>
        </div>
      </ng-container>
    </mat-tab>
    <!-- coupon -->
    <mat-tab label="Redeem Coupon">
      <div class="t-padding-5">
        <fusion-payment-redeem-coupon
          [couponBalance]="couponBalance"
          (notifyRedeamCoupon)="notifyRedeamCoupon.emit($event)"
          (notifyResetCouponBalance)="notifyResetCouponBalance.emit($event)"
        ></fusion-payment-redeem-coupon>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #paymentConfirmation>
  <ng-container
    *ngIf="currentPayment && currentPayment.paid; else paymentAuthorization"
  >
    <div
      border
      radius
      cardSpacing
      centeringText
      cardSpacing
      class="row3 t-margin-5"
    >
      <div smallTitle semiBold class="row3__section-one">
        Payment Successful!
      </div>
      <div exSmallTitle g4FontColor class="row3__section-two">
        Your payment has successfully completed. ${{ currentPayment.amount }}
        has been added in your wallet.
      </div>
      <div class="row3__section-three">
        <button mat-flat-button (click)="resetCurrentPayment()" color="primary">
          Reset to make another transaction
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #paymentAuthorization>
  <div
    errorBorder
    radius
    cardSpacing
    centeringText
    cardSpacing
    class="row3 t-margin-5"
  >
    <div smallTitle semiBold class="row3__section-one">
      Authorization Required!
    </div>
    <div exSmallTitle g4FontColor class="row3__section-two">
      Your bank require authorization to complete this transaction. Once you
      authorize ${{ currentPayment.amount }} will be added in your wallet.
    </div>
    <div class="row3__section-three">
      <button
        mat-flat-button
        color="accent"
        [disabled]="isSendingPayment"
        (click)="authorizeCurrentPayment()"
      >
        Authorize this transaction
      </button>
    </div>
  </div>
</ng-template>
