import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IUser } from "@fusion/common";
import { getValidationRulesWithPresetValue } from "@fusion/form";

@Component({
  selector: "user-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: ["./update-password.component.scss"]
})
export class UpdatePasswordComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: IUser;
  @Input() passwordFormMetadata: any;
  @Input() passwordFormConfigs: any;
  @Input() passwordFormValidationRules: UntypedFormGroup;

  updatedValidationRules: UntypedFormGroup;
  getValidationRulesWithPresetValue = getValidationRulesWithPresetValue;

  constructor() {}

  ngOnInit() {
    this.updatedValidationRules = getValidationRulesWithPresetValue(
      this.passwordFormValidationRules,
      this.passwordFormConfigs,
      this.user
    );
  }
}
