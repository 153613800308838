import { Action } from '@ngrx/store';
import {
  IUserPermission,
  IRedirectSubscriberPayload,
} from '../../models/interfaces';

export enum UserPermissionsActionTypes {
  LoadUserPermissions = '[User Permissions] Load',
  LoadUserPermissionsSuccess = '[User Permissions] Load Success',
  LoadUserPermissionsFail = '[User Permissions] Load Fail',
  RedirectSubscriber = '[User Permissions] Redirect Subscriber',
  RedirectSubscriberSuccess = '[User Permissions] Redirect Subscriber Success',
  RedirectSubscriberFail = '[User Permissions] Redirect Subscriber Fail',
}

export class LoadUserPermissions implements Action {
  readonly type = UserPermissionsActionTypes.LoadUserPermissions;
}

export class LoadUserPermissionsSuccess implements Action {
  readonly type = UserPermissionsActionTypes.LoadUserPermissionsSuccess;

  constructor(public payload: IUserPermission) {}
}

export class LoadUserPermissionsFail implements Action {
  readonly type = UserPermissionsActionTypes.LoadUserPermissionsFail;
}

export class RedirectSubscriber implements Action {
  readonly type = UserPermissionsActionTypes.RedirectSubscriber;

  constructor(public payload: IRedirectSubscriberPayload) {}
}

export class RedirectSubscriberSuccess implements Action {
  readonly type = UserPermissionsActionTypes.RedirectSubscriberSuccess;
}

export class RedirectSubscriberFail implements Action {
  readonly type = UserPermissionsActionTypes.RedirectSubscriberFail;
}

export type UserPermissionsActions =
  | LoadUserPermissions
  | LoadUserPermissionsSuccess
  | LoadUserPermissionsFail
  | RedirectSubscriber
  | RedirectSubscriberSuccess
  | RedirectSubscriberFail;
