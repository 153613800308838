import {
  TransactionActionTypes,
  TransactionActions
} from "../actions/transaction.actions";
import { ITransaction } from "../../models/interfaces";

export interface TransactionState {
  data: ITransaction;
  loading: boolean;
  loaded: boolean;
}

export const initialState: TransactionState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: TransactionActions
): TransactionState {
  switch (action.type) {
    case TransactionActionTypes.LoadTransaction: {
      return {
        ...state,
        loading: true
      };
    }
    case TransactionActionTypes.LoadTransactionSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case TransactionActionTypes.LoadTransactionFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
