import { IDropdown } from '@fusion/common';
import { ApplicationStatus } from '../enums';

export const applicationStatus: IDropdown[] = [
  // {
  //   code: ApplicationStatus.Pending,
  //   name: 'Pending',
  // },
  // {
  //   code: ApplicationStatus.SkillsAssessment,
  //   name: 'Skills Assessment',
  // },
  // {
  //   code: ApplicationStatus.TechnicalExercise,
  //   name: 'Technical Exercise',
  // },
  {
    // icon: 'call',
    code: ApplicationStatus.PhoneScreening,
    name: 'Phone Screening',
  },
  {
    // icon: 'video_call',
    code: ApplicationStatus.VideoInterview,
    name: 'Video Interview',
  },
  {
    // icon: 'people',
    code: ApplicationStatus.InPerson,
    name: 'In Person',
  },
  {
    // icon: 'local_offer',
    code: ApplicationStatus.JobOffer,
    name: 'Job Offer',
  },
];
