<div *ngIf="jobseekerInfo" card class="header row3">
  <div image class="row3__section-one">
    <img
      imageFit
      src="{{
        jobseekerInfo.banner_picture || 'assets/images/users/banner/banner.jpg'
      }}"
    />

    <div class="bottom-left col2">
      <div centering center class="profile-image col2__section-one">
        <div profileImage>
          <img
            imageFit
            src="{{
              jobseekerInfo.profile_picture ||
                'assets/images/users/profile-picture/profile.png'
            }}"
          />
        </div>
      </div>
      <div class="header-summary col2__section-two">
        <h4 g6FontColor>{{ jobseekerInfo.name }}</h4>
        <div *ngIf="jobseekerInfo?.experience" smallTitle g6FontColor>
          {{ jobseekerInfo?.experience?.position }} <span microBold>at</span>
          {{ jobseekerInfo.experience?.company.name }}
          <span class="user-location">
            <span class="dot"></span>
            {{ jobseekerInfo?.experience?.company.city }} -
            {{ jobseekerInfo?.experience?.company.country }}
          </span>
        </div>
        <div extraSmallTitle g5FontColor *ngIf="jobseekerInfo.education">
          <span>{{ jobseekerInfo?.education?.degreeType }}</span>
          <span microBold> on </span>
          <span>{{ jobseekerInfo?.education?.major }}</span>
        </div>
        <div microTitle g5FontColor *ngIf="jobseekerInfo.education">
          <span>{{ jobseekerInfo?.education?.company.city }}</span>
          <span> - {{ jobseekerInfo?.education?.company.country }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row3__section-two">
    <mat-button-toggle-group
      class="jobseeker-profile"
      #group="matButtonToggleGroup"
    >
      <mat-button-toggle value="left" checked>
        <mat-icon>person</mat-icon>
        Profile
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
