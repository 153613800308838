import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IFormConfig } from "../../models/interfaces";

@Component({
  selector: "fusion-form-text-field",
  templateUrl: "./text-field.component.html",
  styleUrls: ["./text-field.component.scss"]
})
export class TextFieldComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;
  hide: boolean = true;

  constructor() {}

  ngOnInit() {}
}
