<div *ngIf="formConfigs && formValidationRules">
  <div
    class="form-group"
    [ngClass]="{
      'has-error':
        !formValidationRules.controls[formConfigs.name].valid &&
        formValidationRules.controls[formConfigs.name].touched
    }"
  >
    <div class="example-container inputGroupContainer">
      <mat-form-field>
        <mat-chip-grid #chipList aria-label="email selection">
          <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)">
            {{ email }}
            <button centering center matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>

          <input
            placeholder="{{ formConfigs.label }}"
            [formControl]="emailsCtrl"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event, emailsCtrl.hasError('pattern'))"
            required="{{
              formValidationRules.controls[formConfigs.name].hasError(
                'required'
              )
            }}"
          />
        </mat-chip-grid>
      </mat-form-field>

      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError('required') &&
          formValidationRules.controls[formConfigs.name].touched
        "
      >
        You must include {{ formConfigs.label }}.
      </mat-error>
      <mat-error *ngIf="emailsCtrl.hasError('pattern') && emailsCtrl.value">
        Your {{ formConfigs.label }} is invalid. Allowed format is
        <b>{{ formConfigs.examplePattern }}</b>
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError(
            'minlength'
          ) && formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} must be at least
        {{ formConfigs.validation.min }} characters long.
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError(
            'maxlength'
          ) && formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} cannot exceed
        {{ formConfigs.validation.max }} characters.
      </mat-error>
    </div>
  </div>
</div>
