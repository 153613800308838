import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import { Router, ActivatedRoute } from '@angular/router';
import {
  LoadFundingOptions,
  getFundingOptions,
  AddFunding,
  IFundingOption,
  LoadFundingBalance,
  getFundingTotal,
} from '@fusion/payment';
import { Observable } from 'rxjs';

@Component({
  selector: 'user-funding',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.scss'],
})
export class FundingComponent implements OnInit {
  fundingOptions$: Observable<IFundingOption[]>;
  fundingTotal$: Observable<number>;

  constructor(
    private store: Store<UserState>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadFundingBalance());
    this.store.dispatch(new LoadFundingOptions());

    this.fundingOptions$ = this.store.select(getFundingOptions);
    this.fundingTotal$ = this.store.select(getFundingTotal);
  }

  onNotifyFundingSelection(fundingOption: IFundingOption) {
    this.store.dispatch(new AddFunding(fundingOption));
    this.router.navigate([`${fundingOption.id}/payment`], {
      relativeTo: this.route,
    });
  }

  onNotifyViewContributors(fundingOption: IFundingOption) {
    this.router.navigate([`${fundingOption.id}`], {
      relativeTo: this.route,
    });
  }
}
