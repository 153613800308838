import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { IScreening } from '../../models/interfaces';

import {
  CurrentScreeningActionTypes,
  SetCurrentScreeningSuccess,
  SetCurrentScreeningFail,
  SetCurrentScreening,
} from '../actions/current-screening.actions';
import { FusionJobState } from '../reducers';
import { getScreenings } from '../selectors';

@Injectable()
export class CurrentScreeningEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionJobState>
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType<SetCurrentScreening>(
      CurrentScreeningActionTypes.SetCurrentScreening
    ),
    map((action) => action.screeningId),
    withLatestFrom(this.store.select(getScreenings)),
    mergeMap(([screeningId, screenings]: [string, IScreening[]]) => {
      const currentScreening = screenings.find(
        (screening) => screening.id === screeningId
      );
      return of(new SetCurrentScreeningSuccess(currentScreening));
    }),
    catchError((error) => of(new SetCurrentScreeningFail()))
  ));
}
