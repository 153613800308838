import { Component, OnInit, Input } from '@angular/core';
import { getCurrentReferrerUrl, getRouterUrlRoot } from '@fusion/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICompany } from '../../models/interfaces';
import { FusionCompanyState } from '../../store/reducers';
import { getCompany } from '../../store/selectors';

@Component({
  selector: 'fusion-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  company$: Observable<ICompany>;
  currentRootPath$: Observable<string>;

  constructor(private store: Store<FusionCompanyState>) {}

  ngOnInit() {
    this.company$ = this.store.select(getCompany);
    this.currentRootPath$ = this.store.select(getCurrentReferrerUrl);
  }
}
