import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from '@fusion/common';
import { CalendarEvent } from 'angular-calendar';
import { Observable } from 'rxjs';
import {
  ICalendarEventMetadata,
  IEventGuest,
  IEventRawData,
  IEventUpdatePayload,
  IVideoConferenceMetadata,
} from '../../models/interfaces';

@Component({
  selector: 'calendar-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
})
export class CreateEventComponent implements OnInit {
  @Output() notifySave = new EventEmitter();
  @Output() notifyGuestUser = new EventEmitter<IUser>();
  @Output() notifyAutocompletion = new EventEmitter<string>();
  @Output() notifyJoinVideoConference =
    new EventEmitter<IVideoConferenceMetadata>();
  @Output() notifyCreateVideoConference =
    new EventEmitter<IVideoConferenceMetadata>();
  @Output() notifyEventUpdate = new EventEmitter<IEventUpdatePayload>();
  users$: IUser[];
  event$: CalendarEvent<ICalendarEventMetadata>;
  applicantGuest$: Observable<IEventGuest>;

  constructor(
    public dialogRef: MatDialogRef<CreateEventComponent>,
    @Inject(MAT_DIALOG_DATA) public eventData: IEventRawData,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.eventData) {
      this.event$ = this.eventData.event;
      this.users$ = this.eventData.users;
      this.applicantGuest$ = this.eventData.applicantGuest;
      this.cdr.detectChanges();
    }
  }
}
