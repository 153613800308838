<div pageSpacing extraLargePage pageBody>
  <!-- <div pageTitle><h1>Calendar!</h1></div> -->
  <div pageContentNoAd>
    <div content class="rowN">
      <div class="calendar-header col3">
        <div class="col3__section-one col2">
          <div class="col2" centering center>
            <button
              mat-stroked-button
              color="warn"
              class="custom-round-button"
              (click)="addEvent()"
            >
              <mat-icon>add</mat-icon> Create
            </button>
            <div>
              <mat-button-toggle-group color="primary">
                <mat-button-toggle
                  mwlCalendarToday
                  [(viewDate)]="viewDate"
                  [checked]="false"
                  >Today</mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
          </div>
          <div centering center>
            <button
              mat-icon-button
              mwlCalendarPreviousView
              [view]="view"
              [daysInWeek]="daysInWeek"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              <mat-icon>arrow_back_ios</mat-icon>
            </button>
            <button
              mat-icon-button
              mwlCalendarNextView
              [view]="view"
              [daysInWeek]="daysInWeek"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>
          </div>
        </div>
        <div centering center class="col3__section-two">
          <span mediumTitle>{{
            viewDate
              | calendarDate
                : view + 'ViewTitle'
                : 'en'
                : weekStartsOn
                : []
                : daysInWeek
          }}</span>
        </div>
        <div class="col3__section-three">
          <mat-button-toggle-group color="primary">
            <mat-button-toggle
              color="primary"
              [checked]="view === CalendarView.Month"
              (click)="setView(CalendarView.Month)"
              >Month</mat-button-toggle
            >
            <mat-button-toggle
              [checked]="view === CalendarView.Week"
              (click)="setView(CalendarView.Week)"
              >Week</mat-button-toggle
            >
            <mat-button-toggle
              [checked]="view === CalendarView.Day"
              (click)="setView(CalendarView.Day)"
              >Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <br />
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [events]="calendarEvents$ | async"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [weekStartsOn]="1"
          [weekendDays]="[6, 0]"
          [daysInWeek]="daysInWeek"
          [viewDate]="viewDate"
          [events]="calendarEvents$ | async"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (hourSegmentClicked)="hourSegmentClicked($event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="calendarEvents$ | async"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
</div>
