import { Action } from '@ngrx/store';

export enum CurrentScreeningActionTypes {
  SetCurrentScreening = '[Current Screening] Set',
  SetCurrentScreeningSuccess = '[Current Screening] Set Success',
  SetCurrentScreeningFail = '[Current Screening] Set Fail'
}

export class SetCurrentScreening implements Action {
  readonly type = CurrentScreeningActionTypes.SetCurrentScreening;

  constructor( public screeningId: string ) { }
}

export class SetCurrentScreeningSuccess implements Action {
  readonly type = CurrentScreeningActionTypes.SetCurrentScreeningSuccess;

  constructor( public payload: any ) { }
}

export class SetCurrentScreeningFail implements Action {
  readonly type = CurrentScreeningActionTypes.SetCurrentScreeningFail;
}

export type CurrentScreeningActions =
  | SetCurrentScreening
  | SetCurrentScreeningSuccess
  | SetCurrentScreeningFail;
