import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  ICustomer,
  IWallet,
  IStripeBillingAddress,
  ITransaction,
  ICouponBalance
} from "../../models/interfaces";

@Component({
  selector: "fusion-payment-wallet",
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.scss"]
})
export class WalletComponent implements OnInit {
  @Output() notifyResetCouponBalance: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() notifyRedeamCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyPaymentAuthorization: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() notifyResetCurrentPayment: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() notifyAddBalance: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyPaymentMethod: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() notifyAddCard: EventEmitter<any> = new EventEmitter<any>();
  @Input() customer: ICustomer;
  @Input() wallet: IWallet;
  @Input() address: IStripeBillingAddress;
  @Input() clientSecret: string;
  @Input() currentPayment: ITransaction;
  @Input() couponBalance: ICouponBalance;
  @Input() isSendingPayment: boolean;

  constructor() {}

  ngOnInit() {}
}
