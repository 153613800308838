<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>Funding Payment to Dot to Circle, Inc</h1></div>
  <div pageContent>
    <div content>
      <ng-container *ngIf="customer$ | async as customer; else noWallet">
        <fusion-payment-funding-payment
          [baseUrl]="baseUrl$ | async"
          [paymentMethods]="customer.paymentMethods"
          [fundingAmount]="(funding$ | async)?.amount"
          [currentPayment]="currentPayment$ | async"
          (notifyFundingContribution)="onNotifyFundingContribution($event)"
          (notifyFundingPaymentAuthorization)="
            onNotifyFundingPaymentAuthorization()
          "
        ></fusion-payment-funding-payment>
      </ng-container>
    </div>
    <div adSpace></div>
  </div>
</div>

<ng-template #noWallet>
  <div border radius centeringText cardSpacing content class="row3">
    <div mediumTitle semiBold class="row3__section-one">No Wallet!</div>
    <div exSmallTitle g3FontColor class="row3__section-two">
      Your wallet is not setup yet. If you are ready setup now.
    </div>
    <div class="row3__section-three">
      <button mat-flat-button (click)="setupCustomerProfile()" color="primary">
        Setup your Wallet
      </button>
    </div>
  </div>
</ng-template>
