import { Injectable } from '@angular/core';
import { MappingService, mappingType, UserService } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';

import {
  ReferralsActionTypes,
  AddReferralsSuccess,
  AddReferralsFail,
  AddReferrals,
  LoadReferralsStatsSuccess,
  LoadReferralsStatsFail,
  LoadReferralsStats,
} from '../actions/referrals.actions';
import { FusionoAuthState } from '../reducers';
import { getoAuthUserId } from '../selectors';

@Injectable()
export class ReferralsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionoAuthState>,
    private userService: UserService,
    private mappingService: MappingService
  ) {}

  
  addReferrals$ = createEffect(() => this.actions$.pipe(
    ofType<AddReferrals>(ReferralsActionTypes.AddReferrals),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([emails, userId]: [string[], string]) => {
      const referralsRaw = {
        referrer_user_id: userId,
        emails: emails,
      };
      return this.userService.addReferrals(referralsRaw).pipe(
        switchMap((dataResult) => {
          return [
            new AddReferralsSuccess(dataResult),
            new LoadReferralsStats(),
          ];
        }),
        catchError((error) => of(new AddReferralsFail()))
      );
    }),
    catchError((error) => of(new AddReferralsFail()))
  ));

  
  loadReferralsStats$ = createEffect(() => this.actions$.pipe(
    ofType(ReferralsActionTypes.LoadReferralsStats),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([action, userId]: [Action, string]) => {
      return this.userService.loadReferralStats(userId).pipe(
        switchMap((dataResult) => {
          const mappedResult = this.mappingService.getMappedData<any>(
            dataResult,
            mappingType.camelize
          );
          return [new LoadReferralsStatsSuccess(mappedResult)];
        }),
        catchError((error) => of(new LoadReferralsStatsFail()))
      );
    }),
    catchError((error) => of(new LoadReferralsStatsFail()))
  ));
}
