<div class="row6">
  <div class="row6__section-one row2">
    <fusion-career-profile-career-summary
      [allowEdit]="allowEdit"
      [careerSummary]="careerSummary"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    ></fusion-career-profile-career-summary>
  </div>
  <div class="row6__section-two">
    <fusion-career-profile-experiences
      [allowEdit]="allowEdit"
      [experiences]="experiences"
      [autoCompleteEntities]="autoCompleteEntities"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
      (notifyDeleteExperience)="notifyDeleteExperience.emit($event)"
      (notifyAutocompletion)="notifyAutocompletion.emit($event)"
      (notifyViewCompany)="notifyViewCompany.emit($event)"
    ></fusion-career-profile-experiences>
  </div>
  <div class="row6__section-three row2">
    <fusion-career-profile-educations
      [allowEdit]="allowEdit"
      [educations]="educations"
      [autoCompleteEntities]="autoCompleteEntities"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
      (notifyAutocompletion)="notifyAutocompletion.emit($event)"
      (notifyDeleteEducation)="notifyDeleteEducation.emit($event)"
      (notifyViewCompany)="notifyViewCompany.emit($event)"
    ></fusion-career-profile-educations>
  </div>
  <div class="row6__section-four row2">
    <fusion-career-profile-projects
      [allowEdit]="allowEdit"
      [projects]="projects"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
      (notifyDeleteProject)="notifyDeleteProject.emit($event)"
    ></fusion-career-profile-projects>
  </div>
  <div class="row6__section-five row2">
    <fusion-career-profile-publications
      [allowEdit]="allowEdit"
      [publications]="publications"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
      (notifyDeletePublication)="notifyDeletePublication.emit($event)"
    ></fusion-career-profile-publications>
  </div>
</div>
