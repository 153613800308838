import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  IEducation,
  IExperience,
  IProject,
  IPublication,
  ISkill,
  IRating,
} from '../../models/interfaces';
import { ModalIconType } from '@fusion/form';

@Component({
  selector: 'fusion-career-profile-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class BodyComponent implements OnInit {
  @Output() notifyViewCompany = new EventEmitter<string>();
  @Output() notifyFormSubmit = new EventEmitter<any>();
  @Output() notifyAutocompletion = new EventEmitter<string>();
  @Output() notifyDeleteExperience = new EventEmitter<any>();
  @Output() notifyDeleteEducation = new EventEmitter<any>();
  @Output() notifyDeleteProject = new EventEmitter<any>();
  @Output() notifyDeletePublication = new EventEmitter<any>();

  @Input() allowEdit: boolean;
  @Input() careerSummary: string;
  @Input() educations: IEducation[];
  @Input() experiences: IExperience[];
  @Input() projects: IProject[];
  @Input() publications: IPublication[];
  @Input() skills: ISkill[];
  @Input() ratings: IRating[];
  @Input() autoCompleteEntities: any[];

  ModalIconType = ModalIconType;

  constructor() {}

  ngOnInit() {}
}
