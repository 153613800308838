import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IPaymentMethod, IStripeBillingAddress } from "../../models/interfaces";

@Component({
  selector: "fusion-payment-payment-methods",
  templateUrl: "./payment-methods.component.html",
  styleUrls: ["./payment-methods.component.scss"]
})
export class PaymentMethodsComponent implements OnInit {
  @Output() notifyPaymentMethod: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() notifyAddCard: EventEmitter<any> = new EventEmitter<any>();
  @Input() paymentMethods: IPaymentMethod[];
  @Input() address: IStripeBillingAddress;
  @Input() clientSecret: string;
  @Input() transactions: any;
  @Input() displayColumns: string[];

  constructor() {}

  ngOnInit() {
    this.displayColumns = ["date", "card", "amount", "receipt"];
  }
}
