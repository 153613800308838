import { SearchActionTypes, SearchActions } from '../actions/search.actions';
import { ISearchPagination } from '@fusion/common';
import { ISearch, SearchOrderType } from '../../models';
import { Action } from '@ngrx/store';

export interface SearchState {
  search: ISearch;
  followUpContentLoadAction: Action;
}

export const initialState: SearchState = {
  search: {
    active: true,
    keywords: null,
    types: [],
    levels: [],
    category: null,
    order: SearchOrderType.DESC,
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      offset: 0,
    },
  },
  followUpContentLoadAction: null,
};

export function reducer(
  state = initialState,
  action: SearchActions
): SearchState {
  switch (action.type) {
    case SearchActionTypes.SearchStart: {
      return {
        ...state,
        followUpContentLoadAction: action.followUpAction,
      };
    }
    case SearchActionTypes.SetSearchKeywords: {
      return {
        ...state,
        search: {
          ...state.search,
          keywords: action.payload,
        },
      };
    }
    case SearchActionTypes.SetSearchLevel: {
      const currentLevel = state.search.levels.find(
        (t) => t === action.payload
      );
      const levels = state.search.levels.filter((t) => t !== action.payload);

      if (currentLevel) {
        return {
          ...state,
          search: {
            ...state.search,
            levels,
          },
        };
      }
      return {
        ...state,
        search: {
          ...state.search,
          levels: [...state.search.levels, action.payload],
        },
      };
    }
    case SearchActionTypes.SetSearchType: {
      const currentType = state.search.types.find((t) => t === action.payload);
      const types = state.search.types.filter((t) => t !== action.payload);

      if (currentType) {
        return {
          ...state,
          search: {
            ...state.search,
            types,
          },
        };
      }
      return {
        ...state,
        search: {
          ...state.search,
          types: [...state.search.types, action.payload],
        },
      };
    }
    case SearchActionTypes.SetSearchCategory: {
      return {
        ...state,
        search: {
          ...state.search,
          category: action.payload,
        },
      };
    }
    case SearchActionTypes.SetOrderBy: {
      return {
        ...state,
        search: {
          ...state.search,
          order: action.payload,
        },
      };
    }
    case SearchActionTypes.SetSearchPagination: {
      return {
        ...state,
        search: {
          ...state.search,
          pagination: action.payload,
        },
      };
    }
    case SearchActionTypes.RestoreSearchSuccess: {
      return {
        ...state,
        search: {
          ...state.search,
          keywords: action.payload.keywords,
          types: action.payload.types,
          levels: action.payload.levels,
          order: action.payload.order,
          category: action.payload.category,
          pagination: action.payload.pagination,
        },
      };
    }
  }
  return state;
}
