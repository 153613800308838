import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap } from 'rxjs/operators';

import { of } from 'rxjs';
import { SetError } from '@fusion/error';
import {
  ApplicationsActionTypes,
  oAuthActionTypes,
  SignUpActionTypes,
  UserProfileActionTypes,
} from '../actions/index';

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions) {}

  
  catchError$ = createEffect(() => this.actions$.pipe(
    ofType(
      ApplicationsActionTypes.LoadApplicationsFail,
      oAuthActionTypes.SessionRestoreFail,
      oAuthActionTypes.TokenDecodeFail,
      oAuthActionTypes.TokenRefreshFail,
      oAuthActionTypes.oAuthFail,
      oAuthActionTypes.PasswordResetRequestFail,
      oAuthActionTypes.ResetPasswordFail,
      oAuthActionTypes.ValidateResetPasswordTokenFail,
      SignUpActionTypes.SignUpFail,
      UserProfileActionTypes.LoadUserProfileFail
    ),
    mergeMap((action: any) => {
      return of(new SetError(action.payload));
    })
  ));
}
