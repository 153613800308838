<div *ngIf="formConfigs && formValidationRules" class="col3">
  <ng-container *ngFor="let config of formConfigs">
    <div class="col3__section-one">
      <fusion-form-text-field
        *ngIf="config.name == 'salary'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
    <div class="col3__section-two">
      <fusion-form-dropdown
        *ngIf="config.name == 'currencyCode'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
    <div class="col3__section-three">
      <fusion-form-dropdown
        *ngIf="config.name == 'salaryUnit'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
  </ng-container>
</div>
