import { Action } from '@ngrx/store';

export enum RecruitingAgenciesActionTypes {
  LoadRecruitingAgencies = '[Recruiting agencies] Load',
  LoadRecruitingAgenciesSuccess = '[Recruiting agencies] Load Success',
  LoadRecruitingAgenciesFail = '[Recruiting agencies] Load Fail'
}

export class LoadRecruitingAgencies implements Action {
  readonly type = RecruitingAgenciesActionTypes.LoadRecruitingAgencies;
}

export class LoadRecruitingAgenciesSuccess implements Action {
  readonly type = RecruitingAgenciesActionTypes.LoadRecruitingAgenciesSuccess;

  constructor( public payload: any ) { }
}

export class LoadRecruitingAgenciesFail implements Action {
  readonly type = RecruitingAgenciesActionTypes.LoadRecruitingAgenciesFail;
}

export type RecruitingAgenciesActions =
  | LoadRecruitingAgencies
  | LoadRecruitingAgenciesSuccess
  | LoadRecruitingAgenciesFail;
