<div *ngIf="formConfigs && formValidationRules" class="col2">
  <ng-container *ngFor="let config of formConfigs">
    <div class="col2__section-one">
      <fusion-form-datepicker
        *ngIf="config.name == 'start'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-datepicker>
    </div>
    <div class="col2__section-two">
      <fusion-form-datepicker
        *ngIf="config.name == 'end'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-datepicker>
    </div>
  </ng-container>
</div>
