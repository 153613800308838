import { Component, OnInit, Input, EventEmitter, Output, Inject } from "@angular/core";
import { ImageType } from "../../models/enums";
import { IFileUpload } from "../../models/interfaces";
import ResizeImage from "image-resize";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "fusion-common-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"]
})
export class ImageUploadComponent implements OnInit {
  @Output() notifyImageUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyCheckout: EventEmitter<any> = new EventEmitter<any>();
  @Input() image: string | ArrayBuffer;
  @Input() imageType: ImageType;
  @Input() uploadTitle: string;
  @Input() isActiveProperty: boolean = true;
  @Input() isUploading: boolean;
  @Input() isResizeDisabled: boolean = false;

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  ngOnInit() {}

  onFileChange(event) {
    let currentClassInstance = this;
    const imageInput: any = this._document.getElementById("cover-image__file-input");
    const reader = new FileReader();
    const file = imageInput.files[0];
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (this.isResizeDisabled) {
          const imagePayload: IFileUpload = {
            blob: file,
            name: file.name,
            type: file.type
          };
          currentClassInstance.notifyImageUpload.emit(imagePayload);
          return;
        }

        const image: any = new Image();
        image.src = reader.result;

        //Validate the File Height and Width.
        image.onload = function() {
          const resizeImage = new ResizeImage({
            width: this.width && this.width > 1500 ? 1500 : this.width,
            quality: currentClassInstance.getResizeQuality(file.size / 1000),
            outputType: "blob"
          });
          resizeImage
            .play(imageInput)
            .then(function(response) {
              const imagePayload: IFileUpload = {
                blob: response,
                name: file.name,
                type: file.type
              };
              currentClassInstance.notifyImageUpload.emit(imagePayload);
            })
            .catch(function(error) {
              console.error(error);
            });
        };
      };
    }

    return;
  }

  getResizeQuality(size: number) {
    if (size < 300) {
      return 1;
    }
    if (size > 300 && size < 500) {
      return 0.92;
    }
    if (size > 500 && size < 800) {
      return 0.88;
    }
    return 0.85;
  }
}
