import { IJob } from '../../models/interfaces';
import {
  CurrentOpenPositionActionTypes,
  CurrentOpenPositionActions,
} from '../actions/current-open-position.actions';

export interface CurrentOpenPositionState {
  data: IJob;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CurrentOpenPositionState = {
  data: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: CurrentOpenPositionActions
): CurrentOpenPositionState {
  switch (action.type) {
    case CurrentOpenPositionActionTypes.LoadCurrentOpenPosition: {
      return {
        ...state,
        loading: true,
      };
    }
    case CurrentOpenPositionActionTypes.LoadCurrentOpenPositionSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case CurrentOpenPositionActionTypes.LoadCurrentOpenPositionFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
