<fusion-common-back
  [currentRootPath]="currentRootPath$ | async"
  [buttonText]="'back'"
></fusion-common-back>

<div pageSpacing extraLargePage pageBody>
  <div pageTitle>
    <!-- <h1>Job Application!</h1> -->
  </div>
  <div pageContent>
    <ng-container *ngIf="jobApplication$ | async as jobApplication">
      <div content class="rowN">
        <div primaryFontColor class="col2">
          <div smallProfileImage>
            <img
              imageFit
              src="{{
                jobApplication?.applicant?.profilePicture ||
                  '/assets/images/job/job-banner-9.jpg'
              }}"
            />
          </div>
          <div class="application_header col2">
            <div>
              <div mediumTitle semiBold>
                {{ jobApplication?.applicant?.firstName }}
                {{ jobApplication?.applicant?.lastName }}
              </div>
              <div microTitle>
                {{ jobApplication.createdAt | fancyTime }}
              </div>
            </div>

            <div centering centerRight>
              <div centering center>
                <div>
                  <div semiBold extraSmallTitle>
                    {{ (screenings$ | async).length || '-'
                    }}<sup>{{
                      (screenings$ | async).length | ordinalNumber
                    }}</sup>
                  </div>
                  <div picoFont>Round Screening</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row2">
          <div extraSmallTitle>
            <span semiBold>Applied for:</span>
            <span> {{ jobApplication?.job?.position }}</span>
          </div>
          <div>
            <div extraSmallTitle semiBold>Cover Letter</div>
            <fusion-common-description
              [description]="jobApplication.coverLetter"
              [defaultLength]="250"
            ></fusion-common-description>
          </div>
        </div>

        <!-- screening -->
        <div extraSmallTitle semiBold>Application Screening</div>

        <ng-container *ngFor="let screening of screenings$ | async">
          <div border radius bgGray1 class="row3">
            <div cardSpacing bottomBorder class="col3">
              <div semiBold>
                {{ screening.round
                }}<sup>{{ screening?.round | ordinalNumber }}</sup> Round
              </div>
              <div centering>
                <span center chip light live>{{ screening.status }}</span>
              </div>
              <div></div>
            </div>
            <div cardSpacing>
              <!-- interview options -->
              <ng-container *ngIf="screening?.interview">
                <!-- interview readonly -->
                <ng-container [ngSwitch]="screening.status">
                  <ng-container
                    *ngSwitchCase="ApplicationStatus.PhoneScreening"
                  >
                    <phone-screening
                      [isPreview]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></phone-screening>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="ApplicationStatus.VideoInterview"
                  >
                    <video-interview
                      [isPreview]="true"
                      [isApplicant]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></video-interview>
                  </ng-container>
                  <ng-container *ngSwitchCase="ApplicationStatus.InPerson">
                    <in-person
                      [isPreview]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></in-person>
                  </ng-container>
                  <ng-container *ngSwitchCase="ApplicationStatus.JobOffer">
                    <job-offer
                      [isPreview]="true"
                      [company]="jobApplication.company"
                      [applicant]="jobApplication.applicant"
                      [currentScreening]="screening"
                      (notifyScreeningUpdate)="onNotifyScreeningUpdate($event)"
                    ></job-offer>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <!-- TODO may be fetch error component -->
                    <div class="activity__empty"></div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <div topBorder cardSpacing class="action-buttons col2">
              <div microTitle centering centerLeft class="col2__section-one">
                <span semiBold>Last Updated</span>
                <span>: {{ screening.updatedAt | fancyTime }}</span>
              </div>
              <div centering center class="col2__section-two">
                <a
                  class="custom-round-button"
                  mat-raised-button
                  color="warn"
                  [disabled]="screening?.interview?.hasAccepted"
                  (click)="onAcceptInterviewScreening(screening.id)"
                  >Accept</a
                >
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div adSpace></div>
  </div>
</div>
