import { IError } from '@fusion/error';
import { Action } from '@ngrx/store';
import { FusionPaymentError } from '../../models/enums';
import { ICustomer, IPaymentMethod } from '../../models/interfaces';

export enum CustomerActionTypes {
  LoadCustomer = '[Customer] Load',
  LoadCustomerSuccess = '[Customer] Load Success',
  LoadCustomerFail = '[Customer] Load Fail',
  SetupCustomer = '[Customer] Setup',
  SetupCustomerSuccess = '[Customer] Setup Success',
  SetupCustomerFail = '[Customer] Setup Fail',
  AddCard = '[Customer] Add Card',
  AddCardSuccess = '[Customer] Add Card Success',
  AddCardFail = '[Customer] Add Card Fail',
  ConfirmCard = '[Customer] Confirm Card',
  ConfirmCardSuccess = '[Customer] Confirm Card Success',
  ConfirmCardFail = '[Customer] Confirm Card Fail',
}

export class LoadCustomer implements Action {
  readonly type = CustomerActionTypes.LoadCustomer;
}

export class LoadCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.LoadCustomerSuccess;

  constructor(public payload: ICustomer) {}
}

export class LoadCustomerFail implements Action {
  readonly type = CustomerActionTypes.LoadCustomerFail;
}

export class SetupCustomer implements Action {
  readonly type = CustomerActionTypes.SetupCustomer;
}

export class SetupCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.SetupCustomerSuccess;

  constructor(public payload: ICustomer) {}
}

export class SetupCustomerFail implements Action {
  readonly type = CustomerActionTypes.SetupCustomerFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class AddCard implements Action {
  readonly type = CustomerActionTypes.AddCard;
}

export class AddCardSuccess implements Action {
  readonly type = CustomerActionTypes.AddCardSuccess;

  constructor(public payload: IPaymentMethod) {}
}

export class AddCardFail implements Action {
  readonly type = CustomerActionTypes.AddCardFail;
}

export class ConfirmCard implements Action {
  readonly type = CustomerActionTypes.ConfirmCard;

  constructor(public payload: string) {}
}

export class ConfirmCardSuccess implements Action {
  readonly type = CustomerActionTypes.ConfirmCardSuccess;

  constructor(public payload: IPaymentMethod) {}
}

export class ConfirmCardFail implements Action {
  readonly type = CustomerActionTypes.ConfirmCardFail;
}

export type CustomerActions =
  | LoadCustomer
  | LoadCustomerSuccess
  | LoadCustomerFail
  | SetupCustomer
  | SetupCustomerSuccess
  | SetupCustomerFail
  | AddCard
  | AddCardSuccess
  | AddCardFail
  | ConfirmCard
  | ConfirmCardSuccess
  | ConfirmCardFail;
