import { IForm, FormFieldType, FormFieldPattern } from '@fusion/form';

export const UpdateUserPasswordForm: IForm = {
  metadata: {
    name: 'UPDATE_USER_PROFILE_FORM',
    title: 'Update User Password',
    submitButtonText: 'Update',
  },
  configs: [
    {
      label: 'Password',
      name: 'password',
      type: FormFieldType.Password,
      placeholder: 'password',
      examplePattern: 'at least 1 uppercase, 1 lowercase, 1 special case char and 1 number',
      validation: {
        required: true,
        pattern: FormFieldPattern.Password,
        min: 8,
        max: 50,
      },
    },
  ],
};
