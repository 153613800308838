<div pageSpacing extraLargePage pageBody>
  <ng-container *ngIf="funding$ | async as funding">
    <div *ngIf="funding.title" pageTitle>
      <h1>{{ funding.title }}s!</h1>
    </div>
    <div pageContent>
      <div content class="row2">
        <!-- contribution card -->
        <div *ngIf="funding.title" card class="row2__section-one col2">
          <div cardSpacing rightBorder class="col2__section-one row3">
            <div mediumTitle microBold>{{ funding.title }}</div>
            <div>
              {{ funding.description1 }}
            </div>
            <div>
              {{ funding.description2 }}
            </div>
          </div>
          <div cardSpacing centering center class="col2__section-two">
            <button
              mat-raised-button
              color="primary"
              class="custom-round-button"
              (click)="onNotifyFundingSelection(funding)"
            >
              Contribute
            </button>
          </div>
        </div>
        <div class="row2__section-two row2">
          <div mediumTitle microBold class="row2__section-one">
            Contributors
          </div>
          <div class="row2__section-two colN">
            <ng-container *ngIf="contributors$ | async as contributors">
              <div logo *ngFor="let contributor of contributors">
                <img
                  border
                  radius
                  imageFit
                  matTooltip="{{ contributor.financierName }}"
                  [matTooltipPosition]="'above'"
                  src="{{
                    contributor.logo ||
                      '/assets/images/users/company-logo/logoPlaceholder.png'
                  }}"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div adSpace></div>
    </div>
  </ng-container>
</div>
