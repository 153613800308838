import {
  Component,
  ViewChild,
  TemplateRef,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { addMinutes, isSameDay, isSameMonth } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DAYS_OF_WEEK,
} from 'angular-calendar';
import { rawEvents } from '../../models';
import { CalendarState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import {
  AddEventGuest,
  CreateEvent,
  CreateVideoConference,
  DeleteCurrentEvent,
  getApplicant,
  getApplicantGuest,
  getCurrentEvent,
  getCurrentGuest,
  getEvents,
  getGuests,
  getGuestSummary,
  JoinVideoConference,
  LoadEvents,
  LoadEventsSuccess,
  SaveCurrentEvent,
  SetCurrentEvent,
  UpdateCurrentEvent,
  UpdateJoiningStatus,
} from '../../store';
import { MatDialog } from '@angular/material/dialog';
import { CreateEventComponent } from '../../components/create-event/create-event.component';
import { JoinEventComponent } from '../../components/join-event/join-event.component';
import { IUser } from '@fusion/common';
import { getUsers, SearchUsers } from '@fusion/company';
import { debounceTime, tap } from 'rxjs/operators';
import {
  ICalendarEventMetadata,
  ICreateEventPayload,
  IEventGuest,
  IEventUpdatePayload,
  IGuestSummary,
  IVideoConferenceMetadata,
} from '../../models/interfaces';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, OnDestroy {
  users$: Observable<IUser[]>;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  calendarEvents$: Observable<CalendarEvent<ICalendarEventMetadata>[]>;
  currentEvent$: Observable<CalendarEvent<ICalendarEventMetadata>>;
  currentGuest$: Observable<IEventGuest>;
  guests$: Observable<IEventGuest[]>;
  guestSummary$: Observable<IGuestSummary>;
  applicantGuest$: Observable<IEventGuest>;
  breakpoint$: Observable<BreakpointState>;

  view: CalendarView = CalendarView.Week;
  daysInWeek = 7;

  CalendarView = CalendarView;
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh = new Subject<void>();
  rawEvents: CalendarEvent[] = rawEvents;
  activeDayIsOpen: boolean = true;

  private destroy$ = new Subject<void>();
  CALENDAR_RESPONSIVE = {
    small: {
      breakpoint: '(max-width: 576px)',
      daysInWeek: 2,
    },
    medium: {
      breakpoint: '(max-width: 768px)',
      daysInWeek: 3,
    },
    large: {
      breakpoint: '(max-width: 960px)',
      daysInWeek: 5,
    },
  };

  constructor(
    public dialog: MatDialog,
    private store: Store<CalendarState>,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadEvents());

    this.users$ = this.store.select(getUsers);
    this.calendarEvents$ = this.store.select(getEvents);
    this.currentEvent$ = this.store.select(getCurrentEvent);
    this.guests$ = this.store.select(getGuests);
    this.guestSummary$ = this.store.select(getGuestSummary);
    this.currentGuest$ = this.store.select(getCurrentGuest);
    this.applicantGuest$ = this.store.select(getApplicant);

    this.breakpointObserver
      .observe(
        Object.values(this.CALENDAR_RESPONSIVE).map(
          ({ breakpoint }) => breakpoint
        )
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(this.CALENDAR_RESPONSIVE).find(
          ({ breakpoint }) => !!state.breakpoints[breakpoint]
        );
        if (foundBreakpoint) {
          this.daysInWeek = foundBreakpoint.daysInWeek;
        } else {
          this.daysInWeek = 7;
        }
        this.cd.markForCheck();
      });
  }

  onNotifyAutocompletion(data) {
    this.store.dispatch(new SearchUsers(data));
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  hourSegmentClicked(event) {
    const createEventPayload: ICreateEventPayload = {
      start: event.date,
      end: addMinutes(new Date(event.date), 60),
    };
    this.store.dispatch(new CreateEvent(createEventPayload));
    this.createEvent();
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.store.dispatch(new SetCurrentEvent(event));
    this.joinEvent(event);
  }

  addEvent(): void {
    // const createEventPayload: ICreateEventPayload = {
    //   // start: event.date,
    //   // end: addMinutes(new Date(event.date), 60),
    // };
    this.store.dispatch(new CreateEvent());
    this.createEvent();
  }

  createEvent() {
    const dialogRef = this.dialog.open(CreateEventComponent, {
      width: '550px',
      maxHeight: '80vh',
      data: {
        event: this.currentEvent$,
        users: this.users$,
      },
    });

    dialogRef.componentInstance.notifyAutocompletion
      .pipe(debounceTime(500))
      .subscribe((inputData: string) => {
        this.store.dispatch(new SearchUsers(inputData));
      });
    dialogRef.componentInstance.notifyGuestUser.subscribe(
      (guestUser: IUser) => {
        const guestPayload: IEventGuest = {
          user: guestUser,
        };
        this.store.dispatch(new AddEventGuest(guestPayload));
      }
    );
    dialogRef.componentInstance.notifyEventUpdate.subscribe(
      (updatePayload: IEventUpdatePayload) => {
        this.store.dispatch(new UpdateCurrentEvent(updatePayload));
      }
    );
    dialogRef.componentInstance.notifyCreateVideoConference.subscribe(
      (updatePayload: IVideoConferenceMetadata) => {
        this.store.dispatch(new CreateVideoConference(updatePayload));
      }
    );
    dialogRef.componentInstance.notifyJoinVideoConference.subscribe(
      (videoConferencePayload) => {
        this.store.dispatch(new JoinVideoConference(videoConferencePayload));
      }
    );
    dialogRef.componentInstance.notifySave.subscribe(() => {
      this.store.dispatch(new SaveCurrentEvent());
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  joinEvent(event: CalendarEvent) {
    const dialogRef = this.dialog.open(JoinEventComponent, {
      width: '550px',
      maxHeight: '80vh',
      data: {
        event,
        currentGuest: this.currentGuest$,
        guests: this.guests$,
        guestSummary: this.guestSummary$,
        applicantGuest: this.applicantGuest$,
      },
    });

    dialogRef.componentInstance.notifyJoiningStatusUpdate.subscribe(
      (status) => {
        this.store.dispatch(new UpdateJoiningStatus(status));
      }
    );

    dialogRef.componentInstance.notifyDeleteEvent.subscribe((status) => {
      this.store.dispatch(new DeleteCurrentEvent());
    });

    dialogRef.componentInstance.notifyJoinVideoConference.subscribe(
      (videoConferencePayload) => {
        this.store.dispatch(new JoinVideoConference(videoConferencePayload));
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    // this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
