import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ICompany } from '@fusion/company';
import {
  ErrorActionType,
  ErrorHandlingType,
  ErrorSource,
  IError,
} from '@fusion/error';
import { getoAuthUserId, getSubscriberCompany } from '@fusion/oauth';
import { AddOrderItem, IOrderItemPayload } from '@fusion/payment';
import { getRouterParams, getRouterUrlBase } from '@fusion/router';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map,
} from 'rxjs/operators';
import { jobDetailsData } from '../../models/data';
import { FusionJobError } from '../../models/enums';
import { IDetail, IJob } from '../../models/interfaces';

import {
  CurrentOpenPositionActionTypes,
  LoadCurrentOpenPositionSuccess,
  LoadCurrentOpenPositionFail,
  LoadCurrentOpenPosition,
  UpdateCurrentOpenPosition,
  UpdateCurrentOpenPositionSuccess,
  UpdateCurrentOpenPositionFail,
  UpdateCurrentOpenPositionStatus,
  UpdateCurrentOpenPositionStatusFail,
  UpdateCurrentOpenPositionStatusSuccess,
  PromoteCurrentOpenPositionSuccess,
  PromoteCurrentOpenPosition,
  PromoteCurrentOpenPositionFail,
} from '../actions/current-open-position.actions';
import { FusionJobState } from '../reducers';
import { getCurrentOpenPosition } from '../selectors';

@Injectable()
export class CurrentOpenPositionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionJobState>,
    private jobService: JobService,
    private mappingService: MappingService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  
  loadCurrentOpenPosition$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentOpenPositionActionTypes.LoadCurrentOpenPosition),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([action, params]: [any, Params]) => {
      const currentOpenPositionId = params.openPositionId;
      let errorPayload: IError<FusionJobError> = {
        code: FusionJobError.LoadCurrentJobFail,
        source: ErrorSource.Validation,
        data: null,
      };
      return this.jobService.getOpenPosition(currentOpenPositionId).pipe(
        switchMap((result) => {
          const mappedJob = this.mappingService.getMappedData<IJob>(
            result,
            mappingType.camelize
          );
          return [new LoadCurrentOpenPositionSuccess(mappedJob)];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading current open position. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadCurrentOpenPosition()],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new LoadCurrentOpenPositionFail(errorPayload));
        })
      );
    })
  ));

  
  updateCurrentOpenPosition$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCurrentOpenPosition>(
      CurrentOpenPositionActionTypes.UpdateCurrentOpenPosition
    ),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getCurrentOpenPosition),
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId),
      this.store.select(getSubscriberCompany)
    ),
    mergeMap(
      ([payload, currentOpenPosition, params, userId, company]: [
        IJob,
        IJob,
        Params,
        string,
        ICompany
      ]) => {
        let errorPayload: IError<FusionJobError> = {
          code: FusionJobError.UpdateCurrentOpenPositionFail,
          source: ErrorSource.Validation,
          data: null,
        };
        const openPositionId = params.openPositionId;
        const companyId = params.subscriberId;
        const updatedJob = this.getMappedOpenPosition(
          userId,
          companyId,
          currentOpenPosition,
          payload,
          company
        );

        const mappedOpenPositionJob = this.mappingService.getMappedData<any>(
          updatedJob,
          mappingType.underscore
        );

        delete mappedOpenPositionJob.company_id;
        delete mappedOpenPositionJob.likes;
        delete mappedOpenPositionJob.applications;
        delete mappedOpenPositionJob.default_address;
        return this.jobService
          .updateOpenPosition(openPositionId, mappedOpenPositionJob)
          .pipe(
            switchMap((dataResult: any) => {
              return [
                new UpdateCurrentOpenPositionSuccess(dataResult),
                new LoadCurrentOpenPosition(),
              ];
            }),
            catchError((error) => {
              errorPayload = {
                ...errorPayload,
                source: ErrorSource.API,
                data: error,
                config: {
                  type: ErrorHandlingType.Dialog,
                  message:
                    'Sorry, we are having some issue updating your Open Position. Please try again later.',
                  action: {
                    primary: {
                      type: ErrorActionType.Dispatch,
                      reference: [new UpdateCurrentOpenPosition(payload)],
                      title: 'Retry',
                    },
                  },
                },
              };
              return of(new UpdateCurrentOpenPositionFail(errorPayload));
            })
          );
      }
    )
  ));

  
  updateCurrentOpenPositionStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCurrentOpenPositionStatus>(
      CurrentOpenPositionActionTypes.UpdateCurrentOpenPositionStatus
    ),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentOpenPosition)),
    mergeMap(([isActive, currentPosition]: [boolean, IJob]) => {
      let errorPayload: IError<FusionJobError> = {
        code: FusionJobError.UpdateCurrentOpenPositionFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const openPositionId = currentPosition.id;
      const openPositionStatus = {
        active: isActive,
      };
      return this.jobService
        .updateOpenPositionStatus(openPositionId, openPositionStatus)
        .pipe(
          switchMap((dataResult: any) => {
            return [
              new UpdateCurrentOpenPositionStatusSuccess(dataResult),
              new LoadCurrentOpenPosition(),
            ];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue updating your Open Position. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new UpdateCurrentOpenPositionStatus(isActive)],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new UpdateCurrentOpenPositionStatusFail(errorPayload));
          })
        );
    })
  ));

  
  promoteOpenPosition$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentOpenPositionActionTypes.PromoteCurrentOpenPosition),
    withLatestFrom(
      this.store.select(getCurrentOpenPosition),
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId),
      this.store.select(getRouterUrlBase)
    ),
    mergeMap(
      ([payload, openPosition, params, userId, path]: [
        any,
        IJob,
        Params,
        string,
        string
      ]) => {
        let errorPayload: IError<FusionJobError> = {
          code: FusionJobError.AddJobFail,
          source: ErrorSource.Validation,
          data: null,
        };
        const rootPath = `/${this.getCleanedPath(path)}`;
        const companyId = params.subscriberId;
        const mappedOpenPosition = this.mappingService.getMappedData(
          openPosition,
          mappingType.underscore
        );
        const mappedJob = this.getMappedJob(
          userId,
          companyId,
          mappedOpenPosition
        );
        delete mappedJob.id;
        delete mappedJob.allow_recruiter;
        delete mappedJob.created_at;
        delete mappedJob.updated_at;
        return this.jobService.postJob(mappedJob).pipe(
          switchMap((dataResult: any) => {
            const itemData: IOrderItemPayload = {
              productId: 'e083e16b-acd6-4037-ba2d-84cf2d6d8c1a',
              quantity: 1,
              orderReferenceId: dataResult.id,
            };
            this.router.navigate([`${rootPath}/jobs/${dataResult.id}`]);
            return [
              new PromoteCurrentOpenPositionSuccess(dataResult),
              new AddOrderItem(itemData),
            ];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue promoting this Position. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new PromoteCurrentOpenPosition()],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new PromoteCurrentOpenPositionFail(errorPayload));
          })
        );
      }
    )
  ));

  getMappedJob(userId: string, companyId: string, openPositionRaw: any) {
    return {
      ...openPositionRaw,
      active: false,
      position_id: openPositionRaw.id,
      user_id: userId,
      company_id: companyId,
      client_company_id: openPositionRaw.company_id,
    };
  }

  getCleanedPath(path: string) {
    const parts = path.split('/');
    return parts[1];
  }

  getMappedOpenPosition(
    userId: string,
    companyId: string,
    currentJob: IJob,
    jobRaw: any,
    company: ICompany
  ) {
    return {
      ...currentJob,
      ...jobRaw,
      companyId,
      userId,
      companyName: company.name,
      jobDetails: this.getMappedOpenPositionDetails(
        currentJob.jobDetails,
        jobRaw.jobDetails || []
      ),
    };
  }

  getMappedOpenPositionDetails(
    jobDetails: IDetail[],
    jobDetailsPayload: IDetail[]
  ) {
    let updatedJobDetails = jobDetails.map((detail) => {
      const mappedDetail = jobDetailsPayload.find(
        (d) => d.title === detail.title
      );
      if (mappedDetail) {
        return {
          ...detail,
          content: mappedDetail.content,
        };
      }
      return {
        ...detail,
      };
    });

    jobDetailsPayload.map((d) => {
      const detail = updatedJobDetails.find(
        (updatedDetail) => updatedDetail.title === d.title
      );
      if (!detail) {
        updatedJobDetails = [
          ...updatedJobDetails,
          {
            ...d,
          },
        ];
      }
    });

    // sorting
    let sortedDetails = [];
    jobDetailsData.map((detailData) => {
      const item = updatedJobDetails.find(
        (updatedDetail) => updatedDetail.title === detailData.title
      );
      if (item) {
        sortedDetails = [
          ...sortedDetails,
          {
            ...item,
          },
        ];
      }
    });

    return sortedDetails;
  }
}
