import { UntypedFormGroup, AbstractControl, UntypedFormControl } from '@angular/forms';
import { IFormConfig } from '../interfaces';
import { FormFieldType } from '../enums';

let isValidationRulesUpdated = false;

export function getValidationRulesWithPresetValue(
  validationRules: UntypedFormGroup,
  formConfigs: IFormConfig[],
  existingValues: any
) {
  if (existingValues && !isValidationRulesUpdated) {
    formConfigs?.map((formField) => {
      switch (formField.type) {
        case FormFieldType.Group: {
          formField.configs.map((field) => {
            setFieldValue(field, validationRules, existingValues);
          });
          break;
        }
        default: {
          setFieldValue(formField, validationRules, existingValues);
        }
      }
    });
    // isValidationRulesUpdated = true;
  }

  return validationRules;
}

function setFieldValue(
  formField: IFormConfig | any,
  validationRules: UntypedFormGroup,
  existingValues: any
) {
  const currentValue = existingValues[formField.name];
  const currentFormControl = validationRules.controls[formField.name];
  if (existingValues.id) {
    validationRules.addControl('id', new UntypedFormControl(''));
    validationRules.controls['id'].setValue(existingValues.id);
  }

  switch (formField.type) {
    case FormFieldType.Text: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Textarea: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Number: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Date: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Email: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Password: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Phone: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Autocomplete: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Url: {
      currentFormControl.setValue(currentValue);
      break;
    }
    case FormFieldType.Ckeditor: {
      currentFormControl.patchValue(null, {
        onlySelf: true,
      });
      currentFormControl.patchValue(currentValue, {
        onlySelf: true,
      });
      break;
    }
    case FormFieldType.Checkbox: {
      setCheckboxValue(formField, validationRules, existingValues);
      break;
    }
    case FormFieldType.Radio: {
      setRadioValue(formField, validationRules, existingValues);
      break;
    }
    case FormFieldType.Dropdown: {
      setDropdownValue(formField, currentFormControl, currentValue);
      break;
    }
  }
}

function setCheckboxValue(
  formField: IFormConfig,
  validationRules: UntypedFormGroup,
  existingValues: any
) {
  formField?.items?.map((item) => {
    const exist =
      existingValues instanceof Array
        ? existingValues.find((i) => i.name == item.name)
        : existingValues[item.name];
    if (exist) {
      validationRules.controls[item.name].setValue(true);
    }
  });
}

function setRadioValue(
  formField: IFormConfig,
  validationRules: UntypedFormGroup,
  existingValues: any
) {
  formField.options.map((item) => {
    const value = existingValues[formField.name];
    if (value == item.label) {
      validationRules.controls[formField.name].setValue(item.value);
    }
  });
}

function setDropdownValue(
  formField: IFormConfig,
  currentFormControl: AbstractControl,
  currentValue: any
) {
  if (formField.isMultiSelectDropdown && currentValue) {
    const keys = currentValue.map((item) => (item.title ? item.title : item));
    currentFormControl.setValue(keys);
  } else {
    const value =
      currentValue && currentValue.title ? currentValue.title : currentValue;
    currentFormControl.setValue(value);
  }
}
