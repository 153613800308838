import { NgModule } from '@angular/core';
import { SideNavComponent } from './side-nav/side-nav.component';
import { FooterComponent } from './footer/footer.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PaginatorComponent } from './paginator/paginator.component';
import { MetadataComponent } from './metadata/metadata.component';
import { SortComponent } from './sort/sort.component';
import { CarouselComponent } from './carousel/carousel.component';
import { RouterModule } from '@angular/router';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { CardFooterComponent } from './card-footer/card-footer.component';
import { SocialIconsComponent } from './social-icons/social-icons.component';
import { ProfileNavComponent } from './profile-nav/profile-nav.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { DescriptionComponent } from './description/description.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { BackComponent } from './back/back.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImagesPreviewComponent } from './images-preview/images-preview.component';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { LogoUploadComponent } from './logo-upload/logo-upload.component';
import { BannerImageCropComponent } from './banner-image-crop/banner-image-crop.component';
import { BannerImageUploadComponent } from './banner-image-upload/banner-image-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';

// import 'hammerjs'; // Mandatory for angular-modal-gallery 3.x.x or greater (`npm i --save hammerjs`)
// import 'mousetrap'; // Mandatory for angular-modal-gallery 3.x.x or greater

@NgModule({
  declarations: [
    SideNavComponent,
    FooterComponent,
    CardHeaderComponent,
    PaginatorComponent,
    MetadataComponent,
    SortComponent,
    CarouselComponent,
    DialogHeaderComponent,
    ExpansionPanelComponent,
    CardFooterComponent,
    SocialIconsComponent,
    ProfileNavComponent,
    ImageGalleryComponent,
    EmptyStateComponent,
    DescriptionComponent,
    PageTitleComponent,
    BackComponent,
    ImageUploadComponent,
    ImagesPreviewComponent,
    ImageCropComponent,
    LogoUploadComponent,
    BannerImageCropComponent,
    BannerImageUploadComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    MatIconModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatSidenavModule,
    MatListModule,
    MatChipsModule,
    MatButtonModule,
    AngularCropperjsModule,
    GalleryModule.forRoot(),
  ],
  exports: [
    SideNavComponent,
    FooterComponent,
    CardHeaderComponent,
    PaginatorComponent,
    MetadataComponent,
    SortComponent,
    CarouselComponent,
    DialogHeaderComponent,
    ExpansionPanelComponent,
    CardFooterComponent,
    SocialIconsComponent,
    ProfileNavComponent,
    ImageGalleryComponent,
    EmptyStateComponent,
    DescriptionComponent,
    PageTitleComponent,
    BackComponent,
    ImageUploadComponent,
    ImagesPreviewComponent,
    ImageCropComponent,
    LogoUploadComponent,
    BannerImageCropComponent,
  ],
})
export class FusionCommonModule {}
