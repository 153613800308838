import { OrderActionTypes, OrderActions } from '../actions/order.actions';
import { IDiscount, IOrder } from '../../models/interfaces';

export interface OrderState {
  order: IOrder;
  pendingOrders: IOrder[];
  discount: IDiscount;
  loading: boolean;
  loaded: boolean;
}

export const initialState: OrderState = {
  order: null,
  pendingOrders: null,
  discount: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: OrderActions
): OrderState {
  switch (action.type) {
    case OrderActionTypes.LoadOrder: {
      return {
        ...state,
        loading: true,
      };
    }
    case OrderActionTypes.LoadOrderSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        order: action.payload,
      };
    }
    case OrderActionTypes.LoadPendingOrderSuccess: {
      return {
        ...state,
        pendingOrders: action.payload,
      };
    }
    case OrderActionTypes.LoadOrderFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case OrderActionTypes.SetOrderDiscunt: {
      return {
        ...state,
        discount: action.discunt,
      };
    }
  }
  return state;
}
