<div cardHrSpacing primaryFontColor class="header col2">
  <div centering center largeLogo class="col2__section-one">
    <img
      imageFit
      src="{{
        company?.logo || '/assets/images/users/company-logo/logoPlaceholder.png'
      }}"
    />
  </div>

  <div class="col2__section-two row3">
    <div class="row3__section-one">
      <span largeTitle bold>{{ company?.name }}</span>
    </div>

    <div microTitle class="row3__section-two">
      <span errorFontColor>{{ company?.category }}</span>

      <span dot></span>
      <span *ngIf="company.address">{{ company.address }}, </span>
      <span
        >{{ company.city }}<span *ngIf="company.state">, {{ company.state }}</span> -
        {{ company.country }}</span
      >
    </div>
    <div nanoTitle semiBold class="row3__section-three">
      Member Since {{ company.createdAt | fancyTime }}
    </div>
  </div>
</div>



