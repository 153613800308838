import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserState } from '../../store/reducers';
import { ICompany } from '@fusion/common';
import {
  LoadCurrentJob,
  getCurrentJob,
  getJobMetadata,
  IJob,
  JobApply,
} from '@fusion/job';
import { Store } from '@ngrx/store';
import { getCompany } from '@fusion/company';
import { getRouterUrlRoot } from '@fusion/router';

@Component({
  selector: 'user-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent implements OnInit {
  job$: Observable<IJob>;
  jobMetadata$: Observable<any>;
  currentRootPath$: Observable<string>;
  company$: Observable<ICompany>;

  JobApply = JobApply;

  constructor(private store: Store<UserState>) {}

  ngOnInit() {
    this.store.dispatch(new LoadCurrentJob());
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.job$ = this.store.select(getCurrentJob);
    this.jobMetadata$ = this.store.select(getJobMetadata);
    this.company$ = this.store.select(getCompany);
  }
}
