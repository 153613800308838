import { Action } from '@ngrx/store';
import {
  IOrderPayload,
  IOrderItemPayload,
  IPendingOrderPayload,
  IDiscount,
} from '../../models/interfaces';
import { IError } from '@fusion/error';
import { FusionPaymentError } from '../../models/enums';

export enum OrderActionTypes {
  AddOrder = '[Order] Add',
  OrderExist = '[Order] Exist',
  AddOrderSuccess = '[Order] Add Success',
  AddOrderFail = '[Order] Add Fail',

  LoadOrder = '[Order] Load',
  LoadOrderSuccess = '[Order] Load Success',
  LoadOrderFail = '[Order] Load Fail',

  LoadPendingOrder = '[Pending Order] Load',
  LoadPendingOrderSuccess = '[Pending Order] Load Success',
  LoadPendingOrderFail = '[Pending Order] Load Fail',

  ConfirmOrder = '[Order] Confirm',
  ConfirmOrderSuccess = '[Order] Confirm Success',
  ConfirmOrderFail = '[Order] Confirm Fail',

  AddOrderItem = '[Order] Add Item',
  AddOrderItemSuccess = '[Order] Add Item Success',
  AddOrderItemFail = '[Order] Add Item Fail',

  // used by consumer service/app
  ActivateOrderItem = '[Order] Activate Item',
  ActivateOrderItemSuccess = '[Order] Activate Item Success',
  ActivateOrderItemFail = '[Order] Activate Item Fail',

  RemoveOrderItem = '[Order] Remove Item',
  RemoveOrderItemSuccess = '[Order] Remove Item Success',
  RemoveOrderItemFail = '[Order] Remove Item Fail',

  UpdateOrderItem = '[Order] Update Item',
  UpdateOrderItemSuccess = '[Order] Update Item Success',
  UpdateOrderItemFail = '[Order] Update Item Fail',

  SetOrderDiscunt = '[Order] Set Discunt',
}

export class AddOrder implements Action {
  readonly type = OrderActionTypes.AddOrder;

  constructor(public payload: IOrderPayload) {}
}

export class AddOrderSuccess implements Action {
  readonly type = OrderActionTypes.AddOrderSuccess;

  constructor(public payload: any) {}
}

export class AddOrderFail implements Action {
  readonly type = OrderActionTypes.AddOrderFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class LoadOrder implements Action {
  readonly type = OrderActionTypes.LoadOrder;
}

export class OrderExist implements Action {
  readonly type = OrderActionTypes.OrderExist;
}

export class LoadOrderSuccess implements Action {
  readonly type = OrderActionTypes.LoadOrderSuccess;

  constructor(public payload: any) {}
}

export class LoadOrderFail implements Action {
  readonly type = OrderActionTypes.LoadOrderFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class LoadPendingOrder implements Action {
  readonly type = OrderActionTypes.LoadPendingOrder;

  constructor(public payload: IPendingOrderPayload) {}
}

export class LoadPendingOrderSuccess implements Action {
  readonly type = OrderActionTypes.LoadPendingOrderSuccess;

  constructor(public payload: any) {}
}

export class LoadPendingOrderFail implements Action {
  readonly type = OrderActionTypes.LoadPendingOrderFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class ConfirmOrder implements Action {
  readonly type = OrderActionTypes.ConfirmOrder;
}

export class ConfirmOrderSuccess implements Action {
  readonly type = OrderActionTypes.ConfirmOrderSuccess;

  constructor(public payload: any) {}
}

export class ConfirmOrderFail implements Action {
  readonly type = OrderActionTypes.ConfirmOrderFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class ActivateOrderItem implements Action {
  readonly type = OrderActionTypes.ActivateOrderItem;

  constructor(public payload: string) {}
}

export class ActivateOrderItemSuccess implements Action {
  readonly type = OrderActionTypes.ActivateOrderItemSuccess;
}

export class ActivateOrderItemFail implements Action {
  readonly type = OrderActionTypes.ActivateOrderItemFail;
}

export class AddOrderItem implements Action {
  readonly type = OrderActionTypes.AddOrderItem;

  constructor(public payload: IOrderItemPayload) {}
}

export class AddOrderItemSuccess implements Action {
  readonly type = OrderActionTypes.AddOrderItemSuccess;

  constructor(public payload: any) {}
}

export class AddOrderItemFail implements Action {
  readonly type = OrderActionTypes.AddOrderItemFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class RemoveOrderItem implements Action {
  readonly type = OrderActionTypes.RemoveOrderItem;
}

export class RemoveOrderItemSuccess implements Action {
  readonly type = OrderActionTypes.RemoveOrderItemSuccess;

  constructor(public payload: any) {}
}

export class RemoveOrderItemFail implements Action {
  readonly type = OrderActionTypes.RemoveOrderItemFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class UpdateOrderItem implements Action {
  readonly type = OrderActionTypes.UpdateOrderItem;
}

export class UpdateOrderItemSuccess implements Action {
  readonly type = OrderActionTypes.UpdateOrderItemSuccess;

  constructor(public payload: any) {}
}

export class UpdateOrderItemFail implements Action {
  readonly type = OrderActionTypes.UpdateOrderItemFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class SetOrderDiscunt implements Action {
  readonly type = OrderActionTypes.SetOrderDiscunt;

  constructor(public discunt: IDiscount) {}
}

export type OrderActions =
  | AddOrder
  | AddOrderSuccess
  | AddOrderFail
  | LoadOrder
  | OrderExist
  | LoadOrderSuccess
  | LoadOrderFail
  | LoadPendingOrder
  | LoadPendingOrderSuccess
  | LoadPendingOrderFail
  | ConfirmOrder
  | ConfirmOrderSuccess
  | ConfirmOrderFail
  | ActivateOrderItem
  | ActivateOrderItemSuccess
  | ActivateOrderItemFail
  | AddOrderItem
  | AddOrderItemSuccess
  | AddOrderItemFail
  | RemoveOrderItem
  | RemoveOrderItemSuccess
  | RemoveOrderItemFail
  | UpdateOrderItem
  | UpdateOrderItemSuccess
  | UpdateOrderItemFail
  | SetOrderDiscunt;
