import { Injectable } from '@angular/core';
import { IUser } from '@fusion/common';
import { ContentService, MappingService, mappingType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CalendarEvent } from 'angular-calendar';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map,
} from 'rxjs/operators';
import { IEventGuest, JoiningStatus, VideoConferenceRole } from '../../models';
import { AddEventGuest, LoadCurrentEvent, LoadEvents } from '../actions';

import {
  GuestsActionTypes,
  LoadGuestsSuccess,
  LoadGuestsFail,
  AddGuests,
  AddGuestsSuccess,
  AddGuestsFail,
  RemoveGuests,
  RemoveGuestsSuccess,
  RemoveGuestsFail,
} from '../actions/guests.actions';
import { CalendarState } from '../reducers';
import { getCurrentEvent } from '../selectors';

@Injectable()
export class GuestsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CalendarState>,
    private contentService: ContentService,
    private mappingService: MappingService
  ) {}

  
  loadGuests$ = createEffect(() => this.actions$.pipe(
    ofType(GuestsActionTypes.LoadGuests),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(([payload, currentEvent]: [CalendarEvent, any]) => {
      const eventId = currentEvent.id;
      return this.contentService.getGuests(eventId).pipe(
        switchMap((dataResult) => {
          const guests: IEventGuest[] = this.mappingService.getMappedData(
            dataResult,
            mappingType.camelize
          );
          return [new LoadGuestsSuccess(guests)];
        }),
        catchError((error) => of(new LoadGuestsFail()))
      );
    }),
    catchError((error) => of(new LoadGuestsFail()))
  ));

  
  addGuest$ = createEffect(() => this.actions$.pipe(
    ofType<AddGuests>(GuestsActionTypes.AddGuests),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(([currentGuest, currentEvent]: [IEventGuest, any]) => {
      const eventId: any = currentEvent.id;
      const guestPayload = {
        user_id: currentGuest?.user?.id,
        joining_status: JoiningStatus.Pending,
        guest_role: currentGuest.guestRole,
        is_organizer: false,
      };
      return this.contentService.addGuest(eventId, guestPayload).pipe(
        switchMap((dataResult: any) => {
          const newGuest: IEventGuest = {
            ...currentGuest,
            id: dataResult.id,
          };
          return [
            new AddGuestsSuccess(dataResult),
            new AddEventGuest(newGuest),
          ];
        }),
        catchError((error) => of(new AddGuestsFail()))
      );
    }),
    catchError((error) => of(new AddGuestsFail()))
  ));

  
  removeGuest$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveGuests>(GuestsActionTypes.RemoveGuests),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentEvent)),
    mergeMap(([currentGuest, currentEvent]: [IEventGuest, any]) => {
      const eventId: any = currentEvent.id;
      return this.contentService.removeGuest(eventId, currentGuest.id).pipe(
        switchMap((dataResult) => {
          return [new RemoveGuestsSuccess(dataResult), new LoadCurrentEvent()];
        }),
        catchError((error) => of(new RemoveGuestsFail()))
      );
    }),
    catchError((error) => of(new RemoveGuestsFail()))
  ));
}
