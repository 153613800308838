import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { mergeMap } from "rxjs/operators";

import { of } from "rxjs";
import { SetError } from "@fusion/error";
import {
  AddJobActionTypes,
  CurrentJobActionTypes,
  JobsActionTypes
} from "../actions/index";

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions) {}

  
  catchError$ = createEffect(() => this.actions$.pipe(
    ofType(
      AddJobActionTypes.AddJobFail,
      CurrentJobActionTypes.LoadCurrentJobFail,
      JobsActionTypes.LoadJobsFail
    ),
    mergeMap((action: any) => {
      return of(new SetError(action.payload));
    })
  ));
}
