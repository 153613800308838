<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>Job Search!</h1></div>
  <div pageContentNoAd class="row2">
    <div class="row2__section-one row2">
      <div class="row2__section-one">
        <fusion-form-search
          exMediumFont
          [searchForm]="searchForm"
          [keywords]="keywords$ | async"
          (notifySearch)="notifySearch($event)"
          (notifySearchCategory)="notifyJobCategory($event)"
        ></fusion-form-search>
      </div>
      <div class="row2__section-two">
        <fusion-search-result
          [removable]="removable"
          [keywords]="keywords$ | async"
          [searchCategory]="jobCategory$ | async"
          (notifyRemoveCategory)="notifyRemoveCategory()"
        ></fusion-search-result>
      </div>
    </div>
    <div class="row2__section-two col2">
      <div class="col2__section-one">
        <fusion-form-option-list
          [selectedJobLevels]="jobLevels$ | async"
          [selectedJobTypes]="jobTypes$ | async"
          (notifyJobType)="notifyJobType($event)"
          (notifyJobLevel)="notifyJobLevel($event)"
        ></fusion-form-option-list>
      </div>
      <div class="col2__section-two">
        <div card cardHrSpacing class="row3 t-padding-2">
          <div class="row3__section-one col2">
            <div centering class="col2__section-one">
              <fusion-common-sort
                [sortOption]="sortOption"
                [orderBy]="orderBy$ | async"
                (notifySort)="notifySort($event)"
              ></fusion-common-sort>
            </div>
            <div class="col2__section-two">
              <fusion-common-paginator
                [count]="jobCount$ | async"
                [jobPagination]="jobPagination$ | async"
                (notifyPagination)="notifyPagination($event)"
              ></fusion-common-paginator>
            </div>
          </div>
          <div topDivider class="row3__section-two">
            <fusion-job-list
              [jobs]="jobs$ | async"
              [currentRootPath]="currentRootPath$ | async"
              [parentDir]="'jobs'"
              (notifyCurrentJob)="notifyCurrentJob($event)"
            ></fusion-job-list>
          </div>
          <div class="row3__section-three">
            <fusion-common-paginator
              [count]="jobCount$ | async"
              [jobPagination]="jobPagination$ | async"
              (notifyPagination)="notifyPagination($event)"
            ></fusion-common-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
