import { createSelector } from '@ngrx/store';

import { getFusionoAuthState } from '../reducers';
import { Subdomain } from '../../models/enums';
import { IBaseUrls } from '../../models/interfaces';

export const getApplicationsState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.applications;
  }
);

export const getApplications = createSelector(getApplicationsState, (state) => {
  if (state == null) {
    return null;
  }
  return state.data;
});

export const getCompanyAppBaseUrl = createSelector(
  getApplications,
  (apps): string => {
    if (apps == null) {
      return null;
    }

    const protocol = apps.protocol;
    const domain = apps.domain;
    const subdomainPorts = apps.subdomainPorts;

    return domain === 'localhost'
      ? `${protocol}://${domain}:${subdomainPorts[Subdomain.company]}`
      : `${protocol}://${Subdomain.company}.${domain}`;
  }
);

export const getAppBaseUrls = createSelector(
  getApplications,
  (apps): IBaseUrls => {
    if (apps == null) {
      return null;
    }
    const protocol = apps.protocol;
    const domain = apps.domain;
    const subdomainPorts = apps.subdomainPorts;

    return {
      home:
        domain === 'localhost'
          ? `${protocol}://${domain}:${subdomainPorts[Subdomain.home]}`
          : `${protocol}://${domain}`,
      company:
        domain === 'localhost'
          ? `${protocol}://${domain}:${subdomainPorts[Subdomain.company]}`
          : `${protocol}://${Subdomain.company}.${domain}`,
      mosque:
        domain === 'localhost'
          ? `${protocol}://${domain}:${subdomainPorts[Subdomain.Mosque]}`
          : `${protocol}://${Subdomain.Mosque}.${domain}`,
    };
  }
);

export const getUserAppBaseUrl = createSelector(
  getApplications,
  (apps): string => {
    if (apps == null) {
      return null;
    }
    const protocol = apps.protocol;
    const domain = apps.domain;
    const subdomainPorts = apps.subdomainPorts;

    return domain === 'localhost'
      ? `${protocol}://${domain}:${subdomainPorts[Subdomain.user]}`
      : `${protocol}://${Subdomain.user}.${domain}`;
  }
);

export const getHomeAppBaseUrl = createSelector(
  getApplications,
  (apps): string => {
    if (apps == null) {
      return null;
    }
    const protocol = apps.protocol;
    const domain = apps.domain;
    const subdomainPorts = apps.subdomainPorts;

    return domain === 'localhost'
      ? `${protocol}://${domain}:${subdomainPorts[Subdomain.home]}`
      : `${protocol}://${domain}`;
  }
);
