import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import {
  IAppSettings,
  IBaseUrls,
  IRedirectSubscriberPayload,
  ISideNav,
  ISubscriptionNav,
  ITopNav,
} from '../../models/interfaces';
import { Store } from '@ngrx/store';
import { FusionoAuthState } from '../../store/reducers';
import {
  getoAuthCompanies,
  getCompanyAppBaseUrl,
  getoAuthUserProfilePath,
  getoAuthUser,
  getoAuthSessionToken,
  getoAuthUserTopNavigation,
  getoAuthTopNavigations,
  getAppBaseUrls,
} from '../../store/selectors';
import { Observable, Subscription } from 'rxjs';
import { IUser, ICompany } from '@fusion/common';
import {
  SessionEnd,
  oAuthStart,
  SignUpStart,
  DestroyUserSession,
  RedirectSubscriber,
} from '../../store/actions';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  ModalIconType,
  IFormMetadata,
  IFormConfig,
  setFormValidationRules,
} from '@fusion/form';
import { SignInForm, SignUpForm } from '../../models/forms';
import { NavService } from '../../services/nav/nav.service';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fusion-oauth-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SideNavComponent implements OnInit {
  @Input() mode: string;
  @Input() isOpen: boolean;
  @Input() isHomeApp: boolean;
  @Input() isUserApp: boolean;
  @Input() sideNav: ISideNav[];
  @Input() currentRootPath: string;
  @Input() appSettings: IAppSettings;

  token$: Observable<string>;
  oAuthUser$: Observable<IUser>;
  appBaseUrls$: Observable<IBaseUrls>;
  oAuthCompanies$: Observable<ICompany[]>;
  companyAppBaseUrl$: Observable<string>;
  authUserProfilePath$: Observable<string>;
  oAuthUserTopNavigation$: Observable<ITopNav>;
  oAuthTopNavigations$: Observable<ITopNav[]>;
  breakpoint$: Observable<BreakpointState>;
  isPlaylistCollapsed = true;

  breakpointSubscription: Subscription;
  ModalIconType = ModalIconType;

  // form templates
  signInFormMetadata: IFormMetadata = SignInForm.metadata;
  signInFormConfigs: IFormConfig[] = SignInForm.configs;
  signInFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(SignInForm.configs)
  );

  signUpFormMetadata: IFormMetadata = SignUpForm.metadata;
  signUpFormConfigs: IFormConfig[] = SignUpForm.configs;
  signUpFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(SignUpForm.configs)
  );

  @ViewChild('appDrawer', { static: true }) appDrawer: ElementRef;
  constructor(
    private store: Store<FusionoAuthState>,
    private fb: UntypedFormBuilder,
    private navService: NavService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit() {
    this.breakpoint$ = this.breakpointObserver.observe(['(max-width: 960px)']);
    this.navService.appDrawer = this.appDrawer;
    this.token$ = this.store.select(getoAuthSessionToken);
    this.oAuthUser$ = this.store.select(getoAuthUser);
    this.appBaseUrls$ = this.store.select(getAppBaseUrls);
    this.oAuthCompanies$ = this.store.select(getoAuthCompanies);
    this.companyAppBaseUrl$ = this.store.select(getCompanyAppBaseUrl);
    this.authUserProfilePath$ = this.store.select(getoAuthUserProfilePath);
    this.oAuthTopNavigations$ = this.store.select(getoAuthTopNavigations);
    this.oAuthUserTopNavigation$ = this.store.select(getoAuthUserTopNavigation);
  }

  notifySignInFormSubmit(formData) {
    this.store.dispatch(new oAuthStart(formData.data));
  }

  notifySignUpFormSubmit(formData) {
    this.store.dispatch(new SignUpStart(formData.data));
  }

  navigateToRouterLink(nav: ISideNav) {
    let path = `${this.currentRootPath}/${nav.path}`;

    if (this.isUserApp) {
      path = nav.path === '' ? `users${this.currentRootPath}` : `${nav.path}`;
    }
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }

  navigateToCompany(appBaseUrls: IBaseUrls, companyNav: ITopNav) {
    if (companyNav.category === 'Mosque') {
      this._document.defaultView.location.href = `${appBaseUrls.mosque}/${companyNav.subscriberId}`;
    } else {
      this._document.defaultView.location.href = `${appBaseUrls.company}/${companyNav.subscriberId}`;
    }
  }

  navigateToUserProfile(baseUrl: string) {
    this._document.defaultView.location.href = `${baseUrl}`;
  }

  toggleNav(isResponsive: boolean) {
    if (isResponsive) {
      this.navService.toggleNav();
      this.navService.toggleNavResponsive();
    }
  }

  signOut() {
    this.store.dispatch(new DestroyUserSession());
  }

  onOpen(isHomeApp: boolean) {
    if (isHomeApp) {
      this.isPlaylistCollapsed = false;
    } else {
      this.isPlaylistCollapsed = true;
    }
    this.cdr.detectChanges();
  }

  onClose(isHomeApp: boolean) {
    if (isHomeApp) {
      this.isPlaylistCollapsed = true;
    } else {
      this.isPlaylistCollapsed = false;
    }
    this.cdr.detectChanges();
  }

  navigateToSubscription(subscription: ISubscriptionNav) {
    const payload: IRedirectSubscriberPayload = {
      domain: subscription.domain,
      subscriberId: subscription.subscriberId,
    };
    this.store.dispatch(new RedirectSubscriber(payload));
  }
}
