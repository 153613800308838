import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';

import {
  UserProfileActionTypes,
  LoadUserProfileSuccess,
  LoadUserProfileFail,
  LoadUserProfile,
} from '../actions/user-profile.actions';
import { Store } from '@ngrx/store';
import { FusionoAuthState } from '../reducers/index';
import {
  AuthorizationService,
  MappingService,
  mappingType,
} from '@fusion/service';
import { getoAuthUserId } from '../selectors/index';
import { oAuthActionTypes } from '../actions/index';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { IUserProfile } from '../../models/interfaces';

@Injectable()
export class UserProfileEffects {
  constructor(
    private store: Store<FusionoAuthState>,
    private actions$: Actions,
    private authService: AuthorizationService,
    private mappingService: MappingService
  ) {}

  effect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserProfileActionTypes.LoadUserProfile
        // oAuthActionTypes.TokenDecodeSuccess
      ),
      withLatestFrom(this.store.select(getoAuthUserId)),
      mergeMap(([action, userId]: [any, string]) => {
        let errorPayload: IError<FusionoAuthError> = {
          code: FusionoAuthError.LoadUserProfileFail,
          source: ErrorSource.Validation,
          data: null,
        };
        return this.authService.getUserAuthProfile(userId).pipe(
          switchMap((dataResult) => {
            const mappedData = this.mappingService.getMappedData<IUserProfile>(
              dataResult,
              mappingType.camelize
            );
            return [new LoadUserProfileSuccess(mappedData)];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue loading your profile. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new LoadUserProfile()],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new LoadUserProfileFail(errorPayload));
          })
        );
      })
    )
  );
}
