import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { IErrorHandlingConfig } from '@fusion/error';
import { IFormConfig, IFormMetadata } from '../../models/interfaces';
import { IProgressState } from '@fusion/common';
import { AutocompleteType } from '../../models/enums';
import { getValidationRulesWithPresetValue } from '../../models/utils';

@Component({
  selector: 'fusion-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTemplateComponent implements OnInit {
  @Output() notifyFormValidationUpdate = new EventEmitter<boolean>();
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();
  @Input() dialogRef: MatDialogRef<any> | null;
  @Input() isDialog: boolean;
  @Input() autoCompleteEntities: any;
  @Input() autoCompleteType: AutocompleteType;
  @Input() entityType: string;
  @Input() formMetadata: IFormMetadata;
  @Input() error: IErrorHandlingConfig;
  @Input() progressState: IProgressState;
  _formValidationRules: UntypedFormGroup;
  _existingValues: any;
  _formConfigs: IFormConfig[];
  @Input('formConfigs') set formConfigs(formConfigs: any) {
    if (!formConfigs) {
      return;
    }
    this._formConfigs = formConfigs;
    this.updateFormValidation(this._existingValues);
  }
  @Input('formValidationRules') set formValidationRules(
    formValidationRules: any
  ) {
    if (!formValidationRules) {
      return;
    }
    this._formValidationRules = formValidationRules;
    this.updateFormValidation(this._existingValues);
  }
  @Input('existingValues') set existingValues(existingValues: any) {
    if (!existingValues) {
      return;
    }
    this._existingValues = existingValues;
    this.updateFormValidation(existingValues);
  }

  get formValidationRules() {
    return this._formValidationRules;
  }

  get formConfigs() {
    return this._formConfigs;
  }

  role: string[];
  isValidationUpdated = false;

  constructor() {}

  ngOnInit() {}

  formClose() {
    // TODO emit event to notify form close
  }

  updateFormValidation(existingValues: any) {
    if (
      this.formValidationRules &&
      this.formConfigs &&
      existingValues &&
      !this.isValidationUpdated
    ) {
      this.isValidationUpdated = true;
      this.formValidationRules = getValidationRulesWithPresetValue(
        this.formValidationRules,
        this.formConfigs,
        existingValues
      );
    }
  }
}
