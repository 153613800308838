<ng-container *ngIf="company">
  <div cardSpacing border radius class="col1__section-one rowN">
    <div class="">
      <span microTitle semiBold>Website</span>
      <div microTitle>
        <a target="_blank" href="{{ company.website }}">{{
          company.website
        }}</a>
      </div>
    </div>

    <div class="">
      <span microTitle semiBold>
        {{ company.type === 'parent/holding' ? 'Headquarter' : 'Location' }}
      </span>
      <div microTitle>
        {{ company.address }}, {{ company.city }},
        {{ company.state ? company.state + ',' : '' }} {{ company.country }} -
        {{ company.zipCode }}
      </div>
    </div>

    <div class="">
      <span microTitle semiBold>Company Type</span>
      <div microTitle>{{ company.type }}</div>
    </div>

    <div class="">
      <span microTitle semiBold>Company Category</span>
      <div microTitle>{{ company.category }}</div>
    </div>

    <div class="">
      <span microTitle semiBold>Year Founded</span>
      <div microTitle>
        {{ company.founded | date: 'y' }} ({{ company.founded | fancyTime }})
      </div>
    </div>

    <div class="">
      <span microTitle semiBold>Company Size</span>
      <div microTitle>{{ company.noOfEmployees }}</div>
    </div>
  </div>
</ng-container>
