import { createSelector } from "@ngrx/store";

import { getFusionPaymentState } from "../reducers";
import { IFunding, IFundingOption, IContributor } from "../../models/interfaces";

export const getFundingState = createSelector(getFusionPaymentState, state => {
  if (state == null) {
    return null;
  }
  return state.funding;
});

export const getFundingOptions = createSelector(
  getFundingState,
  (state): IFundingOption[] => {
    if (state == null) {
      return null;
    }
    return state.fundingOptions;
  }
);

export const getCurrentFunding = createSelector(
  getFundingState,
  (state): IFundingOption => {
    if (state == null) {
      return null;
    }
    return state.currentFundingOption;
  }
);

export const getInProgressFunding = createSelector(
  getFundingState,
  (state): IFunding => {
    if (state == null) {
      return null;
    }
    return state.inProgressFunding;
  }
);

export const getFunding = createSelector(
  getFundingState,
  (state): IFunding[] => {
    if (state == null) {
      return null;
    }
    return state.funding;
  }
);

export const getFundingTotal = createSelector(
  getFundingState,
  (state): number => {
    if (state == null) {
      return null;
    }
    return state.fundingTotal;
  }
);

export const getFundingContributors = createSelector(
  getFundingState,
  (state): IContributor[] => {
    if (state == null) {
      return null;
    }
    return state.contributors;
  }
);
