import { createSelector } from '@ngrx/store';
import { IReferralStats } from '../../models/interfaces';

import { getFusionoAuthState } from '../reducers';

export const getReferralsState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.referrals;
  }
);

export const getReferralStats = createSelector(
  getReferralsState,
  (state): IReferralStats => {
    if (state == null || state?.stats == null) {
      return null;
    }
    const stats = state?.stats;
    const pending = stats?.total - stats?.signUp;
    return {
      ...stats,
      amount: {
        pending: Math.floor(pending / 5) * 2,
        earned: Math.floor(stats.signUp / 5) * 2,
      },
    };
  }
);
