<div
  *ngIf="orders && orders.length; else pendingOrderEmptyState"
  card
  class="row2"
>
  <fusion-common-card-header
    class="row2__section-one"
    [isColor]="false"
    [isEditable]="false"
    [title]="'Pending Orders'"
  ></fusion-common-card-header>
  <div cardHrSpacing class="orders row2__section-two b-padding-8">
    <table mat-table [dataSource]="orders">
      <!-- Amount Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="metadata">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.metadata.date | date: 'MMM d, y, h:mm a' }}</div>
          <div g3FontColor exSmallFont>{{ element.metadata.agentName }}</div>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <div chip light draft>pending</div>
        </td>
      </ng-container>

      <!-- Invoice Column -->
      <ng-container matColumnDef="checkout">
        <th mat-header-cell *matHeaderCellDef>Checkout</th>
        <td mat-cell *matCellDef="let element">
          <a routerLink="{{ element.checkout }}">Checkout Now</a>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef>{{ actionColumnHeaderTitle }}</th>
        <td mat-cell *matCellDef="let element">
          <a routerLink="{{ element.reference }}">{{ actionColumnRowTitle }}</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>
  </div>
</div>

<ng-template #pendingOrderEmptyState>
  <div centeringText cardExSpacing border radius class="row2">
    <div mediumTitle semiBold class="row2__section-one">No Pending Order</div>
    <div exSmallTitle g3FontColor class="row2__section-two">
      You do not have any pending order!
    </div>
  </div>
</ng-template>
