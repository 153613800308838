<div class="row2">
  <div cardSmallSpacing class="rowN" [formGroup]="articleFormGroup">
    <div class="col2">
      <div centering topLeft>
        <mat-icon>event</mat-icon>
      </div>
      <mat-form-field fullWidth>
        <mat-label>Event Title</mat-label>
        <input
          fullWidth
          matInput
          type="text"
          formControlName="title"
          placeholder="Event Title"
          (change)="onEventUpdate()"
        />
      </mat-form-field>
    </div>
    <div class="col2">
      <div centering topLeft>
        <mat-icon>schedule</mat-icon>
      </div>
      <div class="meeting-time col3">
        <div>
          <mat-form-field fullWidth
            ><mat-label>Start Date</mat-label>
            <input
              matInput
              type="text"
              mwlFlatpickr
              formControlName="start"
              [altInput]="true"
              [convertModelValue]="true"
              (ngModelChange)="onEventUpdate()"
              [enableTime]="true"
              dateFormat="Y-m-dTH:i"
              altFormat="j F, Y h:i K"
              placeholder="Start Date"
            />
          </mat-form-field>
        </div>
        <div centering center><span> to </span></div>
        <div>
          <mat-form-field fullWidth>
            <mat-label>End Date</mat-label>
            <input
              matInput
              type="text"
              mwlFlatpickr
              formControlName="end"
              [altInput]="true"
              [convertModelValue]="true"
              (ngModelChange)="onEventUpdate()"
              [enableTime]="true"
              dateFormat="Y-m-dTH:i"
              altFormat="j F, Y h:i K"
              placeholder="End Date"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- event templates -->
    <ng-container [ngSwitch]="event?.meta?.eventType">
      <ng-container *ngSwitchCase="CalendarEventType.VideoConference">
        <!-- Video -->
        <div class="col2">
          <div centering centerLeft>
            <mat-icon color="accent">voice_chat</mat-icon>
          </div>
          <div>
            <button
              *ngIf="!event.meta.videoConferenceId"
              mat-flat-button
              color="primary"
              (click)="onCreateVideoConference(event)"
            >
              Add Fusion Live video conferencing
            </button>

            <button
              *ngIf="event.meta.videoConferenceId"
              mat-flat-button
              color="primary"
              (click)="onJoinVideoConference(event)"
            >
              Join with Fusion Live
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="CalendarEventType.PhoneConference">
        <!-- Phone -->
        <div class="col2">
          <div centering topLeft class="t-padding-1">
            <mat-icon>call</mat-icon>
          </div>
          <div *ngIf="event?.meta?.eventCategory === EventCategory.Interview">
            <div primaryFontColor semiBold>Join Applicant by Phone</div>
            <div extraSmallFont>{{ applicantGuest?.user?.phoneNo }}</div>
          </div>
          <div *ngIf="event?.meta?.eventCategory === EventCategory.Meeting">
            <div primaryFontColor semiBold>Join Organizer by Phone</div>
            <div extraSmallFont>--- --- ----</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="CalendarEventType.InPersonConference">
        <!-- Video -->
        <div class="col2">
          <div centering centerLeft>
            <mat-icon color="accent">voice_chat</mat-icon>
          </div>
          <div>
            <button
              *ngIf="!event.meta.videoConferenceId"
              mat-flat-button
              color="primary"
              (click)="onCreateVideoConference(event)"
            >
              Add Fusion Live video conferencing
            </button>

            <button
              *ngIf="event.meta.videoConferenceId"
              mat-flat-button
              color="primary"
              (click)="onJoinVideoConference(event)"
            >
              Join with Fusion Live
            </button>
          </div>
        </div>

        <!-- InPerson -->
        <div class="col2">
          <div centering topLeft>
            <mat-icon>place</mat-icon>
          </div>
          <div border radius cardSmallSpacing class="">
            <div class="address-line1 col2">
              <mat-form-field fullWidth>
                <mat-label>Address</mat-label>
                <input
                  fullWidth
                  matInput
                  type="text"
                  formControlName="address"
                  placeholder="Address"
                  (change)="onEventUpdate()"
                />
              </mat-form-field>
              <mat-form-field fullWidth>
                <mat-label>Unit#</mat-label>
                <input
                  fullWidth
                  matInput
                  type="text"
                  formControlName="unit"
                  placeholder="Unit#"
                  (change)="onEventUpdate()"
                />
              </mat-form-field>
            </div>
            <div class="address-line2 col2">
              <mat-form-field fullWidth>
                <mat-label>City</mat-label>
                <input
                  fullWidth
                  matInput
                  type="text"
                  formControlName="city"
                  placeholder="City"
                  (change)="onEventUpdate()"
                />
              </mat-form-field>
              <mat-form-field fullWidth>
                <mat-label>State</mat-label>
                <input
                  fullWidth
                  matInput
                  type="text"
                  formControlName="state"
                  placeholder="State"
                  (change)="onEventUpdate()"
                />
              </mat-form-field>
            </div>
            <div class="address-line2 col2">
              <mat-form-field fullWidth>
                <mat-label>Zip Code</mat-label>
                <input
                  fullWidth
                  matInput
                  type="text"
                  formControlName="zipCode"
                  placeholder="Zip Code"
                  (change)="onEventUpdate()"
                />
              </mat-form-field>
              <mat-form-field fullWidth>
                <mat-label>Country</mat-label>
                <input
                  fullWidth
                  matInput
                  type="text"
                  formControlName="country"
                  placeholder="Country"
                  (change)="onEventUpdate()"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!-- address -->

    <div class="col2">
      <div centering centerLeft>
        <mat-icon>group_add</mat-icon>
      </div>
      <div>
        <mat-form-field class="col-12">
          <mat-label>Add guests</mat-label>
          <input
            matInput
            aria-label="Country"
            [matAutocomplete]="auto"
            [formControl]="userSearchCtrl"
            placeholder="Add guests"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName">
            <mat-option *ngFor="let entity of users" [value]="entity">
              <img
                style="vertical-align: middle"
                aria-hidden
                src="{{
                  entity.profilePicture ||
                    '/assets/images/users/profile-picture/profile.png'
                }}"
                height="25"
              />
              <span> {{ entity?.firstName }} {{ entity?.lastName }}</span> |
              <small>{{ entity?.address }}, </small>
              <small *ngIf="entity.city">{{ entity?.city }}, </small>
              <small *ngIf="entity.state">{{ entity?.state }}, </small>
              <small *ngIf="entity.country">{{ entity?.country }} </small>
              <small *ngIf="entity.zipCode">- {{ entity?.zipCode }}</small>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <!-- guest list -->
    <div class="col2">
      <div centering topLeft>
        <mat-icon>people</mat-icon>
      </div>
      <div class="guests rowN">
        <ng-container *ngFor="let guest of event.meta.guests">
          <div class="col2">
            <div centering center>
              <div microProfileImage>
                <img
                  imageFit
                  src="{{
                    guest?.user?.profilePicture ||
                      'assets/images/users/profile-picture/profile.png'
                  }}"
                />
                <div overlay class="l-margin-5">
                  <ng-container [ngSwitch]="guest?.joiningStatus">
                    <ng-container *ngSwitchCase="JoiningStatus.Accepted">
                      <mat-icon color="accent" class="icon-display"
                        >check_circle</mat-icon
                      >
                    </ng-container>

                    <ng-container *ngSwitchCase="JoiningStatus.Rejected">
                      <mat-icon color="warn" class="icon-display"
                        >highlight_off</mat-icon
                      >
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <!-- TODO may be fetch error component -->
                      <div class="activity__empty"></div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div centering centerLeft>
              <div>
                <div>
                  <a href="users/{{ guest.user.id }}" target="_blank"
                    >{{ guest.user?.firstName }} {{ guest.user?.middleName }}
                    {{ guest.user?.lastName }}</a
                  >
                </div>
                <div nanoFont semiBold gray9FontColor *ngIf="guest.isOrganizer">
                  Organizer
                </div>
                <div nanoFont semiBold gray9FontColor *ngIf="guest.isApplicant">
                  Applicant
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!--  -->
    <div class="col2">
      <div centering topLeft>
        <mat-icon>notes</mat-icon>
      </div>
      <div class="t-padding-1">
        <mat-form-field fullWidth>
          <mat-label>Description</mat-label>
          <textarea
            fullWidth
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="15"
            (change)="onEventUpdate()"
            formControlName="description"
            placeholder="Event Description"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div stickyFooter cardSmallSpacing topBorder class="col2">
    <div centering centerLeft></div>
    <div centering centerRight>
      <button mat-button (click)="notifySave.emit()" [mat-dialog-close]="true">
        Save
      </button>
    </div>
  </div>
</div>
