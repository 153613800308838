<div *ngIf="formConfigs && formValidationRules">
  <div
    class="form-group"
    [ngClass]="{
      'has-error':
        !formValidationRules.controls[formConfigs.name].valid &&
        formValidationRules.controls[formConfigs.name].touched
    }"
  >
    <div class="example-container inputGroupContainer">
      <mat-form-field *ngIf="formConfigs.type !== 'password'">
        <mat-label>{{ formConfigs.label }} </mat-label>
        <mat-icon iconFont *ngIf="formConfigs.prefixIcon" matPrefix>{{
          formConfigs.prefixIcon
        }}</mat-icon>
        <input
          matInput
          #textInput
          type="{{ formConfigs.type }}"
          [formControl]="formValidationRules.controls[formConfigs.name]"
          required="{{
            formValidationRules.controls[formConfigs.name].hasError('required')
          }}"
        />
        <mat-icon iconFont *ngIf="formConfigs.suffixIcon" matSuffix>{{
          formConfigs.suffixIcon
        }}</mat-icon>
        <button
          class="custom-button"
          *ngIf="textInput.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="formValidationRules.controls[formConfigs.name].reset()"
        >
          <mat-icon iconFont>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field *ngIf="formConfigs.type === 'password'">
        <mat-label>{{ formConfigs.label }} </mat-label>
        <input
          matInput
          #textInput
          [type]="hide ? 'password' : 'text'"
          placeholder="{{ formConfigs.label }}"
          [formControl]="formValidationRules.controls[formConfigs.name]"
          required="{{
            formValidationRules.controls[formConfigs.name].hasError('required')
          }}"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? 'visibility' : 'visibility_off'
        }}</mat-icon>
      </mat-form-field>

      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError('required') &&
          formValidationRules.controls[formConfigs.name].touched
        "
      >
        You must include {{ formConfigs.label }}.
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError('pattern') &&
          formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} is invalid. Allowed format is
        <b>{{ formConfigs.examplePattern }}</b>
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError(
            'minlength'
          ) && formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} must be at least
        {{ formConfigs.validation.min }} characters long.
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError(
            'maxlength'
          ) && formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} cannot exceed
        {{ formConfigs.validation.max }} characters.
      </mat-error>
    </div>
  </div>
</div>
