import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';

import {
  SignUpActionTypes,
  SignUpSuccess,
  SignUpFail,
  SignUpStart,
} from '../actions/sign-up.actions';
import { FusionoAuthState } from '../reducers';
import { Store } from '@ngrx/store';
import { MappingService, mappingType, TokenService } from '@fusion/service';
import { IUser } from '@fusion/common';
import { oAuthStart } from '../actions';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { getRouterQueryParams } from '@fusion/router';
import { Params } from '@angular/router';
import { IoAuthCredentials } from '../../models/interfaces';

@Injectable()
export class SignUpEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionoAuthState>,
    private tokenService: TokenService,
    private mappingService: MappingService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType<SignUpStart>(SignUpActionTypes.SignUpStart),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getRouterQueryParams)),
    mergeMap(([payload, params]: [IUser, Params]) => {
      let errorPayload: IError<FusionoAuthError> = {
        code: FusionoAuthError.SignUpFail,
        source: ErrorSource.Validation,
        data: null,
      };
      let userRaw: any = {
        ...payload,
      };

      // referral signup
      const referralId = params.referral_id;
      if (referralId) {
        userRaw = {
          ...userRaw,
          referralId: referralId,
          privacy: true,
        };
      }
      const mappedUser = this.mappingService.getMappedData(
        userRaw,
        mappingType.underscore
      );
      return this.tokenService.postUser(mappedUser).pipe(
        switchMap((dataResult) => {
          const credential: IoAuthCredentials = {
            email: payload.email || dataResult.email,
            password: payload.password,
          };
          return [new SignUpSuccess(dataResult), new oAuthStart(credential)];
        }),
        catchError((error) => {
          const dupEmail = payload.email
            ? `'${payload.email}' email is already registered.`
            : 'You already have signed up using this invitation. Please try to sign in.';
          const code = error.status;
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                code === 409
                  ? dupEmail
                  : 'Sorry, we are having some issue creating your account. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new SignUpStart(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new SignUpFail(errorPayload));
        })
      );
    })
  ));
}
