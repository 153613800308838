import { CalendarEvent } from 'angular-calendar';
import { ICalendarEventMetadata } from '../../models/interfaces';
import {
  CurrentEventActionTypes,
  CurrentEventActions,
} from '../actions/current-event.actions';

export interface CurrentEventState {
  data: CalendarEvent<ICalendarEventMetadata>;
  newEventId: string;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CurrentEventState = {
  data: null,
  newEventId: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: CurrentEventActions
): CurrentEventState {
  switch (action.type) {
    case CurrentEventActionTypes.CreateEvent: {
      return {
        ...state,
        loading: true,
      };
    }
    case CurrentEventActionTypes.CurrentEventSync:
    case CurrentEventActionTypes.SetCurrentEventSuccess:
    case CurrentEventActionTypes.LoadCurrentEventSuccess:
    case CurrentEventActionTypes.UpdateCurrentEventSuccess:
    case CurrentEventActionTypes.CreateEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case CurrentEventActionTypes.SaveCurrentEventSuccess: {
      return {
        ...state,
        newEventId: action?.payload?.id,
      };
    }
    case CurrentEventActionTypes.NotifyCreateEventComplete: {
      return {
        ...state,
        newEventId: null,
      };
    }
    case CurrentEventActionTypes.CreateEventFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
