export enum ModalIconType {
  EditIcon,
  EditPlainIcon,
  AddIcon,
  AddIconButton,
  RoundedButton,
  SignIn,
  SignUp,
  Menu,
  Card,
  None,
}

export enum ModalButtonType {
  Icon,
  Menu,
  Card,
  IconText
}
