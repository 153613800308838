import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IJob } from '../../models/interfaces/job.interfaces';
import { UntypedFormGroup } from '@angular/forms';
import {
  getValidationRulesWithPresetValue,
  IFormConfig,
  IFormMetadata,
  ModalIconType,
} from '@fusion/form';
import { JobApply } from '../../models/enums';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fusion-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyFormValidationUpdate: EventEmitter<any> =
    new EventEmitter<any>();
  @Input() job: IJob;
  @Input() metadata: any;
  @Input() company: any;
  @Input() currentRootPath: string;
  @Input() formValidationRules: UntypedFormGroup;
  @Input() formConfigs: IFormConfig[];
  @Input() formMetadata: IFormMetadata;
  @Input() isEditable: boolean;

  JobApply = JobApply;
  ModalIconType = ModalIconType;
  updatedValidationRules: UntypedFormGroup;
  updatedJob: IJob;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.updateValidationRules();
  }

  navigateToApply() {
    this.router.navigate(['apply'], { relativeTo: this.route });
  }

  onNotifyViewCompany(companyId: string) {
    const currentUrl = this.router.url;
    this.router.navigate([`../../companies/${companyId}`], {
      queryParams: { referrerUrl: currentUrl },
      relativeTo: this.route,
    });
  }

  updateValidationRules() {
    if (this.isEditable) {
      this.getMappedJobDetails();
      this.updatedValidationRules = getValidationRulesWithPresetValue(
        this.formValidationRules,
        this.formConfigs,
        this.updatedJob
      );
    }
  }

  getMappedJobDetails() {
    const details = [
      {
        title: 'Job Description',
        content: 'jobDescription',
      },
      {
        title: 'Job Responsibility',
        content: 'jobResponsibility',
      },
      {
        title: 'Education Requirements',
        content: 'educationRequirements',
      },
      {
        title: 'Experience Requirements',
        content: 'experienceRequirements',
      },
      {
        title: 'Additional Requirements',
        content: 'additionalRequirements',
      },
      {
        title: 'Benifits',
        content: 'benifits',
      },
      {
        title: 'Apply Instructions',
        content: 'instructions',
      },
    ];

    this.updatedJob = {
      ...this.job,
    };
    details?.map((detail) => {
      const item = this.updatedJob?.jobDetails?.find(
        (d) => d.title === detail.title
      );
      this.updatedJob[detail.content] = item ? item.content : null;
    });
  }

  onManageJob() {
    this.router.navigate(['edit'], { relativeTo: this.route });
  }
}
