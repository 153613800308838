import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentRef {
  constructor(
    private _platform: PlatformService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public getNativeDocument(): Document {
    if (this._platform.isBrowser) {
      return this._document;
    }
    return {} as Document;
  }
}
