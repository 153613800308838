import { ActiveNavigationGuard } from './active-profile-navigation.guard';
import { ApplicationGuard } from './application.guard';
import { LoadApplicationResolver } from './load-application.resolver';
import { LoadSubscriptionsResolver } from './load-subscriptions.resolver';

// include all guards
export const guards: any[] = [
  ApplicationGuard,
  LoadSubscriptionsResolver,
  LoadApplicationResolver,
  ActiveNavigationGuard,
];

// export from guards
export * from './application.guard';
export * from './load-subscriptions.resolver';
export * from './load-application.resolver';
export * from './active-profile-navigation.guard';
