import { IError } from '@fusion/error';
import { Action } from '@ngrx/store';
import { FusionJobError } from '../../models/enums';
import { IJob } from '../../models/interfaces';

export enum CurrentOpenPositionActionTypes {
  LoadCurrentOpenPosition = '[Current Open Position] Load',
  LoadCurrentOpenPositionSuccess = '[Current Open Position] Load Success',
  LoadCurrentOpenPositionFail = '[Current Open Position] Load Fail',
  UpdateCurrentOpenPosition = '[Current Open Position] Update',
  UpdateCurrentOpenPositionSuccess = '[Current Open Position] Update Success',
  UpdateCurrentOpenPositionFail = '[Current Open Position] Update Fail',
  PromoteCurrentOpenPosition = '[Current Open Position] Promote',
  PromoteCurrentOpenPositionSuccess = '[Current Open Position] Promote Success',
  PromoteCurrentOpenPositionFail = '[Current Open Position] Promote Fail',
  UpdateCurrentOpenPositionStatus = '[Current Open Position] Update Status',
  UpdateCurrentOpenPositionStatusSuccess = '[Current Open Position] Update Status Success',
  UpdateCurrentOpenPositionStatusFail = '[Current Open Position] Update Status Fail',
}

export class LoadCurrentOpenPosition implements Action {
  readonly type = CurrentOpenPositionActionTypes.LoadCurrentOpenPosition;
}

export class LoadCurrentOpenPositionSuccess implements Action {
  readonly type = CurrentOpenPositionActionTypes.LoadCurrentOpenPositionSuccess;

  constructor(public payload: IJob) {}
}

export class LoadCurrentOpenPositionFail implements Action {
  readonly type = CurrentOpenPositionActionTypes.LoadCurrentOpenPositionFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class UpdateCurrentOpenPosition implements Action {
  readonly type = CurrentOpenPositionActionTypes.UpdateCurrentOpenPosition;

  constructor(public payload: any) {}
}

export class UpdateCurrentOpenPositionSuccess implements Action {
  readonly type =
    CurrentOpenPositionActionTypes.UpdateCurrentOpenPositionSuccess;

  constructor(public payload: IJob) {}
}

export class UpdateCurrentOpenPositionFail implements Action {
  readonly type = CurrentOpenPositionActionTypes.UpdateCurrentOpenPositionFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class PromoteCurrentOpenPosition implements Action {
  readonly type = CurrentOpenPositionActionTypes.PromoteCurrentOpenPosition;
}

export class PromoteCurrentOpenPositionSuccess implements Action {
  readonly type =
    CurrentOpenPositionActionTypes.PromoteCurrentOpenPositionSuccess;

  constructor(public payload: IJob) {}
}

export class PromoteCurrentOpenPositionFail implements Action {
  readonly type = CurrentOpenPositionActionTypes.PromoteCurrentOpenPositionFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class UpdateCurrentOpenPositionStatus implements Action {
  readonly type =
    CurrentOpenPositionActionTypes.UpdateCurrentOpenPositionStatus;

  constructor(public payload: boolean) {}
}

export class UpdateCurrentOpenPositionStatusSuccess implements Action {
  readonly type =
    CurrentOpenPositionActionTypes.UpdateCurrentOpenPositionStatusSuccess;

  constructor(public payload: any) {}
}

export class UpdateCurrentOpenPositionStatusFail implements Action {
  readonly type =
    CurrentOpenPositionActionTypes.UpdateCurrentOpenPositionStatusFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export type CurrentOpenPositionActions =
  | LoadCurrentOpenPosition
  | LoadCurrentOpenPositionSuccess
  | LoadCurrentOpenPositionFail
  | UpdateCurrentOpenPosition
  | UpdateCurrentOpenPositionSuccess
  | UpdateCurrentOpenPositionFail
  | UpdateCurrentOpenPositionStatus
  | UpdateCurrentOpenPositionStatusSuccess
  | UpdateCurrentOpenPositionStatusFail;
