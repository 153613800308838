import { AddJobActionTypes, AddJobActions } from '../actions/add-job.actions';

export interface AddJobState {
  data: any;
  loading: boolean;
  loaded: boolean;
}

export const initialState: AddJobState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: AddJobActions
): AddJobState {
  switch (action.type) {
    case AddJobActionTypes.AddJob: {
      return {
        ...state,
        loading: true
      };
    }
    case AddJobActionTypes.AddJobSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case AddJobActionTypes.AddJobFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
