import { OrderEffects } from "./order.effects";
import { PaymentEffects } from "./payment.effects";
import { TransactionEffects } from "./transaction.effects";
import { ErrorEffects } from "./error.effects";
import { CustomerEffects } from "./customer.effects";
import { WalletEffects } from "./wallet.effects";
import { FundingEffects } from "./funding.effects";

// include all effects
export const effects: any[] = [
  OrderEffects,
  PaymentEffects,
  TransactionEffects,
  ErrorEffects,
  CustomerEffects,
  WalletEffects,
  FundingEffects
];

// export from effects
