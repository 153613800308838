import { createSelector } from '@ngrx/store';
import { IRecruitingClient } from '../../models/interfaces';

import { getFusionJobState } from '../reducers';

export const getRecruitingClientsState = createSelector(
  getFusionJobState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.recruitingClients;
  }
);

export const getRecruitingClients = createSelector(
  getRecruitingClientsState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.clients;
  }
);

export const getCurrentRecruitingClient = createSelector(
  getRecruitingClientsState,
  (state): IRecruitingClient => {
    if (state == null) {
      return null;
    }
    return state.currentClient;
  }
);
