export enum MetaTagType {
  Property = 'property',
  Name = 'name',
}

export enum TwitterCardType {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
  App = 'app',
  Player = 'player',
}

export enum OpenGraphType {
  Article = 'article',
  Book = 'book',
  Profile = 'profile',
  Website = 'website',
  Video = 'video',
}
