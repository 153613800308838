<footer whiteBgColor class="">
  <div
    *ngIf="!appSettings || !appSettings?.isStandalone"
    pageSpacing
    bottomBorder
    class="row3__section-one footer__social row2"
  >
    <div extraSmallTitle semiBold class="row2__section-one">Follow</div>
    <div class="row2__section-two colN">
      <a
        mat-mini-fab
        color="primary"
        class="mat-elevation-z0"
        href="https://www.facebook.com/dottocircle.us"
        target="_blank"
      >
        <mat-icon svgIcon="facebook"></mat-icon>
      </a>
      <a
        mat-mini-fab
        color="primary"
        class="mat-elevation-z0"
        href="https://www.linkedin.com/company/dottocircle"
        target="_blank"
      >
        <mat-icon svgIcon="linkedin"></mat-icon>
      </a>
      <a
        mat-mini-fab
        color="primary"
        class="mat-elevation-z0"
        href="https://twitter.com/dottocircle_us"
        target="_blank"
      >
        <mat-icon svgIcon="twitter"></mat-icon>
      </a>
      <a
        mat-mini-fab
        color="primary"
        class="mat-elevation-z0"
        href="https://www.youtube.com/channel/UC84SJ1TmsIcz86ekuawgG0w"
        target="_blank"
      >
        <mat-icon svgIcon="youtube"></mat-icon>
      </a>
    </div>
  </div>
  <!-- <div class="row3__section-two">
    <mat-divider></mat-divider>
  </div> -->
  <div
    pageSpacing
    centering
    centerRight
    class="row3__section-three footer__copywrite"
  >
    <ng-container *ngIf="appSettings; else platformEchosystem">
      <span>© 2022 {{ appSettings?.legalName }}. All Rights Reserved</span>
    </ng-container>

    <ng-template #platformEchosystem>
      <span>© 2022 Dot to Circle, Inc. All Rights Reserved</span>
    </ng-template>
  </div>
</footer>
