import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ICompany } from '../../models/interfaces';

@Component({
  selector: 'fusion-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {
  @Output() notifyViewCompany: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyManageCompany: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() companies: ICompany[];
  @Input() isEmployerMode: boolean;
  @Input() isNavigateMode: boolean;
  @Input() currentRootPath: string;
  @Input() formValidationRules: UntypedFormGroup;
  @Input() formConfigs: Object;
  @Input() formMetadata: any;
  @Input() unclaimedCompanies: ICompany[];
  @Input() buttonLabel: string;

  constructor() {}

  ngOnInit() {}
}
