import { createSelector } from '@ngrx/store';

import { getFusionJobState } from '../reducers';

export const getAddJobState = createSelector(
  getFusionJobState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.addJob;
  }
);
