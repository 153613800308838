<div pageSpacing extraLargePage pageBody>
  <!-- page title -->
  <div pageTitle>
    <h1>Manage Subscriptions!</h1>
  </div>

  <!-- page body -->
  <div pageContent>
    <!-- body -->
    <div content>
      <!-- error handling -->
      <ng-container
        *ngIf="
          errorHandlingConfig$ | async as errorHandlingConfig;
          else subscriptionList
        "
      >
        <ng-template
          [fusionErrorHandler]="errorHandlingConfig"
          errorHandlingType="ErrorHandlingType.Template"
        ></ng-template>
      </ng-container>
      <!-- subscription list -->
      <ng-template #subscriptionList>
        <ng-container *ngIf="applications$ | async as applications">
          <ng-container *ngIf="applications.length; else emptyStateView">
            <fusion-subscription-list
              [subscriptions]="applications$ | async"
              [isOnlySubscriptions]="isOnlySubscriptions"
              (notifyViewApplication)="notifyViewApplication($event)"
            ></fusion-subscription-list>
          </ng-container>
        </ng-container>
        <!-- empty state -->
        <ng-template #emptyStateView>
          <fusion-common-empty-state
            [emptyState]="emptyState"
            [currentRootPath]="'/' + (currentRootPath$ | async)"
          ></fusion-common-empty-state>
        </ng-template>
      </ng-template>
    </div>
    <!-- ad column -->
    <div adSpace></div>
  </div>
</div>
