import { createSelector } from '@ngrx/store';
import {
  IPermission,
  ISubscriptionNav,
  ITopNav,
} from '../../models/interfaces';
import { ICompany, IUser } from '@fusion/common';

import { getFusionoAuthState } from '../reducers';
import { getRouterParams } from '@fusion/router';
import {
  getoAuthUser,
  getoAuthUserFullName,
  getoAuthUserProfilePath,
} from './user-session.selectors';

export const getUserPermissionsState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.userPermissions;
  }
);

export const getUserPermissionsData = createSelector(
  getUserPermissionsState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getUserPermissions = createSelector(
  getUserPermissionsData,
  (permissions): IPermission[] => {
    if (permissions == null) {
      return null;
    }

    return [
      ...(permissions.employers || []),
      ...(permissions.representatives || []),
      ...(permissions.subscriptions || []),
    ];
  }
);

export const getEmployerOrganizations = createSelector(
  getUserPermissionsData,
  (state): ICompany[] => {
    if (state == null) {
      return null;
    }
    return state.employers.map((com) => {
      return {
        ...com.company,
      };
    });
  }
);

export const getEmployerCompanies = createSelector(
  getEmployerOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category !== 'Mosque');
  }
);

export const getEmployerMosques = createSelector(
  getEmployerOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category === 'Mosque');
  }
);

export const getRepresentativeOrganizations = createSelector(
  getUserPermissionsData,
  (state): ICompany[] => {
    if (state == null) {
      return null;
    }
    return state.representatives.map((com) => {
      return {
        ...com.company,
      };
    });
  }
);

export const getRepresentativeCompanies = createSelector(
  getRepresentativeOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category !== 'Mosque');
  }
);

export const getRepresentativeMosques = createSelector(
  getRepresentativeOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category === 'Mosque');
  }
);

export const getoAuthCompanies = createSelector(
  getEmployerOrganizations,
  getRepresentativeOrganizations,
  (empCompanies, repCompanies): ICompany[] => {
    if (empCompanies == null || repCompanies == null) {
      return null;
    }
    return [...empCompanies, ...repCompanies];
  }
);

export const getSubscriptionPermissions = createSelector(
  getUserPermissionsData,
  (state): IPermission[] => {
    if (state == null) {
      return null;
    }
    return state.subscriptions;
  }
);

export const getoAuthTopNavigations = createSelector(
  getoAuthCompanies,
  getSubscriptionPermissions,
  (companies, permissions): ITopNav[] => {
    if (companies == null || permissions == null) {
      return null;
    }
    const menue: ITopNav[] = companies.map((company) => {
      const currentPermissions: IPermission[] = permissions.filter(
        (permission) => company.id === permission?.subscription?.subscriberId
      );

      return {
        subscriberId: company.id,
        title: company.name,
        logo: company.logo,
        category: company.category,
        subscriptions: currentPermissions.map((permission) => {
          return {
            domain: permission?.subscription?.domain,
            applicationName: permission?.subscription?.applicationName,
            subscriberId: permission?.subscription?.subscriberId,
          };
        }),
      };
    });
    return menue;
  }
);

export const getoAuthUserTopNavigation = createSelector(
  getoAuthUser,
  getoAuthUserFullName,
  getoAuthUserProfilePath,
  getSubscriptionPermissions,
  (oAuthUser, userName, userProfilePath, permissions): ITopNav => {
    if (oAuthUser == null || permissions == null) {
      return null;
    }

    const currentPermissions: IPermission[] = permissions.filter(
      (permission) =>
        oAuthUser.id === permission?.subscription?.subscriberId &&
        permission?.subscription?.domain !== 'company' &&
        permission?.subscription?.domain !== 'mosque' &&
        permission?.subscription?.domain !== 'career'
    );

    return {
      subscriberId: oAuthUser.id,
      title: userName,
      profilePicture: oAuthUser.profilePicture,
      targetPath: userProfilePath,
      subscriptions: currentPermissions.map((permission) => {
        return {
          domain: permission?.subscription?.domain,
          applicationName: permission?.subscription?.applicationName,
          subscriberId: permission?.subscription?.subscriberId,
        };
      }),
    };
  }
);

export const getSubscriberCompany = createSelector(
  getRouterParams,
  getEmployerCompanies,
  getEmployerMosques,
  getRepresentativeCompanies,
  getRepresentativeMosques,
  (params, userCompanies, userMosques, repCompanies, repMosques): ICompany => {
    if (params == null || userCompanies == null || repCompanies == null) {
      return null;
    }
    const companyId = params.subscriberId || params.companyId;
    const companies = [
      ...userCompanies,
      ...userMosques,
      ...repCompanies,
      ...repMosques,
    ];

    return companies.find((com) => com.id === companyId);
  }
);
