import { Component, OnInit } from '@angular/core';
import {
  AddMosqueForm,
  AddressForm,
  MosqueContactForm,
  MosqueOverviewForm,
  MosquePreferencesForm,
} from '../../models/forms';
import {
  IFormMetadata,
  IFormConfig,
  setFormValidationRules,
  getUpdatedFormConfigs,
  setDisplayControllers,
} from '@fusion/form';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getRouterUrlRoot } from '@fusion/router';
import { AddCompany } from '@fusion/company';

@Component({
  selector: 'user-add-mosque',
  templateUrl: './add-mosque.component.html',
  styleUrls: ['./add-mosque.component.scss'],
})
export class AddMosqueComponent implements OnInit {
  currentRootPath$: Observable<string>;
  isLinear = true;
  mosqueFormData: any = {};

  mosquePreferencesFormMetadata: IFormMetadata = MosquePreferencesForm.metadata;
  mosquePreferencesFormConfigs: IFormConfig[] = MosquePreferencesForm.configs;
  mosquePreferencesFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(MosquePreferencesForm.configs)
  );

  addMosqueFormMetadata: IFormMetadata = AddMosqueForm.metadata;
  addMosqueFormConfigs: IFormConfig[] = AddMosqueForm.configs;
  addMosqueFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(AddMosqueForm.configs)
  );

  mosqueOverviewFormMetadata: IFormMetadata = MosqueOverviewForm.metadata;
  mosqueOverviewFormConfigs: IFormConfig[] = MosqueOverviewForm.configs;
  mosqueOverviewFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(MosqueOverviewForm.configs)
  );

  addressFormMetadata: IFormMetadata = AddressForm.metadata;
  addressFormConfigs: IFormConfig[] = AddressForm.configs;
  addressFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(AddressForm.configs)
  );

  mosqueContactFormMetadata: IFormMetadata = MosqueContactForm.metadata;
  mosqueContactFormConfigs: IFormConfig[] = MosqueContactForm.configs;
  mosqueContactFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(MosqueContactForm.configs)
  );

  constructor(private fb: UntypedFormBuilder, private store: Store<UserState>) {}

  ngOnInit(): void {
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
  }

  notifyPartialFormSubmit(formData) {
    this.mosqueFormData = {
      ...this.mosqueFormData,
      ...formData,
    };
  }

  notifyFormSubmit(formData) {
    this.mosqueFormData = {
      ...this.mosqueFormData,
      ...formData,
      type: 'Nonprofit',
      category: 'Mosque',
    };

    this.store.dispatch(new AddCompany(this.mosqueFormData));
  }

  onNotifyFormValidationUpdate(isUpdate: boolean) {
    this.mosquePreferencesFormConfigs = getUpdatedFormConfigs(
      MosquePreferencesForm.configs,
      isUpdate
    );
    setDisplayControllers(
      this.mosquePreferencesFormConfigs,
      this.mosquePreferencesFormValidationRules,
      isUpdate
    );
  }
}
