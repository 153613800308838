<div class="row3">
  <div bottomDivider cardSpacing tRadius class="row3__section-one col2">
    <div smallLogo class="col2__section-one">
      <img
        pointer
        imageFit
        src="{{
          company?.logo ||
            '/assets/images/users/company-logo/logoPlaceholder.png'
        }}"
        (click)="notifyViewCompany.emit(company.id)"
      />
    </div>
    <div class="col2__section-two">
      <h5 pointer g6FontColor (click)="notifyViewCompany.emit(company.id)">
        {{ company.name }}
      </h5>
      <div xSmallTitle g5FontColor class="">
        {{ company.type }} <span class="dot"></span> {{ company.category }}
      </div>
      <div microTitle g4FontColor class="">
        {{ company.city }}, {{ company.state }} - {{ company.zipCode }}
      </div>
    </div>
  </div>
  <div
    cardSpacing
    class="row3__section-two"
    [innerHTML]="company?.companyDetail | truncate: 230"
  ></div>
  <div cardSpacing centeringRight class="row3__section-three">
    <button
      mat-stroked-button
      (click)="notifyManageCompany.emit(company.id)"
      class="custom-round-button"
    >
      {{ buttonLabel ? buttonLabel : 'Manage Company' }}
    </button>
  </div>
</div>
