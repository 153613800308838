import { Component, OnInit } from '@angular/core';
import { UserState } from '../../store/reducers/index';
import { Store } from '@ngrx/store';
import { IProfileNav, IApplication } from '@fusion/common';
import { Observable } from 'rxjs';
import { getoAuthUserId } from '@fusion/oauth';
import {
  getSubscriptions,
  FusionSubscriptionError,
  ISubscriptionPayload,
} from '@fusion/subscription';
import { SubscribeStart } from '@fusion/subscription';
import {
  IErrorHandlingConfig,
  getErrorHandlingConfig,
  ErrorHandlingType,
} from '@fusion/error';

@Component({
  selector: 'user-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
  subscriptions$: Observable<any[]>;
  currentRootPath$: Observable<string>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;
  badgeCount: number = 18;
  ErrorHandlingType = ErrorHandlingType;

  constructor(private store: Store<UserState>) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getoAuthUserId);
    this.subscriptions$ = this.store.select(getSubscriptions);
    this.errorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionSubscriptionError.LoadSubscriptionsFail)
    );
  }

  notifySubscribe(app: IApplication) {
    const payload: ISubscriptionPayload = {
      applicationId: app.id,
    };
    this.store.dispatch(new SubscribeStart(payload));
  }
}
