import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import {
  ProjectsActionTypes,
  LoadProjects,
  LoadProjectsSuccess,
  LoadJobseekerProfile,
  AddProjectFail,
  AddProject,
  UpdateProject,
  UpdateProjectFail,
  DeleteProject,
  DeleteProjectSuccess,
  DeleteProjectFail,
  UpdateProjectSuccess,
  AddProjectSuccess
} from "../actions/index";
import { getMappedEntities } from "@fusion/common";
import { JobseekerService, MappingService, mappingType } from "@fusion/service";
import { Store } from "@ngrx/store";
import { FusionCareerProfileState } from "../reducers";
import { getoAuthUserId } from "@fusion/oauth";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

@Injectable()
export class ProjectsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionCareerProfileState>,
    private jobseekerService: JobseekerService,
    private mappingService: MappingService
  ) {}

  
  getProjects$ = createEffect(() => this.actions$.pipe(
    ofType<LoadProjects>(ProjectsActionTypes.LoadProjects),
    map(action => action.payload),
    mergeMap((payload: any) => {
      return [new LoadProjectsSuccess(getMappedEntities(payload))];
    })
  ));

  
  addProject$ = createEffect(() => this.actions$.pipe(
    ofType<AddProject>(ProjectsActionTypes.AddProject),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([project, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.AddProjectFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.jobseekerService
        .postProject(this.getMappedProject(userId, project))
        .pipe(
          switchMap(dataResult => {
            return [
              new AddProjectSuccess(dataResult),
              new LoadJobseekerProfile()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue adding your project. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new AddProject(project)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new AddProjectFail(errorPayload));
          })
        );
    })
  ));

  
  updateProject$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateProject>(ProjectsActionTypes.UpdateProject),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([project, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.UpdateProjectFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.jobseekerService
        .putProject(this.getMappedProject(userId, project))
        .pipe(
          switchMap(dataResult => {
            return [
              new UpdateProjectSuccess(dataResult),
              new LoadJobseekerProfile()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue updating your project. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new UpdateProject(project)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new UpdateProjectFail(errorPayload));
          })
        );
    })
  ));

  
  deleteProject$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteProject>(ProjectsActionTypes.DeleteProject),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([projectId, userId]: [string, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.DeleteProjectFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.jobseekerService.deleteProject(projectId, userId).pipe(
        switchMap(dataResult => {
          return [
            new DeleteProjectSuccess(dataResult),
            new LoadJobseekerProfile()
          ];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue deleting your project. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new DeleteProject(projectId)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new DeleteProjectFail(errorPayload));
        })
      );
    })
  ));

  getMappedProject(userId: string, projectRaw: any) {
    const project: any = this.mappingService.getMappedData(
      projectRaw,
      mappingType.underscore
    );
    return {
      ...project,
      user_id: userId
    };
  }
}
