import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap } from 'rxjs/operators';

import { of } from 'rxjs';
import { SetError } from '@fusion/error';
import {
  CurrentAppActionTypes,
  CurrentSubscriptionActionTypes,
  PermissionsActionTypes,
  ProfileNavigationActionTypes,
  RedirectSubscriberActionTypes,
  SubscribeActionTypes,
  SubscriptionsActionTypes
} from '../actions/index';

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions) {}

  
  catchError$ = createEffect(() => this.actions$.pipe(
    ofType(
      CurrentAppActionTypes.LoadCurrentAppFail,
      CurrentSubscriptionActionTypes.LoadCurrentSubscriptionFail,
      PermissionsActionTypes.AddPermissionsFail,
      PermissionsActionTypes.LoadPermissionsFail,
      PermissionsActionTypes.UpdatePermissionsFail,
      ProfileNavigationActionTypes.SetProfileNavigationFail,
      RedirectSubscriberActionTypes.LoadRedirectSubscriberFail,
      SubscribeActionTypes.SubscribeFail,
      SubscriptionsActionTypes.LoadSubscriptionsFail
    ),
    mergeMap((action: any) => {
      return of(new SetError(action.payload));
    })
  ));
}
