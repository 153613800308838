import { Injectable } from '@angular/core';
import { getoAuthUserId } from '@fusion/oauth';
import { ContentService, MappingService, mappingType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CalendarEvent } from 'angular-calendar';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { ICalendarEventMetadata } from '../../models/interfaces';
import {
  CurrentEventActionTypes,
  UpdateJoiningStatusSuccess,
} from '../actions/current-event.actions';

import {
  EventsActionTypes,
  LoadEventsSuccess,
  LoadEventsFail,
} from '../actions/events.actions';
import { CalendarState } from '../reducers';
import { getEvents } from '../selectors';

@Injectable()
export class EventsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<CalendarState>,
    private contentService: ContentService,
    private mappingService: MappingService
  ) {}

  
  loadEvents$ = createEffect(() => this.actions$.pipe(
    ofType(EventsActionTypes.LoadEvents),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([action, userId]: [any, string]) => {
      return this.contentService.getEvents(userId).pipe(
        switchMap((dataResult) => {
          const mappedEvents: CalendarEvent<ICalendarEventMetadata> =
            this.mappingService.getMappedData<
              CalendarEvent<ICalendarEventMetadata>
            >(dataResult, mappingType.camelize);
          return [new LoadEventsSuccess(mappedEvents)];
        }),
        catchError((error) => of(new LoadEventsFail()))
      );
    }),
    catchError((error) => of(new LoadEventsFail()))
  ));

  
  updateEventJoiningStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateJoiningStatusSuccess>(
      CurrentEventActionTypes.UpdateJoiningStatusSuccess
    ),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getEvents)),
    mergeMap(
      ([currentEvent, events]: [
        CalendarEvent,
        CalendarEvent<ICalendarEventMetadata>[]
      ]) => {
        const updatedEvents: CalendarEvent<ICalendarEventMetadata>[] =
          events.map((event) => {
            if (event.id === currentEvent.id) {
              return {
                ...event,
                ...currentEvent,
              };
            }

            return event;
          });
        return [new LoadEventsSuccess(updatedEvents)];
      }
    ),
    catchError((error) => of(new LoadEventsFail()))
  ));
}
