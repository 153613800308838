import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { getErrorHandlingConfig, IErrorHandlingConfig } from '@fusion/error';
import { IProfileNav, IProgressState, IUser } from '@fusion/common';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import { getoAuthUserId, getoAuthUser } from '@fusion/oauth';
import { userNav } from '../../models/data';
import {
  getCustomerAddress,
  IStripeBillingAddress,
  ICustomer,
  IWallet,
  getCustomer,
  SetupCustomer,
  getWallet,
  AddCard,
  getClientSecret,
  ConfirmCard,
  MakePaymentWithCard,
  ITransaction,
  getCurrentPayment,
  AuthorizePayment,
  ResetCurrentPayment,
  ICouponBalance,
  getCouponBalance,
  RedeemCoupon,
  ResetCouponBalance,
  isSendingPayment,
  isWalletLoading,
  getSetupCustomerProgressState,
  FusionPaymentError,
} from '@fusion/payment';

@Component({
  selector: 'user-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {
  currentRootPath$: Observable<string>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;
  badgeCount: number = 18;
  user$: Observable<IUser>;
  address$: Observable<IStripeBillingAddress>;
  customer$: Observable<ICustomer>;
  wallet$: Observable<IWallet>;
  clientSecret$: Observable<string>;
  currentPayment$: Observable<ITransaction>;
  couponBalance$: Observable<ICouponBalance>;
  isSendingPayment$: Observable<boolean>;
  isWalletLoading$: Observable<boolean>;
  customerSetupError$: Observable<IErrorHandlingConfig>;
  customerSetupProgressState$: Observable<IProgressState>;

  constructor(private store: Store<UserState>) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getoAuthUserId);

    this.store.dispatch(new ResetCurrentPayment());
    this.user$ = this.store.select(getoAuthUser);
    this.address$ = this.store.select(getCustomerAddress);
    this.customer$ = this.store.select(getCustomer);
    this.wallet$ = this.store.select(getWallet);
    this.clientSecret$ = this.store.select(getClientSecret);
    this.currentPayment$ = this.store.select(getCurrentPayment);
    this.couponBalance$ = this.store.select(getCouponBalance);
    this.isSendingPayment$ = this.store.select(isSendingPayment);
    this.isWalletLoading$ = this.store.select(isWalletLoading);
    this.customerSetupProgressState$ = this.store.select(
      getSetupCustomerProgressState
    );
    this.customerSetupError$ = this.store.select(
      getErrorHandlingConfig(FusionPaymentError.SetupCustomerFail)
    );
  }

  onNotifyAddCard() {
    this.store.dispatch(new AddCard());
  }

  onNotifyPaymentMethod(paymentMethod) {
    if (paymentMethod) {
      this.store.dispatch(new ConfirmCard(paymentMethod));
    }
  }

  setupCustomerProfile() {
    this.store.dispatch(new SetupCustomer());
  }

  onNotifyAddBalance(balance) {
    this.store.dispatch(new MakePaymentWithCard(balance));
  }

  onNotifyPaymentAuthorization() {
    this.store.dispatch(new AuthorizePayment());
  }

  onNotifyResetCurrentPayment() {
    this.store.dispatch(new ResetCurrentPayment());
  }

  onNotifyRedeamCoupon(code: string) {
    this.store.dispatch(new RedeemCoupon(code));
  }

  onNotifyResetCouponBalance() {
    this.store.dispatch(new ResetCouponBalance());
  }
}
