import { createSelector } from "@ngrx/store";

import { getFusionPaymentState } from "../reducers";
import { ITransaction } from "../../models/interfaces";

export const getTransactionState = createSelector(
  getFusionPaymentState,
  state => {
    if (state == null) {
      return null;
    }
    return state.transaction;
  }
);

export const getTransactions = createSelector(
  getTransactionState,
  (state): ITransaction => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
