import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  IFormMetadata,
  IFormConfig,
  setFormValidationRules,
  ModalIconType
} from "@fusion/form";
import { CareerSummaryForm } from "../../models/forms";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "fusion-career-profile-career-summary",
  templateUrl: "./career-summary.component.html",
  styleUrls: ["./career-summary.component.scss"]
})
export class CareerSummaryComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Input() careerSummary: string;
  @Input() allowEdit: boolean;

  careerSummaryFormMetadata: IFormMetadata = CareerSummaryForm.metadata;
  careerSummaryFormConfigs: IFormConfig[] = CareerSummaryForm.configs;
  careerSummaryFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(CareerSummaryForm.configs)
  );
  ModalIconType = ModalIconType;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {}
}
