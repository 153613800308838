import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  switchMap,
  withLatestFrom,
  map,
} from 'rxjs/operators';

import {
  FundingActionTypes,
  AddFundingSuccess,
  AddFundingFail,
  LoadFundingOptionsSuccess,
  LoadFundingOptionsFail,
  AddFunding,
  LoadCurrentFundingOptionSuccess,
  LoadCurrentFundingOptionFail,
  LoadInProgressFundingSuccess,
  LoadInProgressFundingFail,
  ConfirmFundingSuccess,
  ConfirmFundingFail,
  LoadFundingBalanceSuccess,
  LoadFundingBalanceFail,
  LoadContributorsSuccess,
} from '../actions/funding.actions';
import { PaymentService, MappingService, mappingType } from '@fusion/service';
import {
  IFunding,
  IFundingOption,
  IContributor,
} from '../../models/interfaces';
import { Store } from '@ngrx/store';
import { FusionPaymentState } from '../reducers';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';
import { getUserType, getoAuthUserId } from '@fusion/oauth';
import { UserType } from '@fusion/common';
import { getInProgressFunding } from '../selectors';

@Injectable()
export class FundingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionPaymentState>,
    private paymentService: PaymentService,
    public mappingService: MappingService
  ) {}

  
  addFunding$ = createEffect(() => this.actions$.pipe(
    ofType<AddFunding>(FundingActionTypes.AddFunding),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getUserType),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(
      ([payload, params, userType, userId]: [
        IFundingOption,
        Params,
        string,
        string
      ]) => {
        const financierId =
          userType === UserType.User ? userId : params.companyId;
        const funding = {
          fundingoption_id: payload.id,
          representative_id: userId,
          financier_id: financierId,
          financier_type: userType,
          paid: false,
        };
        return this.paymentService.postFunding(funding).pipe(
          switchMap((dataResult) => {
            const mappedData = this.mappingService.getMappedData<IFunding>(
              dataResult,
              mappingType.camelize
            );
            return of(new AddFundingSuccess(mappedData));
          })
        );
      }
    ),
    catchError((error) => of(new AddFundingFail()))
  ));

  
  confirmFunding$ = createEffect(() => this.actions$.pipe(
    ofType(FundingActionTypes.ConfirmFunding),
    withLatestFrom(this.store.select(getInProgressFunding)),
    mergeMap(([payload, inProgressFunding]: [IFundingOption, IFunding]) => {
      return this.paymentService.confirmFunding(inProgressFunding.id).pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData<IFunding>(
            dataResult,
            mappingType.camelize
          );
          return of(new ConfirmFundingSuccess(mappedData));
        })
      );
    }),
    catchError((error) => of(new ConfirmFundingFail()))
  ));

  
  loadFundingOptions$ = createEffect(() => this.actions$.pipe(
    ofType(FundingActionTypes.LoadFundingOptions),
    mergeMap((action: any) => {
      return this.paymentService.getFundingOptions().pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData<
            IFundingOption[]
          >(dataResult, mappingType.camelize);
          return of(new LoadFundingOptionsSuccess(mappedData));
        })
      );
    }),
    catchError((error) => of(new LoadFundingOptionsFail()))
  ));

  
  loadCurrentFundingOption$ = createEffect(() => this.actions$.pipe(
    ofType(FundingActionTypes.LoadCurrentFundingOption),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([action, params]: [any, Params]) => {
      const fundingOptionId = params.fundingOptionId;
      return this.paymentService.getFundingOption(fundingOptionId).pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData<IFundingOption>(
            dataResult,
            mappingType.camelize
          );
          return of(new LoadCurrentFundingOptionSuccess(mappedData));
        })
      );
    }),
    catchError((error) => of(new LoadCurrentFundingOptionFail()))
  ));

  
  loadInProgressFunding$ = createEffect(() => this.actions$.pipe(
    ofType(FundingActionTypes.LoadInProgressFunding),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(([action, params, userId]: [any, Params, string]) => {
      const financierId = params.companyId || userId;
      const paid = false;
      return this.paymentService.getFunding(financierId, paid).pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData<IFunding>(
            dataResult,
            mappingType.camelize
          );
          return of(new LoadInProgressFundingSuccess(mappedData));
        })
      );
    }),
    catchError((error) => of(new LoadInProgressFundingFail()))
  ));

  
  loadFundingBalance$ = createEffect(() => this.actions$.pipe(
    ofType(FundingActionTypes.LoadFundingBalance),
    mergeMap((action: any) => {
      return this.paymentService.getFundingTotal().pipe(
        switchMap((dataResult) => {
          return of(new LoadFundingBalanceSuccess(dataResult.total));
        })
      );
    }),
    catchError((error) => of(new LoadFundingBalanceFail()))
  ));

  
  loadContributors$ = createEffect(() => this.actions$.pipe(
    ofType(FundingActionTypes.LoadContributors),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([action, params]: [any, Params]) => {
      const fundingOptionId = params.fundingOptionId;
      return this.paymentService.getFundingContributors(fundingOptionId).pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData<IContributor[]>(
            dataResult,
            mappingType.camelize
          );
          return of(new LoadContributorsSuccess(mappedData));
        })
      );
    }),
    catchError((error) => of(new LoadFundingBalanceFail()))
  ));
}
