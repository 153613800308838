export enum FormFieldGroup {
  Address = 'address',
  ShortAddress = 'short-address',
  Name = 'name',
  Salary = 'salary',
  HomeProperty = 'homeProperty',
  Search = 'search',
  JobMetadata = 'jobMetadata',
  JobApplyType = 'jobApplyType',
  DateRange = 'dateRange',
  Time = 'time',
}
