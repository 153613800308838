import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromoAuth from './oauth.reducer';
import * as fromUserProfile from './user-profile.reducer';
import * as fromSignUp from './sign-up.reducer';
import * as fromApplications from './applications.reducer';
import * as fromUser from './user.reducer';
import * as fromUserPermissions from './user-permissions.reducer';
import * as fromReferrals from './referrals.reducer';
import * as fromAppSession from './app-session.reducer';
import * as fromUserSession from './user-session.reducer';

export interface FusionoAuthState {
  oAuth: fromoAuth.oAuthState;
  userSession: fromUserSession.UserSessionState;
  appSession: fromAppSession.AppSessionState;
  userPermissions: fromUserPermissions.UserPermissionsState;
  userProfile: fromUserProfile.UserProfileState;
  signUp: fromSignUp.SignUpState;
  applications: fromApplications.ApplicationsState;
  user: fromUser.UserState;
  referrals: fromReferrals.ReferralsState;
}

export const reducers: ActionReducerMap<FusionoAuthState> = {
  oAuth: fromoAuth.reducer,
  userSession: fromUserSession.reducer,
  appSession: fromAppSession.reducer,
  userPermissions: fromUserPermissions.reducer,
  userProfile: fromUserProfile.reducer,
  signUp: fromSignUp.reducer,
  applications: fromApplications.reducer,
  user: fromUser.reducer,
  referrals: fromReferrals.reducer,
};

export const getFusionoAuthState =
  createFeatureSelector<FusionoAuthState>('fusionoAuth');

// export reducer state interfaces
