import { ICompany } from '@fusion/common';
import { Action } from '@ngrx/store';
import { RecruitingPermissionType } from '../../models/enums';
import { IRecruitingClient } from '../../models/interfaces';

export enum RecruitingClientsActionTypes {
  LoadRecruitingClients = '[Recruiting clients] Load',
  LoadRecruitingClientsSuccess = '[Recruiting clients] Load Success',
  LoadRecruitingClientsFail = '[Recruiting clients] Load Fail',
  AddRecruitingClients = '[Recruiting clients] Add',
  AddRecruitingClientsSuccess = '[Recruiting clients] Add Success',
  AddRecruitingClientsFail = '[Recruiting clients] Add Fail',
  ApproveRecruitingPermission = '[Recruiting clients] Approve',
  ApproveRecruitingPermissionSuccess = '[Recruiting clients] Approve Success',
  ApproveRecruitingPermissionFail = '[Recruiting clients] Approve Fail',
  SetCurrentRecruitingClients = '[Recruiting clients] Set Current',
  SetCurrentRecruitingClientsSuccess = '[Recruiting clients] Set Current Success',
  SetCurrentRecruitingClientsFail = '[Recruiting clients] Set Current Fail',
}

export class LoadRecruitingClients implements Action {
  readonly type = RecruitingClientsActionTypes.LoadRecruitingClients;

  constructor(public permissionType: RecruitingPermissionType) {}
}

export class LoadRecruitingClientsSuccess implements Action {
  readonly type = RecruitingClientsActionTypes.LoadRecruitingClientsSuccess;

  constructor(public payload: IRecruitingClient[]) {}
}

export class LoadRecruitingClientsFail implements Action {
  readonly type = RecruitingClientsActionTypes.LoadRecruitingClientsFail;
}

export class ApproveRecruitingPermission implements Action {
  readonly type = RecruitingClientsActionTypes.ApproveRecruitingPermission;

  constructor(public permissionId: string) {}
}

export class ApproveRecruitingPermissionSuccess implements Action {
  readonly type = RecruitingClientsActionTypes.ApproveRecruitingPermissionSuccess;

  constructor(public payload: any) {}
}

export class ApproveRecruitingPermissionFail implements Action {
  readonly type = RecruitingClientsActionTypes.ApproveRecruitingPermissionFail;
}

export class AddRecruitingClients implements Action {
  readonly type = RecruitingClientsActionTypes.AddRecruitingClients;
}

export class AddRecruitingClientsSuccess implements Action {
  readonly type = RecruitingClientsActionTypes.AddRecruitingClientsSuccess;

  constructor(public payload: IRecruitingClient) {}
}

export class AddRecruitingClientsFail implements Action {
  readonly type = RecruitingClientsActionTypes.AddRecruitingClientsFail;
}

export class SetCurrentRecruitingClients implements Action {
  readonly type = RecruitingClientsActionTypes.SetCurrentRecruitingClients;

  constructor(public payload: ICompany) {}
}

export class SetCurrentRecruitingClientsSuccess implements Action {
  readonly type =
    RecruitingClientsActionTypes.SetCurrentRecruitingClientsSuccess;

  constructor(public payload: IRecruitingClient) {}
}

export class SetCurrentRecruitingClientsFail implements Action {
  readonly type = RecruitingClientsActionTypes.SetCurrentRecruitingClientsFail;
}

export type RecruitingClientsActions =
  | LoadRecruitingClients
  | LoadRecruitingClientsSuccess
  | LoadRecruitingClientsFail
  | ApproveRecruitingPermission
  | ApproveRecruitingPermissionSuccess
  | ApproveRecruitingPermissionFail
  | AddRecruitingClients
  | AddRecruitingClientsSuccess
  | AddRecruitingClientsFail
  | SetCurrentRecruitingClients
  | SetCurrentRecruitingClientsSuccess
  | SetCurrentRecruitingClientsFail;
