<ng-container *ngIf="company$ | async as company">
  <div bottomBorder image class="row2__section-one">
    <div overlayGrayShade class="header col2">
      <div class="overlay-container row2">
        <ng-container
          *ngIf="currentRootPath$ | async as currentRootPath; else noBack"
        >
          <fusion-common-back
            [currentRootPath]="currentRootPath"
            [targetDir]="''"
            [buttonText]="'back'"
          ></fusion-common-back>
        </ng-container>
        <ng-template #noBack>
          <div cardVrSpacing></div>
        </ng-template>
        <div fullHeight>
          <fusion-company-header [company]="company"></fusion-company-header>
        </div>
      </div>
    </div>
    <img
      imageFit
      src="{{ company.banner || '/assets/images/job/job-banner-9.jpg' }}"
    />
  </div>
  <!--  -->

  <div pageSpacing extraLargePage pageBody>
    <div pageContent>
      <div content>
        <div class="body col2">
          <div>
            <fusion-company-body [company]="company"></fusion-company-body>
          </div>
          <div>
            <fusion-company-summary
              [company]="company"
            ></fusion-company-summary>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
