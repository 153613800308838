import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { IStripeBillingAddress } from '../../models/interfaces';
import {
  Element as StripeElement,
  ElementOptions,
  ElementsOptions,
} from '../../stripe/models/interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { countries } from '@fusion/common';
import { StripeService } from '../../stripe/services/stripe.service';

@Component({
  selector: 'fusion-payment-add-card-dialog',
  templateUrl: './add-card-dialog.component.html',
  styleUrls: ['./add-card-dialog.component.scss'],
})
export class AddCardDialogComponent implements OnInit {
  @Output() notifyPaymentMethod: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Input() address: IStripeBillingAddress;
  @Input() clientSecret: string;

  checked = false;
  error: any;
  complete = false;
  element: StripeElement;
  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '80px',
        fontWeight: 300,
        fontFamily: '"Montserrat", sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.55)',
        },
      },
    },
  };
  elementsOptions: ElementsOptions = {
    locale: 'en',
  };
  billingAddressFormGroup: UntypedFormGroup;
  countries = countries;

  constructor(
    public dialogRef: MatDialogRef<AddCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private _stripe: StripeService
  ) {}

  ngOnInit() {
    if (this.data) {
      this.address = this.data.address;
      this.clientSecret = this.data.clientSecret;
    }

    this.billingAddressFormGroup = this._formBuilder.group({
      default_address: [''],
      name: ['', Validators.required],
      line1: ['', Validators.required],
      line2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postal_code: ['', Validators.required],
      country: ['', Validators.required],
      default: [''],
    });

    this.billingAddressFormGroup.controls[
      'default_address'
    ].valueChanges.subscribe((val) => {
      if (val && this.address) {
        this.billingAddressFormGroup.controls['line1'].setValue(
          this.address.line1
        );
        this.billingAddressFormGroup.controls['line2'].setValue(
          this.address.line2
        );
        this.billingAddressFormGroup.controls['city'].setValue(
          this.address.city
        );
        this.billingAddressFormGroup.controls['state'].setValue(
          this.address.state
        );
        this.billingAddressFormGroup.controls['postal_code'].setValue(
          this.address.postal_code
        );
        this.billingAddressFormGroup.controls['country'].setValue(
          this.address.country
        );
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  cardUpdated(result) {
    this.element = result.element;
    this.complete = result.card.complete;
    this.error = undefined;
  }

  formSubmit(value) {
    const billing: IStripeBillingAddress = {
      name: value.name,
      line1: value.line1,
      line2: value.line2,
      city: value.city,
      state: value.state,
      country: value.country,
    };
    this.confirmSetupSetup(billing);
  }

  confirmSetupSetup(billing: IStripeBillingAddress) {
    if (this.clientSecret) {
      this._stripe
        .confirmSetupSetup(this.clientSecret, {
          payment_method: {
            card: this.element,
            billing_details: {
              name: billing.name,
              address: {
                line1: billing.line1,
                line2: billing.line2,
                city: billing.city,
                state: billing.state,
                country: billing.country,
              },
            },
          },
        })
        .subscribe((result) => {
          if (result.setupIntent) {
            const paymentMethod = result.setupIntent.payment_method;
            this.notifyPaymentMethod.emit(paymentMethod);
            this.dialogRef.close();
          } else {
            console.log('confirmSetupSetup => Error:', result);
          }
        });
    }
  }
}
