import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: any, count?: any): any {
    let convertedValue = this.convertToPlain(value);
    if (convertedValue && convertedValue.length > count) {
      convertedValue = convertedValue.substring(0, count).trim() + '...';
    }
    return convertedValue;
  }

  convertToPlain(html) {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }
}
