import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFormConfig } from '../../models/interfaces';

@Component({
  selector: 'fusion-form-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  @Input() formConfigs: IFormConfig[];
  @Input() formValidationRules: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
