<div *ngIf="keywords || searchCategory" class="col-12 b-padding-5 search-result">
  Search Result: <span *ngIf="keywords">for </span>
  <span bold [innerHTML]="keywords" class="keywords"></span>
  {{ resultType }}
  <span *ngIf="searchCategory">
    of
    <span class="keywords">
      <mat-chip-listbox aria-label="Fish selection">
        <mat-chip-option
          color="primary"
          [selectable]="true"
          [removable]="true"
          (removed)="notifyRemoveCategory.emit()"
        >
          {{ searchCategory.name || searchCategory }}
          <mat-icon color="primary" matChipRemove *ngIf="removable"
            >cancel</mat-icon
          >
        </mat-chip-option>
      </mat-chip-listbox>
    </span>
    category.
  </span>
</div>
