import {
  CustomerActionTypes,
  OrderActionTypes,
  PaymentActionTypes,
  TransactionActionTypes
} from "../../store/actions";

export type FusionPaymentError =
  | OrderActionTypes
  | PaymentActionTypes
  | CustomerActionTypes
  | TransactionActionTypes;

export const FusionPaymentError = {
  ...OrderActionTypes,
  ...PaymentActionTypes,
  ...CustomerActionTypes,
  ...TransactionActionTypes
};
