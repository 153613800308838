<fusion-common-back
  [currentRootPath]="currentRootPath$ | async"
  [buttonText]="'back'"
></fusion-common-back>
<div pageHrSpacing largePage pageBody>
  <div pageTitle></div>
  <div pageContentNoAd>
    <div content class="rowN">
      <calendar-event-edit-form
        [users]="users$ | async"
        [event]="currentEvent$ | async"
        [applicantGuest]="applicantGuest$ | async"
        (notifySave)="onNotifySave()"
        (notifyGuestUser)="onNotifyGuestUser($event)"
        (notifyAutocompletion)="onNotifyAutocompletion($event)"
        (notifyEventUpdate)="onNotifyEventUpdate($event)"
        (notifyRemoveGuest)="onNotifyRemoveGuest($event)"
        (notifyJoinVideoConference)="onNotifyJoinVideoConference($event)"
        (notifyCreateVideoConference)="onNotifyCreateVideoConference($event)"
      ></calendar-event-edit-form>
    </div>
  </div>
</div>
