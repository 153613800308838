import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FusionCommonModule } from '@fusion/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers/index';
import { effects } from './store/effects/index';
import { SearchResultComponent } from './containers/search-result/search-result.component';
import { PaginatorComponent } from './containers/paginator/paginator.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [SearchResultComponent, PaginatorComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    FusionCommonModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
    StoreModule.forFeature('fusionSearch', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [SearchResultComponent, PaginatorComponent],
})
export class FusionSearchModule {}
