<div card class="row">
  <div *ngIf="formConfig && validationRules" class="col-12">
    <div
      *ngFor="let config of formConfig; let i = index"
      class="list-option card-content-padding"
    >
      <div
        pointer
        microTitle
        semiBold
        (click)="displayLevel[i].value = displayLevel[i].value ? false : true"
        class="col2"
      >
        <div centering topLeft>{{ config.label }}</div>
        <div>
          <mat-icon>{{
            displayLevel[i].value ? 'expand_less' : 'expand_more'
          }}</mat-icon>
        </div>
      </div>
      <mat-selection-list
        [ngClass]="{ 'visually-hidden': !displayLevel[i].value }"
        color="primary"
        dense
      >
        <mat-list-option
          color="primary"
          selected="{{
            getSelectionState(
              option,
              config.name,
              selectedJobLevels,
              selectedJobTypes
            )
          }}"
          #selectedOption
          class="options"
          *ngFor="let option of config.options"
          (click)="submitOption(option, config.name, selectedOption.selected)"
        >
          {{ option }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
</div>
