<div
  *ngIf="!couponBalance; else redeemConfirmation"
  border
  radius
  cardSpacing
  class="redeem col2"
>
  <div class="col2__section-one long-container">
    <mat-form-field [style.fontSize]="'18px'">
      <input #couponCode matInput placeholder="Coupon Code" required />
    </mat-form-field>
  </div>
  <div centering centerLeft class="col2__section-two">
    <button
      mat-flat-button
      [disabled]="!couponCode.value"
      color="primary"
      (click)="notifyRedeamCoupon.emit(couponCode.value)"
    >
      Redeem
    </button>
  </div>
</div>

<ng-template #redeemConfirmation>
  <ng-container *ngIf="couponBalance.isRedeemed; else redeemFail">
    <div border radius cardSpacing centeringText cardSpacing class="row3">
      <div smallTitle semiBold class="row3__section-one">
        Coupon Redeem Successful!
      </div>
      <div exSmallTitle g4FontColor class="row3__section-two">
        Your coupon redeem has successfully completed. ${{
          couponBalance.amount
        }}
        has been added in your wallet.
      </div>
      <div class="row3__section-three">
        <button
          mat-flat-button
          (click)="notifyResetCouponBalance.emit()"
          color="primary"
        >
          Reset to redeem another coupon
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #redeemFail>
  <div errorBorder radius cardSpacing centeringText cardSpacing class="row3">
    <div smallTitle semiBold class="row3__section-one">
      Coupon Redeem Failed!
    </div>
    <div
      *ngIf="couponBalance.isUsed"
      exSmallTitle
      g4FontColor
      class="row3__section-two"
    >
      Your coupon was redeemed already. You can redeem any coupon only one time.
    </div>
    <div
      *ngIf="couponBalance.isExpired"
      exSmallTitle
      g4FontColor
      class="row3__section-two"
    >
      Your coupon is expired. You can not redeem any expired coupon.
    </div>
    <div
      *ngIf="couponBalance.isInvalid"
      exSmallTitle
      g4FontColor
      class="row3__section-two"
    >
      Your coupon is invalid. Please try a valid coupon to redeem.
    </div>
    <div class="row3__section-three">
      <button
        mat-flat-button
        color="accent"
        (click)="notifyResetCouponBalance.emit()"
      >
        Reset to redeem another coupon
      </button>
    </div>
  </div>
</ng-template>
