import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';
import { SalaryComponent } from './components/salary/salary.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CkeditorComponent } from './components/ckeditor/ckeditor.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { RadioComponent } from './components/radio/radio.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { FormHandlerComponent } from './containers/form-handler/form-handler.component';
import { FormTemplateComponent } from './containers/form-template/form-template.component';
import { UploadComponent } from './components/upload/upload.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { OptionListComponent } from './containers/option-list/option-list.component';
import { SearchComponent } from './components/search/search.component';
import { FormDialogComponent } from './dialogs/form-dialog/form-dialog.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { FusionCommonModule } from '@fusion/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { DialogTemplateComponent } from './containers/dialog-template/dialog-template.component';
import { AddressComponent } from './components/address/address.component';
import { HomePropertyComponent } from './components/home-property/home-property.component';
import { NameComponent } from './components/name/name.component';
import { JobMetadataComponent } from './components/job-metadata/job-metadata.component';
import { JobApplyTypeComponent } from './components/job-apply-type/job-apply-type.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { RouterModule } from '@angular/router';
import { TimeComponent } from './components/time/time.component';
import { DatetimepickerComponent } from './components/datetimepicker/datetimepicker.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { QuillEditorComponent } from './components/quill-editor/quill-editor.component';
import { FormStepperComponent } from './containers/form-stepper/form-stepper.component';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { FormComponent } from './containers/form/form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    SalaryComponent,
    CheckboxComponent,
    CkeditorComponent,
    DatepickerComponent,
    DropdownComponent,
    RadioComponent,
    TextFieldComponent,
    TextareaComponent,
    AutoCompleteComponent,
    FormHandlerComponent,
    FormTemplateComponent,
    UploadComponent,
    PaginatorComponent,
    OptionListComponent,
    SearchComponent,
    FormDialogComponent,
    NotificationDialogComponent,
    DialogTemplateComponent,
    AddressComponent,
    HomePropertyComponent,
    NameComponent,
    JobMetadataComponent,
    JobApplyTypeComponent,
    DateRangeComponent,
    TimeComponent,
    DatetimepickerComponent,
    TimepickerComponent,
    QuillEditorComponent,
    FormStepperComponent,
    ChipListComponent,
    FormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    FusionCommonModule,
    CKEditorModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatRippleModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatTabsModule,
    QuillModule.forRoot(),
    RouterModule,
    FlatpickrModule.forRoot(),
  ],
  exports: [
    FormTemplateComponent,
    OptionListComponent,
    SearchComponent,
    FormDialogComponent,
    NotificationDialogComponent,
    DialogTemplateComponent,
    FormStepperComponent,
    FormComponent,
  ],
})
export class FusionFormModule {}
