import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.job.host;
    this.api = this.configService.getConfig().services.job.api;
  }

  getContentItems(limit: number, offset: number, keywords): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/jobs?limit=${limit}&offset=${offset}&keywords=${keywords}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  getJobs(payload: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/search`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, payload, options);
  }

  getJob(jobId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/jobs/` + jobId;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  postJobApllication(jobId: string, jobRaw: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/jobs/${jobId}/apply`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, jobRaw, options);
  }

  getRecruitingClients(
    permissionType: any,
    recruiterCompanyId: string
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/${permissionType}/${recruiterCompanyId}/permissions`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  requestRecruitingPermission(permissionRaw: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/permissions`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, permissionRaw, options);
  }

  updateRecruitingPermission(
    employerId: string,
    permissionId: string,
    permissionRaw: any
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/employers/${employerId}/permissions/${permissionId}/status`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.put(fullUrl, permissionRaw, options);
  }

  postJobScreening(applicationId: string, screeningRaw: any): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/applications/${applicationId}/screenings`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, screeningRaw, options);
  }

  updateJobScreening(
    applicationId: string,
    screeningId: string,
    screeningRaw: any
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/applications/${applicationId}/screenings/${screeningId}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.put(fullUrl, screeningRaw, options);
  }

  getJobApplications(jobId: string): Observable<any> {
    const fullUrl = `${this.baseUrl}${this.api}/jobs/${jobId}/applications`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  getJobseekerApplications(applicantId: string): Observable<any> {
    const fullUrl = `${this.baseUrl}${this.api}/applicants/${applicantId}/applications`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  getApplicationById(id: string): Observable<any> {
    const fullUrl = `${this.baseUrl}${this.api}/applications/${id}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  postJob(job: any) {
    const fullUrl = `${this.baseUrl}${this.api}/jobs`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, job, options);
  }

  updateJob(jobId: string, job: any) {
    const fullUrl = `${this.baseUrl}${this.api}/jobs/${jobId}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.put(fullUrl, job, options);
  }

  getOpenPositions(payload: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/positions/search`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, payload, options);
  }

  getOpenPosition(openPositionId: string) {
    const fullUrl = `${this.baseUrl}${this.api}/positions/${openPositionId}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  postOpenPosition(openPositionRaw: any) {
    const fullUrl = `${this.baseUrl}${this.api}/positions`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, openPositionRaw, options);
  }

  updateOpenPosition(openPositionId: string, job: any) {
    const fullUrl = `${this.baseUrl}${this.api}/positions/${openPositionId}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.put(fullUrl, job, options);
  }

  updateOpenPositionStatus(openPositionId: string, job: any) {
    const fullUrl = `${this.baseUrl}${this.api}/positions/${openPositionId}/status`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.put(fullUrl, job, options);
  }
}
