<div class="row2">
  <div stickyHeader cardSmallSpacing centering centerRight>
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="icon-display-90">close</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="event$ | async as event">
    <calendar-update-event
      [users]="users$ | async"
      [event]="event$ | async"
      [applicantGuest]="applicantGuest$ | async"
      (notifySave)="notifySave.emit()"
      (notifyGuestUser)="notifyGuestUser.emit($event)"
      (notifyAutocompletion)="notifyAutocompletion.emit($event)"
      (notifyEventUpdate)="notifyEventUpdate.emit($event)"
      (notifyJoinVideoConference)="notifyJoinVideoConference.emit($event)"
      (notifyCreateVideoConference)="notifyCreateVideoConference.emit($event)"
    ></calendar-update-event>
  </ng-container>
</div>
