import { Component, OnInit, Input } from '@angular/core';
import { IJob } from '../../models/interfaces/job.interfaces';

@Component({
  selector: 'fusion-job-body',
  templateUrl: './job-body.component.html',
  styleUrls: ['./job-body.component.scss'],
})
export class JobBodyComponent implements OnInit {
  @Input() job: IJob;

  constructor() {}

  ngOnInit() {}
}
