import { IUserPermission } from '../../models/interfaces';
import {
  UserPermissionsActionTypes,
  UserPermissionsActions,
} from '../actions/user-permissions.actions';

export interface UserPermissionsState {
  data: IUserPermission;
  loading: boolean;
  loaded: boolean;
}

export const initialState: UserPermissionsState = {
  data: null,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: UserPermissionsActions
): UserPermissionsState {
  switch (action.type) {
    case UserPermissionsActionTypes.LoadUserPermissions: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserPermissionsActionTypes.LoadUserPermissionsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case UserPermissionsActionTypes.LoadUserPermissionsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
