import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import {
  NgModule,
  ErrorHandler,
  Inject,
  PLATFORM_ID,
  APP_ID,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FusionCommonModule } from '@fusion/common';
import { FusionServiceModule, TagManagerModule } from '@fusion/service';
import { FusionFormModule } from '@fusion/form';
import { FusionRouterModule } from '@fusion/router';
import { FusionoAuthModule } from '@fusion/oauth';
import { StoreModule } from '@ngrx/store';
import { metaReducers } from 'projects/employer/src/app/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SubscriptionsComponent } from './containers/subscriptions/subscriptions.component';
import { reducers } from './store/reducers/index';
import { effects } from './store/effects/index';
import { FusionPipeModule } from '@fusion/pipe';
import { SubscribeComponent } from './containers/subscribe/subscribe.component';
import { FusionCompanyModule } from '@fusion/company';
import { CompaniesComponent } from './containers/companies/companies.component';
import { FusionSubscriptionModule } from '@fusion/subscription';
import { FusionThemeModule } from '@fusion/theme';
import {
  ServerErrorInterceptor,
  ErrorHandlerService,
  FusionErrorModule,
} from '@fusion/error';
import { FusionSearchModule } from '@fusion/search';
import { SettingsComponent } from './containers/settings/settings.component';
import { NotificationsComponent } from './containers/notifications/notifications.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { UpdateEmailComponent } from './components/update-email/update-email.component';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';
import { WalletComponent } from './containers/wallet/wallet.component';
import { FusionPaymentModule } from '@fusion/payment';
import { FundingComponent } from './containers/funding/funding.component';
import { FundingPaymentComponent } from './containers/funding-payment/funding-payment.component';
import { ContributorsComponent } from './containers/contributors/contributors.component';
import { MosquesComponent } from './containers/mosques/mosques.component';
import { AddMosqueComponent } from './containers/add-mosque/add-mosque.component';
import { MosqueSearchComponent } from './components/mosque-search/mosque-search.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { SearchComponent } from './containers/search/search.component';
import { JobComponent } from './containers/job/job.component';
import { FusionCareerProfileModule } from '@fusion/career-profile';
import { FusionJobModule } from '@fusion/job';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { isPlatformBrowser } from '@angular/common';
import { FusionCalendarModule } from '@fusion/calendar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    SearchComponent,
    JobComponent,
    SubscriptionsComponent,
    SubscribeComponent,
    CompaniesComponent,
    SettingsComponent,
    NotificationsComponent,
    UpdatePasswordComponent,
    UpdateEmailComponent,
    UpdateProfileComponent,
    WalletComponent,
    FundingComponent,
    FundingPaymentComponent,
    ContributorsComponent,
    MosquesComponent,
    AddMosqueComponent,
    MosqueSearchComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    TagManagerModule.forRoot(environment),
    FusionServiceModule.forRoot(environment),
    FusionThemeModule.forRoot(),
    FusionCommonModule,
    FusionCareerProfileModule,
    FusionJobModule,
    FusionFormModule,
    FusionRouterModule,
    FusionoAuthModule,
    MatTabsModule,
    MatDividerModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
    FusionPipeModule,
    FusionCompanyModule,
    FusionSubscriptionModule,
    FusionErrorModule,
    FusionSearchModule,
    FusionCalendarModule,
    MatProgressSpinnerModule,
    FusionPaymentModule.forRoot(environment),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
