import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromSearch from './search.reducer';
import * as fromPrivateSearch from './private-search.reducer';

export interface FusionSearchState {
  publicSearch: fromSearch.SearchState;
  privateSearch: fromPrivateSearch.PrivateSearchState;
}

export const reducers: ActionReducerMap<FusionSearchState> = {
  publicSearch: fromSearch.reducer,
  privateSearch: fromPrivateSearch.reducer
};

export const getFusionSearchState = createFeatureSelector<FusionSearchState>(
  'fusionSearch'
);

// export reducer state interfaces
export { SearchState } from './search.reducer';
export { PrivateSearchState } from './private-search.reducer';
