<div pageSpacing extraLargePage pageBody>
  <div pageTitle>
    <h1>Manage Job Applications!</h1>
  </div>
  <div pageContent>
    <div content>
      <div class="rowN">
        <ng-container *ngFor="let application of jobApplications$ | async">
          <div border radius cardSpacing bgGray1>
            <div class="application col4">
              <div class="col4__section-one col2">
                <div smallLogo>
                  <img
                    pointer
                    imageFit
                    src="{{
                      application?.company?.logo ||
                        '/assets/images/job/job-banner-9.jpg'
                    }}"
                    (click)="onViewCompany(application?.company?.id)"
                  />
                </div>
                <div>
                  <div extraSmallTitle g6FontColor semiBold>
                    {{ application?.job?.position }}
                  </div>
                  <div
                    pointer
                    nanoTitle
                    errorFontColor
                    (click)="onViewCompany(application?.company?.id)"
                  >
                    {{ application?.company.name }}
                  </div>
                  <div nanoTitle g5FontColor>
                    <span *ngIf="application?.job?.address"
                      >{{ application?.job?.address }},
                    </span>
                    <span
                      >{{ application?.job?.city
                      }}<span *ngIf="application?.job?.state"
                        >, {{ application?.job?.state }}</span
                      >
                      - {{ application?.job?.country }}</span
                    >
                    <span dot></span>
                    <span>{{
                      application?.latestScreening.updatedAt | fancyTime
                    }}</span>
                  </div>
                </div>
              </div>
              <div centering class="col4__section-two">
                <div center>
                  <div semiBold extraSmallFont>
                    {{ application?.latestScreening?.round || '-'
                    }}<sup>{{
                      application?.latestScreening?.round | ordinalNumber
                    }}</sup>
                  </div>
                  <div picoFont>Round</div>
                </div>
              </div>
              <div centering class="col4__section-three">
                <span center chip light live>{{
                  application?.latestScreening?.status || 'pending'
                }}</span>
              </div>
              <div fullWidth centering center class="col4__section-four">
                <a
                  class="custom-round-button"
                  mat-button
                  color="warn"
                  (click)="onManageJobApplication(application)"
                  >Manage</a
                >
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div adSpace></div>
  </div>
</div>
