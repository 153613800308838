<form [formGroup]="billingAddressFormGroup">
  <div cardVrSpacing class="h-padding-8 rowN">
    <div mediumTitle microBold>Add new card</div>

    <div border radius cardSpacing>
      <div microTitle class="">
        All major credit / debit cards accepted.
      </div>
      <fusion-payment-stripe
        [options]="cardOptions"
        [elementsOptions]="elementsOptions"
        (change)="cardUpdated($event)"
        (error)="error = $event"
      ></fusion-payment-stripe>
    </div>
    <div border radius cardSpacing class="long-container">
      <mat-form-field>
        <input
          #loadAmount
          matInput
          placeholder="Cardholder name"
          formControlName="name"
          class="example-right-align"
          required
        />
      </mat-form-field>
    </div>
    <div border radius cardSpacing class="row2">
      <div class="row2__section-one">
        <div microTitle semiBold>BILLING ADDRESS</div>
        <div microTitle g3FontColor>
          Please provide the billing address associated with the credit card
          you've provided.
        </div>
      </div>
      <div class="row2__section-two">
        <!-- <div>
          <mat-checkbox formControlName="default_address"
            >Same as my account address</mat-checkbox
          >
        </div> -->

        <div
          *ngIf="
            !billingAddressFormGroup.controls['default_address'].value;
            else currentAddress
          "
        >
          <div class="long-container">
            <mat-form-field>
              <input
                #loadAmount
                matInput
                placeholder="Address line 1"
                formControlName="line1"
                class="example-right-align"
                required
              />
            </mat-form-field>
          </div>

          <div class="long-container">
            <mat-form-field>
              <input
                #loadAmount
                matInput
                placeholder="Address line 2"
                formControlName="line2"
                class="example-right-align"
              />
            </mat-form-field>
          </div>

          <div class="col2">
            <div class="long-container col2__section-one">
              <mat-form-field>
                <input
                  #loadAmount
                  matInput
                  placeholder="City"
                  formControlName="city"
                  class="example-right-align"
                  required
                />
              </mat-form-field>
            </div>

            <div class="long-container col2__section-two">
              <mat-form-field>
                <input
                  #loadAmount
                  matInput
                  placeholder="State"
                  formControlName="state"
                  class="example-right-align"
                  required
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col2">
            <div class="long-container col2__section-one">
              <mat-form-field>
                <input
                  #loadAmount
                  matInput
                  placeholder="Zip Code"
                  formControlName="postal_code"
                  class="example-right-align"
                  required
                />
              </mat-form-field>
            </div>

            <div class="long-container col2__section-two">
              <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select formControlName="country" required>
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country.code"
                  >
                    {{ country.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <mat-checkbox formControlName="default"
        >Set as default payment method</mat-checkbox
      >
    </div>

    <div centering center class="t-padding-5 b-padding-7">
      <span class="r-padding-3">
        <button mat-button (click)="closeDialog()">
          cancel
        </button>
      </span>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!complete || !billingAddressFormGroup.valid"
        (click)="formSubmit(billingAddressFormGroup.value)"
      >
        save card
      </button>
    </div>
  </div>
</form>

<ng-template #currentAddress>
  <ng-container *ngIf="address">
    <div border radius cardSpacing class="t-margin-5">
      <div semiBold g4FontColor>
        <span>{{ address.line1 }}</span>
        <span *ngIf="address.line2">, {{ address.line2 }}</span>
      </div>
      <div g4FontColor>
        <span>{{ address.city }}</span>
        <span>, {{ address.state }}</span>
        <span *ngIf="address.postal_code"> - {{ address.postal_code }}</span>
      </div>
      <div g4FontColor>
        <span>{{ address.country }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
