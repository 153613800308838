import { Action } from '@ngrx/store';

export enum CurrentApplicationActionTypes {
  LoadCurrentApplication = '[Job Current Application] Load',
  LoadCurrentApplicationSuccess = '[Job Current Application] Load Success',
  LoadCurrentApplicationFail = '[Job Current Application] Load Fail'
}

export class LoadCurrentApplication implements Action {
  readonly type = CurrentApplicationActionTypes.LoadCurrentApplication;
}

export class LoadCurrentApplicationSuccess implements Action {
  readonly type = CurrentApplicationActionTypes.LoadCurrentApplicationSuccess;

  constructor( public payload: any ) { }
}

export class LoadCurrentApplicationFail implements Action {
  readonly type = CurrentApplicationActionTypes.LoadCurrentApplicationFail;
}

export type CurrentApplicationActions =
  | LoadCurrentApplication
  | LoadCurrentApplicationSuccess
  | LoadCurrentApplicationFail;
