import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  UrlTree,
} from '@angular/router';
import { Observable, interval } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, delayWhen, tap, take, filter } from 'rxjs/operators';
import { FusionPaymentState } from '../reducers';
import { LoadCustomer, LoadWallet } from '../actions';
import { getWalletState } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadWalletGuard implements CanActivate {
  constructor(private store: Store<FusionPaymentState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // always load wallet for latest data
    this.store.dispatch(new LoadCustomer());
    this.store.dispatch(new LoadWallet());

    return this.store.select(getWalletState).pipe(
      take(2),
      filter((wallet) => wallet !== null),
      map(() => true)
    );
  }
}
