import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { FusionoAuthState } from '../reducers';
import { getUserSessionToken } from '../selectors';
import { filter, map, take, tap } from 'rxjs/operators';
import { StartUserSession } from '../actions';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserSessionGuard implements CanActivateChild {
  constructor(
    private store: Store<FusionoAuthState>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!isPlatformBrowser(this.platformId)) {
      return of(true);
    }
    return this.store.select(getUserSessionToken).pipe(
      tap((token) => {
        if (!token) {
          this.store.dispatch(new StartUserSession());
        }
      }),
      take(2),
      map(() => true)
    );
  }
}
