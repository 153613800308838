// import guards
import { OauthRouteGuard } from './oauth-route.guard';
import { OauthSignInGuard } from './oauth-sign-in.guard';
import { OauthUserGuard } from './oauth-user.guard';
import { AppSessionGuard } from './app-session.guard';
import { UserSessionGuard } from './user-session.guard';

// include all guards
export const guards: any[] = [
  OauthRouteGuard,
  OauthUserGuard,
  OauthSignInGuard,
  AppSessionGuard,
  UserSessionGuard,
];

// export from guards
export * from './oauth-route.guard';
export * from './oauth-user.guard';
export * from './oauth-sign-in.guard';
export * from './app-session.guard';
export * from './user-session.guard';
