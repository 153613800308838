import { NgModule } from '@angular/core';
import { HeaderComponent } from './containers/header/header.component';
import { BodyComponent } from './containers/body/body.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers/index';
import { effects } from './store/effects/index';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FusionCommonModule } from '@fusion/common';
import { FusionPipeModule } from '@fusion/pipe';
import { ExperiencesComponent } from './components/experiences/experiences.component';
import { EducationsComponent } from './components/educations/educations.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { SkillsComponent } from './components/skills/skills.component';
import { RatingsComponent } from './components/ratings/ratings.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { FusionFormModule } from '@fusion/form';
import { guards } from './store/guards/index';
import { CareerSummaryComponent } from './components/career-summary/career-summary.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    HeaderComponent,
    BodyComponent,
    ExperiencesComponent,
    EducationsComponent,
    ProjectsComponent,
    PublicationsComponent,
    SkillsComponent,
    RatingsComponent,
    CardHeaderComponent,
    CareerSummaryComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FusionCommonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatButtonModule,
    FusionPipeModule,
    FusionFormModule,
    StoreModule.forFeature('fusionCareerProfile', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [HeaderComponent, BodyComponent],
  providers: [guards],
})
export class FusionCareerProfileModule {}
