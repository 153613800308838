<div *ngIf="description" class="show-more">
  <span
    [innerHTML]="
      (description | slice: 0:textLength) +
      (!(description.length < textLength) && isShowLess ? '... ' : ' ')
    "
  ></span>
  <a
    semiBold
    *ngIf="!(description.length < textLength)"
    (click)="setLength(description)"
    >{{ isShowLess ? '[show more]' : '[show less]' }}</a
  >
</div>
