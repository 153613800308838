import { IEventGuest } from '../../models';
import { GuestsActionTypes, GuestsActions } from '../actions/guests.actions';

export interface GuestsState {
  data: IEventGuest[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: GuestsState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: GuestsActions
): GuestsState {
  switch (action.type) {
    case GuestsActionTypes.LoadGuests: {
      return {
        ...state,
        loading: true,
      };
    }
    case GuestsActionTypes.LoadGuestsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case GuestsActionTypes.LoadGuestsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
