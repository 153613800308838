<ng-container *ngIf="appBaseUrls$ | async as appBaseUrls">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <!-- <button
          #responsiveView
          mat-icon-button
          fxShow="true"
          fxHide.gt-sm
          (click)="toggleNav()"
        >
          <mat-icon>menu</mat-icon>
        </button> -->
      <img
        pointer
        brandLogo
        (click)="onClickOfBrand()"
        alt="elitecareer"
        src="/assets/images/users/company-logo/dot2circle-logo-white.svg"
      />
      <span pointer class="l-padding-4" (click)="onClickOfBrand()">{{
        appSettings?.brandName || 'Dot to Circle'
      }}</span>
      <span class="menu-spacer"></span>
      <div class="menu" fxShow="true" fxHide.lt-md>
        <!-- <a
          *ngFor="let option of topNavigation"
          href="{{ option.path }}"
          mat-button
          >{{ option.title }}</a
        > -->
        <ng-container *ngIf="!appSettings || !appSettings.isStandalone">
          <ng-container *ngIf="!(token$ | async); else oauth_user">
            <span class="navigation">
              <a mat-button routerLink="/signin">Sign In</a>
              <a mat-button routerLink="/signup">Sign Up</a>
            </span>
          </ng-container>
        </ng-container>

        <ng-template #oauth_user>
          <button mat-button [matMenuTriggerFor]="menu">
            <div *ngIf="oAuthUser$ | async as user" centering>
              <span extraSmallProfileImage class="r-margin-3">
                <img
                  imageFit
                  src="{{
                    user.profilePicture ||
                      'assets/images/users/profile-picture/profile.png'
                  }}"
                />
              </span>
              <span>{{ oAuthUserName$ | async }}</span>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            <!-- user -->
            <ng-container
              *ngIf="oAuthUserTopNavigation$ | async as userTopNavigation"
            >
              <a
                (click)="navigateToUserProfile(userTopNavigation?.targetPath)"
                mat-menu-item
                [matMenuTriggerFor]="userSubscriptionMenu"
              >
                <div centering>
                  <span extraSmallProfileImage class="r-margin-3">
                    <img
                      imageFit
                      src="{{
                        userTopNavigation.profilePicture ||
                          'assets/images/users/profile-picture/profile.png'
                      }}"
                    />
                  </span>
                  <span>{{ userTopNavigation.title }}</span>
                </div>
              </a>
              <!-- user sub menu -->
              <mat-menu #userSubscriptionMenu="matMenu">
                <a
                  (click)="navigateToUserProfile(userTopNavigation?.targetPath)"
                  mat-menu-item
                  bottomBorder
                >
                  <div centering>
                    <span extraSmallProfileImage class="r-margin-3">
                      <img
                        imageFit
                        src="{{
                          userTopNavigation.profilePicture ||
                            'assets/images/users/profile-picture/profile.png'
                        }}"
                      />
                    </span>
                    <span>Career Profile</span>
                  </div>
                </a>

                <!-- <button mat-menu-item disabled>
                  <mat-icon>verified_user</mat-icon>
                  <span>Personal Subscriptions</span>
                </button> -->
                <ng-container
                  *ngFor="let subscription of userTopNavigation.subscriptions"
                >
                  <a
                    (click)="navigateToSubscription(subscription)"
                    mat-menu-item
                  >
                    <div centering>
                      <span>{{ subscription.applicationName }} Service </span>
                    </div>
                  </a>
                </ng-container>
              </mat-menu>
            </ng-container>

            <!-- companies -->
            <ng-container *ngFor="let company of oAuthTopNavigations$ | async">
              <ng-container
                *ngIf="company?.subscriptions.length; else noSubMenu"
              >
                <a
                  (click)="navigateToCompany(appBaseUrls, company)"
                  mat-menu-item
                  [matMenuTriggerFor]="companySubscriptionMenu"
                >
                  <div centering center>
                    <span smallLogo class="r-margin-3">
                      <img
                        imageFit
                        src="{{
                          company?.logo ||
                            '/assets/images/users/company-logo/logoPlaceholder.png'
                        }}"
                      />
                    </span>
                    <span>{{ company.title | truncate : 22 }}</span>
                  </div>
                </a>

                <!-- compnay sub menu -->
                <mat-menu #companySubscriptionMenu="matMenu">
                  <a
                    (click)="navigateToCompany(appBaseUrls, company)"
                    mat-menu-item
                    bottomBorder
                  >
                    <div centering center>
                      <span smallLogo class="r-margin-3">
                        <img
                          imageFit
                          src="{{
                            company?.logo ||
                              '/assets/images/users/company-logo/logoPlaceholder.png'
                          }}"
                        />
                      </span>
                      <span>Company Profile</span>
                    </div>
                  </a>

                  <!-- <button mat-menu-item disabled>
                    <mat-icon>verified_user</mat-icon>
                    <span>Company Subscriptions</span>
                  </button> -->
                  <ng-container
                    *ngFor="let subscription of company.subscriptions"
                  >
                    <a
                      (click)="navigateToSubscription(subscription)"
                      mat-menu-item
                    >
                      <div centering>
                        <span>{{ subscription.applicationName }} Service </span>
                      </div>
                    </a>
                  </ng-container>
                </mat-menu>
              </ng-container>
              <ng-template #noSubMenu>
                <a
                  (click)="navigateToCompany(appBaseUrls, company)"
                  mat-menu-item
                >
                  <div centering>
                    <span smallLogo class="r-margin-3">
                      <img
                        imageFit
                        src="{{
                          company?.logo ||
                            '/assets/images/users/company-logo/logoPlaceholder.png'
                        }}"
                      />
                    </span>
                    <span>{{ company.title | truncate : 22 }}</span>
                  </div>
                </a>
              </ng-template>
            </ng-container>
          </mat-menu>
          <a
            class="custom-round-button"
            mat-flat-button
            color="accent"
            (click)="signOut()"
            >Sign Out</a
          >
        </ng-template>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-menu #menu="matMenu">
    <ng-container *ngIf="!(token$ | async); else oauth_user_2">
      <div class="menu" mat-menu-item>
        <a mat-button routerLink="/signin">Sign In</a>
        <!-- <fusion-form-dialog
            [isDialog]="true"
            [iconType]="ModalIconType.None"
            [buttonTitle]="'Sign In'"
            [formValidationRules]="signInFormValidationRules"
            [formConfigs]="signInFormConfigs"
            [formMetadata]="signInFormMetadata"
            (notifyFormSubmit)="notifySignInFormSubmit($event)"
          ></fusion-form-dialog> -->
      </div>
      <div class="menu" mat-menu-item>
        <a mat-button routerLink="/signup">Sign Up</a>
        <!-- <fusion-form-dialog
            [isDialog]="true"
            [iconType]="ModalIconType.None"
            [buttonTitle]="'Sign Up'"
            [formValidationRules]="signUpFormValidationRules"
            [formConfigs]="signUpFormConfigs"
            [formMetadata]="signUpFormMetadata"
            (notifyFormSubmit)="notifySignUpFormSubmit($event)"
          ></fusion-form-dialog> -->
      </div>
    </ng-container>

    <ng-template #oauth_user_2>
      <a
        *ngIf="authUserProfilePath$ | async as userAppBaseUrl"
        (click)="navigateToUserProfile(userAppBaseUrl)"
        mat-menu-item
      >
        <div *ngIf="oAuthUser$ | async as user" centering>
          <span extraSmallProfileImage class="r-margin-3">
            <img
              imageFit
              src="{{
                user.profilePicture ||
                  'assets/images/users/profile-picture/profile.png'
              }}"
            />
          </span>
          <span>Career Profile</span>
        </div>
      </a>
      <ng-container *ngFor="let company of oAuthTopNavigations$ | async">
        <a (click)="navigateToCompany(appBaseUrls, company)" mat-menu-item>
          <div centering>
            <span smallLogo class="r-margin-3">
              <img
                imageFit
                src="{{
                  company?.logo ||
                    '/assets/images/users/company-logo/logoPlaceholder.png'
                }}"
              />
            </span>
            <span>{{ company.title | truncate : 22 }}</span>
          </div>
        </a>
      </ng-container>
      <span mat-menu-item>
        <mat-icon color="accent">link_off</mat-icon>
        <span secondaryFontColor (click)="signOut()">Sign Out</span>
      </span>
    </ng-template>
  </mat-menu>
</ng-container>
