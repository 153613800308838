<div border radius cardSmallSpacing>
  <!-- 1 -->
  <div *ngIf="formConfigs && formValidationRules" class="row b-margin-5">
    <ng-container
      *ngFor="let config of getRequiredConfig(formConfigs, ['date'])"
    >
      <div class="col-6" *ngIf="config.name == 'date'">
        <fusion-form-datepicker
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-datepicker>
      </div>
    </ng-container>
  </div>
  <!-- 2 -->
  <div class="col3">
    <div *ngIf="formConfigs && formValidationRules" class="row b-margin-5">
      <ng-container
        *ngFor="
          let config of getRequiredConfig(formConfigs, [
            'startDatetime',
            'startTime',
            'startHour',
            'startMinute',
            'startPeriod'
          ])
        "
      >
        <div class="col-12" *ngIf="config.name == 'startDatetime'">
          <fusion-form-datetimepicker
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-datetimepicker>
        </div>
        <div class="col-4" *ngIf="config.name == 'startHour'">
          <fusion-form-dropdown
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-dropdown>
        </div>
        <div class="col-4" *ngIf="config.name == 'startMinute'">
          <fusion-form-dropdown
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-dropdown>
        </div>
        <div class="col-4" *ngIf="config.name == 'startPeriod'">
          <fusion-form-dropdown
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-dropdown>
        </div>
      </ng-container>
    </div>
    <div centering>
      <span center> - </span>
    </div>
    <!-- 3 -->
    <div *ngIf="formConfigs && formValidationRules" class="row b-margin-5">
      <ng-container
        *ngFor="
          let config of getRequiredConfig(formConfigs, [
            'endDatetime',
            'endTime',
            'endHour',
            'endMinute',
            'endPeriod'
          ])
        "
      >
        <div class="col-12" *ngIf="config.name == 'endDatetime'">
          <fusion-form-datetimepicker
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-datetimepicker>
        </div>
        <div class="col-4" *ngIf="config.name == 'endHour'">
          <fusion-form-dropdown
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-dropdown>
        </div>
        <div class="col-4" *ngIf="config.name == 'endMinute'">
          <fusion-form-dropdown
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-dropdown>
        </div>
        <div class="col-4" *ngIf="config.name == 'endPeriod'">
          <fusion-form-dropdown
            [formConfigs]="config"
            [formValidationRules]="formValidationRules"
          ></fusion-form-dropdown>
        </div>
      </ng-container>
    </div>
  </div>
</div>
