import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/index';
import { ErrorComponent } from './components/error/error.component';
import { ErrorHandlerDirective } from './directives/error-handler.directive';
import { CommonModule } from '@angular/common';
import {
  ErrorDialogComponent,
  ErrorDialogTemplateComponent,
} from './components/error-dialog/error-dialog.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    ErrorComponent,
    ErrorHandlerDirective,
    ErrorDialogComponent,
    ErrorDialogTemplateComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    StoreModule.forFeature('fusionError', reducers),
  ],
  exports: [ErrorComponent, ErrorHandlerDirective],
})
export class FusionErrorModule {}
