import { Action } from '@ngrx/store';
import { IError } from '@fusion/error';
import { FusionJobError } from '../../models/enums';
import { IJob } from '../../models/interfaces/job.interfaces';

export enum JobsActionTypes {
  LoadJobs = '[Jobs] Load',
  LoadJobsSuccess = '[Jobs] Load Success',
  LoadJobsFail = '[Jobs] Load Fail',
  LoadPrivateJobs = '[Private Jobs] Load',
  LoadPrivateJobsSuccess = '[Private Jobs] Load Success',
  LoadPrivateJobsFail = '[Private Jobs] Load Fail',
  SetJobsCount = '[Jobs] Set Jobs Count',
}

export class LoadJobs implements Action {
  readonly type = JobsActionTypes.LoadJobs;
}

export class LoadJobsSuccess implements Action {
  readonly type = JobsActionTypes.LoadJobsSuccess;

  constructor(public payload: IJob[]) {}
}

export class LoadJobsFail implements Action {
  readonly type = JobsActionTypes.LoadJobsFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class LoadPrivateJobs implements Action {
  readonly type = JobsActionTypes.LoadPrivateJobs;
}

export class LoadPrivateJobsSuccess implements Action {
  readonly type = JobsActionTypes.LoadPrivateJobsSuccess;

  constructor(public payload: IJob[]) {}
}

export class LoadPrivateJobsFail implements Action {
  readonly type = JobsActionTypes.LoadPrivateJobsFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class SetJobsCount implements Action {
  readonly type = JobsActionTypes.SetJobsCount;

  constructor(public payload: number) {}
}

export type JobsActions =
  | LoadJobs
  | LoadJobsSuccess
  | LoadJobsFail
  | LoadPrivateJobs
  | LoadPrivateJobsSuccess
  | LoadPrivateJobsFail
  | SetJobsCount;
