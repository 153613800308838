import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { mergeMap } from "rxjs/operators";

import { of } from "rxjs";
import { SetError } from "@fusion/error";
import {
  CustomerActionTypes,
  OrderActionTypes,
  PaymentActionTypes,
  TransactionActionTypes
} from "../actions/index";

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions) {}

  
  catchError$ = createEffect(() => this.actions$.pipe(
    ofType(
      OrderActionTypes.AddOrderFail,
      OrderActionTypes.AddOrderItemFail,
      OrderActionTypes.ConfirmOrderFail,
      OrderActionTypes.LoadOrderFail,
      OrderActionTypes.LoadPendingOrderFail,
      OrderActionTypes.RemoveOrderItemFail,
      OrderActionTypes.UpdateOrderItemFail,
      PaymentActionTypes.PostPaymentFail,
      TransactionActionTypes.LoadTransactionFail,
      CustomerActionTypes.SetupCustomerFail
    ),
    mergeMap((action: any) => {
      return of(new SetError(action.payload));
    })
  ));
}
