import {
  IForm,
  FormFieldType,
  FormFieldPattern,
  NonFormItemType,
} from '@fusion/form';

export const SignInForm: IForm = {
  metadata: {
    name: 'SIGN_IN_FORM',
    title: 'Sign In',
    submitButtonText: 'Sign In',
    style: {
      topMargin: '0px',
      maxWidth: '500px',
      maxHeight: '300px',
    },
  },
  configs: [
    // {
    //   type: FormFieldType.NonFormItem,
    //   nonFormItem: {
    //     label: `Or`,
    //     type: NonFormItemType.Text,
    //   },
    // },
    // {
    //   type: FormFieldType.NonFormItem,
    //   nonFormItem: {
    //     label: `We have upgraded our password security protocol, to comply with the new changes you need to reset your password by following 'Forgot your password?'.`,
    //     type: NonFormItemType.Alert,
    //   },
    // },
    {
      label: 'Email',
      name: 'email',
      type: FormFieldType.Email,
      placeholder: 'shakil.khan@dottocircle.com',
      examplePattern: 'shakil.khan@dottocircle.com',
      validation: {
        required: true,
        pattern: FormFieldPattern.Email,
        max: 100,
      },
    },
    {
      label: 'Password',
      name: 'password',
      type: FormFieldType.Password,
      placeholder: 'subject',
      validation: {
        required: true,
        max: 50,
      },
    },
    {
      label: '',
      type: FormFieldType.Checkbox,
      options: [
        {
          label: 'Remember me',
          name: 'remember',
          value: 'remember',
          validations: {
            required: false,
          },
        },
      ],
    },
    {
      type: FormFieldType.NonFormItem,
      nonFormItem: {
        label: '<a href="/reset">Forgot your password?</a>',
        type: NonFormItemType.Html,
      },
    },
  ],
};
