import { Action } from '@ngrx/store';

export enum SeoActionTypes {
  SetJobseekerSeo = '[Seo Jobseeker] Set',
  SetJobseekerSeoSuccess = '[Seo Jobseeker] Set Success',
  SetJobseekerSeoFail = '[Seo Jobseeker] Set Fail',
}

export class SetJobseekerSeo implements Action {
  readonly type = SeoActionTypes.SetJobseekerSeo;
}

export class SetJobseekerSeoSuccess implements Action {
  readonly type = SeoActionTypes.SetJobseekerSeoSuccess;

  constructor( public payload: any ) { }
}

export class SetJobseekerSeoFail implements Action {
  readonly type = SeoActionTypes.SetJobseekerSeoFail;
}

export type SeoActions =
  | SetJobseekerSeo
  | SetJobseekerSeoSuccess
  | SetJobseekerSeoFail;
