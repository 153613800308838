<fusion-common-back
  [currentRootPath]="currentRootPath$ | async"
  [buttonText]="'back'"
></fusion-common-back>

<div pageSpacing extraLargePage pageBody>
  <div pageTitle>
    <!-- <h1>Job Application!</h1> -->
  </div>
  <div pageContent>
    <div content>
      <ng-container *ngIf="job$ | async as currentJob">
        <div border radius cardSpacing bgGray1 class="rowN">
          <div primaryFontColor>
            <span smallTitle semiBold>{{ currentJob.position }}</span>
            <div microTitle class="row3__section-two">
              <span>{{ currentJob.createdAt | fancyTime }}</span>
              <span dot></span>
              <span *ngIf="currentJob.address">{{ currentJob.address }}, </span>
              <span
                >{{ currentJob.city
                }}<span *ngIf="currentJob.state">, {{ currentJob.state }}</span>
                - {{ currentJob.country }}</span
              >
            </div>
          </div>
          <div class="rowN">
            <ng-container *ngFor="let application of jobApplications$ | async">
              <div border radius cardSpacing class="application col4">
                <div class="col4__section-one col2">
                  <div extraSmallProfileImage>
                    <img
                      imageFit
                      src="{{
                        application?.applicant?.profilePicture ||
                          '/assets/images/job/job-banner-9.jpg'
                      }}"
                    />
                  </div>
                  <div>
                    <div extraSmallTitle g6FontColor semiBold>
                      {{ application?.applicant?.firstName }}
                      {{ application?.applicant?.lastName }}
                    </div>
                    <div nanoTitle g5FontColor>
                      {{ application.createdAt | fancyTime }}
                    </div>
                  </div>
                </div>
                <div centering class="col4__section-two">
                  <div center>
                    <div semiBold extraSmallFont>
                      {{ application?.latestScreening?.round || '-'
                      }}<sup>{{
                        application?.latestScreening?.round | ordinalNumber
                      }}</sup>
                    </div>
                    <div picoFont>Round</div>
                  </div>
                </div>
                <div centering class="col4__section-three">
                  <span center chip light live>{{
                    application?.latestScreening?.status || 'pending'
                  }}</span>
                </div>
                <div fullWidth centering center class="col4__section-four">
                  <a
                    class="custom-round-button"
                    mat-button
                    color="warn"
                    (click)="onManageJobApplication(application)"
                    >Manage</a
                  >
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div adSpace></div>
  </div>
</div>
