import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.subscription.host;
    this.api = this.configService.getConfig().services.subscription.api;
  }

  getApplications(userId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/applications/subscriber/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getCurrentApplication(appId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/applications/${appId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getSubscriptions(subscriberId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/subscriptions/subscriber/${subscriberId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getCompanyPermissions(companyId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/companies/${companyId}/permissions`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getUserPermissions(userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}/permissions`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getCurrentSubscription(
    subscriberId: string,
    applicationId: string
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/subscriptions/subscriber/${subscriberId}/applications/${applicationId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  addSubscription(subscription: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/subscriptions`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, subscription, options);
  }

  addPermission(companyId: string, permission: any): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/companies/${companyId}/permissions`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, permission, options);
  }

  updatePermission(
    companyId: string,
    permissionId: string,
    permission: any
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/companies/${companyId}/permissions/${permissionId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, permission, options);
  }
}
