import {
  ProfileNavigationActionTypes,
  ProfileNavigationActions,
} from '../actions/profile-navigation.actions';
import { IFeature } from '../../models/interfaces';

export interface ProfileNavigationState {
  data: IFeature[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: ProfileNavigationState = {
  data: [],
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: ProfileNavigationActions
): ProfileNavigationState {
  switch (action.type) {
    case ProfileNavigationActionTypes.SetProfileNavigation: {
      return {
        ...state,
        loading: true,
      };
    }
    case ProfileNavigationActionTypes.SetProfileNavigationSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };
    }
    case ProfileNavigationActionTypes.SetProfileNavigationFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ProfileNavigationActionTypes.SetCurrentProfileNavigation: {
      const currentNav = action.payload;
      const currentState = {
        ...state,
      };
      return {
        ...state,
        data: currentState.data.map((nav) => {
          if (nav.path === currentNav) {
            nav = {
              ...nav,
              isCurrentPath: true,
            };
          } else {
            nav = {
              ...nav,
              isCurrentPath: false,
            };
          }
          return nav;
        }),
      };
    }
  }
  return state;
}
