import {
  IForm,
  FormFieldType,
  FormFieldGroup,
  FormFieldPattern,
} from '@fusion/form';
import { noOfEmployees, countries } from '@fusion/common';

export const AddMosqueForm: IForm = {
  metadata: {
    name: 'MOSQUE_FORM',
    title: 'Add Mosque',
    description: 'add mosque / prayer place & help other muslim to pray',
    submitButtonText: 'Next',
    stepper: {
      isStepper: true
    },
  },
  configs: [
    {
      label: 'Mosque Name',
      name: 'name',
      type: FormFieldType.Text,
      placeholder: 'Mosque Name',
      validation: {
        required: true,
        min: 2,
        max: 100,
      },
    },
    // {
    //   label: "Company Type",
    //   name: "type",
    //   type: FormFieldType.Dropdown,
    //   options: companyTypes,
    //   validation: {
    //     required: true
    //   }
    // },
    // {
    //   label: "Company Category",
    //   name: "category",
    //   type: FormFieldType.Dropdown,
    //   options: companyCategories,
    //   validation: {
    //     required: true
    //   }
    // },
    {
      label: 'No of Employees',
      name: 'noOfEmployees',
      type: FormFieldType.Dropdown,
      options: noOfEmployees,
      validation: {
        required: true,
      },
    },
    {
      label: 'Date Founded',
      name: 'founded',
      type: FormFieldType.Date,
      placeholder: 'YYYY-MM-DD',
      examplePattern: '1985-12-30',
      validation: {
        required: true,
      },
    },
  ],
};
