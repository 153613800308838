import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import * as moment_ from 'moment';
import * as jwt from 'jsonwebtoken';

import { FusionoAuthState } from '../reducers/index';
import { Action, Store } from '@ngrx/store';
import {
  TokenService,
  MappingService,
  mappingType,
  UserService,
} from '@fusion/service';
import {
  oAuthFail,
  PasswordResetRequestSuccess,
  PasswordResetRequest,
  PasswordResetRequestFail,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordFail,
  ValidateResetPasswordTokenFail,
  ValidateResetPasswordTokenSuccess,
  oAuthStart,
  oAuthActionTypes,
  oAuthSuccess,
  StartUserSession,
} from '../actions/index';
import { IError, ErrorSource, ErrorHandlingType } from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { IoAuthCredentials, ISession } from '../../models/interfaces';
import { getRouterQueryParams } from '@fusion/router';
import { Params } from '@angular/router';
import { IUser } from '@fusion/common';

const moment = moment_;

@Injectable()
export class oAuthEffects {
  constructor(
    private store: Store<FusionoAuthState>,
    private actions$: Actions,
    private tokenService: TokenService,
    private userService: UserService,
    private mappingService: MappingService
  ) {}

  getUserToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<oAuthStart>(oAuthActionTypes.oAuthStart),
      map((action) => action.payload),
      mergeMap((userCredential: IoAuthCredentials) => {
        let mappedCredentials: any = {
          ...userCredential,
        };
        mappedCredentials = this.mappingService.getMappedData(
          mappedCredentials,
          mappingType.underscore
        );
        return this.tokenService.getUserToken(mappedCredentials).pipe(
          switchMap((dataResult) => {
            const decodedToken = jwt.decode(dataResult.token);
            const session: ISession = {
              token: dataResult.token,
              expiration: new Date(decodedToken.exp * 1000),
            };

            return [new StartUserSession(session), new oAuthSuccess(session)];
          }),
          catchError((error) => {
            const invalidCredentials = `Invalid email or password!`;
            const code = error.status;
            const errorPayload: IError<FusionoAuthError> = {
              code: FusionoAuthError.oAuthFail,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Message,
                message:
                  code === 404
                    ? invalidCredentials
                    : 'Sorry, we are having some issue authenticating your account. Please try again later.',
              },
            };
            return of(new oAuthFail(errorPayload));
          })
        );
      })
    )
  );

  validateResetPasswordToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(oAuthActionTypes.ValidateResetPasswordToken),
      withLatestFrom(this.store.select(getRouterQueryParams)),
      switchMap(([, params]: [Action, Params]) => {
        const userToken = params.token;
        const decodedToken = jwt.decode(userToken);
        const isExpired = this.isExpired(moment.unix(decodedToken.exp));
        if (isExpired) {
          const invalidCredentials = `You password reset link has expired. Please request another reset link.`;
          const errorPayload: IError<FusionoAuthError> = {
            code: FusionoAuthError.ValidateResetPasswordTokenFail,
            source: ErrorSource.Validation,
            data: invalidCredentials,
            config: {
              type: ErrorHandlingType.Message,
              message: invalidCredentials,
            },
          };
          return of(new ValidateResetPasswordTokenFail(errorPayload));
        }
        return of(new ValidateResetPasswordTokenSuccess());
      }),
      catchError(() => {
        const invalidCredentials = `You password reset link is invalid. Please request another reset link.`;
        const errorPayload: IError<FusionoAuthError> = {
          code: FusionoAuthError.ValidateResetPasswordTokenFail,
          source: ErrorSource.Validation,
          data: invalidCredentials,
          config: {
            type: ErrorHandlingType.Message,
            message: invalidCredentials,
          },
        };
        return of(new ValidateResetPasswordTokenFail(errorPayload));
      })
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetPassword>(oAuthActionTypes.ResetPassword),
      map((action) => action.password),
      withLatestFrom(this.store.select(getRouterQueryParams)),
      switchMap(([password, params]: [string, Params]) => {
        const userToken = params.token;
        const decodedToken = jwt.decode(userToken);
        const mappedUserToken: IUser = this.mappingService.getMappedData<any>(
          decodedToken,
          mappingType.camelize
        ).user;
        const isExpired = this.isExpired(moment.unix(decodedToken.exp));
        if (isExpired) {
          const invalidCredentials = `Your password reset link has expired. Please request another reset link.`;
          const errorPayload: IError<FusionoAuthError> = {
            code: FusionoAuthError.ResetPasswordFail,
            source: ErrorSource.Validation,
            data: invalidCredentials,
            config: {
              type: ErrorHandlingType.Message,
              message: invalidCredentials,
            },
          };
          return of(new ResetPasswordFail(errorPayload));
        }
        const id = mappedUserToken.id;
        const email = mappedUserToken.email;
        return this.userService.updateUserDirect({ password }, id).pipe(
          switchMap(() => {
            const credentials: IoAuthCredentials = {
              email: email,
              password: password,
            };
            return [new ResetPasswordSuccess(), new oAuthStart(credentials)];
          }),
          catchError((error) => {
            const invalidCredentials = `We couldn’t verify your account. Please try again.`;
            const code = error.status;
            const errorPayload: IError<FusionoAuthError> = {
              code: FusionoAuthError.ResetPasswordFail,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Message,
                message:
                  code === 404
                    ? invalidCredentials
                    : 'Sorry, we are having some issue resetting your password. Please try again later.',
              },
            };
            return of(new ResetPasswordFail(errorPayload));
          })
        );
      })
    )
  );

  resetPasswordRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PasswordResetRequest>(oAuthActionTypes.PasswordResetRequest),
      map((action) => action.email),
      switchMap((email: string) => {
        return this.userService.resetPassword(email).pipe(
          switchMap(() => {
            return [new PasswordResetRequestSuccess()];
          }),
          catchError((error) => {
            const invalidCredentials = `We couldn’t find that email. Please try again.`;
            const code = error.status;
            const errorPayload: IError<FusionoAuthError> = {
              code: FusionoAuthError.PasswordResetRequestFail,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Message,
                message:
                  code === 404
                    ? invalidCredentials
                    : 'Sorry, we are having some issue authenticating your account. Please try again later.',
              },
            };
            return of(new PasswordResetRequestFail(errorPayload));
          })
        );
      })
    )
  );

  getDays(exp, iat) {
    return moment(exp).diff(iat, 'days');
  }

  isExpired(exp) {
    return moment(exp).diff(new Date(), 'minute') <= 0;
  }
}
