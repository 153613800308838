// import effects

import { EventsEffects } from './events.effects';
import { CurrentEventEffects } from './current-event.effects';
import { VideoConferenceEffects } from './video-conference.effects';
import { GuestsEffects } from './guests.effects';

// include all effects
export const effects: any[] = [
  EventsEffects,
  CurrentEventEffects,
  VideoConferenceEffects,
  GuestsEffects,
];

// export from effects
