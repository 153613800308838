import { IError } from '@fusion/error';
import { Action } from '@ngrx/store';
import { FusionJobError } from '../../models/enums';

export enum OpenPositionsActionTypes {
  LoadOpenPositions = '[Open Positions] Load',
  LoadOpenPositionsSuccess = '[Open Positions] Load Success',
  LoadOpenPositionsFail = '[Open Positions] Load Fail',
  LoadClientOpenPositions = '[Open Positions] Load Client',
  LoadClientOpenPositionsSuccess = '[Open Positions] Load Client Success',
  LoadClientOpenPositionsFail = '[Open Positions] Load Client Fail',
  SetOpenPositionsCount = '[Open Positions] Set Count',
  AddOpenPositions = '[Open Positions] Add',
  AddOpenPositionsSuccess = '[Open Positions] Add Success',
  AddOpenPositionsFail = '[Open Positions] Add Fail',
}

export class LoadOpenPositions implements Action {
  readonly type = OpenPositionsActionTypes.LoadOpenPositions;
}

export class LoadOpenPositionsSuccess implements Action {
  readonly type = OpenPositionsActionTypes.LoadOpenPositionsSuccess;

  constructor(public payload: any) {}
}

export class LoadOpenPositionsFail implements Action {
  readonly type = OpenPositionsActionTypes.LoadOpenPositionsFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class LoadClientOpenPositions implements Action {
  readonly type = OpenPositionsActionTypes.LoadClientOpenPositions;
}

export class LoadClientOpenPositionsSuccess implements Action {
  readonly type = OpenPositionsActionTypes.LoadClientOpenPositionsSuccess;

  constructor(public payload: any) {}
}

export class LoadClientOpenPositionsFail implements Action {
  readonly type = OpenPositionsActionTypes.LoadClientOpenPositionsFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class SetOpenPositionsCount implements Action {
  readonly type = OpenPositionsActionTypes.SetOpenPositionsCount;

  constructor(public payload: number) {}
}

export class AddOpenPositions implements Action {
  readonly type = OpenPositionsActionTypes.AddOpenPositions;

  constructor(public payload: any) {}
}

export class AddOpenPositionsSuccess implements Action {
  readonly type = OpenPositionsActionTypes.AddOpenPositionsSuccess;

  constructor(public payload: any) {}
}

export class AddOpenPositionsFail implements Action {
  readonly type = OpenPositionsActionTypes.AddOpenPositionsFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export type OpenPositionsActions =
  | LoadOpenPositions
  | LoadOpenPositionsSuccess
  | LoadOpenPositionsFail
  | LoadClientOpenPositions
  | LoadClientOpenPositionsSuccess
  | LoadClientOpenPositionsFail
  | AddOpenPositions
  | AddOpenPositionsSuccess
  | AddOpenPositionsFail
  | SetOpenPositionsCount;
