import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap, map } from 'rxjs/operators';
import { AppRouterActionTypes, Navigate } from '../actions/index';
import { Router } from '@angular/router';

@Injectable()
export class AppRouterEffects {
  constructor(private actions$: Actions, private router: Router) {}

  
  navigate$ = createEffect(() => this.actions$.pipe(
    ofType<Navigate>(AppRouterActionTypes.Navigate),
    map((action) => action.payload),
    tap((payload: any) => {
      this.router.navigate([`${payload}`]);
    })
  ), { dispatch: false });
}
