import { createSelector } from '@ngrx/store';

import { getFusionSearchState } from '../reducers/index';
import { ISearchPagination } from '@fusion/common';

export const getSearchState = createSelector(getFusionSearchState, (state) => {
  if (state == null) {
    return null;
  }
  return state.publicSearch;
});

export const getSearch = createSelector(getSearchState, (state) => {
  if (state == null) {
    return null;
  }
  return state.search;
});

export const getSearchFollowUpAction = createSelector(
  getSearchState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.followUpContentLoadAction;
  }
);

export const getSearchKeywords = createSelector(getSearch, (state) => {
  if (state == null) {
    return null;
  }
  return state.keywords;
});

export const getSearchLevels = createSelector(getSearch, (state) => {
  if (state == null) {
    return null;
  }
  return state.levels;
});

export const getSearchTypes = createSelector(getSearch, (state) => {
  if (state == null) {
    return null;
  }
  return state.types;
});

export const getSearchOrderBy = createSelector(getSearch, (state) => {
  if (state == null) {
    return null;
  }
  return state.order;
});

export const getSearchCategories = createSelector(getSearch, (state) => {
  if (state == null) {
    return null;
  }
  return state.category;
});

export const getSearchPagination = createSelector(
  getSearch,
  (state): ISearchPagination => {
    if (state == null) {
      return null;
    }
    return state.pagination;
  }
);

// export const getJobSearchSeo = createSelector(
//   getRouterUrl,
//   getKeywords,
//   (url, keywords): ISeo => {
//     if (url == null) {
//       return null;
//     }
//     return {
//       type: 'Job Search',
//       title: 'Job Search Results',
//       description: keywords ? `Job Search Results for ${keywords}` : 'All Jobs',
//       url: url
//     };
//   }
// );
