import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap
} from "rxjs/operators";

import {
  LoadPublicationsSuccess,
  LoadPublications,
  PublicationsActionTypes,
  AddPublication,
  LoadJobseekerProfile,
  AddPublicationFail,
  UpdatePublication,
  UpdatePublicationFail,
  DeletePublication,
  DeletePublicationFail,
  DeletePublicationSuccess,
  UpdatePublicationSuccess,
  AddPublicationSuccess
} from "../actions/index";
import { getMappedEntities } from "@fusion/common";
import { Store } from "@ngrx/store";
import { FusionCareerProfileState } from "../reducers";
import { JobseekerService, MappingService, mappingType } from "@fusion/service";
import { getoAuthUserId } from "@fusion/oauth";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

@Injectable()
export class PublicationsEffects {
  constructor(
    public actions$: Actions,
    private store: Store<FusionCareerProfileState>,
    private jobseekerService: JobseekerService,
    private mappingService: MappingService
  ) {}

  
  getPublications$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPublications>(PublicationsActionTypes.LoadPublications),
    map(action => action.payload),
    mergeMap((payload: any) => {
      return [new LoadPublicationsSuccess(getMappedEntities(payload))];
    })
  ));

  
  addPublication$ = createEffect(() => this.actions$.pipe(
    ofType<AddPublication>(PublicationsActionTypes.AddPublication),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([publication, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.AddPublicationFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.jobseekerService
        .postPublication(this.getMappedPublication(userId, publication))
        .pipe(
          switchMap(dataResult => {
            return [
              new AddPublicationSuccess(dataResult),
              new LoadJobseekerProfile()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue adding your publication. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new AddPublication(publication)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new AddPublicationFail(errorPayload));
          })
        );
    })
  ));

  
  updatePublication$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePublication>(PublicationsActionTypes.UpdatePublication),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([publication, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.UpdatePublicationFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.jobseekerService
        .putPublication(this.getMappedPublication(userId, publication))
        .pipe(
          switchMap(dataResult => {
            return [
              new UpdatePublicationSuccess(dataResult),
              new LoadJobseekerProfile()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue updating your publication. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new UpdatePublication(publication)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new UpdatePublicationFail(errorPayload));
          })
        );
    })
  ));

  
  deletePublication$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePublication>(PublicationsActionTypes.DeletePublication),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([publicationId, userId]: [string, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.DeletePublicationFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.jobseekerService
        .deletePublication(publicationId, userId)
        .pipe(
          switchMap(dataResult => {
            return [
              new DeletePublicationSuccess(dataResult),
              new LoadJobseekerProfile()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue deleting your publication. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new DeletePublication(publicationId)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new DeletePublicationFail(errorPayload));
          })
        );
    })
  ));

  getMappedPublication(userId: string, projectRaw: any) {
    const project: any = this.mappingService.getMappedData(
      projectRaw,
      mappingType.underscore
    );
    return {
      ...project,
      user_id: userId
    };
  }
}
