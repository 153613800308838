export enum Subdomain {
  home = 'home',
  user = 'career',
  homerent = 'homerent',
  job = 'job',
  career = 'career',
  company = 'company',
  learning = 'learning',
  article = 'article',
  Mosque = 'mosque',
}
