import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import {
  ApplicationsActionTypes,
  LoadApplicationsSuccess,
  LoadApplicationsFail,
  LoadApplications,
} from '../actions/applications.actions';
import { ConfigService } from '@fusion/service';
import { IError, ErrorSource } from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';

@Injectable()
export class ApplicationsEffects {
  constructor(
    private actions$: Actions,
    private configService: ConfigService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType(ApplicationsActionTypes.LoadApplications),
    mergeMap((action: LoadApplications) => {
      const apps = this.configService.getConfig().apps;
      return of(new LoadApplicationsSuccess(apps));
    }),
    catchError((error) => {
      let errorPayload: IError<FusionoAuthError> = {
        code: FusionoAuthError.LoadApplicationsFail,
        source: ErrorSource.Validation,
        data: error,
      };
      return of(new LoadApplicationsFail(errorPayload));
    })
  ));
}
