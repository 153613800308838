import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { FusionSubscriptionState } from '../reducers';
import { SetCurrentProfileNavigation } from '../actions';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ActiveNavigationGuard implements CanActivate {
  constructor(
    private store: Store<FusionSubscriptionState>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!isPlatformBrowser(this.platformId)) {
      return of(true);
    }
    const currentPath = next.data.currentPath || '';
    this.store.dispatch(new SetCurrentProfileNavigation(currentPath));
    return of(true);
  }
}
