import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import {
  RecruitingAgenciesActionTypes,
  LoadRecruitingAgenciesSuccess,
  LoadRecruitingAgenciesFail,
} from '../actions/recruiting-agencies.actions';

@Injectable()
export class RecruitingAgenciesEffects {
  constructor(private actions$: Actions) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType(RecruitingAgenciesActionTypes.LoadRecruitingAgencies),
    mergeMap((action: any) =>
      of(new LoadRecruitingAgenciesSuccess(action.payload))
    ),
    catchError((error) => of(new LoadRecruitingAgenciesFail()))
  ));
}
