<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>Manage & Load Wallet!</h1></div>
  <div pageContent>
    <ng-container *ngIf="isWalletLoading$ | async; else walletLoaded">
      <div centering center>
        <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
      </div>
    </ng-container>

    <ng-template #walletLoaded>
      <ng-container
        *ngIf="customerSetupProgressState$ | async as progressState"
      >
        <ng-container *ngIf="!(wallet$ | async); else wallet">
          <div border radius centeringText cardSpacing content class="row3">
            <div mediumTitle semiBold class="row3__section-one">No Wallet!</div>
            <div exSmallTitle g3FontColor class="row3__section-two">
              Your wallet is not setup yet. If you are ready setup now.
            </div>
            <div class="row3__section-three">
              <button
                mat-flat-button
                (click)="setupCustomerProfile()"
                color="primary"
                [disabled]="
                  progressState?.isInProgress || progressState?.isSuccess
                "
              >
                <span
                  [ngClass]="{ 'in-progress': progressState?.isInProgress }"
                >
                  <mat-spinner
                    *ngIf="progressState?.isInProgress"
                    [strokeWidth]="2"
                    [diameter]="15"
                  ></mat-spinner>
                  Setup your Wallet</span
                >
              </button>
              <div
                errorFontColor
                nanoFont
                class="t-padding-5"
                *ngIf="customerSetupError$ | async as error"
              >
                {{ error?.message }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
    <div adSpace></div>
  </div>
</div>

<ng-template #wallet>
  <div content>
    <fusion-payment-wallet
      [customer]="customer$ | async"
      [wallet]="wallet$ | async"
      [address]="address$ | async"
      [clientSecret]="clientSecret$ | async"
      [currentPayment]="currentPayment$ | async"
      [couponBalance]="couponBalance$ | async"
      [isSendingPayment]="isSendingPayment$ | async"
      (notifyAddCard)="onNotifyAddCard($event)"
      (notifyPaymentMethod)="onNotifyPaymentMethod($event)"
      (notifyAddBalance)="onNotifyAddBalance($event)"
      (notifyPaymentAuthorization)="onNotifyPaymentAuthorization()"
      (notifyResetCurrentPayment)="onNotifyResetCurrentPayment()"
      (notifyRedeamCoupon)="onNotifyRedeamCoupon($event)"
      (notifyResetCouponBalance)="onNotifyResetCouponBalance($event)"
    ></fusion-payment-wallet>
  </div>
</ng-template>
