import { Component, OnInit, Input } from '@angular/core';
import { ICompany } from '../../models/interfaces';

@Component({
  selector: 'fusion-company-body',
  templateUrl: './company-body.component.html',
  styleUrls: ['./company-body.component.scss'],
})
export class CompanyBodyComponent implements OnInit {
  @Input() company: ICompany;

  constructor() {}

  ngOnInit() {}
}
