<ng-container *ngIf="!isNavigateMode; else navigateView">
  <div class="row2">
    <div class="row2__section-one">
      <fusion-form-dialog
        [isDialog]="true"
        [formValidationRules]="formValidationRules"
        [formConfigs]="formConfigs"
        [formMetadata]="formMetadata"
        [autoCompleteEntities]="autoCompleteEntities"
        (notifyFormSubmit)="notifyFormSubmit.emit($event)"
        (notifyAutocompletion)="notifyAutocompletion.emit($event)"
      ></fusion-form-dialog>
    </div>
    <div centering class="row2__section-two">
      <div g3FontColor exSmallFont>{{ formMetadata.description }}</div>
    </div>
  </div>
</ng-container>

<ng-template #navigateView>
  <div class="colN">
    <div
      border
      radius
      cardSpacing
      elevation
      pointer
      class="add-property row2"
      [ngClass]="{ disabled: isLoading }"
      (click)="navigateTo(currentRootPath, 'new')"
    >
      <div centeringBottom class="row2__section-one">
        <div *ngIf="isLoading" class="loading-shade">
          <mat-spinner></mat-spinner>
        </div>
        <button mat-fab color="accent"><mat-icon>add</mat-icon></button>
      </div>
      <div class="row2__section-two row2">
        <div centeringText class="row2__section-one">
          <h6>{{ formMetadata.title }}</h6>
        </div>

        <div centering class="row2__section-two">
          <div g3FontColor exSmallFont>{{ formMetadata.description }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
