import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { getRouterParams } from '@fusion/router';
import { JobService, MappingService, mappingType } from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { IJobApplication } from '../../models/interfaces';

import {
  CurrentApplicationActionTypes,
  LoadCurrentApplicationSuccess,
  LoadCurrentApplicationFail,
} from '../actions/current-application.actions';
import { FusionJobState } from '../reducers';

@Injectable()
export class CurrentApplicationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionJobState>,
    private jobService: JobService,
    private mappingService: MappingService
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentApplicationActionTypes.LoadCurrentApplication),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([action, params]: [any, Params]) => {
      const applicationId = params.applicationId;
      return this.jobService.getApplicationById(applicationId).pipe(
        switchMap((dataResult) => {
          const mappedApplication =
            this.mappingService.getMappedData<IJobApplication>(
              dataResult,
              mappingType.camelize
            );
          return [new LoadCurrentApplicationSuccess(mappedApplication)];
        })
      );
    }),
    catchError((error) => of(new LoadCurrentApplicationFail()))
  ));
}
