import { Action } from '@ngrx/store';
import { ISearchPagination } from '@fusion/common';
import { SearchOrderType } from '../../models';

export enum SearchActionTypes {
  SearchStart = '[Search] Start',
  SetSearchKeywords = '[Search] Set Keywords',
  SetSearchType = '[Search] Set Type',
  SetSearchLevel = '[Search] Set Level',
  SetSearchCategory = '[Search] Set Category',
  SetOrderBy = '[Search] Set Order By',
  SetSearchPagination = '[Search] Set Pagination',
  UpdateSearchUrl = '[Search] Update Url',
  RestoreSearch = '[Search] Restore',
  RestoreSearchSuccess = '[Search] Restore Success',
  LoadSearchContents = '[Search] Load Contents',
  LoadSearchContentsSuccess = '[Search] Load Contents Success',
  LoadSearchContentsFail = '[Search] Load Contents Fail',
}

export class SearchStart implements Action {
  readonly type = SearchActionTypes.SearchStart;

  constructor(public followUpAction: Action) {}
}

export class SetSearchKeywords implements Action {
  readonly type = SearchActionTypes.SetSearchKeywords;

  constructor(public payload: string) {}
}

export class SetSearchType implements Action {
  readonly type = SearchActionTypes.SetSearchType;

  constructor(public payload: string) {}
}

export class SetSearchLevel implements Action {
  readonly type = SearchActionTypes.SetSearchLevel;

  constructor(public payload: string) {}
}

export class SetSearchCategory implements Action {
  readonly type = SearchActionTypes.SetSearchCategory;

  constructor(public payload: string) {}
}

export class SetOrderBy implements Action {
  readonly type = SearchActionTypes.SetOrderBy;

  constructor(public payload: SearchOrderType) {}
}

export class SetSearchPagination implements Action {
  readonly type = SearchActionTypes.SetSearchPagination;

  constructor(public payload: ISearchPagination) {}
}

export class UpdateSearchUrl implements Action {
  readonly type = SearchActionTypes.UpdateSearchUrl;
}

export class RestoreSearch implements Action {
  readonly type = SearchActionTypes.RestoreSearch;

  constructor(public followUpAction: Action) {}
}

export class RestoreSearchSuccess implements Action {
  readonly type = SearchActionTypes.RestoreSearchSuccess;

  constructor(public payload: any) {}
}

export class LoadSearchContents implements Action {
  readonly type = SearchActionTypes.LoadSearchContents;
}
export class LoadSearchContentsSuccess implements Action {
  readonly type = SearchActionTypes.LoadSearchContentsSuccess;
}

export class LoadSearchContentsFail implements Action {
  readonly type = SearchActionTypes.LoadSearchContentsFail;
}

export type SearchActions =
  | SearchStart
  | SetSearchKeywords
  | SetSearchType
  | SetSearchLevel
  | SetSearchCategory
  | SetOrderBy
  | SetSearchPagination
  | UpdateSearchUrl
  | RestoreSearch
  | RestoreSearchSuccess
  | LoadSearchContents
  | LoadSearchContentsSuccess
  | LoadSearchContentsFail;
