export enum ApplicationId {
  Islam = 'e4f32bad-6c7c-4346-9a25-e2f61283943a',
  Mosque = '39577aee-44a6-4e65-b2a3-5f1b96a5c027',
  User = '76e588af-6a00-47f9-b752-771cd38dc80c',
  Company = '3f8837a7-c264-4791-8b5a-48ebff15a5f7',
  Job = '5a42b359-b9b7-4d71-ae86-e56784d2e544',
  HomeRent = '1bfc9e49-7f32-46f5-8b81-77a0aabc5d69',
  Career = '4411311a-151d-405e-837e-26ec79a9d8c3',
  Feed = 'aa53b8ee-28e8-4fc1-9ed9-0ec4f514644e',
  Article = 'a65b7994-936a-4e76-851b-ce894119e38f',
  Learning = '6a671ae5-aa13-4a0e-b089-f5f7acdbfef8',
  Author = 'de542e45-988e-42e7-87ba-d199ff5bd998',
  Tutorials = '2602022b-3b58-4646-be81-cda976aa96c7',
  Meetups = 'd50f1530-ea36-441f-9a63-e13801b30997',
  Shopping = 'f41bb0e6-372c-4b51-8fed-c8a57e5e3483',
}
