<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>Add and Manage your Companies!</h1></div>
  <div pageContent>
    <div content class="rowN">
      <ng-container
        *ngIf="
          errorHandlingConfig$ | async as errorHandlingConfig;
          else subscriptionList
        "
      >
        <ng-template
          [fusionErrorHandler]="errorHandlingConfig"
          errorHandlingType="ErrorHandlingType.Template"
        ></ng-template>
      </ng-container>
      <ng-template #subscriptionList>
        <div class="row2" *ngIf="hasCompanySubscription$ | async">
          <div class="row2__section-one">
            <span largeTitle g5FontColor light>Organizations</span>
          </div>
          <div class="row2__section-two">
            <fusion-company-list
              [companies]="companies$ | async"
              [unclaimedCompanies]="unclaimedCompanies$ | async"
              [isEmployerMode]="true"
              [formConfigs]="addCompanyFormConfigs"
              [formMetadata]="addCompanyFormMetadata"
              [formValidationRules]="addCompanyFormValidationRules"
              (notifyViewCompany)="onNotifyViewCompany($event)"
              (notifyManageCompany)="onNotifyManageCompany($event)"
              (notifyAutocompletion)="onNotifyAutocompletion($event)"
              (notifyFormSubmit)="onNotifyFormSubmit($event)"
            ></fusion-company-list>
          </div>
        </div>
        <div *ngIf="!(hasCompanySubscription$ | async)">
          <fusion-common-empty-state
            [emptyState]="emptyState"
            [currentRootPath]="'/'"
          ></fusion-common-empty-state>
        </div>
        <div class="row2">
          <ng-container
            *ngIf="
              userProfileErrorHandlingConfig$ | async as errorHandlingConfig;
              else repCompanyList
            "
          >
            <ng-template
              [fusionErrorHandler]="errorHandlingConfig"
              errorHandlingType="ErrorHandlingType.Template"
            ></ng-template>
          </ng-container>
          <ng-template #repCompanyList>
            <ng-container
              *ngIf="(repCompanies$ | async) && (repCompanies$ | async).length"
            >
              <div class="row2__section-one">
                <span largeTitle g5FontColor light>Representative</span>
              </div>
              <div class="row2__section-two">
                <fusion-company-list
                  [companies]="repCompanies$ | async"
                  (notifyViewCompany)="onNotifyViewCompany($event)"
                  (notifyManageCompany)="onNotifyManageCompany($event)"
                ></fusion-company-list>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </ng-template>
    </div>
    <div adSpace></div>
  </div>
</div>
