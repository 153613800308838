<fusion-common-back
  [currentRootPath]="currentRootPath$ | async"
  [targetDir]="''"
  [buttonText]="'back'"
></fusion-common-back>
<div pageSpacing extraLargePage pageBody>
  <div pageTitle>
    <h1>Register your Mosque!</h1>
  </div>

  <div pageContentNoAd>
    <mat-vertical-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="mosquePreferencesFormValidationRules">
        <ng-template matStepLabel>
          <div smallTitle microBold sectionTitle>Mosque Detail</div>
        </ng-template>
        <div class="rowN">
          <mat-divider></mat-divider>
          <div section>
            <div sectionHeader>
              <div sectionSubtitle microTitle>
                If you have legal authority to manage this Mosque then select
                Yes, you will get access with Owner permission. But you still
                should add a Mosque even if you do not have legal authority.
              </div>
            </div>

            <div card cardSpacing sectionContent>
              <fusion-form-template
                [isDialog]="false"
                [formValidationRules]="mosquePreferencesFormValidationRules"
                [formConfigs]="mosquePreferencesFormConfigs"
                [formMetadata]="mosquePreferencesFormMetadata"
                (notifyFormSubmit)="notifyPartialFormSubmit($event)"
                (notifyFormValidationUpdate)="
                  onNotifyFormValidationUpdate($event)
                "
              ></fusion-form-template>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="addMosqueFormValidationRules">
        <ng-template matStepLabel>
          <div smallTitle microBold sectionTitle>Mosque Detail</div>
        </ng-template>
        <div class="rowN">
          <mat-divider></mat-divider>
          <div section>
            <div sectionHeader>
              <div sectionSubtitle microTitle>
                Mosque details will be displayed in the search result.
              </div>
            </div>

            <div card cardSpacing sectionContent>
              <fusion-form-template
                [isDialog]="false"
                [formValidationRules]="addMosqueFormValidationRules"
                [formConfigs]="addMosqueFormConfigs"
                [formMetadata]="addMosqueFormMetadata"
                (notifyFormSubmit)="notifyPartialFormSubmit($event)"
              ></fusion-form-template>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="mosqueOverviewFormValidationRules">
        <ng-template matStepLabel>
          <div smallTitle microBold sectionTitle>Overview</div>
        </ng-template>
        <div class="rowN">
          <mat-divider></mat-divider>
          <div section>
            <div sectionHeader>
              <div sectionSubtitle microTitle>
                Here you can add a summary of provided services by this Mosque.
              </div>
            </div>

            <div card cardSpacing sectionContent>
              <fusion-form-template
                [isDialog]="false"
                [formValidationRules]="mosqueOverviewFormValidationRules"
                [formConfigs]="mosqueOverviewFormConfigs"
                [formMetadata]="mosqueOverviewFormMetadata"
                (notifyFormSubmit)="notifyPartialFormSubmit($event)"
              ></fusion-form-template>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="mosqueContactFormValidationRules">
        <ng-template matStepLabel>
          <div smallTitle microBold sectionTitle>Contact</div>
        </ng-template>
        <div class="rowN">
          <mat-divider></mat-divider>
          <div section>
            <div sectionHeader>
              <div sectionSubtitle microTitle>
                This contact info will help other people to communicate with
                this Mosque authority.
              </div>
            </div>

            <div card cardSpacing sectionContent>
              <fusion-form-template
                [isDialog]="false"
                [formValidationRules]="mosqueContactFormValidationRules"
                [formConfigs]="mosqueContactFormConfigs"
                [formMetadata]="mosqueContactFormMetadata"
                (notifyFormSubmit)="notifyPartialFormSubmit($event)"
              ></fusion-form-template>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="addressFormValidationRules">
        <ng-template matStepLabel>
          <div smallTitle microBold sectionTitle>Location</div>
        </ng-template>
        <div class="rowN">
          <mat-divider></mat-divider>
          <div section>
            <div sectionHeader>
              <div sectionSubtitle microTitle>
                Mosque Location will help everyone to find a local or nearby
                Mosque for prayer.
              </div>
            </div>

            <div card cardSpacing sectionContent>
              <fusion-form-template
                [isDialog]="false"
                [formValidationRules]="addressFormValidationRules"
                [formConfigs]="addressFormConfigs"
                [formMetadata]="addressFormMetadata"
                (notifyFormSubmit)="notifyFormSubmit($event)"
              ></fusion-form-template>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
