<div card class="row3">
  <fusion-career-profile-card-header
    class="row3__section-one"
    [isColor]="false"
    [isEditable]="allowEdit"
    [title]="'Experiences'"
    [buttonTitle]="'Add'"
    [iconType]="ModalIconType.AddIcon"
    [formValidationRules]="experienceFormValidationRules"
    [formConfigs]="experienceFormConfigs"
    [formMetadata]="experienceFormMetadata"
    [autoCompleteEntities]="autoCompleteEntities"
    (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    (notifyAutocompletion)="notifyAutocompletion.emit($event)"
  ></fusion-career-profile-card-header>
  <div class="row3__section-two">
    <div
      hover
      bottomDivider
      cardSpacing
      career
      *ngFor="let experience of experiences"
    >
      <div careerLogo smallLogo>
        <img
          pointer
          imageFit
          src="{{
            experience.company.logo ||
              '/assets/images/users/company-logo/logoPlaceholder.png'
          }}"
          (click)="notifyViewCompany.emit(experience.company.id)"
        />
      </div>
      <div careerDetail>
        <div careerDetailHeader>
          <h5 g6FontColor>{{ experience?.position }}</h5>
          <div
            pointer
            exSmallTitle
            g6FontColor
            (click)="notifyViewCompany.emit(experience.company.id)"
          >
            {{ experience?.company.name }}
          </div>
          <div microTitle g5FontColor>
            {{ experience?.startDate | date: 'MMM, yyyy' }} -
            {{
              experience.endDate
                ? (experience.endDate | date: 'MMM, yyyy')
                : 'current'
            }}
            <span class="dot"></span>
            {{
              experience?.startDate | fancyTime: 'duration':experience.endDate
            }}
          </div>
          <div microTitle g5FontColor>
            {{ experience?.company.address }}, {{ experience?.company.city }} -
            {{ experience?.company.zipCode }}
          </div>
        </div>
        <div
          careerDetailSummary
          *ngIf="isExpanded && experience.responsibility"
        >
          <h6 g6FontColor>Job Responsibilities</h6>
          <div [innerHTML]="experience.responsibility"></div>
        </div>
      </div>
      <div *ngIf="allowEdit" careerButton hoverDisplay>
        <button
          (click)="notifyDeleteExperience.emit(experience.id)"
          class="custom-button"
          mat-fab
          color="accent"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <fusion-career-profile-card-header
          [currentEntity]="experience"
          [buttonTitle]="'Edit'"
          [iconType]="ModalIconType.EditIcon"
          [formValidationRules]="experienceUpdateFormValidationRules"
          [formConfigs]="experienceUpdateFormConfigs"
          [formMetadata]="experienceUpdateFormMetadata"
          [autoCompleteEntities]="autoCompleteEntities"
          (notifyFormSubmit)="notifyFormSubmit.emit($event)"
          (notifyAutocompletion)="notifyAutocompletion.emit($event)"
        ></fusion-career-profile-card-header>
      </div>
    </div>
  </div>
  <div *ngIf="experiences.length" class="row3__section-three">
    <fusion-common-card-footer
      (notifyCardFooterClick)="notifyCardFooterClick()"
      [title]="isExpanded ? 'view less' : 'view more'"
    ></fusion-common-card-footer>
  </div>
</div>

<!-- <div card class="row3">
    <fusion-career-profile-card-header
      class="row3__section-one"
      [isColor]="false"
      [isEditable]="true"
      [title]="'Experiences'"
      [formName]="'addExperience'"
      [buttonTitle]="'Add'"
      [iconType]="ModalIconType.AddIcon"
      [autoCompleteEntities]="autoCompleteEntities"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
      (notifyAutocompletion)="notifyAutocompletion.emit($event)"
    ></fusion-career-profile-card-header>
    <div class="row3__section-two">
      <div
        hover
        bottomDivider
        cardSpacing
        class="col3"
        *ngFor="let experience of experiences"
      >
        <div logo class="col3__section-one">
          <img
            imageFit
            src="/assets/images/users/company-logo/{{
              experience.company.logo || 'logoPlaceholder.png'
            }}"
          />
        </div>
        <div class="col3__section-two rowN">
          <div>
            <h5 g6FontColor>{{ experience?.position }}</h5>
            <div smallTitle g6FontColor>{{ experience?.company.name }}</div>
            <div exSmallTitle g5FontColor>
              {{ experience?.startDate | date: 'MMM, yyyy' }} -
              {{
                experience.endDate
                  ? (experience.endDate | date: 'MMM, yyyy')
                  : 'current'
              }}
              <span class="dot"></span>
              {{
                experience?.startDate | fancyTime: 'duration':experience.endDate
              }}
            </div>
            <div exSmallTitle g5FontColor>
              {{ experience?.company.address }}, {{ experience?.company.city }} -
              {{ experience?.company.zipCode }}
            </div>
          </div>
          <div *ngIf="isExpanded && experience.responsibility">
            <h6 g6FontColor>Job Responsibility</h6>
            <div [innerHTML]="experience.responsibility | truncate: 1000"></div>
          </div>
        </div>
        <div hoverDisplay class="col3__section-three">
          <button
            (click)="notifyDeleteExperience.emit(experience.id)"
            class="custom-button"
            mat-fab
            color="accent"
          >
            <mat-icon>delete</mat-icon>
          </button>
  
          <fusion-career-profile-card-header
            [formName]="'addExperience'"
            [currentEntity]="experience"
            [buttonTitle]="'Edit'"
            [iconType]="ModalIconType.EditIcon"
            [autoCompleteEntities]="autoCompleteEntities"
            (notifyFormSubmit)="notifyFormSubmit.emit($event)"
            (notifyAutocompletion)="notifyAutocompletion.emit($event)"
          ></fusion-career-profile-card-header>
        </div>
      </div>
    </div>
    <div *ngIf="experiences.length" class="row3__section-three">
      <fusion-common-card-footer
        (notifyCardFooterClick)="notifyCardFooterClick()"
        [title]="isExpanded ? 'View Less' : 'View All'"
      ></fusion-common-card-footer>
    </div>
  </div>
   -->
