import { Component, OnInit } from '@angular/core';
import { IUser } from '@fusion/common';
import { getUsers, SearchUsers } from '@fusion/company';
import { getRouterUrlRoot } from '@fusion/router';
import { Store } from '@ngrx/store';
import { CalendarEvent } from 'angular-calendar';
import { Observable } from 'rxjs';
import { ICalendarEventMetadata, IEventGuest, VideoConferenceRole } from '../../models';
import {
  AddGuests,
  CalendarState,
  CreateVideoConference,
  getApplicant,
  getApplicantGuest,
  getCurrentEvent,
  JoinVideoConference,
  LoadCurrentEvent,
  RemoveGuests,
  SaveExistingEvent,
  UpdateCurrentEvent,
} from '../../store';

@Component({
  selector: 'calendar-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss'],
})
export class EventEditComponent implements OnInit {
  users$: Observable<IUser[]>;
  currentRootPath$: Observable<string>;
  currentEvent$: Observable<CalendarEvent<ICalendarEventMetadata>>;
  applicantGuest$: Observable<IEventGuest>;

  constructor(private store: Store<CalendarState>) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadCurrentEvent());

    this.users$ = this.store.select(getUsers);
    this.currentEvent$ = this.store.select(getCurrentEvent);
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.applicantGuest$ = this.store.select(getApplicantGuest);
  }

  onNotifySave() {
    this.store.dispatch(new SaveExistingEvent());
  }
  onNotifyGuestUser(guestUser: IUser) {
    const guestPayload: IEventGuest = {
      user: guestUser,
      guestRole: VideoConferenceRole.Attendee,
    };
    this.store.dispatch(new AddGuests(guestPayload));
  }
  onNotifyRemoveGuest(currentGuest: IEventGuest) {
    this.store.dispatch(new RemoveGuests(currentGuest));
  }
  onNotifyAutocompletion(eventData) {
    this.store.dispatch(new SearchUsers(eventData));
  }
  onNotifyEventUpdate(updatePayload) {
    this.store.dispatch(new UpdateCurrentEvent(updatePayload));
  }
  onNotifyJoinVideoConference(videoConferencePayload) {
    this.store.dispatch(new JoinVideoConference(videoConferencePayload));
  }
  onNotifyCreateVideoConference(updatePayload) {
    this.store.dispatch(new CreateVideoConference(updatePayload));
  }
}
