import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IProgressState } from '@fusion/common';
import { IErrorHandlingConfig } from '@fusion/error';
import { FormCategory, FormDisplayType, ModalIconType } from '../../models/enums';
import { IForm, IFormConfig, IFormMetadata } from '../../models/interfaces';
import {
  getValidationRulesWithPresetValue,
  setFormValidationRules,
} from '../../models/utils';

@Component({
  selector: 'fusion-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @Output() notifyFormSubmit = new EventEmitter<any>();

  @Input() error: IErrorHandlingConfig;
  @Input() progressState: IProgressState;
  _fusionForm: IForm;
  @Input('fusionForm') set fusionForm(fusionForm: IForm) {
    if (!fusionForm) {
      return;
    }

    this._fusionForm = fusionForm;
    if (fusionForm) {
      this.setFormConfigs(fusionForm);
    }
  }

  get fusionForm() {
    return this._fusionForm;
  }

  @Input('formPresetValues') set formPresetValues(formPresetValues: IForm) {
    if (!formPresetValues) {
      return;
    }

    if (formPresetValues && this.fusionForm) {
      this.updateValidationRules(formPresetValues);
    }
  }

  updatedValidationRules: UntypedFormGroup;
  formValidationRules: UntypedFormGroup;
  formConfigs: IFormConfig[];
  formMetadata: IFormMetadata;

  FormCategory = FormCategory;
  FormDisplayType = FormDisplayType;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {}

  setFormConfigs(fusionForm: IForm) {
    this.formMetadata = fusionForm.metadata;
    this.formConfigs = fusionForm.configs;
    this.formValidationRules = this.fb.group(
      setFormValidationRules(fusionForm.configs)
    );
  }

  updateValidationRules(formPresetValues: any) {
    this.updatedValidationRules = getValidationRulesWithPresetValue(
      this.formValidationRules,
      this.formConfigs,
      formPresetValues
    );
  }
}
