<div class="row2">
  <div section>
    <div sectionHeader>
      <div sectionTitle smallTitle microBold>Referrals</div>
    </div>
    <div sectionContent class="row2">
      <div sectionSubtitle microTitle>
        Invite your friends to join Dot to Circle. For every 5 successful
        registrations you will receive $2 wallet ballance. There is no limit to
        the amount of credit you can earn through referrals.
      </div>
      <fusion-form
        [fusionForm]="referralsForm"
        (notifyFormSubmit)="onNotifyFormSubmit($event)"
      ></fusion-form>
    </div>
  </div>
  <ng-container *ngIf="displayStats">
    <div section>
      <div sectionHeader>
        <div sectionTitle smallTitle microBold>Referral Stats</div>
      </div>
      <div sectionContent microTitle>
        <div
          *ngIf="referralStats$ | async as referralStats"
          border
          radius
          class="stats row3"
        >
          <div cardSmallSpacing bottomBorder class="stats-item col3">
            <div centering centerLeft semiBold class="col3__section-one">
              REFERRALS
            </div>
            <div class="col3__section-two">
              Number of People you have invited to join
              <span microBold>Dot to Circle</span>.
            </div>
            <div centering center class="col3__section-three">
              {{ referralStats.total }}
            </div>
          </div>
          <div cardSmallSpacing bottomBorder class="stats-item col3">
            <div centering centerLeft semiBold class="col3__section-one">
              PENDING
            </div>
            <div class="col3__section-two">
              Amount you stand to earn when your referrals complete Sign Up.
            </div>
            <div centering center class="col3__section-three">
              ${{ referralStats.amount.pending }}
            </div>
          </div>
          <div cardSmallSpacing class="stats-item col3">
            <div centering centerLeft semiBold class="col3__section-one">
              EARNED
            </div>
            <div class="col3__section-two">
              Amount that will be applied to your personal wallet balance.
            </div>
            <div centering center class="col3__section-three">
              ${{ referralStats.amount.earned }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
