<div class="row3">
  <div stickyHeader cardSmallSpacing centering centerRight>
    <button
      *ngIf="(currentGuest$ | async)?.isOrganizer"
      (click)="onNotifyUpdateEvent(currentEvent.id)"
      mat-icon-button
      mat-dialog-close
    >
      <mat-icon class="icon-display-90">edit</mat-icon>
    </button>
    <button
      *ngIf="(currentGuest$ | async)?.isOrganizer"
      (click)="notifyDeleteEvent.emit()"
      mat-icon-button
      mat-dialog-close
    >
      <mat-icon class="icon-display-90">delete</mat-icon>
    </button>
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="icon-display-90">close</mat-icon>
    </button>
  </div>
  <div cardSmallSpacing class="rowN">
    <div class="col2">
      <div centering topLeft class="t-padding-2">
        <mat-icon>event</mat-icon>
      </div>
      <div>
        <div mediumFont>{{ currentEvent.title }}!</div>
        <div microFont>
          <span>{{ currentEvent.start | date : 'EEEE, MMMM d' }}</span>
          <span dot></span>
          <span>{{ currentEvent.start | date : 'h:mm a' }}</span>
          <span> - </span>
          <span>{{ currentEvent.end | date : 'hh:mm a' }}</span>
          <span *ngIf="!isSameDay">
            <span dot></span>
            <span>{{ currentEvent.end | date : 'MMMM d' }}</span>
          </span>
        </div>
      </div>
    </div>
    <!-- event templates -->
    <ng-container [ngSwitch]="currentEvent?.meta?.eventType">
      <ng-container *ngSwitchCase="CalendarEventType.VideoConference">
        <!-- Video -->
        <div class="col2">
          <div centering centerLeft>
            <mat-icon color="accent">voice_chat</mat-icon>
          </div>
          <div *ngIf="currentGuest$ | async as currentGuest">
            <button
              mat-flat-button
              color="primary"
              [disabled]="!currentEvent?.meta.videoConferenceId"
              (click)="onJoinVideoConference(currentEvent, currentGuest)"
            >
              Join with Fusion Live
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="CalendarEventType.PhoneConference">
        <!-- Phone -->
        <div class="col2">
          <div centering topLeft class="t-padding-1">
            <mat-icon>call</mat-icon>
          </div>
          <div
            *ngIf="
              currentEvent?.meta?.eventCategory === EventCategory.Interview
            "
          >
            <div primaryFontColor semiBold>Join Applicant by Phone</div>
            <ng-container *ngIf="applicantGuest$ | async as applicantGuest">
              <div extraSmallFont>{{ applicantGuest?.user?.phoneNo }}</div>
            </ng-container>
          </div>
          <div
            *ngIf="currentEvent?.meta?.eventCategory === EventCategory.Meeting"
          >
            <div primaryFontColor semiBold>Join Organizer by Phone</div>
            <div extraSmallFont>--- --- ----</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="CalendarEventType.InPersonConference">
        <!-- Video -->
        <div class="col2">
          <div centering centerLeft>
            <mat-icon color="accent">voice_chat</mat-icon>
          </div>
          <div *ngIf="currentGuest$ | async as currentGuest">
            <button
              mat-flat-button
              color="primary"
              [disabled]="!currentEvent?.meta.videoConferenceId"
              (click)="onJoinVideoConference(currentEvent, currentGuest)"
            >
              Join with Fusion Live
            </button>
          </div>
        </div>
        <!-- InPerson -->
        <div class="col2">
          <div centering topLeft class="t-padding-1">
            <mat-icon>place</mat-icon>
          </div>
          <div centering centerLeft>
            <div primaryFontColor centering center>
              <div border radius cardSmallSpacing>
                <div microTitle semiBold>
                  <span>{{ currentEvent?.meta?.location?.address }}</span>
                  <span>, {{ currentEvent?.meta?.location?.unit }}</span>
                </div>
                <div microFont>
                  <span>{{ currentEvent?.meta?.location?.city }}, </span>
                  <span>{{ currentEvent?.meta?.location?.state }}</span>
                  <span> - {{ currentEvent?.meta?.location?.zipCode }}</span>
                  <div>{{ currentEvent?.meta?.location?.country }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="col2">
      <div centering topLeft>
        <mat-icon>people</mat-icon>
      </div>
      <div class="row2">
        <div *ngIf="guestSummary$ | async as guestSummary">
          <div microFont semiBold>{{ guestSummary.guestCount }} guests</div>
          <div nanoFont>
            <span>{{ guestSummary.acceptedGuestCount }} yes</span>
            <span *ngIf="guestSummary.rejectedGuestCount > 0"
              >, {{ guestSummary.rejectedGuestCount }} no</span
            >
            <span *ngIf="guestSummary.waitingguestCount > 0"
              >, {{ guestSummary.waitingguestCount }} waiting</span
            >
          </div>
        </div>
        <div class="guests rowN">
          <ng-container *ngFor="let guest of guests$ | async">
            <div class="col2">
              <div centering center>
                <div microProfileImage>
                  <img
                    imageFit
                    src="{{
                      guest?.user?.profilePicture ||
                        'assets/images/users/profile-picture/profile.png'
                    }}"
                  />
                  <div overlay class="l-margin-5">
                    <ng-container [ngSwitch]="guest?.joiningStatus">
                      <ng-container *ngSwitchCase="JoiningStatus.Accepted">
                        <mat-icon color="accent" class="icon-display"
                          >check_circle</mat-icon
                        >
                      </ng-container>

                      <ng-container *ngSwitchCase="JoiningStatus.Rejected">
                        <mat-icon color="warn" class="icon-display"
                          >highlight_off</mat-icon
                        >
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        <!-- TODO may be fetch error component -->
                        <div class="activity__empty"></div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div centering centerLeft>
                <div>
                  <div>
                    <a href="users/{{ guest.user.id }}" target="_blank"
                      >{{ guest.user?.firstName }} {{ guest.user?.middleName }}
                      {{ guest.user?.lastName }}</a
                    >
                  </div>
                  <div
                    nanoFont
                    semiBold
                    gray9FontColor
                    *ngIf="guest?.isOrganizer"
                  >
                    Organizer
                  </div>
                  <div
                    nanoFont
                    semiBold
                    gray9FontColor
                    *ngIf="guest.isApplicant"
                  >
                    Applicant
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col2">
      <div centering topLeft>
        <mat-icon>notes</mat-icon>
      </div>
      <div>
        <mat-dialog-content>
          <div [innerHTML]="currentEvent?.meta?.description | safeHtml"></div>
        </mat-dialog-content>
      </div>
    </div>
  </div>
  <div stickyFooter cardSmallSpacing topBorder class="footer-actions col2">
    <div centering centerLeft>
      <span microTitle>Going?</span>
    </div>
    <div centering centerRight class="action-buttons col2">
      <ng-container *ngIf="currentGuest$ | async as currentGuest">
        <ng-container [ngSwitch]="currentGuest.joiningStatus">
          <ng-container *ngSwitchCase="JoiningStatus.Accepted">
            <div
              chip
              pointer
              light
              live
              mat-dialog-close
              (click)="onJoiningStatusUpdate(JoiningStatus.Accepted)"
            >
              Yes
            </div>
            <div
              chip
              pointer
              outline
              mat-dialog-close
              (click)="onJoiningStatusUpdate(JoiningStatus.Rejected)"
            >
              No
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="JoiningStatus.Rejected">
            <div
              chip
              pointer
              outline
              mat-dialog-close
              (click)="onJoiningStatusUpdate(JoiningStatus.Accepted)"
            >
              Yes
            </div>
            <div
              chip
              pointer
              light
              rejected
              mat-dialog-close
              (click)="onJoiningStatusUpdate(JoiningStatus.Rejected)"
            >
              No
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div
              chip
              pointer
              outline
              mat-dialog-close
              (click)="onJoiningStatusUpdate(JoiningStatus.Accepted)"
            >
              Yes
            </div>
            <div
              chip
              pointer
              outline
              mat-dialog-close
              (click)="onJoiningStatusUpdate(JoiningStatus.Rejected)"
            >
              No
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
