import { IForm, FormFieldType, FormFieldGroup } from '@fusion/form';
import { hour, minute, period } from '../data';

export const VideoInterviewForm: IForm = {
  metadata: {
    title: 'Description',
    submitButtonText: 'Update',
    style: {
      topMargin: '0px',
      maxWidth: '800px',
      maxHeight: '400px',
    },
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Time,
      configs: [
        {
          label: 'Interview Start',
          name: 'startDatetime',
          type: FormFieldType.Text,
          placeholder: 'Interview Date',
          validation: {
            required: true,
          },
        },
        {
          label: 'Interview End',
          name: 'endDatetime',
          type: FormFieldType.Text,
          placeholder: 'Interview Date',
          validation: {
            required: true,
          },
        },
        // {
        //   label: 'Interview Date',
        //   name: 'date',
        //   type: FormFieldType.Date,
        //   placeholder: 'Interview Date',
        //   validation: {
        //     required: true,
        //   },
        // },
        // {
        //   label: 'Hour',
        //   name: 'startHour',
        //   type: FormFieldType.Dropdown,
        //   options: hour,
        //   validation: {
        //     required: true,
        //   },
        // },
        // {
        //   label: 'Minute',
        //   name: 'startMinute',
        //   type: FormFieldType.Dropdown,
        //   options: minute,
        //   validation: {
        //     required: true,
        //   },
        // },
        // {
        //   label: 'Period',
        //   name: 'startPeriod',
        //   type: FormFieldType.Dropdown,
        //   options: period,
        //   validation: {
        //     required: true,
        //   },
        // },
        // {
        //   label: 'Hour',
        //   name: 'endHour',
        //   type: FormFieldType.Dropdown,
        //   options: hour,
        //   validation: {
        //     required: true,
        //   },
        // },
        // {
        //   label: 'Minute',
        //   name: 'endMinute',
        //   type: FormFieldType.Dropdown,
        //   options: minute,
        //   validation: {
        //     required: true,
        //   },
        // },
        // {
        //   label: 'Period',
        //   name: 'endPeriod',
        //   type: FormFieldType.Dropdown,
        //   options: period,
        //   validation: {
        //     required: true,
        //   },
        // },
      ],
    },
  ],
};
