import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import * as configLocal from '../../assets/config_local';
import * as configProd from '../../assets/config_prod';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class TagManagerModule {
  constructor(
    @Optional() @SkipSelf() parentModule: TagManagerModule,
    googleTagManagerService: GoogleTagManagerService
  ) {
    if (parentModule) {
      throw new Error(
        'TagManagerModule is already loaded. Import it in the AppModule only'
      );
    }
    googleTagManagerService.setGTM();
  }

  static forRoot(appEnvironment?: any): ModuleWithProviders<TagManagerModule> {
    return {
      ngModule: TagManagerModule,
      providers: [
        { provide: GoogleTagManagerService }
      ],
    };
  }
}
