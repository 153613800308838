export enum AutocompleteType {
  User = 'user',
  Company = 'company',
  Content = 'content',
  LearningPath = 'LearningPath',
}

export enum ContentType {
  Question = 'question',
  Video = 'video',
  VideoPlaylist = 'video-playlist',
  Article = 'article',
  ArticleCollection = 'article-collection',
  Quiz = 'quiz',
  Assessment = 'assessment',
  LearningPath = 'learning-path',
  LiveOnline = 'live-online',
  Recipe = 'recipe',
}
