import { createSelector } from '@ngrx/store';

import { getFusionJobState } from '../reducers';

export const getCurrentScreeningState = createSelector(
  getFusionJobState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.currentScreening;
  }
);

export const getCurrentScreening = createSelector(
  getCurrentScreeningState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
