<div pageSpacing extraLargePage pageBody>
  <div pageTitle><h1>User Settings!</h1></div>
  <div pageContent>
    <div content>
      <div *ngIf="user$ | async as user" class="rowN">
        <mat-divider></mat-divider>
        <div>
          <fusion-oauth-referrals
            [displayStats]="true"
          ></fusion-oauth-referrals>
        </div>
        <mat-divider></mat-divider>
        <div>
          <user-update-profile
            [user]="user"
            [profileFormMetadata]="updateUserProfileFormMetadata"
            [profileFormConfigs]="updateUserProfileFormConfigs"
            [profileFormValidationRules]="updateUserProfileFormValidationRules"
            (notifyFormSubmit)="notifyUpdateUserProfileFormSubmit($event)"
          ></user-update-profile>
        </div>
        <mat-divider></mat-divider>
        <div>
          <user-update-email
            [user]="user"
            [emailFormMetadata]="updateUserEmailFormMetadata"
            [emailFormConfigs]="updateUserEmailFormConfigs"
            [emailFormValidationRules]="updateUserEmailFormValidationRules"
            (notifyFormSubmit)="notifyUpdateUserProfileFormSubmit($event)"
          ></user-update-email>
        </div>
        <mat-divider></mat-divider>
        <div>
          <user-update-password
            [user]="user"
            [passwordFormMetadata]="updateUserPasswordFormMetadata"
            [passwordFormConfigs]="updateUserPasswordFormConfigs"
            [passwordFormValidationRules]="
              updateUserPasswordFormValidationRules
            "
            (notifyFormSubmit)="notifyUpdateUserProfileFormSubmit($event)"
          ></user-update-password>
        </div>
        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Profile Picture</div>
            <div sectionSubtitle microTitle>
              This picture will be displayed with your profile.
            </div>
          </div>
          <div sectionContent class="profile-picture col2">
            <div profileImage class="col2__section-one">
              <img
                imageFit
                src="{{
                  user.profilePicture ||
                    'assets/images/users/profile-picture/profile.png'
                }}"
              />
            </div>
            <div class="col2__section-two">
              <fusion-common-image-crop
                [aspectRatio]="1"
                [isRoundedCanvas]="true"
                [uploadTitle]="'Upload Profile Picture'"
                (notifyImageUpload)="onNotifyImageUpload($event)"
              ></fusion-common-image-crop>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div section>
          <div sectionHeader>
            <div sectionTitle smallTitle microBold>Banner Picture</div>
            <div sectionSubtitle microTitle>
              This image will be displayed with your career profile.
            </div>
          </div>
          <div sectionContent class="row2">
            <div image class="row2__section-one">
              <img
                radius
                imageFit
                src="{{
                  user.bannerPicture || 'assets/images/users/banner/banner.jpg'
                }}"
              />
            </div>
            <div class="row2__section-two">
              <fusion-common-banner-image-crop
                [aspectRatio]="12 / 4"
                [uploadTitle]="'Upload Banner (1200 x 400)px'"
                [isRoundedCanvas]="false"
                (notifyBannerImageUpload)="onNotifyBannerImageUpload($event)"
              ></fusion-common-banner-image-crop>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div adSpace></div>
  </div>
</div>
