<div class="form-group">
  <div class="example-container selectContainer">
    <ng-container
      *ngIf="formConfigs.isDynamicDropdownOptions; else static_data"
    >
      <mat-form-field>
        <mat-label>{{ formConfigs.label }} </mat-label>
        <mat-select
          [formControl]="formValidationRules.controls[formConfigs.name]"
          required="{{
            formValidationRules.controls[formConfigs.name].hasError('required')
          }}"
          [multiple]="formConfigs.isMultiSelectDropdown ? true : false"
          (selectionChange)="notifySelectionChange.emit($event)"
        >
          <ng-container
            *ngIf="formConfigs.isMultiSelectDropdown; else single_selection"
          >
            <mat-option
              *ngFor="let option of formConfigs.options"
              value="{{ option.id }}"
              >{{ option.title }}</mat-option
            >
          </ng-container>
          <ng-template #single_selection>
            <mat-option
              *ngFor="let option of formConfigs.options"
              value="{{ option.id }}"
            >
              <span [innerHTML]="option.title"></span>
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-template #static_data>
      <mat-form-field>
        <mat-label>{{ formConfigs.label }} </mat-label>
        <mat-select
          [formControl]="formValidationRules.controls[formConfigs.name]"
          required="{{
            formValidationRules.controls[formConfigs.name].hasError('required')
          }}"
          [multiple]="formConfigs.isMultiSelectDropdown ? true : false"
          (selectionChange)="notifySelectionChange.emit($event)"
        >
          <ng-container
            *ngIf="formConfigs.isMultiSelectDropdown; else single_selection"
          >
            <mat-option
              *ngFor="let option of formConfigs.options"
              value="{{ option.code || option }}"
              >{{ option.name || option }}</mat-option
            >
          </ng-container>
          <ng-template #single_selection>
            <mat-option
              *ngFor="let option of formConfigs.options"
              value="{{ option.code || option }}"
            >
              <span [innerHTML]="option.name || option"></span>
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <mat-error
      *ngIf="
        formValidationRules.controls[formConfigs.name].hasError('required') &&
        !formValidationRules.controls[formConfigs.name].value &&
        formValidationRules.controls[formConfigs.name].touched
      "
    >
      You must select {{ formConfigs.label }}.
    </mat-error>
  </div>
</div>
