import { getoAuthUserId } from '@fusion/oauth';
import { createSelector } from '@ngrx/store';
import { isPast } from 'date-fns';
import { colors, IEventGuest, JoiningStatus } from '../../models';

import { getCalendarState } from '../reducers';

export const getEventsState = createSelector(getCalendarState, (state) => {
  if (state == null) {
    return null;
  }
  return state.events;
});

export const getEvents = createSelector(
  getEventsState,
  getoAuthUserId,
  (state, userId) => {
    if (state == null) {
      return null;
    }

    // TODO timezone setup
    // https://www.ibm.com/docs/en/was-nd/8.5.5?topic=ctzs-time-zone-ids-that-can-be-specified-usertimezone-property
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const formatedTime = formatInTimeZone(
    //   event.start,
    //   timezone,
    //   'yyyy-MM-dd HH:mm:ss zzz'
    // );
    // console.log('--------->', timezone, formatedTime);
    // timezone

    const updatedEvents = state.data.map((event) => {
      const isExpired = isPast(new Date(event.end));
      const currentGuest: IEventGuest = event?.meta?.currentGuest;
      const hasAccepted =
        currentGuest?.joiningStatus === JoiningStatus.Accepted;
      const hasRejected =
        currentGuest?.joiningStatus === JoiningStatus.Rejected;

      // const totalGuestCount = event?.meta?.guests.length;
      // const acceptedGuests: IEventGuest[] = event?.meta?.guests.filter(
      //   (guest) => guest.joiningStatus === JoiningStatus.Accepted
      // );
      // const acceptedGuestCount = acceptedGuests.length;
      // const rejectedGuests: IEventGuest[] = event?.meta?.guests.filter(
      //   (guest) => guest.joiningStatus === JoiningStatus.Rejected
      // );
      // const rejectedGuestCount = rejectedGuests.length;
      return {
        ...event,
        start: new Date(event.start),
        end: event.end ? new Date(event.end) : null,
        color: hasAccepted
          ? isExpired
            ? colors.acceptedAndExpired
            : colors.accepted
          : hasRejected
          ? isExpired
            ? colors.rejectedAndExpired
            : colors.rejected
          : isExpired
          ? colors.pendingAndExpired
          : colors.pending,
        cssClass: hasAccepted
          ? isExpired
            ? 'fusion-calendar-event__default event__expired'
            : 'fusion-calendar-event__default'
          : hasRejected
          ? isExpired
            ? 'fusion-calendar-event__rejected event__expired'
            : 'fusion-calendar-event__rejected'
          : isExpired
          ? 'fusion-calendar-event__default event__expired'
          : 'fusion-calendar-event__default',
        // meta: {
        //   ...event.meta,
        //   guestCount: totalGuestCount,
        //   acceptedGuestCount: acceptedGuestCount,
        //   rejectedGuestCount: rejectedGuestCount,
        //   waitingguestCount:
        //     totalGuestCount - acceptedGuestCount - rejectedGuestCount,
        // },
      };
    });
    return updatedEvents;
  }
);
