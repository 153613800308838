import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.content.host;
    this.api = this.configService.getConfig().services.content.api;
  }

  getContent(contentId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/contents/${contentId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getContentRevisions(contentId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/contents/${contentId}/revisions`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  createContent(contentData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/contents`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, contentData, options);
  }

  createContentRevision(contentId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/contents/${contentId}/revisions`;
    const options = this.requestOptionsService.generateOptions();
    const contentData = {};
    return this._http.post(fullUrl, contentData, options);
  }

  updateDraftRevision(
    draftRevision: any,
    contentId: string,
    revisionId: string
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/contents/${contentId}/revisions/${revisionId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, draftRevision, options);
  }

  publishOrRetireRevision(
    contentId: string,
    revisionId: string
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/contents/${contentId}/revisions/${revisionId}/status`;
    const options = this.requestOptionsService.generateOptions();
    const contentData = {};
    return this._http.put(fullUrl, contentData, options);
  }

  searchContents(searchPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/contents/preview/search`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, searchPayload, options);
  }

  createMeeting(meetingPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/meetings`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, meetingPayload, options);
  }

  joinMeeting(meetingId: string, meetingPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/meetings/${meetingId}/join`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, meetingPayload, options);
  }

  createEvent(eventPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/events`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, eventPayload, options);
  }

  updateEvent(eventId: string, eventPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/events/${eventId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, eventPayload, options);
  }

  getEvents(userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}/events`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getEvent(eventId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/events/${eventId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getGuests(eventId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/events/${eventId}/guests`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  addGuest(eventId: string, guestPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/events/${eventId}/guests`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, guestPayload, options);
  }

  updateJoiningStatus(
    eventId: string,
    guestId: string,
    joiningStatusPayload: object
  ): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/events/${eventId}/guests/${guestId}/status`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, joiningStatusPayload, options);
  }

  removeGuest(eventId: string, guestId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/events/${eventId}/guests/${guestId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.delete(fullUrl, options);
  }

  removeEvent(eventId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/events/${eventId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.delete(fullUrl, options);
  }

  createTag(tagPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/tags`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, tagPayload, options);
  }

  searchTags(searchPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/tagsearch`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, searchPayload, options);
  }

  searchTagRecommendations(searchPayload: object): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/tagrecommendations`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, searchPayload, options);
  }

  approveTagRecommendation(tagId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/tagrecommendations/${tagId}/approve`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, {}, options);
  }

  rejectTagRecommendation(tagId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/tagrecommendations/${tagId}/reject`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, {}, options);
  }
}
