import { IForm, FormFieldType } from '@fusion/form';

export const MosqueOverviewForm: IForm = {
  metadata: {
    name: 'MOSQUE_OVERVIEW_FORM',
    title: 'Add Mosque',
    description: 'add mosque / prayer place & help other muslim to pray',
    submitButtonText: 'Next',
    stepper: {
      isStepper: true,
    },
  },
  configs: [
    // company details - start
    {
      label: 'Mosque Overview',
      name: 'companyDetail',
      type: FormFieldType.Textarea,
      placeholder: 'mosque description',
      validation: {
        required: true,
        min: 10,
        max: 10000,
      },
    },
  ],
};
