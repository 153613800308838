import { 
  SeoActionTypes,
  SeoActions
} from '../actions/seo.actions';

export interface SeoState {
  data: any;
  loading: boolean;
  loaded: boolean;
}

export const initialState: SeoState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: SeoActions
): SeoState {
  switch (action.type) {
    case SeoActionTypes.SetUserSeo: {
      return {
        ...state,
        loading: true
      };
    }
    case SeoActionTypes.SetUserSeoSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case SeoActionTypes.SetUserSeoFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
