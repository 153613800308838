import { IForm, FormFieldType, FormFieldPattern } from "@fusion/form";
import { PUBLICATION_UPDATE_FORM } from "@fusion/common";

export const PublicationUpdateForm: IForm = {
  metadata: {
    name: PUBLICATION_UPDATE_FORM,
    title: "Update Publication",
    submitButtonText: "Update Publication",
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "530px"
    }
  },
  configs: [
    {
      label: "Publication Title",
      name: "title",
      type: FormFieldType.Text,
      placeholder: "certification name",
      validation: {
        required: true,
        max: 150
      }
    },
    {
      label: "Publisher",
      name: "publisher",
      type: FormFieldType.Text,
      placeholder: "certification name",
      validation: {
        required: true,
        max: 150
      }
    },
    {
      label: "Publishing Date",
      name: "publishedAt",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Publication URL",
      name: "website",
      type: FormFieldType.Url,
      placeholder: "http://elitecareer.net",
      examplePattern: "http://elitecareer.net",
      validation: {
        required: true,
        min: 2,
        max: 1000,
        pattern: FormFieldPattern.Url
      }
    },
    {
      label: "Description",
      name: "description",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: false,
        max: 10000
      }
    }
  ]
};
