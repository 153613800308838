import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'projects/job/src/environments/environment';

// from reducer

export interface UserState {}

export const reducers: ActionReducerMap<UserState> = {};

export const metaReducers: MetaReducer<UserState>[] = !environment.production
  ? []
  : [];

// export reducer state interfaces
