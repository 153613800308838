import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IExperience } from '../../models/interfaces';
import {
  ModalIconType,
  setFormValidationRules,
  IFormMetadata,
  IFormConfig,
} from '@fusion/form';
import { ExperienceForm, ExperienceUpdateForm } from '../../models/forms';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'fusion-career-profile-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss'],
})
export class ExperiencesComponent implements OnInit {
  @Output() notifyViewCompany = new EventEmitter<string>();
  @Output() notifyFormSubmit = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() notifyDeleteExperience = new EventEmitter<any>();
  @Input() experiences: IExperience[];
  @Input() autoCompleteEntities: any;
  @Input() allowEdit: boolean;

  // form setup
  experienceFormMetadata: IFormMetadata = ExperienceForm.metadata;
  experienceFormConfigs: IFormConfig[] = ExperienceForm.configs;
  experienceFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(ExperienceForm.configs)
  );
  experienceUpdateFormMetadata: IFormMetadata = ExperienceUpdateForm.metadata;
  experienceUpdateFormConfigs: IFormConfig[] = ExperienceUpdateForm.configs;
  experienceUpdateFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(ExperienceUpdateForm.configs)
  );

  isExpanded: boolean = false;
  ModalIconType = ModalIconType;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {}

  notifyCardFooterClick() {
    this.isExpanded = this.isExpanded ? false : true;
  }
}
