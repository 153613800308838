import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import {
  RedirectSubscriberActionTypes,
  LoadRedirectSubscriberSuccess,
  LoadRedirectSubscriberFail,
  LoadRedirectSubscriber,
} from '../actions/redirect-subscriber.actions';
import { FusionSubscriptionState } from '../reducers/index';
import { Store } from '@ngrx/store';
import { Params, Router } from '@angular/router';
import { getRouterParams } from '@fusion/router';
import { getApplications, getoAuthUserId } from '@fusion/oauth';
import { IError, ErrorSource } from '@fusion/error';
import { FusionSubscriptionError } from '../../models/enums';
import { IApplication } from '../../models/interfaces';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class RedirectSubscriberEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private router: Router,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  
  effect$ = createEffect(() => this.actions$.pipe(
    ofType<LoadRedirectSubscriber>(
      RedirectSubscriberActionTypes.LoadRedirectSubscriber
    ),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getoAuthUserId),
      this.store.select(getRouterParams),
      this.store.select(getApplications)
    ),
    mergeMap(
      ([payload, oauthUserId, params, apps]: [
        IApplication,
        string,
        Params,
        any
      ]) => {
        const protocol = apps.protocol;
        const domain = apps.domain;
        const subdomains = apps.subdomains;
        const subdomainPorts = apps.subdomainPorts;
        const companyId =
          payload.domain === subdomains.company ||
          payload.domain === subdomains.mosque
            ? payload.id
            : params.companyId;
        const appSubscriberId = companyId ? companyId : oauthUserId;

        const appHost =
          domain === 'localhost'
            ? `${protocol}://${domain}:${subdomainPorts[payload.domain]}`
            : `${protocol}://${subdomains[payload.domain]}.${domain}`;
        // TODO rename payload.domain to payload.subdomain
        switch (payload.domain) {
          case 'companies': {
            this.router.navigate([`companies`]);
            return of(new LoadRedirectSubscriberSuccess(payload));
          }
          case 'mosques': {
            this.router.navigate([`mosques`]);
            return of(new LoadRedirectSubscriberSuccess(payload));
          }
          case subdomains.learning:
          case subdomains.rent: {
            this._document.defaultView.location.href = `${appHost}/${appSubscriberId}/search`;
            break;
          }
          case subdomains.author: {
            this._document.defaultView.location.href = `${appHost}/${appSubscriberId}/contents`;
            break;
          }
          default: {
            this._document.defaultView.location.href = `${appHost}/${appSubscriberId}`;
          }
        }

        return of(new LoadRedirectSubscriberSuccess(payload));
      }
    ),
    catchError((error) => {
      const errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.LoadRedirectSubscriberFail,
        source: ErrorSource.Validation,
        data: error,
      };
      return of(new LoadRedirectSubscriberFail(errorPayload));
    })
  ));
}
