import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { IFormConfig } from '../../models/interfaces';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormFieldPattern } from '../../models/enums';

@Component({
  selector: 'fusion-form-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  emailsCtrl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(FormFieldPattern.Email),
  ]);
  emails: string[] = [];

  constructor() {}

  ngOnInit() {}

  add(event: MatChipInputEvent, hasError: boolean) {
    const value = (event.value || '').trim();

    // Add email
    if (value && !hasError) {
      this.emails.push(value);
      this.updateFormControlValues();
    }

    // Clear the input value
    event.chipInput!.clear();

    this.emailsCtrl.setValue(null);
  }

  remove(email: string) {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.updateFormControlValues();
    }
  }

  updateFormControlValues() {
    this.formValidationRules.controls[this.formConfigs.name].setValue(
      this.emails.length ? this.emails : null
    );
  }
}
