<mat-sidenav-container class="drawer" scrollable>
  <mat-sidenav
    #appDrawer
    fixedInViewport="true"
    class="drawer-container"
    [mode]="mode"
    [opened]="isHomeApp ? false : isOpen"
    (openedStart)="onOpen(isHomeApp)"
    (closedStart)="onClose(isHomeApp)"
  >
    <!-- top nav options -->
    <ng-container *ngIf="!appSettings || !appSettings?.isStandalone">
      <ng-container *ngIf="!(token$ | async); else oauthUser">
        <div class="drawer__top-nav-non-oauth" fxShow="true" fxHide.gt-sm>
          <div class="nav__item" routerLink="/signin" (click)="toggleNav(true)">
            <mat-icon>lock_open</mat-icon>
            <span>Sign In</span>
          </div>
          <div class="nav__item" routerLink="/signup" (click)="toggleNav(true)">
            <mat-icon>person_add</mat-icon>
            <span>Sign Up</span>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #oauthUser>
      <!-- side nav options -->
      <div class="drawer__side-nav">
        <ng-container *ngIf="breakpoint$ | async as breakpoint">
          <ng-container *ngFor="let nav of sideNav">
            <div
              pointer
              class="side-nav__item"
              [class.drawer__item-active]="nav.isCurrentPath"
              [attr.semiBold]="nav.isCurrentPath ? 'semiBold' : null"
              (click)="navigateToRouterLink(nav); toggleNav(breakpoint.matches)"
            >
              <ng-container *ngIf="nav.isCurrentPath; else regularIcon">
                <mat-icon color="primary">{{ nav.icon }}</mat-icon>
                <div centering centerLeft>
                  <span primaryFontColor>
                    {{ nav.title }}
                  </span>
                </div>
              </ng-container>

              <ng-template #regularIcon>
                <mat-icon>{{ nav.icon }}</mat-icon>
                <div centering centerLeft>
                  <span>
                    {{ nav.title }}
                  </span>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!-- top nav vs side nav options separator -->
      <div class="v-margin-5" fxShow="true" fxHide.gt-sm>
        <mat-divider></mat-divider>
      </div>

      <!-- top nav options -->
      <div class="drawer__top-nav" fxShow="true" fxHide.gt-sm>
        <!-- user subscription navigations -->
        <ng-container
          *ngIf="oAuthUserTopNavigation$ | async as userTopNavigation"
        >
          <div class="top-nav__item" [matMenuTriggerFor]="userSubscriptionMenu">
            <div centering>
              <span smallLogo>
                <img
                  imageFit
                  src="{{
                    userTopNavigation.profilePicture ||
                      'assets/images/users/profile-picture/profile.png'
                  }}"
                />
              </span>
            </div>
            <div class="nav-option col2">
              <span centering centerLeft>{{ userTopNavigation.title }}</span>
              <span centering centerRight>
                <mat-icon>arrow_right</mat-icon>
              </span>
            </div>
          </div>
          <mat-menu #userSubscriptionMenu="matMenu" xPosition="after">
            <a
              (click)="navigateToUserProfile(userTopNavigation?.targetPath)"
              mat-menu-item
              bottomBorder
            >
              <div centering>
                <span extraSmallProfileImage class="r-margin-3">
                  <img
                    imageFit
                    src="{{
                      userTopNavigation.profilePicture ||
                        'assets/images/users/profile-picture/profile.png'
                    }}"
                  />
                </span>
                <span>Career Profile</span>
              </div>
            </a>

            <!-- <button mat-menu-item disabled>
              <mat-icon>verified_user</mat-icon>
              <span>Personal Subscriptions</span>
            </button> -->
            <ng-container
              *ngFor="let subscription of userTopNavigation.subscriptions"
            >
              <a (click)="navigateToSubscription(subscription)" mat-menu-item>
                <div centering>
                  <span>{{ subscription.applicationName }} Service </span>
                </div>
              </a>
            </ng-container>
          </mat-menu>
        </ng-container>
        <!-- company subscription navigations -->
        <ng-container *ngIf="appBaseUrls$ | async as appBaseUrls">
          <ng-container *ngFor="let company of oAuthTopNavigations$ | async">
            <ng-container *ngIf="company?.subscriptions.length; else noSubMenu">
              <div
                class="top-nav__item"
                [matMenuTriggerFor]="companySubscriptionMenu"
              >
                <div centering>
                  <span smallLogo>
                    <img
                      imageFit
                      src="{{
                        company?.logo ||
                          '/assets/images/users/company-logo/logoPlaceholder.png'
                      }}"
                    />
                  </span>
                </div>

                <div class="nav-option col2">
                  <span centering centerLeft>{{
                    company.title | truncate: 30
                  }}</span>
                  <span centering centerRight>
                    <mat-icon>arrow_right</mat-icon>
                  </span>
                </div>
              </div>
              <!-- compnay sub menu -->
              <mat-menu #companySubscriptionMenu="matMenu">
                <a
                  (click)="navigateToCompany(appBaseUrls, company)"
                  mat-menu-item
                  bottomBorder
                >
                  <div centering center>
                    <span smallLogo class="r-margin-3">
                      <img
                        imageFit
                        src="{{
                          company?.logo ||
                            '/assets/images/users/company-logo/logoPlaceholder.png'
                        }}"
                      />
                    </span>
                    <span>Company Profile</span>
                  </div>
                </a>

                <!-- <button mat-menu-item disabled>
                  <mat-icon>verified_user</mat-icon>
                  <span>Company Subscriptions</span>
                </button> -->
                <ng-container
                  *ngFor="let subscription of company.subscriptions"
                >
                  <a
                    (click)="navigateToSubscription(subscription)"
                    mat-menu-item
                  >
                    <div centering>
                      <span>{{ subscription.applicationName }} Service </span>
                    </div>
                  </a>
                </ng-container>
              </mat-menu>
            </ng-container>
            <!-- companies with no subscription menu -->
            <ng-template #noSubMenu>
              <div
                class="top-nav__item"
                (click)="navigateToCompany(appBaseUrls, company)"
              >
                <div centering>
                  <span smallLogo>
                    <img
                      imageFit
                      src="{{
                        company?.logo ||
                          '/assets/images/users/company-logo/logoPlaceholder.png'
                      }}"
                    />
                  </span>
                </div>

                <span centering centerLeft>{{
                  company.title | truncate: 30
                }}</span>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container>
          <div class="top-nav__item-sign-out" (click)="signOut()">
            <div centering center>
              <mat-icon color="warn">link_off</mat-icon>
            </div>
            <div centering centerLeft>
              <span errorFontColor>Sign Out</span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </mat-sidenav>

  <!-- side nav controls -->
  <mat-sidenav-content>
    <ng-container *ngIf="breakpoint$ | async as breakpoint">
      <ng-container *ngIf="!appSettings || !appSettings?.isStandalone">
        <ng-container *ngIf="!isHomeApp; else homeApp">
          <div class="side-nav__controls" (click)="toggleNav(true)">
            <button
              color="primary"
              mat-icon-button
              aria-label="Activity playlist"
              [attr.aria-expanded]="!isPlaylistCollapsed"
            >
              <mat-icon class="icon-display">{{
                isPlaylistCollapsed ? 'arrow_back' : 'arrow_forward'
              }}</mat-icon>
            </button>
          </div>
        </ng-container>
        <!-- home app -->
        <ng-template #homeApp>
          <div
            *ngIf="breakpoint.matches"
            class="side-nav__controls"
            (click)="toggleNav(true)"
          >
            <button
              color="primary"
              mat-icon-button
              aria-label="Activity playlist"
              [attr.aria-expanded]="!isPlaylistCollapsed"
            >
              <mat-icon *ngIf="breakpoint.matches" class="icon-display">{{
                isPlaylistCollapsed ? 'arrow_forward' : 'arrow_back'
              }}</mat-icon>
            </button>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
