import { Component, OnInit, Input } from "@angular/core";
import { IPaymentMethod } from "../../models/interfaces";

@Component({
  selector: "fusion-payment-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {
  @Input() paymentMethod: IPaymentMethod;
  @Input() isSelected: boolean;

  constructor() {}

  ngOnInit() {}
}
