<div *ngIf="sortOption" class="col2">
  <div centering class="col2__section-one">
    <span>
      <span semiBold>{{ sortOption.title }}: </span>
      <span
        class="sort-option"
        [ngClass]="{
          'sort-option-active': orderBy === sortOption.optionOne.value
        }"
        (click)="notifySort.emit(sortOption.optionOne.value)"
        >{{ sortOption.optionOne.title }}</span
      >
      |<span
        class="sort-option"
        [ngClass]="{
          'sort-option-active': orderBy === sortOption.optionTwo.value
        }"
        (click)="notifySort.emit(sortOption.optionTwo.value)"
        >{{ sortOption.optionTwo.title }}</span
      >
    </span>
  </div>
</div>
