import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { formConfigs, formMetadata, formValidationRules } from '@fusion/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fusion-form-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
})
export class OptionListComponent implements OnInit {
  @Output() notifyJobType: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyJobLevel: EventEmitter<string> = new EventEmitter<string>();
  @Input() dialogRef: MatDialogRef<any> | null;
  @Input() formName: string;
  @Input() selectedJobLevels: any;
  @Input() selectedJobTypes: any;
  validationRules: UntypedFormGroup;
  formConfig: Object;
  metadata: any;
  role: string[];

  private destroy$ = new Subject<void>();
  displayLevel: any = [
    {
      value: true,
    },
    {
      value: true,
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef
  ) {}

  submitOption(type, name, formData) {
    if (name === 'jobType') {
      this.notifyJobType.emit(type);
    } else if (name === 'jobLevel') {
      this.notifyJobLevel.emit(type);
    }
  }

  ngOnInit() {
    this.formConfig = formConfigs.jobSearchOptionsForm;
    this.metadata = formMetadata.jobSearchOptionsForm;
    this.validationRules = this.fb.group(
      formValidationRules.jobSearchOptionsForm
    );

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.displayLevel = [
            {
              value: false,
            },
            {
              value: false,
            },
          ];
          this.cd.markForCheck();
        } else {
          this.displayLevel = [
            {
              value: true,
            },
            {
              value: true,
            },
          ];
          this.cd.markForCheck();
        }
      });
  }

  getSelectionState(type: string, name: string, jobLevels: any, jobTypes: any) {
    if (name === 'jobType' && jobTypes !== null) {
      const typeExist = jobTypes.find((level) => level === type);
      return typeExist ? true : false;
    } else if (name === 'jobLevel' && jobLevels !== null) {
      const levelExist = jobLevels.find((level) => level === type);
      return levelExist ? true : false;
    }
    return false;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
