import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { mergeMap } from "rxjs/operators";

import { of } from "rxjs";
import { SetError } from "@fusion/error";
import {
  EducationsActionTypes,
  ExperiencesActionTypes,
  JobseekerProfileActionTypes,
  ProjectsActionTypes,
  PublicationsActionTypes,
  RatingActionTypes,
  SkillsActionTypes
} from "../actions/index";

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions) {}

  
  catchError$ = createEffect(() => this.actions$.pipe(
    ofType(
      EducationsActionTypes.AddEducationsFail,
      EducationsActionTypes.UpdateEducationsFail,
      ExperiencesActionTypes.AddExperiencesFail,
      ExperiencesActionTypes.DeleteExperiencesFail,
      ExperiencesActionTypes.LoadExperiencesFail,
      ExperiencesActionTypes.UpdateExperiencesFail,
      JobseekerProfileActionTypes.AddJobseekerProfileFail,
      JobseekerProfileActionTypes.LoadJobseekerProfileFail,
      JobseekerProfileActionTypes.UpdateJobseekerProfileFail,
      ProjectsActionTypes.AddProjectFail,
      ProjectsActionTypes.LoadProjectsFail,
      ProjectsActionTypes.UpdateProjectFail,
      PublicationsActionTypes.AddPublicationFail,
      PublicationsActionTypes.LoadPublicationsFail,
      PublicationsActionTypes.UpdatePublicationFail,
      RatingActionTypes.LoadRatingFail,
      SkillsActionTypes.LoadSkillsFail
    ),
    mergeMap((action: any) => {
      return of(new SetError(action.payload));
    })
  ));
}
