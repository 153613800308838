import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import { Observable } from 'rxjs';
import { ICompany, IEmptyState, ISearchForm, countries } from '@fusion/common';
import {
  getoAuthUserId,
  getoAuthUserFullName,
  getEmployerMosques,
  FusionoAuthError,
  getRepresentativeMosques,
} from '@fusion/oauth';
import {
  LoadRedirectSubscriber,
  hasMosqueSubscription,
  FusionSubscriptionError,
  IApplication,
} from '@fusion/subscription';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  getUnclaimedCompanies,
  LoadUnclaimedCompanies,
  AddCompany,
} from '@fusion/company';
import {
  IErrorHandlingConfig,
  getErrorHandlingConfig,
  ErrorHandlingType,
} from '@fusion/error';
import { AddMosqueForm } from '../../models/forms';
import { setFormValidationRules } from '@fusion/form';
import {
  getSearchKeywords,
  SetSearchKeywords,
  SetSearchCategory,
  getSearchCategories,
} from '@fusion/search';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'user-mosques',
  templateUrl: './mosques.component.html',
  styleUrls: ['./mosques.component.scss'],
})
export class MosquesComponent implements OnInit {
  companies$: Observable<ICompany[]>;
  unclaimedCompanies$: Observable<ICompany[]>;
  repCompanies$: Observable<ICompany[]>;
  userName$: Observable<string>;
  currentRootPath$: Observable<string>;
  keywords$: Observable<string>;
  searchCategory$: Observable<string>;
  badgeCount: number = 18;
  hasMosqueSubscription$: Observable<any>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;
  userProfileErrorHandlingConfig$: Observable<IErrorHandlingConfig>;
  emptyState: IEmptyState = {
    path: '/subscribe',
    card: {
      title: 'add subscription',
      description: 'Explore our unlimited services!',
    },
    message: {
      title: 'no mosque subscription',
      description: 'Subscribe mosque to access resources!',
    },
  };
  buttonLabel = 'View Mosque Profile';
  ErrorHandlingType = ErrorHandlingType;
  searchForm: ISearchForm;
  removable: boolean = true;

  // form setup
  addMosqueFormMetadata: any = AddMosqueForm.metadata;
  addMosqueFormConfigs: any = AddMosqueForm.configs;
  addMosqueFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(AddMosqueForm.configs)
  );

  constructor(
    private store: Store<UserState>,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // search form
    this.searchForm = {
      search: {
        label: 'Search Mosque to Follow',
        paceholder: 'Search by Address, City, Zip Code or mosque Name!',
      },
      searchDropdown: {
        categories: <any>countries,
        label: 'Search by Country',
      },
    };

    this.currentRootPath$ = this.store.select(getoAuthUserId);
    this.userName$ = this.store.select(getoAuthUserFullName);
    this.companies$ = this.store.select(getEmployerMosques);
    this.repCompanies$ = this.store.select(getRepresentativeMosques);
    this.hasMosqueSubscription$ = this.store.select(hasMosqueSubscription);
    this.keywords$ = this.store.select(getSearchKeywords);
    this.searchCategory$ = this.store.select(getSearchCategories);
    this.unclaimedCompanies$ = this.store.select(getUnclaimedCompanies);
    this.errorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionSubscriptionError.LoadSubscriptionsFail)
    );
    this.userProfileErrorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionoAuthError.LoadUserProfileFail)
    );
  }

  onNotifyViewCompany(companyId: string) {
    const currentUrl = this.router.url;
    this.router.navigate([`../companies/${companyId}`], {
      queryParams: { referrerUrl: currentUrl },
      relativeTo: this.route,
    });
  }

  onNotifyManageCompany(companyId: string) {
    const payload: IApplication = {
      domain: 'mosque',
      id: companyId,
    };
    this.store.dispatch(new LoadRedirectSubscriber(payload));
  }

  onNotifyAutocompletion(autoCompleteInput: string) {
    this.store.dispatch(new LoadUnclaimedCompanies(autoCompleteInput));
  }

  onNotifyFormSubmit(formData) {
    const mosque = {
      ...formData.data,
      type: 'Nonprofit',
      category: 'Mosque',
    };

    this.store.dispatch(new AddCompany(formData.data));
  }

  onNotifySearch(keywords: string) {
    this.store.dispatch(new SetSearchKeywords(keywords));
  }

  onNotifySearchCategory(category: string) {
    this.store.dispatch(new SetSearchCategory(category));
  }

  notifyRemoveCategory() {
    this.store.dispatch(new SetSearchCategory(null));
  }
}
