import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ICouponBalance } from "../../models/interfaces";

@Component({
  selector: "fusion-payment-redeem-coupon",
  templateUrl: "./redeem-coupon.component.html",
  styleUrls: ["./redeem-coupon.component.scss"]
})
export class RedeemCouponComponent implements OnInit {
  @Input() couponBalance: ICouponBalance;
  @Output() notifyRedeamCoupon: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyResetCouponBalance: EventEmitter<any> = new EventEmitter<
    any
  >();

  constructor() {}

  ngOnInit() {}
}
