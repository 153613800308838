<div *ngIf="paymentMethod" border radius cardSpacing class="row2">
  <div class="row2__section-one col2">
    <div class="col2__section-one">
      <span bold g4FontColor>{{ paymentMethod?.billingDetails.name }}</span>
      <span> xxxx-{{ paymentMethod?.card.last4 }} </span>
      <span
        *ngIf="paymentMethod?.default"
        radius
        picoFont
        semiBold
        g4FontColor
        class="default"
        >DEFAULT</span
      >
    </div>
    <div *ngIf="isSelected" class="col2__section-two check-icon">
      <mat-icon aria-hidden="false" aria-label="selected" color="accent"
        >check_circle</mat-icon
      >
    </div>
  </div>
  <div class="row2__section-two">
    <span microFont g3FontColor
      >Expires {{ paymentMethod.card.expMonth }}/{{
        paymentMethod.card.expYear
      }}</span
    >
  </div>
</div>
