import { Component, OnInit } from '@angular/core';
import { UserState } from '../../store/reducers/index';
import { Store } from '@ngrx/store';
import { IProfileNav, IEmptyState, ISubscription } from '@fusion/common';
import { Observable } from 'rxjs';
import { getoAuthUserFullName, getoAuthUserId, Subdomain } from '@fusion/oauth';
import {
  getActiveSubscriptions,
  LoadRedirectSubscriber,
  FusionSubscriptionError,
  IApplication,
} from '@fusion/subscription';
import {
  IErrorHandlingConfig,
  getErrorHandlingConfig,
  ErrorHandlingType,
} from '@fusion/error';

@Component({
  selector: 'user-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  applications$: Observable<ISubscription[]>;
  isOnlySubscriptions: boolean = true;
  userName$: Observable<string>;
  currentRootPath$: Observable<string>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;
  badgeCount: number = 18;
  emptyState: IEmptyState = {
    path: '/subscribe',
    card: {
      title: 'add subscription',
      description: 'Explore our unlimited services!',
    },
    message: {
      title: 'no subscription',
      description: 'Subscribe to access any service you need!',
    },
  };
  ErrorHandlingType = ErrorHandlingType;

  constructor(private store: Store<UserState>) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getoAuthUserId);
    this.userName$ = this.store.select(getoAuthUserFullName);
    this.applications$ = this.store.select(getActiveSubscriptions);
    this.errorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionSubscriptionError.LoadSubscriptionsFail)
    );
  }

  notifyViewApplication(app: IApplication) {
    if (app.domain === Subdomain.company) {
      app = {
        ...app,
        domain: 'companies',
      };
    }
    if (app.domain === Subdomain.Mosque) {
      app = {
        ...app,
        domain: 'mosques',
      };
    }
    this.store.dispatch(new LoadRedirectSubscriber(app));
  }
}
