import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IErrorHandlingConfig } from '@fusion/error';
import { IProfileNav, IUser, IFilePayload } from '@fusion/common';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import {
  getoAuthUserId,
  getoAuthUser,
  UpdateUser,
  UploadProfilePicture,
  UploadProfileBanner,
} from '@fusion/oauth';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  setFormValidationRules,
  IFormMetadata,
  IFormConfig,
} from '@fusion/form';
import {
  UpdateUserProfileForm,
  UpdateUserEmailForm,
  UpdateUserPasswordForm,
} from '../../models/forms';

@Component({
  selector: 'user-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  currentRootPath$: Observable<string>;
  user$: Observable<IUser>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;
  badgeCount: number = 18;

  // form setup
  updateUserProfileFormMetadata: IFormMetadata = UpdateUserProfileForm.metadata;
  updateUserProfileFormConfigs: IFormConfig[] = UpdateUserProfileForm.configs;
  updateUserProfileFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(UpdateUserProfileForm.configs)
  );
  updateUserEmailFormMetadata: IFormMetadata = UpdateUserEmailForm.metadata;
  updateUserEmailFormConfigs: IFormConfig[] = UpdateUserEmailForm.configs;
  updateUserEmailFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(UpdateUserEmailForm.configs)
  );
  updateUserPasswordFormMetadata: IFormMetadata =
    UpdateUserPasswordForm.metadata;
  updateUserPasswordFormConfigs: IFormConfig[] = UpdateUserPasswordForm.configs;
  updateUserPasswordFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(UpdateUserPasswordForm.configs)
  );

  constructor(private store: Store<UserState>, private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getoAuthUserId);

    this.user$ = this.store.select(getoAuthUser);
  }

  notifyUpdateUserProfileFormSubmit(formData) {
    this.store.dispatch(new UpdateUser(formData));
  }

  onNotifyImageUpload(image: IFilePayload) {
    image = {
      ...image,
      reference: 'profile_picture',
    };
    this.store.dispatch(new UploadProfilePicture(image));
  }

  onNotifyBannerImageUpload(image: IFilePayload) {
    image = {
      ...image,
      reference: 'banner_picture',
    };
    this.store.dispatch(new UploadProfileBanner(image));
  }
}
