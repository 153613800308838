import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  CurrentAppActionTypes,
  LoadCurrentAppSuccess,
  LoadCurrentAppFail,
  LoadCurrentApp,
} from '../actions/current-app.actions';
import { Store } from '@ngrx/store';
import {
  SubscriptionService,
  MappingService,
  mappingType,
} from '@fusion/service';
import { FusionSubscriptionState } from '../reducers';
import {
  SetProfileNavigation,
  LoadCurrentSubscription,
  SetCurrentProfileNavigation,
} from '../actions';
import { getCurrentApp } from '../selectors';
import { ICurrentAppMetadata, IApplication } from '../../models/interfaces';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { ApplicationId, FusionSubscriptionError } from '../../models/enums';

@Injectable()
export class CurrentAppEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private subscriptionService: SubscriptionService,
    private mappingService: MappingService
  ) {}

  effect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCurrentApp>(CurrentAppActionTypes.LoadCurrentApp),
      map((action) => action.payload),
      withLatestFrom(this.store.select(getCurrentApp)),
      mergeMap(
        ([payload, application]: [ICurrentAppMetadata, IApplication]) => {
          let errorPayload: IError<FusionSubscriptionError> = {
            code: FusionSubscriptionError.LoadCurrentAppFail,
            source: ErrorSource.Validation,
            data: null,
          };

          return this.subscriptionService
            .getCurrentApplication(payload.applicationId)
            .pipe(
              switchMap((dataResult) => {
                const mappedData =
                  this.mappingService.getMappedData<IApplication>(
                    dataResult,
                    mappingType.camelize
                  );
                const profileNav = mappedData.features;

                // skip load current subscription for user app
                if (ApplicationId.User === payload.applicationId) {
                  return [
                    new LoadCurrentAppSuccess(mappedData),
                    new SetProfileNavigation(profileNav),
                    new SetCurrentProfileNavigation(payload.currentPath),
                  ];
                }
                return [
                  new LoadCurrentAppSuccess(mappedData),
                  new SetProfileNavigation(profileNav),
                  new SetCurrentProfileNavigation(payload.currentPath),
                  new LoadCurrentSubscription(payload.applicationId),
                ];
              }),
              catchError((error) => {
                errorPayload = {
                  ...errorPayload,
                  source: ErrorSource.API,
                  data: error,
                  config: {
                    type: ErrorHandlingType.Dialog,
                    message:
                      'Sorry, we are having some issue loading current application. Please try again later.',
                    action: {
                      primary: {
                        type: ErrorActionType.Dispatch,
                        reference: [new LoadCurrentApp(payload)],
                        title: 'Retry',
                      },
                    },
                  },
                };
                return of(new LoadCurrentAppFail(errorPayload));
              })
            );
        }
      )
    )
  );
}
