<div card class="row3">
  <!-- section header -->
  <fusion-career-profile-card-header
    class="row3__section-one"
    [isColor]="false"
    [isEditable]="allowEdit"
    [title]="'Projects'"
    [buttonTitle]="'Add'"
    [iconType]="ModalIconType.AddIcon"
    [formValidationRules]="projectFormValidationRules"
    [formConfigs]="projectFormConfigs"
    [formMetadata]="projectFormMetadata"
    (notifyFormSubmit)="notifyFormSubmit.emit($event)"
  ></fusion-career-profile-card-header>

  <!-- section body -->
  <div class="row3__section-two">
    <div
      hover
      bottomDivider
      cardSpacing
      career
      *ngFor="let project of projects"
    >
      <!-- logo -->
      <div careerLogo smallLogo>
        <img
          imageFit
          src="/assets/images/users/company-logo/{{ 'logoPlaceholder.png' }}"
        />
      </div>

      <!-- detail -->
      <div careerDetail>
        <!-- position -->
        <div careerDetailHeader>
          <h5 g6FontColor>{{ project?.name }}</h5>
          <div exSmallTitle g6FontColor>{{ project?.role }}</div>
          <div microTitle g5FontColor>
            {{ project?.startDate | date: "MMM, yyyy" }} -
            {{
              project.endDate
                ? (project.endDate | date: "MMM, yyyy")
                : "current"
            }}
            <span class="dot"></span>
            {{ project.startDate | fancyTime: "duration" }}
          </div>
          <div exSmallTitle g5FontColor>
            <a href="{{ project.website }}" target="_blank">View Project</a>
          </div>
        </div>

        <!-- summary -->
        <div careerDetailSummary *ngIf="isExpanded && project.description">
          <h6 g6FontColor>Project Details</h6>
          <div [innerHTML]="project.description"></div>
        </div>
      </div>

      <!-- action buttons -->
      <div *ngIf="allowEdit" careerButton hoverDisplay>
        <button
          (click)="notifyDeleteProject.emit(project.id)"
          class="custom-button"
          mat-fab
          color="accent"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <fusion-career-profile-card-header
          [currentEntity]="project"
          [buttonTitle]="'Edit'"
          [iconType]="ModalIconType.EditIcon"
          [formValidationRules]="projectUpdateFormValidationRules"
          [formConfigs]="projectUpdateFormConfigs"
          [formMetadata]="projectUpdateFormMetadata"
          (notifyFormSubmit)="notifyFormSubmit.emit($event)"
        ></fusion-career-profile-card-header>
      </div>
    </div>
  </div>

  <!-- section footer -->
  <div *ngIf="projects.length" class="row3__section-three">
    <fusion-common-card-footer
      (notifyCardFooterClick)="notifyCardFooterClick()"
      [title]="isExpanded ? 'view less' : 'view more'"
    ></fusion-common-card-footer>
  </div>
</div>
