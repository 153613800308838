import { Injectable } from '@angular/core';
import {
  ConfigService,
  CookieService,
  ServerCookieService,
  TokenService,
} from '@fusion/service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, switchMap, map, tap } from 'rxjs/operators';

import * as moment from 'moment';
import * as jwt from 'jsonwebtoken';

import {
  AppSessionActionTypes,
  StartAppSessionSuccess,
  GetAppSession,
  GetAppSessionFail,
  GetAppSessionSuccess,
  RestoreAppSession,
  RestoreAppSessionSuccess,
  RestoreAppSessionFail,
  RefreshAppSession,
  RefreshAppSessionSuccess,
} from '../actions/app-session.actions';
import {
  ErrorActionType,
  ErrorHandlingType,
  ErrorSource,
  IError,
} from '@fusion/error';
import { ISession } from '../../models/interfaces';
import { FusionoAuthError } from '../../models/enums';

@Injectable()
export class AppSessionEffects {
  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private tokenService: TokenService,
    private cookieService: CookieService,
    private serverCookieService: ServerCookieService
  ) {}

  startAppSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSessionActionTypes.StartAppSession),
      switchMap((action: any) => {
        return [new RestoreAppSession()];
      })
    )
  );

  restoreAppSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSessionActionTypes.RestoreAppSession),
      mergeMap((data) => {
        const appSessionCookie =
          this.configService.getConfig().auth.gateway.appSessionCookie;
        return this.cookieService.get(appSessionCookie).pipe(
          switchMap((appToken) => {
            const decodedToken = jwt.decode(appToken.token);
            const exp = moment.unix(decodedToken.exp);
            const iat = moment.unix(decodedToken.iat);
            const expiration = this.getDays(exp, iat);
            const session: any = {
              token: appToken.token,
              expiration: expiration,
            };
            const isExpired = this.isExpired(exp);
            if (isExpired) {
              return [
                new RestoreAppSessionFail(),
                new RefreshAppSession(session),
              ];
            }
            return [
              new RestoreAppSessionSuccess(),
              new StartAppSessionSuccess(session),
            ];
          }),
          catchError((error) => {
            const errorPayload: IError<FusionoAuthError> = {
              code: FusionoAuthError.SessionRestoreFail,
              source: ErrorSource.Validation,
              data: error,
            };
            return [
              new RestoreAppSessionFail(errorPayload),
              new GetAppSession(),
            ];
          })
        );
      })
    )
  );

  getAppSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAppSession>(AppSessionActionTypes.GetAppSession),
      switchMap((action: any) => {
        return this.tokenService.getAppToken().pipe(
          switchMap((dataResult) => {
            const decodedToken = jwt.decode(dataResult.token);
            const session: ISession = {
              token: dataResult.token,
              expiration: new Date(decodedToken.exp * 1000),
            };

            return [
              new GetAppSessionSuccess(session),
              new StartAppSessionSuccess(session),
            ];
          }),
          catchError((error) => {
            const errorPayload: IError<FusionoAuthError> = {
              code: FusionoAuthError.GetAppSessionFail,
              source: ErrorSource.API,
              data: error,
              config: {
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new GetAppSession()],
                    title: 'Retry',
                  },
                },
                type: ErrorHandlingType.Message,
                message:
                  'Sorry, we are having some issue loading your application identity. Please try again later.',
              },
            };
            return of(new GetAppSessionFail(errorPayload));
          })
        );
      })
    )
  );

  refreshAppSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RefreshAppSession>(AppSessionActionTypes.RefreshAppSession),
      map((action) => action.session),
      mergeMap((appSession: ISession) => {
        return this.tokenService.getRefreshAppToken(appSession.token).pipe(
          switchMap((dataResult) => {
            const decodedToken = jwt.decode(dataResult.token);
            const session: ISession = {
              token: dataResult.token,
              expiration: new Date(decodedToken.exp * 1000),
            };

            return [
              new RefreshAppSessionSuccess(session),
              new StartAppSessionSuccess(session),
            ];
          })
        );
      })
    )
  );

  onGetAppSessionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetAppSessionSuccess>(
          AppSessionActionTypes.GetAppSessionSuccess,
          AppSessionActionTypes.RefreshAppSessionSuccess
        ),
        map((action) => action.payload),
        tap((session: ISession) => {
          const apps = this.configService.getConfig().apps;
          const protocol = apps.protocol;
          const domain = apps.domain;
          const isSecure = protocol === 'https' ? true : false;
          const appSessionCookieName =
            this.configService.getConfig().auth.gateway.appSessionCookie;
          this.serverCookieService.set(
            appSessionCookieName,
            session.token,
            session.expiration,
            '/',
            domain,
            isSecure
          );
        })
      ),
    { dispatch: false }
  );

  getDays(expDate, iatDate) {
    return expDate.diff(iatDate, 'days');
  }

  isExpired(expDate) {
    return expDate.diff(new Date(), 'minute') <= 0;
  }
}
