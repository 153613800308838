import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IUser } from '@fusion/common';
import { ICompany } from '@fusion/company';
import {
  IFormConfig,
  IFormMetadata,
  setFormValidationRules,
} from '@fusion/form';
import { ApplicationStatus } from '../../models/enums';
import { InPersonForm } from '../../models/forms';
import { IInterview, IScreening } from '../../models/interfaces';

@Component({
  selector: 'in-person',
  templateUrl: './in-person.component.html',
  styleUrls: ['./in-person.component.scss'],
})
export class InPersonComponent implements OnInit {
  @Output() notifyScreeningUpdate = new EventEmitter<IScreening>();
  @Output() notifySetupMeeting = new EventEmitter<any>();
  @Input() isMeetingSetupInProgress: boolean;
  @Input() isPreview: boolean;
  @Input() company: ICompany;
  @Input() applicant: IUser;
  screeningValues: any = {};
  _currentScreening: IScreening;
  @Input('currentScreening') set currentScreening(
    currentScreening: IScreening
  ) {
    if (!currentScreening) {
      return;
    }
    this._currentScreening = currentScreening;
    const interview: IInterview = currentScreening.interview;
    if (interview) {
      this.screeningValues = {
        hasAccepted: interview.hasAccepted,
        phoneNo: interview.phoneNo,
        ...interview.time,
        ...interview.address,
      };
    }
  }

  get currentScreening() {
    return this._currentScreening;
  }

  currentMeetingId: string;
  videoInterviewMetadata: IFormMetadata = InPersonForm.metadata;
  videoInterviewConfigs: IFormConfig[] = InPersonForm.configs;
  videoInterviewValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(InPersonForm.configs)
  );
  constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  notifyDescription(eventData) {
    const screeningPayload: IScreening = {
      id: this.currentScreening.id,
      status: ApplicationStatus.InPerson,
      interview: {
        time: {
          date: eventData.date,
          startDatetime: eventData.startDatetime,
          endDatetime: eventData.endDatetime,
          startHour: eventData.startHour,
          startMinute: eventData.startMinute,
          startPeriod: eventData.startPeriod,
          endHour: eventData.endHour,
          endMinute: eventData.endMinute,
          endPeriod: eventData.endPeriod,
        },
        address: {
          address: eventData.address,
          unit: eventData.unit,
          city: eventData.city,
          state: eventData.state,
          zipCode: eventData.zipCode,
          country: eventData.country,
        },
      },
    };
    console.log('========>', screeningPayload);
    this.notifyScreeningUpdate.emit(screeningPayload);
  }

  setupMeeting(activity: IInterview) {
    this.isMeetingSetupInProgress = true;
    this.currentMeetingId = activity.eventId;
    this.cdr.detectChanges();
    let editPayload: any = {};

    this.notifySetupMeeting.emit(editPayload);
  }

  onNotifyJoinLiveOnline(eventId: string) {
    // this.store.dispatch(new JoinLiveOnline(eventId));
  }
}
