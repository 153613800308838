<ng-container *ngIf="group === FormFieldGroup.Address; else shortAddress">
  <div *ngIf="formConfigs && formValidationRules" class="row">
    <div
      *ngFor="let config of getRequiredConfig(formConfigs, ['address', 'unit'])"
    >
      <div class="col-9" *ngIf="config.name == 'address'">
        <fusion-form-text-field
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-text-field>
      </div>
      <div class="col-3" *ngIf="config.name == 'unit'">
        <fusion-form-text-field
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-text-field>
      </div>
    </div>
  </div>

  <div *ngIf="formConfigs && formValidationRules" class="row">
    <div
      *ngFor="let config of getRequiredConfig(formConfigs, ['city', 'state'])"
    >
      <div class="col-6" *ngIf="config.name == 'city'">
        <fusion-form-text-field
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-text-field>
      </div>
      <div class="col-6" *ngIf="config.name == 'state'">
        <fusion-form-text-field
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-text-field>
      </div>
    </div>
  </div>

  <div *ngIf="formConfigs && formValidationRules" class="row">
    <div
      *ngFor="
        let config of getRequiredConfig(formConfigs, ['zipCode', 'country'])
      "
    >
      <div class="col-6" *ngIf="config.name == 'zipCode'">
        <fusion-form-text-field
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-text-field>
      </div>
      <div class="col-6" *ngIf="config.name == 'country'">
        <fusion-form-dropdown
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-dropdown>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #shortAddress>
  <div *ngIf="formConfigs && formValidationRules" class="row">
    <div
      *ngFor="let config of getRequiredConfig(formConfigs, ['city', 'country'])"
    >
      <div class="col-6" *ngIf="config.name == 'city'">
        <fusion-form-text-field
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-text-field>
      </div>
      <div class="col-6" *ngIf="config.name == 'country'">
        <fusion-form-dropdown
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-dropdown>
      </div>
    </div>
  </div>
</ng-template>
