import { NgModule } from '@angular/core';
import { SubscriptionListComponent } from './containers/subscription-list/subscription-list.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { FusionCommonModule } from '@fusion/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FusionPipeModule } from '@fusion/pipe';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { JsonLdModule } from 'ngx-seo';
import { reducers } from './store/reducers/index';
import { effects } from './store/effects/index';
import { guards } from './store/guards/index';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SubscriptionListComponent, SubscriptionComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FusionCommonModule,
    FusionPipeModule,
    JsonLdModule,
    MatIconModule,
    StoreModule.forFeature('fusionSubscription', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [SubscriptionListComponent],
  providers: [guards],
})
export class FusionSubscriptionModule {}
