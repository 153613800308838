<div class="metadata">
  <ng-container *ngFor="let data of metadata">
    <ng-container *ngIf="data.isSalary; else otherMetadata">
      <div
        chip
        [innerHTML]="
          (data.amount | currency: data.currencyCode) + ' / ' + data.label
        "
      ></div>
    </ng-container>

    <ng-template #otherMetadata>
      <div chip>{{ data.label }}</div>
    </ng-template>
  </ng-container>
</div>
