import { ISession } from '../../models/interfaces';
import { 
  AppSessionActionTypes,
  AppSessionActions
} from '../actions/app-session.actions';

export interface AppSessionState {
  session: ISession;
  loading: boolean;
  loaded: boolean;
}

export const initialState: AppSessionState = {
  session: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: AppSessionActions
): AppSessionState {
  switch (action.type) {
    case AppSessionActionTypes.StartAppSession: {
      return {
        ...state,
        loading: true
      };
    }
    case AppSessionActionTypes.StartAppSessionSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        session: action.session
      };
    }
    case AppSessionActionTypes.StartAppSessionFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
