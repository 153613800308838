import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';

export interface EmployerState {}

export const reducers: ActionReducerMap<EmployerState> = {};

export const metaReducers: MetaReducer<
  EmployerState
>[] = !environment.production ? [] : [];
