import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map,
} from 'rxjs/operators';

import {
  WalletActionTypes,
  LoadWalletSuccess,
  LoadWalletFail,
  SetupWallet,
  SetupWalletSuccess,
  SetupWalletFail,
  LoadWallet,
  AddBalance,
  AddBalanceSuccess,
  AddBalanceFail,
  MakePaymentWithCard,
  MakePaymentWithCardSuccess,
  MakePaymentWithCardFail,
  AuthorizePayment,
  AuthorizePaymentFail,
  AuthorizePaymentSuccess,
  RedeemCoupon,
  RedeemCouponSuccess,
  RedeemCouponFail,
} from '../actions/wallet.actions';
import { Store } from '@ngrx/store';
import { FusionPaymentState } from '../reducers';
import { PaymentService, MappingService, mappingType } from '@fusion/service';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';
import {
  IWallet,
  ISetupWalletPayload,
  IAddBalancePayload,
  ICustomer,
  IMakePyaymentWithCardPayload,
  ITransaction,
  ICouponBalance,
  IAuthorizePyaymentPayload,
} from '../../models/interfaces';
import { LoadCustomer } from '../actions/customer.actions';
import { getCustomer, getCurrentPayment } from '../selectors';
import {
  ApplicationId,
  getCurrentApp,
  IApplication,
} from '@fusion/subscription';
import { getoAuthUserId, getUserType } from '@fusion/oauth';
import { UserType } from '@fusion/common';
import { PaymentType } from '../../models/enums';
import { ConfirmFunding } from '../actions/funding.actions';

@Injectable()
export class WalletEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionPaymentState>,
    private paymentService: PaymentService,
    private mappingService: MappingService
  ) {}

  
  loadWallet$ = createEffect(() => this.actions$.pipe(
    ofType(WalletActionTypes.LoadWallet),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getoAuthUserId)
    ),
    mergeMap(([action, params, userId]: [any, Params, string]) => {
      const customerId = params.companyId || userId;
      return this.paymentService.getWallet(customerId).pipe(
        switchMap((dataResult) => {
          const mappedDataResult = this.mappingService.getMappedData<IWallet>(
            dataResult,
            mappingType.camelize
          );
          return [new LoadWalletSuccess(mappedDataResult)];
        })
      );
    }),
    catchError((error) => of(new LoadWalletFail()))
  ));

  
  setupWallet$ = createEffect(() => this.actions$.pipe(
    ofType<SetupWallet>(WalletActionTypes.SetupWallet),
    map((action) => action.payload),
    mergeMap((payload: ISetupWalletPayload) => {
      const wallet = {
        customer_id: payload.customerId,
        customer_type: payload.customerType,
      };
      return this.paymentService.postWallet(wallet).pipe(
        switchMap((dataResult) => {
          const mappedDataResult = this.mappingService.getMappedData<IWallet>(
            dataResult,
            mappingType.camelize
          );
          return [
            new SetupWalletSuccess(mappedDataResult),
            new LoadCustomer(),
            new LoadWallet(),
          ];
        })
      );
    }),
    catchError((error) => of(new SetupWalletFail()))
  ));

  
  makePaymentWithCard$ = createEffect(() => this.actions$.pipe(
    ofType<MakePaymentWithCard>(WalletActionTypes.MakePaymentWithCard),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getCustomer),
      this.store.select(getUserType)
    ),
    mergeMap(
      ([payload, customer, userType]: [
        IMakePyaymentWithCardPayload,
        ICustomer,
        UserType
      ]) => {
        const applicationId =
          userType == UserType.User
            ? ApplicationId.User
            : ApplicationId.Company;
        const payment = {
          customer_id: customer.customerId,
          application_id: applicationId,
          card_id: payload.cardId,
          amount: payload.amount,
        };
        return this.paymentService.postPaymentWithCard(payment).pipe(
          switchMap((dataResult) => {
            const mappedDataResult =
              this.mappingService.getMappedData<ITransaction>(
                dataResult,
                mappingType.camelize
              );
            const balance: IAddBalancePayload = {
              paymentId: mappedDataResult.id,
              amount: mappedDataResult.amount,
              description: payload.description
                ? payload.description
                : 'Load Wallet',
            };
            if (mappedDataResult.paid) {
              if (payload.type && payload.type == PaymentType.Funding) {
                return [
                  new MakePaymentWithCardSuccess(mappedDataResult),
                  new AddBalance(balance),
                  new ConfirmFunding(),
                ];
              }
              return [
                new MakePaymentWithCardSuccess(mappedDataResult),
                new AddBalance(balance),
              ];
            } else {
              return [new MakePaymentWithCardSuccess(mappedDataResult)];
            }
          })
        );
      }
    ),
    catchError((error) => of(new MakePaymentWithCardFail()))
  ));

  
  authorizePayment$ = createEffect(() => this.actions$.pipe(
    ofType<AuthorizePayment>(WalletActionTypes.AuthorizePayment),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCurrentPayment)),
    mergeMap(
      ([payload, payment]: [IAuthorizePyaymentPayload, ITransaction]) => {
        return this.paymentService.confirmPaymentWithCard(payment.id).pipe(
          switchMap((dataResult) => {
            const mappedDataResult =
              this.mappingService.getMappedData<ITransaction>(
                dataResult,
                mappingType.camelize
              );
            const balance: IAddBalancePayload = {
              paymentId: mappedDataResult.id,
              amount: mappedDataResult.amount,
              description: payload ? payload.description : 'Load Wallet',
            };
            if (mappedDataResult.paid) {
              if (payload.type == PaymentType.Funding) {
                return [
                  new AuthorizePaymentSuccess(mappedDataResult),
                  new AddBalance(balance),
                  new ConfirmFunding(),
                ];
              }
              return [
                new AuthorizePaymentSuccess(mappedDataResult),
                new AddBalance(balance),
              ];
            } else {
              return [
                new AuthorizePaymentFail(),
                new MakePaymentWithCardSuccess(mappedDataResult),
              ];
            }
          })
        );
      }
    ),
    catchError((error) => of(new AuthorizePaymentFail()))
  ));

  
  addBalance$ = createEffect(() => this.actions$.pipe(
    ofType<AddBalance>(WalletActionTypes.AddBalance),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getCustomer)),
    mergeMap(([payload, customer]: [IAddBalancePayload, ICustomer]) => {
      const balance = {
        payment_id: payload.paymentId,
        amount: payload.amount,
        description: payload.description,
      };
      const walletId = customer.customerId;
      return this.paymentService.addBalance(balance, walletId).pipe(
        switchMap((dataResult) => {
          return [
            new AddBalanceSuccess(),
            new LoadCustomer(),
            new LoadWallet(),
          ];
        })
      );
    }),
    catchError((error) => of(new AddBalanceFail()))
  ));

  
  redeemCoupon$ = createEffect(() => this.actions$.pipe(
    ofType<RedeemCoupon>(WalletActionTypes.RedeemCoupon),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getCustomer),
      this.store.select(getCurrentApp)
    ),
    mergeMap(([payload, customer, app]: [string, ICustomer, IApplication]) => {
      const couponMetadata = {
        provider_id: app.id,
        provider_type: 'application',
        description: 'Redeem Coupon',
      };
      const walletId = customer.customerId;
      return this.paymentService
        .redeemCoupon(couponMetadata, walletId, payload)
        .pipe(
          switchMap((dataResult) => {
            const mappedDataResult =
              this.mappingService.getMappedData<ICouponBalance>(
                dataResult,
                mappingType.camelize
              );
            return [
              new RedeemCouponSuccess(mappedDataResult),
              new LoadCustomer(),
              new LoadWallet(),
            ];
          })
        );
    }),
    catchError((error) => of(new RedeemCouponFail()))
  ));
}
