import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fusion-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  @Input() removable: boolean;
  @Input() keywords: string;
  @Input() resultType: string;
  @Input() searchCategory: any;
  @Output() notifyRemoveCategory: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
