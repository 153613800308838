import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers/index';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { effects } from './store/effects/index';
import { RouterModule } from '@angular/router';
import { TopNavComponent } from './containers/top-nav/top-nav.component';
import { FusionCommonModule } from '@fusion/common';
import { FusionFormModule } from '@fusion/form';
import { FusionPipeModule } from '@fusion/pipe';
import { guards } from './store/guards/index';
import { SideNavComponent } from './containers/side-nav/side-nav.component';
import { BodyComponent } from './containers/body/body.component';
import { SignInComponent } from './containers/sign-in/sign-in.component';
import { SignUpComponent } from './containers/sign-up/sign-up.component';
import { ResetComponent } from './containers/reset/reset.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { FusionErrorModule } from '@fusion/error';
import { ReferralsComponent } from './containers/referrals/referrals.component';
import { ReferralSignUpComponent } from './containers/referral-sign-up/referral-sign-up.component';
import { LayoutComponent } from './components/layout/layout.component';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';

const fbLoginOptions = {
  scope:
    'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
  return_scopes: true,
  enable_profile_selector: true,
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

const googleLoginOptions = {
  scope: 'profile, email',
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

@NgModule({
  declarations: [
    TopNavComponent,
    SideNavComponent,
    BodyComponent,
    SignInComponent,
    SignUpComponent,
    ResetComponent,
    ResetPasswordComponent,
    ReferralsComponent,
    ReferralSignUpComponent,
    LayoutComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FusionCommonModule,
    MatIconModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    FusionFormModule,
    FusionPipeModule,
    FusionErrorModule,
    SocialLoginModule,
    StoreModule.forFeature('fusionoAuth', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [
    TopNavComponent,
    SideNavComponent,
    BodyComponent,
    SignInComponent,
    SignUpComponent,
    ResetComponent,
    ResetPasswordComponent,
    ReferralsComponent,
    ReferralSignUpComponent,
  ],
  providers: [
    guards,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '433599542968-en9kbt2769jkrm1597caqscb9f4vsl79.apps.googleusercontent.com'
              // googleLoginOptions
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '1534662793661853'
              // fbLoginOptions
            ),
          },
        ],
        onError: (err) => {
          console.error('Shakil Error', err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
})
export class FusionoAuthModule {}
