<div *ngIf="invoice" class="row4">
  <div bold class="row4__section-one t-padding-6 b-padding-3 item-list col3">
    <div class="col3__section-one">Items</div>
    <div centering class="col3__section-two">Qty</div>
    <div centering class="col3__section-three">Price</div>
  </div>

  <div class="row4__section-two rowN">
    <div
      *ngFor="let item of invoice.items"
      topBorder
      cardVrSpacing
      class="item-list col3"
    >
      <div class="col3__section-one">
        <div extraSmallTitle semiBold>{{ item.name }}</div>
        <div nanoTitle>{{ item.description | truncate: 80 }}</div>
      </div>
      <div centering cardHrSpacing class="col3__section-two">
        {{ item.quantity }}
      </div>
      <div centering cardHrSpacing class="col3__section-three">
        ${{ item.price }}
      </div>
    </div>
  </div>

  <div topBorder cardVrSpacing class="row4__section-three rowN">
    <div *ngFor="let charge of invoice.charges" class="col2">
      <div class="col2__section-one">
        <span semiBold>{{ charge.title }}</span>
        <span nanoFont *ngIf="charge.subtitle"> ({{ charge.subtitle }})</span>
      </div>
      <div centering class="col2__section-two">
        <span *ngIf="charge.displaySymbol">{{ charge.displaySymbol }}</span>
        <span>${{ charge.displayAmount }}</span>
      </div>
    </div>
  </div>

  <div topBorder cardVrSpacing class="row4__section-four">
    <div exMediumTitle semiBold class="col2">
      <div class="col2__section-one"><div>Total</div></div>
      <div centering class="col2__section-two">
        ${{ invoice?.total.amount }}
      </div>
    </div>
  </div>
</div>
