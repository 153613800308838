import {
  CustomerActionTypes,
  CustomerActions,
} from '../actions/customer.actions';
import { ICustomer, IPaymentMethod } from '../../models/interfaces';

export interface CustomerState {
  data: ICustomer;
  currentPaymentMethod: IPaymentMethod;
  isInProgress: boolean;
  isSuccess: boolean;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CustomerState = {
  data: null,
  currentPaymentMethod: null,
  isInProgress: false,
  isSuccess: false,
  loading: false,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: CustomerActions
): CustomerState {
  switch (action.type) {
    case CustomerActionTypes.LoadCustomer: {
      return {
        ...state,
        loading: true
      };
    }
    case CustomerActionTypes.LoadCustomerSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case CustomerActionTypes.LoadCustomerFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case CustomerActionTypes.SetupCustomer: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case CustomerActionTypes.SetupCustomerSuccess: {
      return {
        ...state,
        isInProgress: false,
        isSuccess: true,
      };
    }
    case CustomerActionTypes.SetupCustomerFail: {
      return {
        ...state,
        isInProgress: false,
      };
    }
    case CustomerActionTypes.AddCardSuccess: {
      return {
        ...state,
        currentPaymentMethod: action.payload,
      };
    }
  }
  return state;
}
