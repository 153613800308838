import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers';
import { getRouterUrlBase } from '@fusion/router';
import {
  ICustomer,
  getCustomer,
  SetupCustomer,
  getCurrentFunding,
  AuthorizePayment,
  MakePaymentWithCard,
  ITransaction,
  getCurrentPayment,
  IMakePyaymentWithCardPayload,
  ResetCurrentPayment,
  LoadCurrentFundingOption,
  IFundingOption,
  LoadInProgressFunding,
  PaymentType,
  IAuthorizePyaymentPayload,
} from '@fusion/payment';

@Component({
  selector: 'user-funding-payment',
  templateUrl: './funding-payment.component.html',
  styleUrls: ['./funding-payment.component.scss'],
})
export class FundingPaymentComponent implements OnInit {
  baseUrl$: Observable<string>;
  customer$: Observable<ICustomer>;
  funding$: Observable<IFundingOption>;
  currentPayment$: Observable<ITransaction>;

  constructor(private store: Store<UserState>) {}

  ngOnInit() {
    this.store.dispatch(new LoadInProgressFunding());
    this.store.dispatch(new LoadCurrentFundingOption());
    this.store.dispatch(new ResetCurrentPayment());
    this.baseUrl$ = this.store.select(getRouterUrlBase);
    this.customer$ = this.store.select(getCustomer);
    this.funding$ = this.store.select(getCurrentFunding);
    this.currentPayment$ = this.store.select(getCurrentPayment);
  }

  setupCustomerProfile() {
    this.store.dispatch(new SetupCustomer());
  }

  onNotifyFundingContribution(balance) {
    const fundingBalance: IMakePyaymentWithCardPayload = {
      ...balance,
      type: PaymentType.Funding,
      description: 'Crowdfunding Reward',
    };
    this.store.dispatch(new MakePaymentWithCard(fundingBalance));
  }

  onNotifyFundingPaymentAuthorization() {
    const fundingBalance: IAuthorizePyaymentPayload = {
      type: PaymentType.Funding,
      description: 'Crowdfunding Reward',
    };
    this.store.dispatch(new AuthorizePayment(fundingBalance));
  }
}
