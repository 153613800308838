import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AutocompleteType, ContentType } from '../../models/enums';
import { IFormConfig } from '../../models/interfaces';

@Component({
  selector: 'fusion-form-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {
  @Input() autoCompleteEntities: any;
  @Input() autoCompleteType: AutocompleteType;
  @Input() entityType: string;
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();
  reset: boolean;
  AutocompleteType = AutocompleteType;
  ContentType = ContentType;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.formValidationRules.valueChanges.subscribe((formData: any) => {
      if (typeof formData[this.formConfigs.name] === 'string') {
        // emit event to get data
        this.notifyAutocompletion.emit(formData[this.formConfigs.name]);
        if (this.reset && this.formConfigs.autoFillItems) {
          this.formConfigs.autoFillItems.map((field) => {
            this.formValidationRules.controls[field].patchValue(null, {
              onlySelf: true,
            });
            this.formValidationRules.controls[field].enable({
              onlySelf: true,
            });
          });
          this.reset = false;
        }
      } else if (formData[this.formConfigs.name]) {
        const entity = formData[this.formConfigs.name];
        if (this.formConfigs.autoFillItems) {
          this.formConfigs.autoFillItems.map((field) => {
            if (entity && entity[field]) {
              this.formValidationRules.controls[field].patchValue(
                entity[field],
                {
                  onlySelf: true,
                }
              );
              this.formValidationRules.controls[field].disable({
                onlySelf: true,
              });
            }
          });
        }
        this.reset = true;
      }
    });
  }

  displayName(entity): string {
    if (entity && entity.name) {
      return entity.name;
    } else if (entity && entity.title) {
      return `${entity.title}`;
    } else if (entity && entity.firstName) {
      return `${entity.firstName} ${entity.lastName}`;
    }
  }

  onFormSubmit(entity: any) {
    // emit selected entity
  }
}
