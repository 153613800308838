import {
  PrivateSearchActionTypes,
  PrivateSearchActions,
} from '../actions/private-search.actions';
import { ISearch, SearchOrderType } from '../../models';
import { Action } from '@ngrx/store';

export interface PrivateSearchState {
  search: ISearch;
  followUpContentLoadAction: Action;
}

export const initialState: PrivateSearchState = {
  search: {
    active: false,
    order: SearchOrderType.DESC,
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      offset: 0,
    },
    hardQuery: null,
  },
  followUpContentLoadAction: null,
};

export function reducer(
  state = initialState,
  action: PrivateSearchActions
): PrivateSearchState {
  switch (action.type) {
    case PrivateSearchActionTypes.PrivateSearchStart: {
      return {
        ...state,
        followUpContentLoadAction: action.followUpAction,
      };
    }
    case PrivateSearchActionTypes.SetHardQuerySuccess: {
      return {
        ...state,
        search: {
          ...state.search,
          hardQuery: action.payload,
        },
      };
    }
    case PrivateSearchActionTypes.SetPrivateSearchPagination: {
      return {
        ...state,
        search: {
          ...state.search,
          pagination: action.payload,
        },
      };
    }
    case PrivateSearchActionTypes.SetPrivateSearchOrderBy: {
      return {
        ...state,
        search: {
          ...state.search,
          order: action.payload,
        },
      };
    }
  }
  return state;
}
