import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IFormConfig } from "../../models/interfaces";

@Component({
  selector: "fusion-form-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"]
})
export class DropdownComponent implements OnInit {
  @Output() notifySelectionChange = new EventEmitter<any>();
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}
}
