import { Action } from '@ngrx/store';
import { IError } from '@fusion/error';
import { FusionPaymentError } from '../../models/enums';
import { IPostPaymentPayload } from '../../models/interfaces';
import { IPayment } from '../../models/interfaces/payment.interfaces';

export enum PaymentActionTypes {
  NavigateToCheckout = '[Payment] Navigate to Checkout',
  PostPayment = '[Payment] Post',
  PostPaymentSuccess = '[Payment] Post Success',
  PostPaymentDeclined = '[Payment] Post Declined',
  PostPaymentFail = '[Payment] Post Fail',
  ResetPaymentMethod = '[Payment] Reset',
}

export class NavigateToCheckout implements Action {
  readonly type = PaymentActionTypes.NavigateToCheckout;

  constructor(public isRootUrl: boolean) {}
}

export class PostPayment implements Action {
  readonly type = PaymentActionTypes.PostPayment;

  constructor(
    public payload: IPostPaymentPayload,
    public followUpAction?: Action
  ) {}
}

export class PostPaymentSuccess implements Action {
  readonly type = PaymentActionTypes.PostPaymentSuccess;

  constructor(public payload: IPayment) {}
}

export class PostPaymentDeclined implements Action {
  readonly type = PaymentActionTypes.PostPaymentDeclined;

  constructor(public payload: IPayment) {}
}

export class PostPaymentFail implements Action {
  readonly type = PaymentActionTypes.PostPaymentFail;

  constructor(public payload: IError<FusionPaymentError>) {}
}

export class ResetPaymentMethod implements Action {
  readonly type = PaymentActionTypes.ResetPaymentMethod;
}

export type PaymentActions =
  | NavigateToCheckout
  | PostPayment
  | PostPaymentSuccess
  | PostPaymentDeclined
  | PostPaymentFail
  | ResetPaymentMethod;
