<ng-container *ngIf="isPreview; else screeningEditor">
  <div class="job-offer-screening col3">
    <!-- interviewer -->
    <div centering center class="col3__section-one">
      <div>
        <div centering center>
          <div centering center extraSmallLogo>
            <img
              imageFit
              src="{{ company?.logo || '/assets/images/job/job-banner-9.jpg' }}"
            />
            <div overlay class="l-margin-6 t-margin-3">
              <mat-icon color="accent" class="icon-display"
                >check_circle</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- meeting time -->
    <div centering center class="col3__section-two">
      <div primaryFontColor class="row2">
        <div>
          <div>
            <svg id="one" width="70" height="70" viewBox="0 0 100 100">
              <g id="copy-1" class="group">
                <g class="large">
                  <path
                    id="large"
                    d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
                    fill="red"
                  />
                </g>
                <g class="large-2" transform="rotate(45)">
                  <use xlink:href="#large" />
                </g>
                <g class="small">
                  <path
                    id="small"
                    d="M41.25,40 L42.5,25 L43.75,40 L45,41.25 L60,42.5 L45,43.75 L43.75,45 L42.5,60 L41.25,45 L40,43.75 L25,42.5 L40,41.25z"
                    fill="red"
                  />
                </g>
              </g>
            </svg>

            <svg id="two" width="40" height="40" viewBox="0 0 100 100">
              <use xlink:href="#copy-1" />
            </svg>

            <svg id="three" width="40" height="40" viewBox="0 0 100 100">
              <use xlink:href="#copy-1" />
            </svg>

            <svg id="four" width="40" height="40" viewBox="0 0 100 100">
              <use xlink:href="#copy-1" />
            </svg>

            <svg id="five" width="40" height="40" viewBox="0 0 100 100">
              <use xlink:href="#copy-1" />
            </svg>
          </div>
        </div>
        <div class="interview-time">
          <mat-icon color="primary">flare</mat-icon>
          <div nanoTitle semiBold>Joining Date</div>
          <div chip light primary>
            <div nanoFont normal>
              <span>{{
                screeningValues.startDatetime | date: 'EEEE, MMMM d'
              }}</span>
              <span dot></span>
              <span>{{ screeningValues.startDatetime | date: 'h:mm a' }}</span>
              <span> - </span>
              <span>{{ screeningValues.endDatetime | date: 'hh:mm a' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- applicant -->
    <div centering center class="col3__section-three">
      <div>
        <div centering center>
          <div centering center extraSmallProfileImage>
            <img
              imageFit
              src="{{
                applicant?.profilePicture ||
                  '/assets/images/job/job-banner-9.jpg'
              }}"
            />
            <div
              *ngIf="screeningValues.hasAccepted"
              overlay
              class="l-margin-6 t-margin-3"
            >
              <mat-icon color="accent" class="icon-display"
                >check_circle</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #screeningEditor>
  <div fullWidth>
    <fusion-form-template
      [isDialog]="false"
      [existingValues]="screeningValues"
      [formValidationRules]="videoInterviewValidationRules"
      [formConfigs]="videoInterviewConfigs"
      [formMetadata]="videoInterviewMetadata"
      (notifyFormSubmit)="notifyDescription($event)"
    ></fusion-form-template>
  </div>
</ng-template>
