<div section>
  <div sectionHeader>
    <div sectionTitle smallTitle microBold>
      Password
    </div>
    <!-- <div sectionSubtitle microTitle>
    </div> -->
  </div>
  <div sectionContent card cardSpacing>
    <fusion-form-template
      [isDialog]="false"
      [formValidationRules]="updatedValidationRules"
      [formConfigs]="passwordFormConfigs"
      [formMetadata]="passwordFormMetadata"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    ></fusion-form-template>
  </div>
</div>
