import { Action } from '@ngrx/store';
import { IError } from '@fusion/error/public-api';
import { FusionJobError } from '../../models/enums';
import { IJob } from '../../models/interfaces';

// TODO rename to JobActionTypes => remove `Add`
export enum AddJobActionTypes {
  AddJob = '[Add Job] Start',
  AddJobSuccess = '[Add Job] Success',
  AddJobFail = '[Add Job] Fail',
  UpdateJob = '[Update Job] Start',
  UpdateJobSuccess = '[Update Job] Success',
  UpdateJobFail = '[Update Job] Fail',
}

export class AddJob implements Action {
  readonly type = AddJobActionTypes.AddJob;

  constructor(public payload: any) {}
}

export class AddJobSuccess implements Action {
  readonly type = AddJobActionTypes.AddJobSuccess;

  constructor(public payload: any) {}
}

export class AddJobFail implements Action {
  readonly type = AddJobActionTypes.AddJobFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export class UpdateJob implements Action {
  readonly type = AddJobActionTypes.UpdateJob;

  constructor(public payload: any) {}
}

export class UpdateJobSuccess implements Action {
  readonly type = AddJobActionTypes.UpdateJobSuccess;

  constructor(public payload: any) {}
}

export class UpdateJobFail implements Action {
  readonly type = AddJobActionTypes.UpdateJobFail;

  constructor(public payload: IError<FusionJobError>) {}
}

export type AddJobActions =
  | AddJob
  | AddJobSuccess
  | AddJobFail
  | UpdateJob
  | UpdateJobSuccess
  | UpdateJobFail;
