import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ThemingService,
  ThemingServiceConfig,
} from './services/theming.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  exports: [],
  declarations: [],
  imports: [CommonModule],
})
export class FusionThemeModule {
  constructor(
    @Optional() @SkipSelf() parentModule: FusionThemeModule,
    themingService: ThemingService,
    public matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    if (parentModule) {
      throw new Error(
        'FusionThemeModule is already loaded. Import it in the AppModule only'
      );
    }
    themingService.applyTheme();

    matIconRegistry.addSvgIcon(
      'mosque',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/mosque.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'church',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/church.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'funeral',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/funeral.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'male',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/male.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'female',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/female.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'cat',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/cat.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'dog',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/dog.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'dishwasher',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/dishwasher.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'air-conditioner',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/air-conditioner.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'trash',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/trash.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'floor',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/floor.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'flower',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/flower.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'bed',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/bed.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'email-open',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/email-open.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'gmail',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/gmail.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'facebook',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/facebook.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'google-plus-box',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/google-plus-box.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'google',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/google.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'linkedin',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/linkedin.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'pinterest-box',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/pinterest-box.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'twitter',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/twitter.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'youtube',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/youtube.svg'
      )
    );
  }

  static forRoot(
    config?: ThemingServiceConfig
  ): ModuleWithProviders<FusionThemeModule> {
    return {
      ngModule: FusionThemeModule,
      providers: [{ provide: ThemingServiceConfig, useValue: config }],
    };
  }
}
