import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { IFunding } from "../../models/interfaces";

@Component({
  selector: "fusion-payment-funding-options",
  templateUrl: "./funding-options.component.html",
  styleUrls: ["./funding-options.component.scss"]
})
export class FundingOptionsComponent implements OnInit {
  @Output() notifyFundingSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyViewContributors: EventEmitter<any> = new EventEmitter<any>();

  @Input() fundingOptions: any;

  floatLabelControl = new UntypedFormControl("auto");
  firstFormGroup: UntypedFormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      amountCtrl: ["", Validators.required]
    });
  }
}
