<div *ngIf="transactions && transactions.length; else transactionsEmptyState">
  <div class="transactions">
    <table border radius mat-table [dataSource]="transactions">
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          <div g4FontColor>
            {{ element.createdAt | date: 'MMM d, y, h:mm a' }}
          </div>
        </td>
      </ng-container>

      <!-- Card Column -->
      <ng-container matColumnDef="card">
        <th mat-header-cell *matHeaderCellDef>Method</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span bold g4FontColor uppercase>{{ element.card.brand }}</span>
            <span> xxxx-{{ element?.card.last4 }} </span>
          </div>
        </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.amount >= 0">${{ element.amount }}</span>
          <span *ngIf="element.amount < 0">- ${{ element.amount * -1 }}</span>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <span
            radius
            exSmallFont
            class="success"
            [ngClass]="{ fail: !element.paid }"
            >{{ element.paid ? 'completed' : 'failed' }}</span
          >
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <!-- Invoice Column -->
      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef>Invoice</th>
        <td mat-cell *matCellDef="let element">
          <a (click)="openDialog(element.invoice)">Invoice</a>
        </td>
      </ng-container>

      <!-- Receipt Column -->
      <ng-container matColumnDef="receipt">
        <th mat-header-cell *matHeaderCellDef>Receipt</th>
        <td mat-cell *matCellDef="let element">
          <a target="_blank" href="{{ element.receiptUrl }}">Receipt</a>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef>Listing</th>
        <td mat-cell *matCellDef="let element">
          <a routerLink="{{ element.reference }}">View Listing</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>
  </div>
</div>

<ng-template #transactionsEmptyState>
  <div border radius centeringText cardSpacing class="row2">
    <div mediumTitle semiBold class="row2__section-one">No Transaction!</div>
    <div exSmallTitle g3FontColor class="row2__section-two">
      Add properties / Load Wallet to view transactions here!
    </div>
  </div>
</ng-template>
