import { JobsActionTypes, JobsActions } from '../actions/jobs.actions';
import { IJob } from '../../models/interfaces/job.interfaces';

export interface JobsState {
  entities: IJob[];
  count: number;
  loading: boolean;
  loaded: boolean;
}

export const initialState: JobsState = {
  entities: [],
  count: 0,
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: JobsActions): JobsState {
  switch (action.type) {
    case JobsActionTypes.LoadJobs: {
      return {
        ...state,
        loading: true,
      };
    }
    case JobsActionTypes.LoadJobsSuccess:
    case JobsActionTypes.LoadPrivateJobsSuccess: {
      return {
        ...state,
        entities: action.payload,
        loading: false,
        loaded: true,
      };
    }
    case JobsActionTypes.LoadJobsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case JobsActionTypes.SetJobsCount: {
      return {
        ...state,
        count: action.payload,
      };
    }
  }
  return state;
}
