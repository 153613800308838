import { NavigationExtras } from '@angular/router';
import { IError } from '@fusion/error';
import { Action } from '@ngrx/store';
import { FusionoAuthError } from '../../models/enums';
import { IoAuthUser, ISession } from '../../models/interfaces';

export enum UserSessionActionTypes {
  StartUserSession = '[User Session] Start',
  StartUserSessionSuccess = '[User Session] Start Success',
  StartUserSessionFail = '[User Session] Start Fail',
  RestoreUserSession = '[User Session] Restore',
  RestoreUserSessionSuccess = '[User Session] Restore Success',
  RestoreUserSessionFail = '[User Session] Restore Fail',
  RefreshUserSession = '[User Session] Refresh',
  RefreshUserSessionSuccess = '[User Session] Refresh Success',
  RefreshUserSessionFail = '[User Session] Refresh Fail',
  DestroyUserSession = '[User Session] Destroy',
  DestroyUserSessionSuccess = '[User Session] Destroy Success',
  DestroyUserSessionFail = '[User Session] Destroy Fail',
  DecodeUserSessionToken = '[User Session] Decode Token',
  DecodeUserSessionTokenSuccess = '[User Session] Decode Token Success',
  DecodeUserSessionTokenFail = '[User Session] Decode Token Fail',
  RedirectToHomeApp = '[User Session] Redirect To Home App',
  RedirectToUserProfileApp = '[User Session] Redirect To User Profile App',
}

export class StartUserSession implements Action {
  readonly type = UserSessionActionTypes.StartUserSession;

  constructor(public session?: ISession) {}
}

export class StartUserSessionSuccess implements Action {
  readonly type = UserSessionActionTypes.StartUserSessionSuccess;

  constructor(public session: ISession) {}
}

export class StartUserSessionFail implements Action {
  readonly type = UserSessionActionTypes.StartUserSessionFail;
}

export class RestoreUserSession implements Action {
  readonly type = UserSessionActionTypes.RestoreUserSession;
}

export class RestoreUserSessionSuccess implements Action {
  readonly type = UserSessionActionTypes.RestoreUserSessionSuccess;

  constructor(public payload: ISession) {}
}

export class RestoreUserSessionFail implements Action {
  readonly type = UserSessionActionTypes.RestoreUserSessionFail;

  constructor(public payload?: IError<FusionoAuthError>) {}
}

export class RefreshUserSession implements Action {
  readonly type = UserSessionActionTypes.RefreshUserSession;

  constructor(public session?: ISession) {}
}

export class RefreshUserSessionSuccess implements Action {
  readonly type = UserSessionActionTypes.RefreshUserSessionSuccess;

  constructor(public session: ISession) {}
}

export class RefreshUserSessionFail implements Action {
  readonly type = UserSessionActionTypes.RefreshUserSessionFail;
}

export class DestroyUserSession implements Action {
  readonly type = UserSessionActionTypes.DestroyUserSession;
}

export class DestroyUserSessionSuccess implements Action {
  readonly type = UserSessionActionTypes.DestroyUserSessionSuccess;
}

export class DestroyUserSessionFail implements Action {
  readonly type = UserSessionActionTypes.DestroyUserSessionFail;
}

export class DecodeUserSessionToken implements Action {
  readonly type = UserSessionActionTypes.DecodeUserSessionToken;

  constructor(public session: ISession, public followUpAction?: Action) {}
}

export class DecodeUserSessionTokenSuccess implements Action {
  readonly type = UserSessionActionTypes.DecodeUserSessionTokenSuccess;

  constructor(public payload: IoAuthUser) {}
}

export class DecodeUserSessionTokenFail implements Action {
  readonly type = UserSessionActionTypes.DecodeUserSessionTokenFail;

  constructor(public payload?: IError<FusionoAuthError>) {}
}

export class RedirectToHomeApp implements Action {
  readonly type = UserSessionActionTypes.RedirectToHomeApp;
}

export class RedirectToUserProfileApp implements Action {
  readonly type = UserSessionActionTypes.RedirectToUserProfileApp;

  constructor(public payload?: NavigationExtras) {}
}

export type UserSessionActions =
  | StartUserSession
  | StartUserSessionSuccess
  | StartUserSessionFail
  | RestoreUserSession
  | RestoreUserSessionSuccess
  | RestoreUserSessionFail
  | RefreshUserSession
  | RefreshUserSessionSuccess
  | RefreshUserSessionFail
  | DestroyUserSession
  | DestroyUserSessionSuccess
  | DestroyUserSessionFail
  | DecodeUserSessionToken
  | DecodeUserSessionTokenSuccess
  | DecodeUserSessionTokenFail
  | RedirectToHomeApp
  | RedirectToUserProfileApp;
