import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  Input,
} from '@angular/core';
import { ICompany, IUser } from '@fusion/common';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FusionoAuthState } from '../../store/reducers/index';
import { Store } from '@ngrx/store';
import {
  oAuthStart,
  SignUpStart,
  RedirectToHomeApp,
  DestroyUserSession,
  RedirectSubscriber,
} from '../../store/actions/index';
import {
  getoAuthSessionToken,
  getoAuthUserFullName,
  getoAuthUserProfilePath,
  getCompanyAppBaseUrl,
  getHomeAppBaseUrl,
  getoAuthUser,
  getAppBaseUrls,
  getoAuthTopNavigations,
  getoAuthUserTopNavigation,
} from '../../store/selectors/index';
import { Observable } from 'rxjs';
import {
  ModalIconType,
  setFormValidationRules,
  IFormMetadata,
  IFormConfig,
} from '@fusion/form';
import { SignInForm, SignUpForm } from '../../models/forms';
import { NavService } from '../../services/nav/nav.service';
import {
  IAppSettings,
  IBaseUrls,
  ITopNav,
  IRedirectSubscriberPayload,
  ISubscriptionNav,
} from '../../models/interfaces';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fusion-oauth-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavComponent implements OnInit {
  @Input() appSettings: IAppSettings;

  token$: Observable<string>;
  oAuthUser$: Observable<IUser>;
  companyAppBaseUrl$: Observable<string>;
  appBaseUrls$: Observable<IBaseUrls>;
  homeAppBaseUrl$: Observable<string>;
  oAuthUserName$: Observable<string>;
  authUserProfilePath$: Observable<string>;
  oAuthUserTopNavigation$: Observable<ITopNav>;
  oAuthTopNavigations$: Observable<ITopNav[]>;

  baseUrl: string;
  ModalIconType = ModalIconType;

  // form templates
  signInFormMetadata: IFormMetadata = SignInForm.metadata;
  signInFormConfigs: IFormConfig[] = SignInForm.configs;
  signInFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(SignInForm.configs)
  );

  signUpFormMetadata: IFormMetadata = SignUpForm.metadata;
  signUpFormConfigs: IFormConfig[] = SignUpForm.configs;
  signUpFormValidationRules: UntypedFormGroup = this.fb.group(
    setFormValidationRules(SignUpForm.configs)
  );

  constructor(
    private store: Store<FusionoAuthState>,
    private fb: UntypedFormBuilder,
    private navService: NavService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit() {
    this.token$ = this.store.select(getoAuthSessionToken);
    this.oAuthUserName$ = this.store.select(getoAuthUserFullName);
    this.oAuthUser$ = this.store.select(getoAuthUser);
    this.authUserProfilePath$ = this.store.select(getoAuthUserProfilePath);
    this.oAuthUserTopNavigation$ = this.store.select(getoAuthUserTopNavigation);
    this.oAuthTopNavigations$ = this.store.select(getoAuthTopNavigations);
    this.companyAppBaseUrl$ = this.store.select(getCompanyAppBaseUrl);
    this.appBaseUrls$ = this.store.select(getAppBaseUrls);
    this.homeAppBaseUrl$ = this.store.select(getHomeAppBaseUrl);
  }

  toggleNav() {
    this.navService.toggleNav();
    this.navService.toggleNavResponsive();
  }

  notifySignInFormSubmit(formData) {
    this.store.dispatch(new oAuthStart(formData.data));
  }

  notifySignUpFormSubmit(formData) {
    this.store.dispatch(new SignUpStart(formData.data));
  }

  signOut() {
    this.store.dispatch(new DestroyUserSession());
  }

  onClickOfBrand() {
    this.store.dispatch(new RedirectToHomeApp());
  }

  navigateToUserProfile(baseUrl: string) {
    this._document.defaultView.location.href = `${baseUrl}`;
  }

  navigateToCompany(appBaseUrls: IBaseUrls, companyNav: ITopNav) {
    if (companyNav.category === 'Mosque') {
      this._document.defaultView.location.href = `${appBaseUrls.mosque}/${companyNav.subscriberId}`;
    } else {
      this._document.defaultView.location.href = `${appBaseUrls.company}/${companyNav.subscriberId}`;
    }
  }

  navigateToSubscription(subscription: ISubscriptionNav) {
    const payload: IRedirectSubscriberPayload = {
      domain: subscription.domain,
      subscriberId: subscription.subscriberId,
    };
    this.store.dispatch(new RedirectSubscriber(payload));
  }
}
