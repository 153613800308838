import { ModuleWithProviders, NgModule } from '@angular/core';
import { FusionCommonModule } from '@fusion/common';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import * as configLocal from '../assets/config_local';
import * as configDev from '../assets/config_dev';
import * as configQa from '../assets/config_qa';
import * as configProd from '../assets/config_prod';
import { CookieService as ServerCookieService, CookieModule } from 'ngx-cookie';

@NgModule({
  declarations: [],
  imports: [FusionCommonModule, CookieModule, CookieModule.forRoot()],
  exports: [],
  providers: [NgxCookieService, ServerCookieService],
})
export class FusionServiceModule {
  static forRoot(
    appEnvironment: any
  ): ModuleWithProviders<FusionServiceModule> {
    return {
      ngModule: FusionServiceModule,
      providers: [
        {
          provide: 'appConfig', // you can also use InjectionToken
          useValue: appEnvironment.production
            ? configProd.fusionConfig
            : appEnvironment.qa
            ? configQa.fusionConfig
            : appEnvironment.dev
            ? configDev.fusionConfig
            : configLocal.fusionConfig,
        },
      ],
    };
  }
}
