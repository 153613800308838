import { DESCRIPTION_FORM } from '../constants';
import { IForm, FormFieldType, NonFormItemType } from '@fusion/form';
import { applicationStatus, jobTypes } from '../data';

export const ManageApplicationForm: IForm = {
  metadata: {
    title: 'Description',
    submitButtonText: 'Update',
    style: {
      topMargin: '0px',
      maxWidth: '800px',
      maxHeight: '400px',
    },
  },
  configs: [
    {
      type: FormFieldType.NonFormItem,
      nonFormItem: {
        label: `You must update application status and detail to enable interview setup or move to next screening round.`,
        type: NonFormItemType.Alert,
      },
    },
    {
      label: 'Status',
      name: 'status',
      type: FormFieldType.Dropdown,
      placeholder: 'project description',
      options: applicationStatus,
      validation: {
        required: true,
        max: 15000,
      },
    },
    {
      label: 'Status Detail',
      name: 'decision',
      type: FormFieldType.Ckeditor,
      placeholder: 'project description',
      validation: {
        required: true,
        max: 15000,
      },
    },
  ],
};
