import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { FusionoAuthState } from '../reducers';
import { getAppSessionToken } from '../selectors';
import { filter, map, take, tap } from 'rxjs/operators';
import { LoadApplications, StartAppSession } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class AppSessionGuard implements CanActivateChild {
  constructor(private store: Store<FusionoAuthState>) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(getAppSessionToken).pipe(
      tap((token) => {
        const sb = next;
        if (!token) {
          this.store.dispatch(new LoadApplications());
          this.store.dispatch(new StartAppSession());
        }
      }),
      take(2),
      filter((token) => token !== null),
      map(() => true)
    );
  }
}
