import { Action } from '@ngrx/store';
import { ISearchPagination } from '@fusion/common';
import { SearchOrderType } from '../../models';

export enum PrivateSearchActionTypes {
  PrivateSearchStart = '[Private Search] Start',
  PrivateSearchSuccess = '[Private Search] Success',
  PrivateSearchFail = '[Private Search] Fail',
  SetHardQuery = '[Private Search] Set Hard Query',
  SetHardQuerySuccess = '[Private Search] Set Hard Query Success',
  SetHardQueryFail = '[Private Search] Set Hard Query Fail',
  SetPrivateSearchPagination = '[Private Search] Set Pagination',
  SetPrivateSearchOrderBy = '[Private Search] Set Order',
  LoadPrivateSearchContents = '[Private Search] Load Contents',
  LoadPrivateSearchContentsSuccess = '[Private Search] Load Contents Success',
  LoadPrivateSearchContentsFail = '[Private Search] Load Contents Fail',
}

export class PrivateSearchStart implements Action {
  readonly type = PrivateSearchActionTypes.PrivateSearchStart;

  constructor(public followUpAction: Action, public hardQueryRef?: string) {}
}

export class PrivateSearchSuccess implements Action {
  readonly type = PrivateSearchActionTypes.PrivateSearchSuccess;

  constructor(public payload: any) {}
}

export class PrivateSearchFail implements Action {
  readonly type = PrivateSearchActionTypes.PrivateSearchFail;
}

export class SetHardQuery implements Action {
  readonly type = PrivateSearchActionTypes.SetHardQuery;

  constructor(public hardQueryRef?: string) {}
}

export class SetHardQuerySuccess implements Action {
  readonly type = PrivateSearchActionTypes.SetHardQuerySuccess;

  constructor(public payload: any) {}
}

export class SetHardQueryFail implements Action {
  readonly type = PrivateSearchActionTypes.SetHardQueryFail;
}

export class SetPrivateSearchPagination implements Action {
  readonly type = PrivateSearchActionTypes.SetPrivateSearchPagination;

  constructor(public payload: ISearchPagination) {}
}

export class SetPrivateSearchOrderBy implements Action {
  readonly type = PrivateSearchActionTypes.SetPrivateSearchOrderBy;

  constructor(public payload: SearchOrderType) {}
}

export class LoadPrivateSearchContents implements Action {
  readonly type = PrivateSearchActionTypes.LoadPrivateSearchContents;
}
export class LoadPrivateSearchContentsSuccess implements Action {
  readonly type = PrivateSearchActionTypes.LoadPrivateSearchContentsSuccess;
}

export class LoadPrivateSearchContentsFail implements Action {
  readonly type = PrivateSearchActionTypes.LoadPrivateSearchContentsFail;
}

export type PrivateSearchActions =
  | PrivateSearchStart
  | PrivateSearchSuccess
  | PrivateSearchFail
  | SetHardQuery
  | SetHardQuerySuccess
  | SetHardQueryFail
  | SetPrivateSearchPagination
  | SetPrivateSearchOrderBy
  | LoadPrivateSearchContents
  | LoadPrivateSearchContentsSuccess
  | LoadPrivateSearchContentsFail;
